import React, { useState } from 'react';
import ContainerLayout from '../../layout/container.layout';
import { Box, ButtonBase, CircularProgress, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { BoxData, InventoryData, ItemData } from '../../const/form.data';
import { ImgProps, Img } from 'react-image';
import { BoxItem } from './box.item';
import { FormIdKey } from '../../types/select.types';
import * as uuid from 'uuid';
import { FormSchema } from '../categoryForm';
const BoxPage = () => {
    const navigation = useNavigate();
    const [boxData, setBoxData] = useState(BoxData);
    const initCreateData = () => {
        return {
            id: uuid.v4(),
            type: '12',
            inventory: { ...InventoryData },
            values: { ...FormSchema },
            item: [...ItemData],
            inventOther: '',
            createdAt: new Date()
        };
    };

    const boxDataAmountCalc = () => {
        return boxData.reduce((prev, cur) => {
            return prev + cur.qty;
        }, 0);
    };

    console.log('boxDataAmountCalc', boxDataAmountCalc());

    const combineLocalData = async () => {
        if (boxDataAmountCalc() === 0) {
            return;
        }
        const data = initCreateData();
        const formikData = { ...data, boxData };
        localStorage.setItem(formikData.id, JSON.stringify(formikData));
        navigation(`/informationBox/${formikData.id}`);
    };

    const handlerQtyChange = (type: 'up' | 'down', i) => {
        if (type === 'up') {
            const temp = [...boxData];
            const tempRow = temp[i];
            const addRow = { ...tempRow, qty: tempRow.qty + 1 };
            temp[i] = addRow;
            setBoxData([...temp]);
        } else {
            const temp = [...boxData];
            const tempRow = temp[i];
            if (tempRow.qty > 0) {
                const addRow = { ...tempRow, qty: tempRow.qty - 1 };
                temp[i] = addRow;
                setBoxData([...temp]);
            }
        }
    };

    const totalAmountCalculator = () => {
        let total = 0;
        for (const row of boxData) {
            const add = row.qty * row.amount;
            total += add;
        }
        return total;
    };

    return (
        <ContainerLayout>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: { md: '80px 15px', xs: '80px 15px' }
                }}
            >
                <Typography variant={'h1'} marginBottom={'8px'}>
                    Box & supplies
                </Typography>
                {/*<Typography variant={'h5'}>Please select the service type that you wish to receive quotes for</Typography>*/}
                <Box width={'100%'} sx={{ padding: { md: '50px 80px', xs: '50px 10px' }, maxWidth: '1100px' }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 4, md: 12, lg: 12 }}>
                        {boxData.map((v, index) => {
                            return <BoxItem data={v} index={index} handlerQtyChange={handlerQtyChange} />;
                        })}
                    </Grid>
                    <Box
                        sx={{
                            marginTop: '20px',
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: { xs: '170px', md: '170px' },
                            boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                            '&:hover': {
                                boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                            },
                            transition: 'box-shadow 0.25s',
                            transitionTimingFunction: 'ease',
                            borderRadius: '8px',
                            padding: '10px'
                        }}
                    >
                        <Typography variant={'h3'} marginBottom={'30px'}>
                            Box order
                        </Typography>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 4, md: 12, lg: 12 }}>
                            <Grid item xs={12}>
                                <Typography variant={'h5'} fontWeight={'800'}>
                                    Shipping Costs
                                </Typography>
                                <Typography variant={'h5'} sx={{ marginTop: '10px' }}>
                                    If your order is over $100 and your delivery is within the Brisbane metro area, your delivery will be
                                    free. For metro orders below $100, shipping is $40 plus gst. For all other locations, freight will be
                                    calculated on a per-order basis. We offer very cost-effective delivery.
                                </Typography>
                                <Typography variant={'h5'} fontWeight={'800'} sx={{ marginTop: '10px' }}>
                                    Important:
                                </Typography>
                                <Typography variant={'h5'} sx={{ marginTop: '10px' }}>
                                    When you place your order we will confirm the freight cost with you before processing and payment.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                {boxData
                                    .filter((v) => v.qty > 0)
                                    .map((v, i) => (
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '8px' }}>
                                            <Typography variant={'h5'}>{`${v.title} x ${v.qty}`}</Typography>
                                            <Typography variant={'h5'} marginBottom={'8px'}>
                                                {`$ ${v.qty * v.amount}`}
                                            </Typography>
                                        </Box>
                                    ))}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '40px' }}>
                                    <Typography variant={'h5'}>Total</Typography>
                                    <Typography variant={'h4'} marginBottom={'8px'}>
                                        {`$ ${totalAmountCalculator()}`}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: '8px' }}>
                                    <ButtonBase
                                        disabled={boxDataAmountCalc() === 0}
                                        sx={{
                                            borderRadius: '8px',
                                            backgroundColor: '#428DFF',
                                            minWidth: '200px',
                                            padding: '10px 20px',
                                            cursor: 'pointer',
                                            '&:disabled': { backgroundColor: '#ACACAC', cursor: 'not-allowed' }
                                        }}
                                        onClick={() => combineLocalData()}
                                    >
                                        <Typography sx={{ color: 'white', fontSize: { xs: '16px', md: '22px' } }}>Continue</Typography>
                                    </ButtonBase>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ContainerLayout>
    );
};

export default BoxPage;
