export enum SelectEnum {
    Local = '1',
    InterState = '2',
    Office = '3',
    Single = '4',
    Few = '5',
    Furniture = '6',
    Piano = '7',
    Pool = '8',
    Ebay = '9',
    Fragile = '10',
    Removal = '11',
    Box = '12'
}

export interface SelectType {
    type: SelectEnum[];
}

export type SelectTypeParamsType = {
    type: SelectEnum;
};

export type FormIdKey = {
    key: string;
};
