import React, { Dispatch, SetStateAction, useState } from 'react';
import { InventoryDataTypes, ItemDataTypes } from '../../const/form.data';
import { SelectEnum } from '../../types/select.types';
import { InventoryForm } from './inventory.form';
import { Box, ButtonBase, CircularProgress, Typography } from '@mui/material';
import { AddressForm } from './address.form';
import { FormikFormProps, FormikProps, FormikProvider, useFormikContext } from 'formik';
import { WhenForm } from './when.form';
import { AdditionalForm } from './additional.form';
import { LevelForm } from './level.form';
import { OtherForm } from './other.form';
import { ItemForm } from './item.form';
import { LoadingButton } from '@mui/lab';
interface RootFormProps {
    formik: any;
    inventData: InventoryDataTypes;
    setInventData: Dispatch<SetStateAction<InventoryDataTypes>>;
    type: SelectEnum;
    setInventOther: Dispatch<SetStateAction<string>>;
    inventOther: string;
    submit: boolean;
    allSubmit: () => void;
    itemError: Object;
    items: ItemDataTypes;
    setItem: Dispatch<SetStateAction<ItemDataTypes>>;
    setItemError: Dispatch<SetStateAction<any>>;
    pickError: Object;
    setPickError: Dispatch<SetStateAction<any>>;
    dropError: Object;
    setDropError: Dispatch<SetStateAction<any>>;
}

export const RootForm = ({
    formik,
    items,
    setItemError,
    setItem,
    inventData,
    setInventData,
    type,
    inventOther,
    setInventOther,
    submit,
    allSubmit,
    itemError,
    pickError,
    setPickError,
    dropError,
    setDropError
}: RootFormProps) => {
    const d = useFormikContext();
    const withErros = () => {
        const rootErros = Object.values(d?.errors ?? {}).length;
        const itmeErros = Object.values(itemError ?? {}).length;
        return rootErros > 0 || itmeErros > 0;
    };

    const typeForm = () => {
        switch (type) {
            case SelectEnum.Local:
            case SelectEnum.InterState:
            case SelectEnum.Office:
            case SelectEnum.Single:
                return (
                    <>
                        <InventoryForm
                            type={type}
                            formik={formik}
                            data={inventData}
                            setData={setInventData}
                            inventOther={inventOther}
                            setInventOther={setInventOther}
                        />
                        <Box height={'60px'} />
                        <AddressForm
                            submit={submit}
                            setError={setPickError}
                            addressType={1}
                            indexNumber={2}
                            title={'Pick-up Details'}
                            formikData={['pickAddress', 'pickPostCode', 'pickType', 'pickRooms', 'pickEtc', 'pickOk', 'pickLat', 'pickLng']}
                        />
                        <Box height={'60px'} />
                        <AddressForm
                            submit={submit}
                            setError={setDropError}
                            addressType={2}
                            indexNumber={3}
                            title={'Drop-off Details'}
                            formikData={['dropAddress', 'dropPostCode', 'dropType', 'dropRooms', 'dropEtc', 'dropOk', 'dropLat', 'dropLng']}
                        />
                        <Box height={'60px'} />
                        <WhenForm indexNumber={4} />
                        <Box height={'60px'} />
                        <AdditionalForm indexNumber={5} />
                        <Box height={'60px'} />
                        <OtherForm indexNumber={6} submit={submit} />
                        <Box height={'30px'} />
                    </>
                );
            case SelectEnum.Few:
            case SelectEnum.Furniture:
            case SelectEnum.Piano:
            case SelectEnum.Pool:
            case SelectEnum.Ebay:
            case SelectEnum.Fragile:
                return (
                    <>
                        <ItemForm submit={submit} items={items} setItem={setItem} setItemError={setItemError} />
                        <Box height={'60px'} />
                        <AddressForm
                            submit={submit}
                            setError={setPickError}
                            addressType={1}
                            indexNumber={2}
                            title={'Pick-up Details'}
                            formikData={['pickAddress', 'pickPostCode', 'pickType', 'pickRooms', 'pickEtc', 'pickOk', 'pickLat', 'pickLng']}
                        />
                        <Box height={'60px'} />
                        <AddressForm
                            submit={submit}
                            setError={setDropError}
                            addressType={2}
                            indexNumber={3}
                            title={'Drop-off Details'}
                            formikData={['dropAddress', 'dropPostCode', 'dropType', 'dropRooms', 'dropEtc', 'dropOk', 'dropLat', 'dropLng']}
                        />
                        <Box height={'60px'} />
                        <WhenForm indexNumber={4} />
                        <Box height={'60px'} />
                        <OtherForm indexNumber={5} submit={submit} />
                        <Box height={'30px'} />
                    </>
                );
            case SelectEnum.Removal:
                return (
                    <>
                        <AddressForm
                            submit={submit}
                            setError={setPickError}
                            addressType={1}
                            indexNumber={1}
                            title={'Pick-up Details'}
                            formikData={['pickAddress', 'pickPostCode', 'pickType', 'pickRooms', 'pickEtc', 'pickOk', 'pickLat', 'pickLng']}
                        />
                        <Box height={'60px'} />
                        <AddressForm
                            submit={submit}
                            setError={setDropError}
                            addressType={2}
                            indexNumber={2}
                            title={'Drop-off Details'}
                            formikData={['dropAddress', 'dropPostCode', 'dropType', 'dropRooms', 'dropEtc', 'dropOk', 'dropLat', 'dropLng']}
                        />
                        <Box height={'60px'} />
                        <OtherForm indexNumber={3} submit={submit} />
                        <Box height={'30px'} />
                    </>
                );
            default:
                return <div>{'Error'}</div>;
        }
    };

    return (
        <Box width={'100%'} sx={{ padding: { md: '50px 80px 0px 80px', xs: '20px 10px' }, maxWidth: '900px' }}>
            {typeForm()}

            <Box>
                <ButtonBase
                    // disabled={withErros()}
                    sx={{ borderRadius: '8px', backgroundColor: '#428DFF', minWidth: '200px', padding: '10px 20px' }}
                    onClick={() => allSubmit()}
                >
                    {submit ? (
                        <Typography sx={{ color: 'white', fontSize: { xs: '16px', md: '22px' } }}>
                            {' '}
                            <CircularProgress color="inherit" size={16} />
                        </Typography>
                    ) : (
                        <Typography sx={{ color: 'white', fontSize: { xs: '16px', md: '22px' } }}>Continue</Typography>
                    )}
                </ButtonBase>
            </Box>
        </Box>
    );
};
