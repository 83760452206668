import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';

import { IconFileExport } from '@tabler/icons';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { InventoryData } from '../../const/form.data';

const TempDownload = () => {
    const handleConvertFileAndDownLoad2 = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.columns = [
            { header: 'Main', key: '1', width: 10 },
            { header: 'Sub', key: '1', width: 10 },
            { header: 'Cubic Volume', key: '1', width: 10 }
        ];

        const data = [...groupRow()];
        for (const row of data) {
            worksheet.addRow(row);
        }

        worksheet.columns.forEach((column) => {
            column.alignment = { vertical: 'middle', horizontal: 'center' };
            column.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });
        worksheet.eachRow((row) => {
            row.height = 18;
        });

        worksheet.columns.forEach((column) => {
            let dataMax = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell?.value?.toString()?.length ?? 0;
                if (columnLength > dataMax) {
                    dataMax = columnLength;
                }
            });
            column.width = dataMax < 10 ? 10 : dataMax;
        });

        const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], mimeType);
        const fileName = format(new Date(), 'yyyyMMddHHmmss');
        saveAs(blob, `Cubic.xlsx`);
    };

    const Descriptionheader = () => ['Main', 'Sub', 'Insert'];
    //  TODO : unit

    const convertSpecificLine = (ws, wsrow: string, changeData: any) => {
        const objectKeyArray = Object.keys(ws);
        for (const row of objectKeyArray) {
            const regex = /[^0-9]/gi;
            const a = row.replace(regex, '');
            if (wsrow === a) {
                if (typeof ws[row] === 'object') {
                    ws[row].s = changeData;
                }
            }
        }
        return ws;
    };
    const convertAllStyle = (ws, changeData: any) => {
        const objectKeyArray = Object.keys(ws);
        console.log('WS', ws);
        for (const row of objectKeyArray) {
            if (typeof ws[row] === 'object') {
                ws[row].s = { ...(ws[row]?.s ?? {}), ...changeData };
            }
        }
        return ws;
    };

    const convertSpecificColulmn = (ws, wsrow: string, changeData: any) => {
        const objectKeyArray = Object.keys(ws);
        for (const row of objectKeyArray) {
            const regex = /[0-9]/g;
            const a = row.replace(regex, '');
            if (wsrow === a) {
                if (typeof ws[row] === 'object') {
                    ws[row].s = changeData;
                }
            }
        }
        return ws;
    };

    const groupRow = () => {
        const formList = Object.entries(InventoryData);
        console.log(formList);
        if (formList.length === 0) {
            return [];
        }
        const result = [];
        // @ts-ignore
        for (const [key, value] of formList) {
            for (const row of value.items) {
                const addRow = [`${key}`, `${row.title}`, ` `];
                result.push(addRow);
            }
        }
        return result;
    };

    return (
        <Box width={'300px'} height={'300px'}>
            <Button
                sx={{ marginRight: '10px' }}
                size="small"
                startIcon={<IconFileExport />}
                onClick={() => handleConvertFileAndDownLoad2()}
            >
                <Typography variant="h4">Export Xlsx</Typography>
            </Button>
        </Box>
    );
};

export default TempDownload;
