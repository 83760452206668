import React from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormSchema } from './index';
interface Props {
    indexNumber: number;
}
export const AdditionalForm = ({ indexNumber }: Props) => {
    const { values, setFieldValue } = useFormikContext<typeof FormSchema>();
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                '&:hover': {
                    boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                },
                transition: 'box-shadow 0.25s',
                transitionTimingFunction: 'ease',
                borderRadius: '8px',
                padding: '10px',
                paddingY: '40px'
            }}
        >
            <Box marginBottom={'40px'} width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' } }}>
                <Box display={'flex'} marginBottom={'20px'}>
                    <Typography variant={'h2'} color={'primary'}>
                        {`${indexNumber}. `}
                    </Typography>
                    <Typography variant={'h2'} paddingLeft={'8px'}>
                        Additional service request
                    </Typography>
                </Box>
            </Box>
            <Box width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' }, maxWidth: '900px' }}>
                <Box sx={{ marginTop: '5px' }}>
                    <FormControlLabel
                        sx={{ '&:span': { fontSize: { xs: '12px', md: '16px' }, fontWeight: 600 } }}
                        label="I need packing&unpacking service"
                        control={
                            <Checkbox
                                checked={values.packing === '1'}
                                onChange={(e) => setFieldValue('packing', e.currentTarget.checked ? '1' : '0')}
                            />
                        }
                    />
                </Box>
                <Box sx={{ marginTop: '5px' }}>
                    <FormControlLabel
                        label="I need storage service"
                        control={
                            <Checkbox
                                checked={values.storage === '1'}
                                onChange={(e) => setFieldValue('storage', e.currentTarget.checked ? '1' : '0')}
                            />
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
};
