import React from 'react';
import { useNavigate } from 'react-router-dom';

export const CopyFooter = () => {
    const navigation = useNavigate();
    return (
        <div className="footer">
            <div className="trimmer">
                <div className="div-block-8" />
            </div>
            <div className="_1400">
                <div className="div-block-9">
                    <h1 className="heading-7">About us</h1>
                    <p className="paragraph-7">
                        We are
                        <strong>WECUBE REMOVALS</strong>
                        in Brisbane. Our professional services have not gone unnoticed by the Brisbane community which is why we are proud
                        to be the most hired Brisbane removalists! Our cost-effective rates and exceptional dedication to customer service
                        has ensured the longevity of our business. To us if you view it as valuable, we view it as valuable which is why we
                        go to great lengths to ensure the safety of all your belongings during the move, leaving you stress-free!
                        <br />
                        <br />
                        <a href="about-us.html" className="link-6">
                            MORE
                        </a>
                    </p>
                </div>
                <div className="div-block-9">
                    <h1 className="heading-7">informations</h1>
                    <h1 className="heading-9">Removals / Local / Interstate / Storage</h1>
                    <p className="paragraph-3">
                        <span className="text-span-4">✆</span>
                        <a href="tel:0423690837" className="link-5-copy">
                            0423 690 837
                        </a>{' '}
                        <span className="text-span-5">(Tap to Call)</span>
                    </p>
                    <p>ABN: 27 628 435 110</p>
                    <p className="paragraph-4">
                        <strong> </strong>
                        <a href="mailto:info@wecuberemovals.com.au" target="_blank" className="link-7">
                            info@wecuberemovals.com.au
                        </a>
                    </p>
                </div>
                <div className="div-block-7">
                    <a href="#" className="link-block w-inline-block" />
                    <h1 className="heading-12">
                        Mon - Fri : 8aM to 5PM
                        <br />
                        Sat : 8AM to 2PM
                        <br />
                        Sun : Closed
                    </h1>
                </div>
                <div className="div-block-5">
                    <div className="text-block">
                        <strong>©</strong>
                        <a href="http://www.wecuberemovals.com.au" className="link">
                            <strong> Wecube Removals</strong>
                        </a>{' '}
                        All rights reserved | Developed by{' '}
                        <a href="http://www.innerworks.cc" target="_blank" className="link-2">
                            <strong className="bold-text">INNERWORKS</strong>
                        </a>
                    </div>
                    <div className="div-block-6">
                        <a href="https://www.wecuberemovals.com.au/about-us" className="footer_link">
                            About us
                        </a>
                        <a href="https://www.wecuberemovals.com.au/services" className="footer_link">
                            SERVICES
                        </a>
                        <a href="/" className="footer_link">
                            Get a quote
                        </a>
                        <a href="/boxpage" className="footer_link">
                            BOX &amp; packing materials
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
