import React from 'react';
import { Container, CssBaseline, Grid, Typography, StyledEngineProvider, ThemeProvider } from '@mui/material';
import theme from 'themes';
import NavigationScroll from './layout/navagtion.scroll';
import Routes from 'routes';
function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme()}>
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
