import React from 'react';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormSchema } from './index';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { isValidDate } from '../../utils/common.func';

const WhenMenu = [
    { label: 'On a specific date', value: 'On a specific date' },
    { label: 'Around a specific date', value: 'Around a specific date' }
];
interface Props {
    indexNumber: number;
}
export const WhenForm = ({ indexNumber }: Props) => {
    const { values, setFieldValue, handleBlur, touched, errors } = useFormikContext<typeof FormSchema>();

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                '&:hover': {
                    boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                },
                transition: 'box-shadow 0.25s',
                transitionTimingFunction: 'ease',
                borderRadius: '8px',
                padding: '10px',
                paddingY: '40px'
            }}
        >
            <Box marginBottom={'40px'} width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' } }}>
                <Box display={'flex'} marginBottom={'20px'}>
                    <Typography variant={'h2'} color={'primary'}>
                        {`${indexNumber}. `}
                    </Typography>
                    <Typography variant={'h2'} paddingLeft={'8px'}>
                        When
                    </Typography>
                </Box>
            </Box>
            <Box width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' }, maxWidth: '900px' }}>
                <Box>
                    <FormControl fullWidth sx={{ marginTop: '0px' }}>
                        <InputLabel id="whenId">When do you need to move</InputLabel>
                        <Select
                            labelId="whenId"
                            id="when"
                            name={'when'}
                            value={values.when}
                            onBlur={handleBlur}
                            error={touched.when && Boolean(errors.when)}
                            label="When do you need to move"
                            onChange={(e) => setFieldValue('when', e.target.value)}
                        >
                            {WhenMenu.map((v, _) => (
                                <MenuItem value={v.value} sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                                    {v.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {touched.when && Boolean(errors.when) && (
                        <FormHelperText id="component-helper-text222" sx={{ color: 'red' }}>
                            {touched.when && errors.when}
                        </FormHelperText>
                    )}
                    {values.when && values.when !== '' && (
                        <Box sx={{ marginTop: '40px' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    label="Select date"
                                    value={isValidDate(values?.whenDate ?? '') ? new Date() : values.whenDate}
                                    onChange={(newValue) => {
                                        setFieldValue('whenDate', newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
