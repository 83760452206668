import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import axios from 'axios';
import cheerio from 'cheerio';
import { ExportOnlyNumber, NumberRefry } from '../../utils/common.func';
import { Grid, Typography } from '@mui/material';
import { IconBrandGoogle } from '@tabler/icons';
import { createProxyMiddleware } from 'http-proxy-middleware';
import { FlareIcon, HiPageIcon, MonthIcon, MoveIcon } from './crawling.icon';
interface IScore {
    score: number;
    review: number;
}
const FindSvg = 'https://www.findamover.com.au/site/fam/images/findamover_logo.svg';
export const CrawlingPage = () => {
    const [wecubeScore, setWecubeScore] = useState<IScore>({ score: 0, review: 0 });
    const [monthScore, setMonthScore] = useState<IScore>({ score: 0, review: 0 });
    const [flareScore, setFlareScore] = useState<IScore>({ score: 0, review: 0 });
    const [hiScroe, setHiScore] = useState<IScore>({ score: 0, review: 0 });
    const getWecubeData = async () => {
        try {
            const html = await axios.get('https://www.findamover.com.au/mover/wecube-removals-pty-ltd-1503');
            const $ = cheerio.load(html.data);
            const select = $('a#reviews-link');
            const review = select.text();
            const score = select.prev().text();
            console.log('wecubeScore', review);
            console.log('review', ExportOnlyNumber(review));
            return { score: NumberRefry(score), review: ExportOnlyNumber(review) };
        } catch (error) {
            return { score: 0, review: 0 };
            console.log('error', error);
        }
    };
    const getFlareAndMonthData = async () => {
        try {
            const html = await axios.get('/reviews/wecube-removals');
            const $ = cheerio.load(html.data);
            const select = $('div .rating-summary').children();
            // @ts-ignore
            const [
                monthSvgE,
                monthRatingE,
                monthStarE,
                monthReviewE,
                monthTempE,
                flareSvgE,
                flareRatingE,
                flareStarE,
                flareReviewE,
                flareTempE
            ] = select ?? [];
            console.log('monthRating', monthRatingE);
            console.log('monthReview', monthReviewE);
            console.log('flareRating', flareRatingE);
            console.log('flareReview', flareReviewE);
            // const flare = $(select[0])
            const monthRating = ExportOnlyNumber($(monthRatingE).text()) / 10;
            const monthReview = ExportOnlyNumber($(monthReviewE).text());
            const flareRating = ExportOnlyNumber($(flareRatingE).text()) / 10;
            const flareReview = ExportOnlyNumber($(flareReviewE).text());
            // setFlareScore({ score: flareRating, review: flareReview });
            // setMonthScore({ score: monthRating, review: monthReview });
            return [
                { score: flareRating, review: flareReview },
                { score: monthRating, review: monthReview }
            ];
        } catch (error) {
            return [
                { score: 0, review: 0 },
                { score: 0, review: 0 }
            ];
            console.log('error', error);
        }
    };
    const getHipageData = async () => {
        try {
            const html = await axios.get('/connect/wecuberemovals');
            const $ = cheerio.load(html.data);
            const selectDiv = $('div#about ul li').first().text();
            console.log('getHipageData', selectDiv);
            const temp = String(ExportOnlyNumber(selectDiv));
            const hiPageRatingTemp = temp.slice(0, 2);
            const hiPageReviewTemp = temp.slice(2);
            const hiPageRating = NumberRefry(hiPageRatingTemp) / 10;
            const hiPageReview = NumberRefry(hiPageReviewTemp);
            console.log('hiPageRating', hiPageRatingTemp);
            console.log('hiPageReview', hiPageReviewTemp);
            return { score: hiPageRating, review: hiPageReview };

            //
            // const review = select.text();
            // const score = select.prev().text();
            // console.log('wecubeScore', review);
            // console.log('review', ExportOnlyNumber(review));
            // setWecubeScore({ score: NumberRefry(score), review: ExportOnlyNumber(review) });
        } catch (error) {
            return { score: 0, review: 0 };
            console.log('error', error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const api = await axios.get('https://recan-api.mirable.cc/crawling/data', { withCredentials: true });
            const { result, data } = api.data;
            if (result) {
                console.log('DATA', data);
                if (data) {
                    const [w, f, m, h] = data;
                    setHiScore({ ...h });
                    setFlareScore({ ...f });
                    setWecubeScore({ ...w });
                    setMonthScore({ ...m });
                }
            }
        } catch (e) {}

        // await getWecubeData();
        // await getFlareAndMonthData();
        // await getHipageData();
    };
    // function getData() {
    //     $.ajax({
    //         type: 'get',
    //         url: 'https://recan-api.mirable.cc/crawling/data',
    //         success: function (s) {
    //             console.log('sucess', s);
    //             const { result, data } = s;
    //             if (result) {
    //                 const [w, f, m, h] = data;
    //                 $('#wecubeRating').text(w.score);
    //                 $('#wecubeReview').text(w.review);
    //                 $('#oneFlareRating').text(f.score);
    //                 $('#oneFlareReview').text(f.review);
    //                 $('#wordRating').text(m.score);
    //                 $('#wordReview').text(m.review);
    //                 $('#hiRating').text(h.score);
    //                 $('#hiReview').text(h.review);
    //             }
    //         },
    //         error: function (s) {
    //             console.log('error', s);
    //         }
    //     });
    // }
    return (
        <Box>
            <Grid
                container
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                sx={{
                    transition: 'width 0.25s',
                    transitionTimingFunction: 'ease'
                }}
            >
                <ScoreCard {...wecubeScore} icon={<MoveIcon />} link={'https://www.findamover.com.au/mover/wecube-removals-pty-ltd-1503'} />
                <ScoreCard {...flareScore} icon={<FlareIcon />} link={'https://www.oneflare.com.au/b/wecube-removals'} />
                <ScoreCard {...hiScroe} icon={<HiPageIcon />} link={'https://hipages.com.au/connect/wecuberemovals'} />
                <ScoreCard {...monthScore} icon={<MonthIcon />} link={'https://www.wordofmouth.com.au/reviews/wecube-removals'} />
            </Grid>
        </Box>
    );
};
interface IScoreCard extends IScore {
    icon: React.ReactElement;
    link: string;
}
const ScoreCard = ({ score, review, icon, link }: IScoreCard) => {
    const openLink = () => {
        window && window.open(link, '_blank')?.focus();
    };
    return (
        <Grid
            item
            sx={{
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                transition: 'box-shadow 0.25s',
                cursor: 'pointer',
                padding: '10px',
                '&:hover': {
                    boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                },
                width: '25%',
                '@media (max-width: 425px)': {
                    width: '50%'
                }
            }}
            onClick={() => openLink()}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%'
                }}
            >
                <Box
                    sx={{
                        height: '46px',
                        width: '46px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {icon}
                </Box>
                <Box sx={{ marginLeft: '20px', width: '60%', height: '100%' }}>
                    <Typography
                        typography={'h5'}
                        fontWeight={'800'}
                        fontSize={'20px'}
                        flexDirection={'row'}
                        display={'flex'}
                        alignItems={'flex-end'}
                    >
                        {score}
                        <Typography typography={'h5'} fontWeight={'500'} fontSize={'10px'} color={'#A6A6A6'}>
                            /5
                        </Typography>
                    </Typography>
                    <Typography
                        typography={'h5'}
                        fontWeight={'500'}
                        color={'#A6A6A6'}
                        sx={{
                            display: 'flex',
                            fontSize: '10px',
                            '@media (max-width: 425px)': {
                                display: 'none',
                                fontSize: '10px'
                            }
                        }}
                    >
                        {review} reviews
                    </Typography>
                    <Typography
                        sx={{
                            display: 'none',
                            '@media (max-width: 425px)': {
                                display: 'flex',
                                fontSize: '10px'
                            }
                        }}
                        typography={'h5'}
                        fontWeight={'500'}
                        paddingTop={'10px'}
                        color={'#A6A6A6'}
                    >
                        {review} reviews
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
};
