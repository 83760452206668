import { IconEmergencyBed } from '@tabler/icons';
import * as uuid from 'uuid';
import { SelectEnum } from '../types/select.types';
import { FormSchema } from '../containers/categoryForm';

export const InventoryData = {
    bedrooms: {
        title: 'Bedrooms',
        icon: '1',
        items: [
            { title: 'Bed King & Mattress', value: 0, amount: 2.0 },
            { title: 'Bed Double & Mattress', value: 0, amount: 1.2 },
            { title: 'Bed Queen & Mattress', value: 0, amount: 1.4 },
            { title: 'Bed Single & Mattress', value: 0, amount: 0.6 },
            { title: 'Bedside table / chest', value: 0, amount: 0.6 },
            { title: 'Bookcase', value: 0, amount: 1 },
            { title: 'Bunks', value: 0, amount: 1.24 },
            { title: 'Chair', value: 0, amount: 1.24 },
            { title: 'Change Table', value: 0, amount: 0.8 },
            { title: 'Chest of drawers', value: 0, amount: 0.5 },
            { title: 'Cot - Collapsible / Rigid', value: 0, amount: 0.5 },
            { title: 'Cupboard', value: 0, amount: 1.0 },
            { title: 'Dressing Table', value: 0, amount: 1.0 },
            { title: 'Headboard', value: 0, amount: 0.3 },
            { title: 'Mirror', value: 0, amount: 0.2 },
            { title: 'Pram', value: 0, amount: 1.2 },
            { title: 'Stool', value: 0, amount: 0.15 },
            { title: 'Table Lamp', value: 0, amount: 0.15 },
            { title: 'Wardrobes (Large)', value: 0, amount: 1.0 },
            { title: 'Wardrobes (Small)', value: 0, amount: 0.5 }
        ]
    },
    kitchen: {
        title: 'Kitchen',
        icon: '1',
        items: [
            { title: 'Cabinet', value: 0, amount: 0.85 },
            { title: 'Chair', value: 0, amount: 0.15 },
            { title: 'Cupboard', value: 0, amount: 1 },
            { title: 'Dishwasher', value: 0, amount: 0.5 },
            { title: 'Freezer', value: 0, amount: 1.0 },
            { title: 'High Chair', value: 0, amount: 0.15 },
            { title: 'Microwave', value: 0, amount: 0.15 },
            { title: 'Fridge Small', value: 0, amount: 0.8 },
            { title: 'Fridge Medium', value: 0, amount: 1.0 },
            { title: 'Fridge Large', value: 0, amount: 1.2 },
            { title: 'Fridge 2 door', value: 0, amount: 1.6 },
            { title: 'Stool', value: 0, amount: 0.15 }
        ]
    },
    dining: {
        title: 'Dining Room',
        icon: '1',
        items: [
            { title: 'Bar or Crystal Cabinet', value: 0, amount: 1.0 },
            { title: 'Bookcase', value: 0, amount: 1.0 },
            { title: 'Buffet', value: 0, amount: 0.8 },
            { title: 'Chairs', value: 0, amount: 0.15 },
            { title: 'Dining table 4 seater', value: 0, amount: 1.8 },
            { title: 'Dining table 6 seater', value: 0, amount: 2.7 },
            { title: 'Dining table 8 seater', value: 0, amount: 3.8 }
        ]
    },
    lounge: {
        title: 'Lounge / Family Rooms',
        icon: '1',
        items: [
            { title: 'AirConditions', value: 0, amount: 0.25 },
            { title: 'Bean Bag', value: 0, amount: 0.4 },
            { title: 'Bookcase', value: 0, amount: 1.0 },
            { title: 'China Cabinet', value: 0, amount: 0.8 },
            { title: 'Coffee Table', value: 0, amount: 0.8 },
            { title: 'Electric Organ', value: 0, amount: 1.0 },
            { title: 'Filing Cabinet', value: 0, amount: 0.85 },
            { title: 'Heater', value: 0, amount: 0.1 },
            { title: 'Lounge 2 Seater', value: 0, amount: 1.7 },
            { title: 'Lounge 3 Seater', value: 0, amount: 2.2 },
            { title: 'Lounge Chair', value: 0, amount: 0.48 },
            { title: 'Lounge Modular', value: 0, amount: 3.5 },
            { title: 'office chair ', value: 0, amount: 0.5 },
            { title: 'Ottoman / Pouffe', value: 0, amount: 0.2 },
            { title: 'Piano Stool', value: 0, amount: 0.15 },
            { title: 'Piano Upright / Grand', value: 0, amount: 1 },
            { title: 'Pool Table', value: 0, amount: 2.4 },
            { title: 'Recliner Chair', value: 0, amount: 0.9 },
            { title: 'Standard lamp', value: 0, amount: 0.14 },
            { title: 'Stereo & Speakers', value: 0, amount: 0.4 },
            { title: 'TV Small', value: 0, amount: 0.3 },
            { title: 'TV Medium', value: 0, amount: 0.4 },
            { title: 'TV Large', value: 0, amount: 0.5 },
            { title: 'TV Unit', value: 0, amount: 0.8 }
        ]
    },
    hall: {
        title: 'Hall',
        icon: '1',
        items: [
            { title: 'Carpets & Rugs', value: 0, amount: 0.2 },
            { title: 'Hall Table', value: 0, amount: 0.28 },
            { title: 'Paintings/mirror(', value: 0, amount: 0.28 }
        ]
    },
    laundry: {
        title: 'Laundry',
        icon: '1',
        items: [
            { title: 'Brooms, Mops etc', value: 0, amount: 0.15 },
            { title: 'Clothes Basket', value: 0, amount: 0.1 },
            { title: 'Clothes Horse / Airer', value: 0, amount: 0.05 },
            { title: 'Cupboard', value: 0, amount: 0.8 },
            { title: 'Dryer', value: 0, amount: 0.5 },
            { title: 'Ironing Board', value: 0, amount: 0.1 },
            { title: 'Stool', value: 0, amount: 0.15 },
            { title: 'Table', value: 0, amount: 0.5 },
            { title: 'Vacuum Cleaner', value: 0, amount: 0.2 },
            { title: 'Washing Machine', value: 0, amount: 0.5 }
        ]
    },
    garage: {
        title: 'Garage',
        icon: '1',
        items: [
            { title: 'Aquarium Small', value: 0, amount: 0.4 },
            { title: 'Aquarium Large', value: 0, amount: 1.0 },
            { title: 'Barbeque', value: 0, amount: 1.0 },
            { title: 'Bicycle', value: 0, amount: 0.3 },
            { title: 'fishing rod', value: 0, amount: 0.2 },
            { title: 'Esky', value: 0, amount: 1.0 },
            { title: 'Folding Chair', value: 0, amount: 0.21 },
            { title: 'Garbage Bin', value: 0, amount: 0.2 },
            { title: 'Garden Seat', value: 0, amount: 0.4 },
            { title: 'Garden Tools & Hose', value: 0, amount: 0.3 },
            { title: 'Golf Bag & Buggy', value: 0, amount: 2.0 },
            { title: 'Ladder', value: 0, amount: 0.3 },
            { title: 'Lawn Mower', value: 0, amount: 1.0 },
            { title: 'Outdoor Table', value: 0, amount: 1.0 },
            { title: 'Rocking Horse', value: 0, amount: 0.85 },
            { title: 'Swing (Dismantled)', value: 0, amount: 1.0 },
            { title: 'Tool Box', value: 0, amount: 0.5 },
            { title: 'Tool Cupboard', value: 0, amount: 0.1 },
            { title: 'Trampoline (Dismantled)', value: 0, amount: 0.58 },
            { title: 'Wheel Barrow', value: 0, amount: 0.58 },
            { title: 'Work Bench', value: 0, amount: 1.0 }
        ]
    },
    carton: {
        title: 'Carton Summary',
        icon: '1',
        items: [
            { title: 'Removalist Cartons(Tchest box)', value: 0, amount: 0.15 },
            { title: 'Book Boxes', value: 0, amount: 0.1 },
            { title: 'Portarobes', value: 0, amount: 0.3 },
            { title: 'Striped Bags', value: 0, amount: 0.15 }
        ]
    },
    pot: {
        title: 'Pot Plants',
        icon: '1',
        items: [
            { title: 'Small', value: 0, amount: 0.3 },
            { title: 'Large', value: 0, amount: 0.7 }
        ]
    },
    office: {
        title: 'Office Inventory',
        icon: '1',
        items: [
            { title: 'Corner Desk', value: 0, amount: 1.5 },
            { title: 'Executive Office Chair', value: 0, amount: 0.3 },
            { title: 'Boardroom Table - 4 Seater', value: 0, amount: 1.0 },
            { title: 'Boardroom Table - 6 Seater', value: 0, amount: 1.5 },
            { title: 'Boardroom Table - 8 Seater', value: 0, amount: 2.0 },
            { title: 'Boardroom Table - 10+ Seater', value: 0, amount: 2.5 },
            { title: 'Filing Cabinet - 2 Drawer', value: 0, amount: 0.25 },
            { title: 'Filing Cabinet - 3 Drawer', value: 0, amount: 0.35 },
            { title: 'Filing Cabinet - 4 Drawer', value: 0, amount: 0.45 },
            { title: 'Whiteboard', value: 0, amount: 0.1 },
            { title: 'Bookshelf', value: 0, amount: 0.5 },
            { title: 'Desk - 2 Seater', value: 0, amount: 0.8 },
            { title: 'Desk - Single Seater', value: 0, amount: 0.6 },
            { title: 'Desk - Corner', value: 0, amount: 1.5 }
        ]
    }
};
export type InventoryDataTypes = typeof InventoryData;
export const ItemData = [
    {
        id: uuid.v4(),
        description: '',
        qty: '1',
        length: '',
        width: '',
        height: '',
        unit: 'cm',
        guess1: '0',
        weight: '',
        guess2: '0'
    }
];
export type ItemDataTypes = typeof ItemData;
export const BoxData = [
    {
        title: 'Tchest box',
        content: '',
        image:
            'https://firebasestorage.googleapis.com/v0/b/wecuberemovals-a1047.appspot.com/o/Tchest%20box.png?alt=media&token=5a1bcd6e-eaf3-45a4-aa71-f989d9154373',
        qty: 0,
        amount: 5.5
    },
    {
        title: 'Book/wine box',
        content: '',
        image:
            'https://firebasestorage.googleapis.com/v0/b/wecuberemovals-a1047.appspot.com/o/wine%20box.png?alt=media&token=b9aaa628-fa53-440e-b367-2bda370875d5',
        qty: 0,
        amount: 3.0
    },
    {
        title: 'Pallet wrap',
        content: '',
        image:
            'https://firebasestorage.googleapis.com/v0/b/wecuberemovals-a1047.appspot.com/o/Pallet%20wrap.png?alt=media&token=50cbb82a-fcd3-4a53-83a6-08aa0f82c7dc',
        qty: 0,
        amount: 27.0
    },
    {
        title: 'Bubble wrap 10mm*100m',
        content: '',

        image:
            'https://firebasestorage.googleapis.com/v0/b/wecuberemovals-a1047.appspot.com/o/Bubble%20wrap%2010mm_100m.png?alt=media&token=43476ac8-7668-4caa-a1eb-6d2e9fb4b8c5',
        qty: 0,
        amount: 50.0
    },
    {
        title: 'Butcher paper 2.5kg',
        content: '',

        image:
            'https://firebasestorage.googleapis.com/v0/b/wecuberemovals-a1047.appspot.com/o/Butcher%20paper%202.5kg.png?alt=media&token=c3088cf2-4973-4a3a-a945-0731f447cb1b',
        qty: 0,
        amount: 16.0
    },
    {
        title: 'Heavy duty Tape',
        content: '',

        image:
            'https://firebasestorage.googleapis.com/v0/b/wecuberemovals-a1047.appspot.com/o/Heavy%20duty%20Tape.png?alt=media&token=3d21841d-bbfc-4d13-b7ec-04926357d693',
        qty: 0,
        amount: 3.5
    },
    {
        title: 'King/Queen mattress cover',
        content: '',

        image:
            'https://firebasestorage.googleapis.com/v0/b/wecuberemovals-a1047.appspot.com/o/Queen%20mattress%20cover.png?alt=media&token=b70c8375-5010-4d30-97fd-49f3cdf48518',
        qty: 0,
        amount: 10.0
    },
    {
        title: 'Single mattress cover',
        content: '',

        image:
            'https://firebasestorage.googleapis.com/v0/b/wecuberemovals-a1047.appspot.com/o/Single%20mattress%20cover.png?alt=media&token=5bd9c42e-e6e4-4f42-bd72-404c7fb9bcaa',
        qty: 0,
        amount: 8.0
    }
];

export type BoxDataTypes = typeof BoxData;

export type RootValuesTypeOne = typeof FormSchema;
export interface RootValuesType extends RootValuesTypeOne {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
}
export type StatusTypes = {
    status: string;
    content: string;
};
export interface RootDataTypes {
    id: string;
    type: SelectEnum;
    inventory: InventoryDataTypes;
    values: RootValuesType;
    item: ItemDataTypes;
    createdAt: Date;
    boxData: BoxDataTypes;
    status: StatusTypes[];
}
