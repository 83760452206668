import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    ButtonBase,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import TextareaAutosize from 'react-textarea-autosize';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { FormikProps, useFormikContext } from 'formik';
import { FormSchema } from './index';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { AddressData } from '../../const/address.data';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useDebounce } from '../../hooks/useDebounce';
import LoadingScreen from '../../componets/loadable/fullscreen.loading';
import { FastInputArea } from '../../componets/inputs/fast.input';
interface Props {
    submit?: boolean;
    indexNumber: number;
    title: string;
    formikData: string[];
    addressType: 1 | 2;
    setError: Dispatch<SetStateAction<any>>;
}
export const AddressMenu = [
    { label: 'Single Storey House', value: '1' },
    { label: 'Double storey house', value: '2' },
    { label: 'Triple storey house', value: '3' },
    { label: 'Apartment with elevator', value: '4' },
    { label: 'Apartment without elevator (1st floor)', value: '5' },
    { label: 'Apartment without elevator (2nd floor)', value: '6' },
    { label: 'Apartment without elevator (3rd floor+)', value: '7' },
    { label: 'Storage Facility', value: '8' },
    { label: 'Commercial Property', value: '9' }
];
export const AddressMenuFind = (value) => {
    return AddressMenu.find((v) => String(value) === v.value).label;
};

const RoomMenu = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6+', value: '6' }
];
const ParkingaccessibilityMenu = [
    { label: 'Flat driveway', value: 'Flat driveway' },
    { label: 'Uphill driveway', value: 'Uphill driveway' },
    { label: 'Downhill driveway', value: 'Downhill driveway' },
    { label: 'Street parking', value: 'Street parking' },
    { label: 'Loading bay', value: 'Loading bay' }
];
const typeOne = {
    pickAddress: '',
    pickPostCode: '',
    pickType: '', // '' == 0
    pickRooms: '',
    pickOk: 'no',
    pickEtc: '',
    pickLat: '',
    pickLng: ''
};

const typeTwo = {
    dropAddress: '',
    dropPostCode: '',
    dropType: '', // '' == 0
    dropEtc: '',
    dropRooms: '',
    dropOk: 'no',
    dropLat: '',
    dropLng: ''
};

const vOne = {
    pickEtc: yup.string()
};
const vTwo = {
    dropEtc: yup.string()
};

const convertAddress = () => {
    return AddressData.map((v) => {
        const value = v ?? '';
        const searchToEmpty = value.replace(/ /gi, '');
        const searchToLower = searchToEmpty.toLowerCase();
        return {
            value: searchToLower,
            label: v
        };
    });
};
export const AddressForm = ({ indexNumber, submit, title, formikData, addressType, setError }: Props) => {
    const [address, postCode, type, rooms, etc, ok, fLat, fLng] = formikData;
    const [etcc, setEtc] = useState('');
    const { setFieldValue, values, setErrors, setValues, errors, touched, handleBlur } = useFormikContext<typeof FormSchema>();
    const formik = useFormik({
        initialValues: addressType === 1 ? typeOne : typeTwo,
        validationSchema: yup.object(addressType === 1 ? vOne : vTwo),
        onSubmit: async (v) => {
            handleSubmitArray(v);
        }
    });
    const handleSubmitArray = (v) => {
        console.log(etc, v[etc]);
        setFieldValue(etc, v[etc]);
    };

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
            types: [],
            language: 'en-AU',
            componentRestrictions: { country: 'AU' }
        },
        debounce: 300
    });
    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    useEffect(() => {
        // if (submit) {
        //     setError({ ...formik.errors });
        //     formik.handleSubmit();
        // }
    }, [submit]);

    const handleSelect = (description) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        console.log(description);
        setLoading(true);
        setGetList([...[]]);
        getGeocode({ address: description })
            .then((results) => {
                const { lat, lng } = getLatLng(results[0]);
                console.log('📍 Coordinates: ', results);
                formattingAddress(results[0], description, lat, lng);
            })
            .finally(() => {
                setGetList([...[]]);
                setLoading(false);
            });

        // Get latitude and longitude via utility functions
    };
    console.log(data);
    const renderSuggestions = () =>
        getList.map((suggestion, i) => {
            const { label, row } = suggestion;

            return (
                <ButtonBase
                    key={row}
                    sx={{ width: '100%', padding: '10px 0', overflow: 'hidden', maxHeight: '40px' }}
                    onClick={handleSelect(label)}
                >
                    <Box display={'flex'} width={'100%'} overflow={'hidden'} maxHeight={'40px'} flexWrap={'nowrap'}>
                        <Typography
                            sx={{ fontSize: { xs: '10px', md: '16px' } }}
                            overflow={'hidden'}
                            maxHeight={'40px'}
                            textOverflow={'hidden'}
                            whiteSpace={'nowrap'}
                        >
                            {label}
                        </Typography>
                    </Box>
                </ButtonBase>
            );
        });

    const [temp, setTemp] = useState('');
    const [inputRefry, setInputRefry] = useState(false);

    const formattingAddress = async (a: any, ad, la, ln) => {
        const { address_components, formatted_address } = a;
        if (!address_components) {
        }
        let addressString = '';
        let moreString = '';
        let postcodeVal = '';
        const componentForm = {
            street_number: ['long_name', 'main'],
            route: ['long_name', 'main'],
            locality: ['long_name', 'main'],
            sublocality_level_1: ['long_name', 'main'],
            administrative_area_level_1: ['short_name', 'more'],
            administrative_area_level_2: ['short_name', 'more'],
            postal_code: ['short_name', 'no']
        };
        for (let i = 0; i < address_components.length; i++) {
            // warning this only gets the first type of the component. E.g. 'sublocality_level_1'
            const addressType = address_components[i].types[0];
            if (componentForm[addressType]) {
                const [addressText, type] = componentForm[addressType];
                const val = address_components[i][addressText];
                if (type === 'no') {
                    postcodeVal += val;
                }
            }
        }
        setFieldValue(ok, 'ok');
        setTemp(ad);
        setFieldValue(address, ad as any);
        setFieldValue(postCode, postcodeVal as any);
        setFieldValue(fLat, la as any);
        setFieldValue(fLng, ln as any);

        // await formik.setFieldValue(postCode as any, postcodeVal as any);
    };
    const formattingAddress2 = (a: any) => {
        const { terms, formatted_address } = a;
        if (!terms) {
            return '';
        }
        let one = '';
        let two = '';
        for (const row of terms) {
            const { offset } = row;
        }
        return `${one} ${two}`;
    };
    const changeAddress = (e) => {
        setTemp(e);
        setValue(e);
    };
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setFieldValue(type, event.target.value);
    };
    const handleChangeEtc = (event) => {
        setFieldValue(etc, event.target.value);
    };

    const [check, setCheck] = useState(false);

    const handleChange2 = (newValue, actionMeta) => {
        console.group('Value Changed');
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
    };
    const handleInputChange = (inputValue: any, actionMeta: any) => {
        console.group('Input Changed');
        console.log(inputValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
    };
    const getAddress = async () => {
        const value = temp ?? '';
        if (value.length === 0) {
            return;
        }
        const searchToEmpty = value.replace(/ /gi, '');
        const searchToLower = searchToEmpty.toLowerCase();
        console.log('convertAddress', convertAddress());
        const find = convertAddress().filter((v) => v.value.includes(searchToLower));
        setGetList([...find.slice(0, 5)]);
        console.log('convertAddressconvertAddress', find);
    };
    const [getList, setGetList] = useState([]);
    useDebounce(getAddress, 300, [temp]);
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                    '&:hover': {
                        boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                    },
                    transition: 'box-shadow 0.25s',
                    transitionTimingFunction: 'ease',
                    borderRadius: '8px',
                    padding: '10px',
                    paddingY: '40px'
                }}
            >
                <Box marginBottom={'40px'} width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' } }}>
                    <Box display={'flex'} marginBottom={'20px'}>
                        <Typography variant={'h2'} color={'primary'}>
                            {`${indexNumber}. `}
                        </Typography>
                        <Typography variant={'h2'} paddingLeft={'8px'}>
                            {title}
                        </Typography>
                    </Box>
                </Box>
                <Box width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' }, maxWidth: '900px' }}>
                    <Box ref={ref}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel htmlFor="address_input" sx={{ fontSize: '16px' }}>
                                Suburb Name OR Postcode
                            </InputLabel>
                            <Input
                                id={address}
                                fullWidth
                                onFocus={() => setFieldValue(ok, 'no')}
                                value={values[ok] === 'ok' ? values[address] : temp}
                                sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                onBlur={handleBlur}
                                error={touched[address] && Boolean(errors[address])}
                                onChange={(e) => setTemp(e.currentTarget.value)}
                            />
                            {values[ok] !== 'ok' && getList.length > 0 && (
                                <Box style={{ boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px', padding: '10px' }}>{renderSuggestions()}</Box>
                            )}
                            {touched[address] && Boolean(errors[address]) ? (
                                <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                    {touched[address] && errors[address]}
                                </FormHelperText>
                            ) : (
                                <FormHelperText id="component-helper-text">type 2 or more charactors and select</FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                    <Box marginTop={'40px'}>
                        <FormControl fullWidth>
                            <InputLabel id={values[type]}>Property type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id={type}
                                value={values[type]}
                                label="Property type"
                                name={type}
                                onChange={handleChange}
                                onBlur={(e) => {
                                    console.log('e', e);
                                    handleBlur(e);
                                }}
                                error={touched[type] && Boolean(errors[type])}
                            >
                                {AddressMenu.map((v, _) => (
                                    <MenuItem value={v.value} sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                                        {v.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {touched[type] && Boolean(errors[type]) && (
                            <FormHelperText id="component-helper-text22" sx={{ color: 'red' }}>
                                {touched[type] && errors[type]}
                            </FormHelperText>
                        )}
                        {Number(values[type]) < 6 && Number(values[type]) > 0 && (
                            <FormControl fullWidth sx={{ marginTop: '40px' }}>
                                <InputLabel id="demo-simple-select-label2">Number of Bedrooms</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    id="demo-simple-select"
                                    value={values[rooms]}
                                    name={rooms}
                                    onBlur={handleBlur}
                                    label="Number of Bedrooms"
                                    onChange={(e) => setFieldValue(rooms, e.target.value)}
                                    error={touched[rooms] && Boolean(errors[rooms])}
                                >
                                    {RoomMenu.map((v, _) => (
                                        <MenuItem value={v.value} sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                                            {v.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {/*    <Box sx={{ marginTop: '40px' }}>*/}
                        {/*        <FormControlLabel*/}
                        {/*            label="Restricted parking or access to the property"*/}
                        {/*            control={<Checkbox checked={check} onChange={(e) => setCheck(e.currentTarget.checked)} />}*/}
                        {/*        />*/}
                        {/*    </Box>*/}
                        {/*    {check && (*/}
                        {/*        <Box sx={{ marginTop: '20px' }}>*/}
                        {/*            <FastInputArea*/}
                        {/*                aria-label="minimum height"*/}
                        {/*                minRows={3}*/}
                        {/*                value={formik.values[etc]}*/}
                        {/*                id={etc}*/}
                        {/*                onHandleChange={setFieldValue}*/}
                        {/*                placeholder="Please describe the access/parking restrictions..."*/}
                        {/*                style={{*/}
                        {/*                    width: '100%',*/}
                        {/*                    fontSize: '16px',*/}
                        {/*                    fontFamily: "'Open Sans',sans-serif",*/}
                        {/*                    padding: '20px'*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*        </Box>*/}
                        {/*    )}*/}
                    </Box>
                    <Box marginTop={'40px'}>
                        <FormControl fullWidth>
                            <InputLabel id={values[etc]}>Parking accessibility</InputLabel>
                            <Select
                                labelId="demo-simple-select-Parking accessibility"
                                id={etc}
                                value={values[etc]}
                                label="Parking accessibility"
                                name={etc}
                                onChange={handleChangeEtc}
                                onBlur={(e) => {
                                    console.log('e', e);
                                    handleBlur(e);
                                }}
                                error={touched[etc] && Boolean(errors[etc])}
                            >
                                {ParkingaccessibilityMenu.map((v, _) => (
                                    <MenuItem value={v.value} sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                                        {v.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {touched[etc] && Boolean(errors[etc]) && (
                            <FormHelperText id="component-helper-text22" sx={{ color: 'red' }}>
                                {touched[etc] && errors[etc]}
                            </FormHelperText>
                        )}
                    </Box>
                </Box>
            </Box>
            {loading && <LoadingScreen />}
        </>
    );
};
