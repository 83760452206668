import React from 'react';
import { Box } from '@mui/material';
import ReactLoading from 'react-loading';
import { useTheme } from '@mui/material/styles';

const full = {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    cursor: 'progress',
    backgroundColor: 'rgba(231,230,230,0.5)'
};

const bar = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '50px',
    height: '50px',
    transform: 'translate(-50%, -50%)'
};

const LoadingScreen = () => {
    const theme = useTheme();
    return (
        <Box
            style={{ cursor: 'progress' }}
            position={'fixed'}
            left={0}
            top={0}
            width={'100%'}
            height={'100%'}
            zIndex={9999}
            bgcolor={'rgba(231,230,230,0.5)'}
        >
            <div
                style={{ position: 'absolute', left: '50%', top: '50%', width: '50px', height: '50px', transform: 'translate(-50%, -50%)' }}
            >
                <ReactLoading type={'spinningBubbles'} color={'#16A6DA'} height={'100%'} width={'100%'} />
            </div>
        </Box>
    );
};

export default LoadingScreen;
