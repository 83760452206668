
export const AddressData = [
    "Aarons Pass, NSW 2850",
    "Abba River, WA 6280",
    "Abbey, WA 6280",
    "Abbeyard, VIC 3737",
    "Abbeywood, QLD 4613",
    "Abbotsbury, NSW 2176",
    "Abbotsford, NSW 2046",
    "Abbotsford, QLD 4670",
    "Abbotsford, VIC 3067",
    "Abbotsham, TAS 7315",
    "Abeckett Street, VIC 8006",
    "Abels Bay, TAS 7112",
    "Abercorn, QLD 4627",
    "Abercrombie, NSW 2795",
    "Abercrombie River, NSW 2795",
    "Aberdare, NSW 2325",
    "Aberdeen, NSW 2359",
    "Aberdeen, NSW 2336",
    "Aberdeen, TAS 7310",
    "Aberfeldie, VIC 3040",
    "Aberfeldy, VIC 3825",
    "Aberfoyle, NSW 2350",
    "Aberfoyle Park, SA 5159",
    "Aberglasslyn, NSW 2320",
    "Abergowrie, QLD 4850",
    "Abermain, NSW 2326",
    "Abernethy, NSW 2325",
    "Abingdon Downs, QLD 4871",
    "Abington, QLD 4660",
    "Abington, NSW 2350",
    "Acacia Creek, NSW 2476",
    "Acacia Gardens, NSW 2763",
    "Acacia Hills, NT 0822",
    "Acacia Hills, TAS 7306",
    "Acacia Ridge, QLD 4110",
    "Acacia Ridge Bc, QLD 4110",
    "Acheron, VIC 3714",
    "Acland, QLD 4401",
    "Acton, ACT 2601",
    "Acton, TAS 7320",
    "Acton Park, TAS 7170",
    "Acton Park, WA 6280",
    "Ada, VIC 3833",
    "Adaminaby, NSW 2629",
    "Adams Estate, VIC 3984",
    "Adamstown, NSW 2289",
    "Adamstown Heights, NSW 2289",
    "Adamsvale, WA 6375",
    "Adare, QLD 4343",
    "Adavale, QLD 4474",
    "Addington, VIC 3352",
    "Adelaide, SA 5000",
    "Adelaide Airport, SA 5950",
    "Adelaide Bc, SA 5000",
    "Adelaide Lead, VIC 3465",
    "Adelaide Park, QLD 4703",
    "Adelaide River, NT 0846",
    "Adelaide University, SA 5005",
    "Adelong, NSW 2729",
    "Adjungbilly, NSW 2727",
    "Advancetown, QLD 4211",
    "Adventure Bay, TAS 7150",
    "Aeroglen, QLD 4870",
    "Afterlee, NSW 2474",
    "Agery, SA 5558",
    "Agnes, VIC 3962",
    "Agnes Banks, NSW 2753",
    "Agnes Water, QLD 4677",
    "Aherrenge, NT 0872",
    "Ainslie, ACT 2602",
    "Airdmillan, QLD 4807",
    "Airds, NSW 2560",
    "Aire Valley, VIC 3237",
    "Aireys Inlet, VIC 3231",
    "Airlie Beach, QLD 4802",
    "Airly, VIC 3851",
    "Airport West, VIC 3042",
    "Airville, QLD 4807",
    "Aitkenvale, QLD 4814",
    "Aitkenvale Bc, QLD 4814",
    "Ajana, WA 6532",
    "Akaroa, TAS 7216",
    "Akolele, NSW 2546",
    "Alabama Hill, QLD 4820",
    "Alawa, NT 0810",
    "Alawoona, SA 5311",
    "Albacutya, VIC 3424",
    "Albanvale, VIC 3021",
    "Albany, WA 6330",
    "Albany Creek, QLD 4035",
    "Albany Po, WA 6332",
    "Albert, NSW 2873",
    "Albert Park, SA 5014",
    "Albert Park, VIC 3206",
    "Alberta, QLD 4702",
    "Alberton, SA 5014",
    "Alberton, VIC 3971",
    "Alberton, QLD 4207",
    "Alberton, TAS 7263",
    "Alberton West, VIC 3971",
    "Albion, VIC 3020",
    "Albion, QLD 4010",
    "Albion, QLD 4822",
    "Albion Bc, QLD 4010",
    "Albion Heights, TAS 7050",
    "Albion Park, NSW 2527",
    "Albion Park Rail, NSW 2527",
    "Albury, NSW 2640",
    "Albury Msc, NSW 2708",
    "Alcomie, TAS 7330",
    "Aldavilla, NSW 2440",
    "Alderley, QLD 4051",
    "Aldershot, QLD 4650",
    "Aldersyde, WA 6306",
    "Aldgate, SA 5154",
    "Aldinga, SA 5173",
    "Aldinga Beach, SA 5173",
    "Aldoga, QLD 4694",
    "Alectown, NSW 2870",
    "Alexander Heights, WA 6064",
    "Alexandra, VIC 3714",
    "Alexandra, QLD 4740",
    "Alexandra Bridge, WA 6288",
    "Alexandra Headland, QLD 4572",
    "Alexandra Hills, QLD 4161",
    "Alexandria, NSW 1435",
    "Alexandria, QLD 4825",
    "Alexandria, NSW 2015",
    "Alford, SA 5555",
    "Alfords Point, NSW 2234",
    "Alfred Cove, WA 6154",
    "Alfredton, VIC 3350",
    "Alfredtown, NSW 2650",
    "Algester, QLD 4115",
    "Ali Curung, NT 0872",
    "Alice, NSW 2469",
    "Alice Creek, QLD 4610",
    "Alice River, QLD 4817",
    "Alice Springs, NT 0870",
    "Alice Springs, NT 0871",
    "Alice Springs, NT 0872",
    "Alison, NSW 2420",
    "Alison, NSW 2259",
    "Alkimos, WA 6038",
    "Allambee, VIC 3823",
    "Allambee Reserve, VIC 3871",
    "Allambee South, VIC 3871",
    "Allambie Heights, NSW 2100",
    "Allan, QLD 4370",
    "Allandale, QLD 4310",
    "Allandale, NSW 2320",
    "Allanooka, WA 6525",
    "Allans Flat, VIC 3691",
    "Allansford, VIC 3277",
    "Allanson, WA 6225",
    "Allawah, NSW 2218",
    "Alleena, NSW 2671",
    "Allenby Gardens, SA 5009",
    "Allendale, VIC 3364",
    "Allendale East, SA 5291",
    "Allendale North, SA 5373",
    "Allens Rivulet, TAS 7150",
    "Allenstown, QLD 4700",
    "Allenview, QLD 4285",
    "Allestree, VIC 3305",
    "Allgomera, NSW 2441",
    "Alligator Creek, QLD 4740",
    "Alligator Creek, QLD 4816",
    "Allingham, QLD 4850",
    "Allora, QLD 4362",
    "Alloway, QLD 4670",
    "Allworth, NSW 2425",
    "Allynbrook, NSW 2311",
    "Alma, SA 5401",
    "Alma, WA 6535",
    "Alma, VIC 3465",
    "Alma Park, NSW 2659",
    "Almaden, QLD 4871",
    "Almonds, VIC 3727",
    "Almurta, VIC 3979",
    "Alonnah, TAS 7150",
    "Aloomba, QLD 4871",
    "Alpha, QLD 4724",
    "Alphington, VIC 3078",
    "Alpine, NSW 2575",
    "Alpurrurulam, NT 4825",
    "Alsace, QLD 4702",
    "Alstonvale, NSW 2477",
    "Alstonville, NSW 2477",
    "Alton Downs, QLD 4702",
    "Altona, VIC 3018",
    "Altona, SA 5351",
    "Altona East, VIC 3025",
    "Altona Gate, VIC 3025",
    "Altona Meadows, VIC 3028",
    "Altona North, VIC 3025",
    "Alumy Creek, NSW 2460",
    "Alva, QLD 4807",
    "Alvie, VIC 3249",
    "Alyangula, NT 0885",
    "Amamoor, QLD 4570",
    "Amamoor Creek, QLD 4570",
    "Amaroo, QLD 4829",
    "Amaroo, NSW 2866",
    "Amaroo, ACT 2914",
    "Amata, SA 0872",
    "Ambania, WA 6632",
    "Ambarvale, NSW 2560",
    "Amber, QLD 4871",
    "Ambergate, WA 6280",
    "Amberley, QLD 4306",
    "Ambleside, TAS 7310",
    "Ambrose, QLD 4695",
    "Amby, QLD 4462",
    "Amelup, WA 6338",
    "American Beach, SA 5222",
    "American River, SA 5221",
    "Amherst, VIC 3371",
    "Amiens, QLD 4380",
    "Amity, QLD 4183",
    "Amity Point, QLD 4183",
    "Amoonguna, NT 0872",
    "Amor, VIC 3825",
    "Amphitheatre, VIC 3468",
    "Ampilatwatja, NT 0872",
    "Amyton, SA 5431",
    "Anabranch North, NSW 2648",
    "Anabranch South, NSW 2648",
    "Anakie, QLD 4702",
    "Anakie, VIC 3221",
    "Anama, SA 5464",
    "Anambah, NSW 2320",
    "Anatye, NT 0872",
    "Ancona, VIC 3715",
    "Andamooka, SA 5722",
    "Andergrove, QLD 4740",
    "Anderleigh, QLD 4570",
    "Anderson, VIC 3995",
    "Ando, NSW 2631",
    "Andover, TAS 7120",
    "Andrews, SA 5454",
    "Andrews Farm, SA 5114",
    "Andromache, QLD 4800",
    "Anduramba, QLD 4355",
    "Anembo, NSW 2621",
    "Angas Plains, SA 5255",
    "Angas Valley, SA 5238",
    "Angaston, SA 5353",
    "Angelo River, WA 6642",
    "Angle Park, SA 5010",
    "Angle Vale, SA 5117",
    "Angledale, NSW 2550",
    "Angledool, NSW 2834",
    "Anglers Reach, NSW 2629",
    "Anglers Rest, VIC 3898",
    "Anglesea, VIC 3230",
    "Angourie, NSW 2464",
    "Angurugu, NT 0822",
    "Anindilyakwa, NT 0822",
    "Anketell, WA 6167",
    "Anmatjere, NT 0872",
    "Anna Bay, NSW 2316",
    "Annadale, SA 5356",
    "Annandale, NSW 2038",
    "Annandale, QLD 4814",
    "Annangrove, NSW 2156",
    "Annerley, QLD 4103",
    "Annie River, NT 0822",
    "Anniebrook, WA 6280",
    "Annuello, VIC 3549",
    "Ansons Bay, TAS 7264",
    "Anstead, QLD 4070",
    "Antechamber Bay, SA 5222",
    "Antewenegerrde, NT 0872",
    "Anthony, QLD 4310",
    "Antigua, QLD 4650",
    "Antill Ponds, TAS 7120",
    "Antonymyre, WA 6714",
    "Antwerp, VIC 3414",
    "Anula, NT 0812",
    "Anula, NT 0812",
    "Apamurra, SA 5237",
    "Apoinga, SA 5413",
    "Apollo Bay, TAS 7150",
    "Apollo Bay, VIC 3233",
    "Appila, SA 5480",
    "Appin, VIC 3579",
    "Appin, NSW 2560",
    "Appin South, VIC 3579",
    "Apple Tree Creek, QLD 4660",
    "Apple Tree Flat, NSW 2850",
    "Appleby, NSW 2340",
    "Applecross, WA 6153",
    "Applecross, WA 6953",
    "Applecross North, WA 6153",
    "Applethorpe, QLD 4378",
    "Appletree Flat, NSW 2330",
    "Apslawn, TAS 7190",
    "Apsley, NSW 2820",
    "Apsley, TAS 7030",
    "Apsley, VIC 3319",
    "Arable, NSW 2630",
    "Arakoon, NSW 2431",
    "Araluen, NT 0870",
    "Araluen, NSW 2622",
    "Araluen, QLD 4570",
    "Aramac, QLD 4726",
    "Aramara, QLD 4620",
    "Arana Hills, QLD 4054",
    "Aranbanga, QLD 4625",
    "Aranda, ACT 2614",
    "Arapiles, VIC 3409",
    "Ararat, VIC 3377",
    "Aratula, NSW 2714",
    "Aratula, QLD 4309",
    "Arawata, VIC 3951",
    "Arbouin, QLD 4871",
    "Arbuckle, VIC 3858",
    "Arcadia, NSW 2159",
    "Arcadia, QLD 4819",
    "Arcadia, VIC 3631",
    "Arcadia South, VIC 3631",
    "Arcadia Vale, NSW 2283",
    "Archdale, VIC 3475",
    "Archdale Junction, VIC 3475",
    "Archer, NT 0830",
    "Archer River, QLD 4871",
    "Archerfield, QLD 4108",
    "Archerfield Bc, QLD 4108",
    "Archerton, VIC 3723",
    "Archies Creek, VIC 3995",
    "Ardath, WA 6419",
    "Ardeer, VIC 3022",
    "Ardglen, NSW 2338",
    "Arding, NSW 2358",
    "Ardlethan, NSW 2665",
    "Ardmona, VIC 3629",
    "Ardross, WA 6153",
    "Ardrossan, SA 5571",
    "Areegra, VIC 3480",
    "Areyonga, NT 0872",
    "Argalong, NSW 2720",
    "Argenton, NSW 2284",
    "Argents Hill, NSW 2449",
    "Argoon, QLD 4702",
    "Argoon, NSW 2707",
    "Argyle, VIC 3523",
    "Argyle, NSW 2350",
    "Argyle, WA 6239",
    "Argyll, QLD 4721",
    "Ariah Park, NSW 2665",
    "Arkaroola Village, SA 5710",
    "Arkell, NSW 2795",
    "Arkstone, NSW 2795",
    "Armadale, WA 6992",
    "Armadale, VIC 3143",
    "Armadale, WA 6112",
    "Armadale North, VIC 3143",
    "Armagh, SA 5453",
    "Armatree, NSW 2831",
    "Armidale, NSW 2350",
    "Armstrong, VIC 3377",
    "Armstrong Beach, QLD 4737",
    "Armstrong Creek, QLD 4520",
    "Arncliffe, NSW 2205",
    "Arndell Park, NSW 2148",
    "Arno Bay, SA 5603",
    "Arnold, NT 0852",
    "Arnold, VIC 3551",
    "Arnold West, VIC 3551",
    "Aroona, QLD 4551",
    "Arrawarra, NSW 2456",
    "Arrawarra Headland, NSW 2456",
    "Arriga, QLD 4880",
    "Arrino, WA 6519",
    "Arrowsmith, WA 6525",
    "Arrowsmith East, WA 6519",
    "Artarmon, NSW 2064",
    "Artarmon, NSW 1570",
    "Arthur River, WA 6315",
    "Arthur River, TAS 7330",
    "Arthurs Creek, VIC 3099",
    "Arthurs Lake, TAS 7030",
    "Arthurs Seat, VIC 3936",
    "Arthurton, SA 5572",
    "Arthurville, NSW 2820",
    "Arumbera, NT 0870",
    "Arumpo, NSW 2715",
    "Arundel, QLD 4214",
    "Arundel Bc, QLD 4214",
    "Ascot, VIC 3364",
    "Ascot, QLD 4007",
    "Ascot, WA 6104",
    "Ascot, VIC 3551",
    "Ascot Park, SA 5043",
    "Ascot Vale, VIC 3032",
    "Ashbourne, VIC 3442",
    "Ashbourne, SA 5157",
    "Ashburton, VIC 3147",
    "Ashbury, NSW 2193",
    "Ashby, NSW 2463",
    "Ashby, WA 6065",
    "Ashby Heights, NSW 2463",
    "Ashby Island, NSW 2463",
    "Ashcroft, NSW 2168",
    "Ashendon, WA 6111",
    "Ashfield, NSW 2131",
    "Ashfield, WA 6054",
    "Ashfield, QLD 4670",
    "Ashfield, NSW 1800",
    "Ashford, NSW 2361",
    "Ashford, SA 5035",
    "Ashgrove, QLD 4060",
    "Ashgrove West, QLD 4060",
    "Ashley, NSW 2400",
    "Ashmont, NSW 2650",
    "Ashmore, QLD 4214",
    "Ashmore City, QLD 4214",
    "Ashton, SA 5137",
    "Ashtonfield, NSW 2323",
    "Ashville, SA 5259",
    "Ashwell, QLD 4340",
    "Ashwood, VIC 3147",
    "Aspendale, VIC 3195",
    "Aspendale Gardens, VIC 3195",
    "Aspley, QLD 4034",
    "Asquith, NSW 2077",
    "Athelstone, SA 5076",
    "Atherton, QLD 4883",
    "Athlone, VIC 3818",
    "Athol, QLD 4350",
    "Athol Park, SA 5012",
    "Atholwood, NSW 2361",
    "Atitjere, NT 0872",
    "Atkinsons Dam, QLD 4311",
    "Ato Activity Statements, NSW 1391",
    "Attadale, WA 6156",
    "Attunga, NSW 2345",
    "Attwood, VIC 3049",
    "Atwell, WA 6164",
    "Aubigny, QLD 4401",
    "Aubin Grove, WA 6164",
    "Aubrey, VIC 3393",
    "Auburn, NSW 2144",
    "Auburn, NSW 1835",
    "Auburn, SA 5451",
    "Auburn, VIC 3123",
    "Auburn South, VIC 3122",
    "Auburn Vale, NSW 2360",
    "Auchenflower, QLD 4066",
    "Auchmore, VIC 3570",
    "Audley, NSW 2232",
    "Augathella, QLD 4477",
    "Augusta, WA 6290",
    "Augustine Heights, QLD 4300",
    "Auldana, SA 5072",
    "Aurukun, QLD 4871",
    "Austinmer, NSW 2515",
    "Austins Ferry, TAS 7011",
    "Austinville, QLD 4213",
    "Austral, NSW 2179",
    "Austral Eden, NSW 2440",
    "Australia Fair, QLD 4215",
    "Australia Plains, SA 5374",
    "Australia Post Sales And Marketing, QLD 9250",
    "Australian Defence Forces, NSW 2890",
    "Australind, WA 6233",
    "Avalon, NSW 2107",
    "Avalon, VIC 3212",
    "Avalon Beach, NSW 2107",
    "Aveley, WA 6069",
    "Avenel, VIC 3664",
    "Avenell Heights, QLD 4670",
    "Avenue Range, SA 5273",
    "Avisford, NSW 2850",
    "Avoca, TAS 7213",
    "Avoca, QLD 4670",
    "Avoca, VIC 3467",
    "Avoca, NSW 2577",
    "Avoca Beach, NSW 2251",
    "Avoca Dell, SA 5253",
    "Avoca Vale, QLD 4306",
    "Avon, SA 5501",
    "Avon, NSW 2574",
    "Avon Downs, NT 0862",
    "Avon Plains, VIC 3477",
    "Avon Valley National Park, WA 6084",
    "Avondale, NSW 2530",
    "Avondale, QLD 4670",
    "Avondale Heights, VIC 3034",
    "Avonmore, VIC 3559",
    "Avonside, NSW 2628",
    "Avonsleigh, VIC 3782",
    "Awaba, NSW 2283",
    "Axe Creek, VIC 3551",
    "Axedale, VIC 3551",
    "Ayers Rock, NT 0872",
    "Aylmerton, NSW 2575",
    "Ayr, QLD 4807",
    "Ayrford, VIC 3268",
    "Ayton, QLD 4895",
    "Baan Baa, NSW 2390",
    "Baandee, WA 6412",
    "Babakin, WA 6428",
    "Babbage Island, WA 6701",
    "Babinda, NSW 2825",
    "Babinda, QLD 4861",
    "Babyl Creek, NSW 2470",
    "Bacchus Marsh, VIC 3340",
    "Back Creek, NSW 2622",
    "Back Creek, NSW 2422",
    "Back Creek, NSW 2372",
    "Back Creek, NSW 2484",
    "Back Creek, NSW 2671",
    "Back Creek, NSW 2390",
    "Back Forest, NSW 2535",
    "Back Plains, QLD 4361",
    "Back Valley, SA 5211",
    "Backmede, NSW 2470",
    "Backwater, NSW 2365",
    "Backy Point, SA 5600",
    "Baddaginnie, VIC 3670",
    "Baden, TAS 7120",
    "Badgebup, WA 6317",
    "Badger Creek, VIC 3777",
    "Badger Head, TAS 7270",
    "Badgerin Rock, WA 6475",
    "Badgerys Creek, NSW 2555",
    "Badgin, WA 6302",
    "Badgingarra, WA 6521",
    "Badja, NSW 2630",
    "Badjaling, WA 6383",
    "Badu Island, QLD 4875",
    "Bael Bael, VIC 3579",
    "Baerami, NSW 2333",
    "Baerami Creek, NSW 2333",
    "Baffle Creek, QLD 4674",
    "Baffle West, QLD 4454",
    "Bagdad, TAS 7030",
    "Bagdad North, TAS 7030",
    "Bagnoo, NSW 2446",
    "Bago, NSW 2446",
    "Bagot, NT 0820",
    "Bagot Well, SA 5373",
    "Bagotville, NSW 2477",
    "Bagshot, VIC 3551",
    "Bagshot North, VIC 3551",
    "Bahgallah, VIC 3312",
    "Bahrs Scrub, QLD 4207",
    "Bailieston, VIC 3608",
    "Bailup, WA 6082",
    "Baines, NT 0852",
    "Baird Bay, SA 5671",
    "Bairnsdale, VIC 3875",
    "Bajool, QLD 4699",
    "Bakara, SA 5354",
    "Bakara Well, SA 5354",
    "Bakers Beach, TAS 7307",
    "Bakers Bend, QLD 4470",
    "Bakers Creek, NSW 2359",
    "Bakers Creek, NSW 2422",
    "Bakers Creek, QLD 4740",
    "Bakers Creek, NSW 2447",
    "Bakers Hill, WA 6562",
    "Bakers Swamp, NSW 2820",
    "Bakery Hill, VIC 3354",
    "Bakery Hill, VIC 3350",
    "Bakewell, NT 0832",
    "Bakewell, NT 0832",
    "Baking Board, QLD 4413",
    "Balaclava, VIC 3183",
    "Balaclava, NSW 2575",
    "Balaklava, SA 5461",
    "Balala, NSW 2358",
    "Balarang, NSW 2529",
    "Balbarrup, WA 6258",
    "Balberra, QLD 4740",
    "Balcatta, WA 6021",
    "Balcatta, WA 6914",
    "Balcolyn, NSW 2264",
    "Balcomba, QLD 4702",
    "Bald Blair, NSW 2365",
    "Bald Hills, NSW 2549",
    "Bald Hills, VIC 3364",
    "Bald Hills, QLD 4036",
    "Bald Hills, SA 5203",
    "Bald Knob, QLD 4552",
    "Bald Nob, NSW 2370",
    "Bald Ridge, NSW 2795",
    "Baldersleigh, NSW 2365",
    "Baldina, SA 5417",
    "Baldivis, WA 6171",
    "Baldry, NSW 2867",
    "Balfes Creek, QLD 4820",
    "Balfours Peak, NSW 2402",
    "Balga, WA 6061",
    "Balgal Beach, QLD 4816",
    "Balgowan, SA 5573",
    "Balgowan, QLD 4401",
    "Balgowlah, NSW 2093",
    "Balgowlah Heights, NSW 2093",
    "Balgownie, NSW 2519",
    "Balhannah, SA 5242",
    "Balickera, NSW 2324",
    "Balingup, WA 6253",
    "Balintore, VIC 3249",
    "Balkuling, WA 6383",
    "Ball Bay, QLD 4741",
    "Balla Balla, WA 6714",
    "Balladong, WA 6302",
    "Balladonia, WA 6443",
    "Balladoran, NSW 2831",
    "Ballajura, WA 6066",
    "Ballalaba, NSW 2622",
    "Ballan, VIC 3342",
    "Ballandean, QLD 4382",
    "Ballangeich, VIC 3279",
    "Ballapur, VIC 3483",
    "Ballarat, VIC 3353",
    "Ballarat, VIC 3350",
    "Ballarat Central, VIC 3350",
    "Ballarat East, VIC 3350",
    "Ballarat North, VIC 3350",
    "Ballarat Roadside Delivery, VIC 3352",
    "Ballarat West, VIC 3350",
    "Ballard, QLD 4352",
    "Ballaroo, QLD 4455",
    "Ballast Head, SA 5221",
    "Ballaying, WA 6315",
    "Balldale, NSW 2646",
    "Ballendella, VIC 3561",
    "Ballengarra, NSW 2441",
    "Balliang, VIC 3340",
    "Balliang East, VIC 3340",
    "Ballidu, WA 6606",
    "Ballimore, NSW 2830",
    "Ballina, NSW 2478",
    "Ballogie, QLD 4610",
    "Bally Bally, WA 6304",
    "Ballyroe, NSW 2795",
    "Ballyrogan, VIC 3375",
    "Balmain, NSW 2041",
    "Balmain East, NSW 2041",
    "Balmattum, VIC 3666",
    "Balmoral, QLD 4171",
    "Balmoral, NSW 2088",
    "Balmoral, NSW 2571",
    "Balmoral, NSW 2283",
    "Balmoral, VIC 3407",
    "Balmoral Ridge, QLD 4552",
    "Balnagowan, QLD 4740",
    "Balnarring, VIC 3926",
    "Balnarring Beach, VIC 3926",
    "Balook, VIC 3971",
    "Balranald, NSW 2715",
    "Balwyn, VIC 3103",
    "Balwyn East, VIC 3103",
    "Balwyn North, VIC 3104",
    "Bamaga, QLD 4876",
    "Bamarang, NSW 2540",
    "Bamawm, VIC 3561",
    "Bamawm Extension, VIC 3564",
    "Bambaroo, QLD 4850",
    "Bamboo, QLD 4873",
    "Bamboo Creek, QLD 4860",
    "Bambra, VIC 3241",
    "Bambun, WA 6503",
    "Bamganie, VIC 3333",
    "Ban Ban, QLD 4625",
    "Ban Ban Springs, QLD 4625",
    "Banana, QLD 4702",
    "Banca, TAS 7265",
    "Bancroft, QLD 4630",
    "Banda Banda, NSW 2446",
    "Bandiana, VIC 3694",
    "Bandiana, VIC 3691",
    "Bandiana Milpo, VIC 3694",
    "Bandon, NSW 2871",
    "Bandon Grove, NSW 2420",
    "Bandy Creek, WA 6450",
    "Bandya, WA 6440",
    "Bangadang, NSW 2729",
    "Bangadilly, NSW 2577",
    "Bangalee, QLD 4703",
    "Bangalee, NSW 2541",
    "Bangalow, NSW 2479",
    "Bangerang, VIC 3393",
    "Bangham, SA 5268",
    "Bangheet, NSW 2404",
    "Bangholme, VIC 3175",
    "Bango, NSW 2582",
    "Bangor, NSW 2234",
    "Bangor, SA 5481",
    "Bangor, TAS 7267",
    "Banjup, WA 6164",
    "Banks, ACT 2906",
    "Banks Creek, QLD 4306",
    "Banks Island, QLD 4875",
    "Banks Pocket, QLD 4570",
    "Banksia, NSW 2216",
    "Banksia Beach, QLD 4507",
    "Banksia Grove, WA 6031",
    "Banksia Park, SA 5091",
    "Banksia Peninsula, VIC 3875",
    "Banksiadale, WA 6213",
    "Banksmeadow, NSW 2019",
    "Bankstown, NSW 2200",
    "Bankstown, NSW 1885",
    "Bankstown, NSW 1888",
    "Bankstown Aerodrome, NSW 2200",
    "Bankstown North, NSW 2200",
    "Bankstown Square, NSW 2200",
    "Bannaby, NSW 2580",
    "Bannerton, VIC 3549",
    "Bannister, NSW 2580",
    "Bannister, WA 6390",
    "Bannockburn, VIC 3331",
    "Bannockburn, QLD 4207",
    "Banoon, NSW 2347",
    "Banora Point, NSW 2486",
    "Banyabba, NSW 2469",
    "Banyan, VIC 3485",
    "Banyena, VIC 3388",
    "Banyo, QLD 4014",
    "Banyule, VIC 3084",
    "Bapaume, QLD 4352",
    "Bar Beach, NSW 2300",
    "Bar Point, NSW 2083",
    "Bara, NSW 2850",
    "Barabba, SA 5460",
    "Baradine, NSW 2396",
    "Baralaba, QLD 4702",
    "Barambah, QLD 4601",
    "Baranduda, VIC 3691",
    "Barangaroo, NSW 2000",
    "Barbalin, WA 6479",
    "Barberton, WA 6510",
    "Barbigal, NSW 2830",
    "Barcaldine, QLD 4725",
    "Barcaldine Downs, QLD 4725",
    "Barcoongere, NSW 2460",
    "Barden Ridge, NSW 2234",
    "Bardia, NSW 2565",
    "Bardon, QLD 4065",
    "Bardwell Park, NSW 2207",
    "Bardwell Valley, NSW 2207",
    "Baree, QLD 4714",
    "Bareena, VIC 3220",
    "Barellan, NSW 2665",
    "Barellan Point, QLD 4306",
    "Barfold, VIC 3444",
    "Bargara, QLD 4670",
    "Bargo, NSW 2574",
    "Barham, NSW 2732",
    "Barigan, NSW 2850",
    "Baringhup, VIC 3463",
    "Baringhup West, VIC 3463",
    "Barinia, SA 5453",
    "Barjarg, VIC 3723",
    "Barker Creek Flat, QLD 4615",
    "Barkers Creek, VIC 3451",
    "Barkers Vale, NSW 2474",
    "Barkly, VIC 3384",
    "Barkly, QLD 4825",
    "Barkstead, VIC 3364",
    "Barlil, QLD 4605",
    "Barlows Hill, QLD 4703",
    "Barlyne, QLD 4625",
    "Barmah, VIC 3639",
    "Barmaryee, QLD 4703",
    "Barmedman, NSW 2668",
    "Barmera, SA 5345",
    "Barmoya, QLD 4703",
    "Barna, SA 5641",
    "Barnadown, VIC 3557",
    "Barnard, QLD 4702",
    "Barnawartha, VIC 3688",
    "Barnawartha North, VIC 3691",
    "Barndioota, SA 5434",
    "Barnes Bay, TAS 7150",
    "Barney Point, QLD 4680",
    "Barney View, QLD 4287",
    "Barneys Reef, NSW 2852",
    "Barnsley, NSW 2278",
    "Baromi, VIC 3871",
    "Barongarook, VIC 3249",
    "Barongarook West, VIC 3249",
    "Barooga, NSW 3644",
    "Baroota, SA 5495",
    "Barossa Goldfields, SA 5351",
    "Barraba, NSW 2347",
    "Barrabool, VIC 3221",
    "Barrabup, WA 6275",
    "Barrack Heights, NSW 2528",
    "Barrack Point, NSW 2528",
    "Barraganyatti, NSW 2441",
    "Barragga Bay, NSW 2546",
    "Barragup, WA 6209",
    "Barrakee, VIC 3525",
    "Barramornie, QLD 4416",
    "Barramunga, VIC 3249",
    "Barraport, VIC 3537",
    "Barraport West, VIC 3537",
    "Barratta, QLD 4809",
    "Barratta, NSW 2710",
    "Barren Grounds, NSW 2577",
    "Barrengarry, NSW 2577",
    "Barretta, TAS 7054",
    "Barretts Creek, NSW 2460",
    "Barrine, QLD 4872",
    "Barringella, NSW 2540",
    "Barringha, QLD 4816",
    "Barrington, NSW 2422",
    "Barrington, TAS 7306",
    "Barrington Tops, NSW 2422",
    "Barringun, QLD 4490",
    "Barron, QLD 4878",
    "Barron Gorge, QLD 4870",
    "Barrow Creek, NT 0872",
    "Barrow Island, WA 6712",
    "Barry, NSW 2340",
    "Barry, NSW 2799",
    "Barrys Reef, VIC 3458",
    "Bartle Frere, QLD 4861",
    "Barton, ACT 0221",
    "Barton, ACT 2600",
    "Barunah Park, VIC 3329",
    "Barunah Plains, VIC 3329",
    "Barunga, NT 0852",
    "Barunga Gap, SA 5520",
    "Barwidgee, VIC 3737",
    "Barwidgi, QLD 4872",
    "Barwite, VIC 3722",
    "Barwon, NSW 2396",
    "Barwon Downs, VIC 3243",
    "Barwon Heads, VIC 3227",
    "Baryulgil, NSW 2460",
    "Basalt, VIC 3460",
    "Basalt, QLD 4820",
    "Basilisk, QLD 4871",
    "Basin Pocket, QLD 4305",
    "Basin View, NSW 2540",
    "Baskerville, WA 6056",
    "Basket Range, SA 5138",
    "Bass, VIC 3991",
    "Bass Hill, NSW 2197",
    "Bassendean, NSW 2365",
    "Bassendean, WA 6054",
    "Bassendean, WA 6934",
    "Batar Creek, NSW 2439",
    "Batchelor, NT 0845",
    "Bateau Bay, NSW 2261",
    "Batehaven, NSW 2536",
    "Bateman, WA 6150",
    "Batemans Bay, NSW 2536",
    "Batesford, VIC 3221",
    "Bathampton, NSW 2795",
    "Bathumi, VIC 3730",
    "Bathurst, NSW 2795",
    "Bathurst Island, NT 0822",
    "Bathurst Street Po, TAS 7000",
    "Batlow, NSW 2730",
    "Batman, VIC 3058",
    "Battery Hill, QLD 4551",
    "Battery Point, TAS 7004",
    "Baudin, WA 6284",
    "Baudin Beach, SA 5222",
    "Bauhinia, QLD 4718",
    "Baulkham Hills, NSW 2153",
    "Baulkham Hills, NSW 1755",
    "Bauple, QLD 4650",
    "Bauple Forest, QLD 4650",
    "Baw Baw, NSW 2580",
    "Baw Baw, VIC 3833",
    "Baw Baw Village, VIC 3833",
    "Bawley Point, NSW 2539",
    "Baxter, VIC 3911",
    "Baxters Ridge, NSW 2422",
    "Bay Of Shoals, SA 5223",
    "Bay Village, NSW 2261",
    "Bayindeen, VIC 3375",
    "Bayles, VIC 3981",
    "Baynton, VIC 3444",
    "Baynton, WA 6714",
    "Baynton East, VIC 3444",
    "Bayonet Head, WA 6330",
    "Bayrick, QLD 4478",
    "Bayswater, WA 6053",
    "Bayswater, VIC 3153",
    "Bayswater, WA 6933",
    "Bayswater North, VIC 3153",
    "Bayview, NT 0820",
    "Bayview, NT 0820",
    "Bayview, NSW 2104",
    "Bayview Heights, QLD 4868",
    "Beach Holm, QLD 4818",
    "Beachlands, WA 6530",
    "Beachmere, QLD 4510",
    "Beachport, SA 5280",
    "Beacon, WA 6472",
    "Beacon Hill, NSW 2100",
    "Beaconsfield, TAS 7270",
    "Beaconsfield, WA 6162",
    "Beaconsfield, QLD 4740",
    "Beaconsfield, NSW 2015",
    "Beaconsfield, VIC 3807",
    "Beaconsfield Upper, VIC 3808",
    "Beadell, WA 6440",
    "Bealiba, VIC 3475",
    "Bean Creek, NSW 2469",
    "Bearbong, NSW 2827",
    "Beard, ACT 2620",
    "Bearii, VIC 3641",
    "Bears Lagoon, VIC 3517",
    "Beatrice, QLD 4886",
    "Beatty, SA 5320",
    "Beauchamp, VIC 3579",
    "Beaudesert, QLD 4285",
    "Beaufort, QLD 4724",
    "Beaufort, SA 5550",
    "Beaufort, VIC 3373",
    "Beaufort River, WA 6394",
    "Beaumaris, TAS 7215",
    "Beaumaris, VIC 3193",
    "Beaumont, WA 6450",
    "Beaumont, SA 5066",
    "Beaumont, NSW 2577",
    "Beaumont Hills, NSW 2155",
    "Beaumonts, SA 5320",
    "Beauty Point, TAS 7270",
    "Beaver Rock, QLD 4650",
    "Beazleys Bridge, VIC 3477",
    "Beckenham, WA 6107",
    "Beckom, NSW 2665",
    "Bective, NSW 2340",
    "Bectric, NSW 2665",
    "Bedford, WA 6052",
    "Bedford Park, SA 5042",
    "Bedford Road, VIC 3135",
    "Bedfordale, WA 6112",
    "Bedgerebong, NSW 2871",
    "Bedourie, QLD 4829",
    "Beeac, VIC 3251",
    "Beebo, QLD 4385",
    "Beech Forest, VIC 3237",
    "Beechboro, WA 6063",
    "Beecher, QLD 4680",
    "Beechford, TAS 7252",
    "Beechina, WA 6556",
    "Beechmont, QLD 4211",
    "Beechwood, NSW 2446",
    "Beechworth, VIC 3747",
    "Beecroft, NSW 2119",
    "Beecroft Peninsula, NSW 2540",
    "Beedelup, WA 6260",
    "Beela, WA 6224",
    "Beelbangera, NSW 2680",
    "Beelbi Creek, QLD 4659",
    "Beelerup, WA 6239",
    "Beeliar, WA 6164",
    "Beemunnel, NSW 2824",
    "Beenaam Valley, QLD 4570",
    "Beenak, VIC 3139",
    "Beenleigh, QLD 4207",
    "Beenong, WA 6353",
    "Beerburrum, QLD 4517",
    "Beermullah, WA 6503",
    "Beeron, QLD 4626",
    "Beerwah, QLD 4519",
    "Bees Creek, NT 0822",
    "Beetaloo, SA 5523",
    "Bega, NSW 2550",
    "Begonia, QLD 4487",
    "Beilba, QLD 4454",
    "Bejoording, WA 6566",
    "Belair, SA 5052",
    "Belalie East, SA 5491",
    "Belalie North, SA 5491",
    "Belanglo, NSW 2577",
    "Belbora, NSW 2422",
    "Belcong, QLD 4723",
    "Belconnen, ACT 2616",
    "Belconnen, ACT 2617",
    "Beldon, WA 6027",
    "Belfield, NSW 2191",
    "Belford, NSW 2335",
    "Belfrayden, NSW 2650",
    "Belgian Gardens, QLD 4810",
    "Belgrave, VIC 3160",
    "Belgrave Heights, VIC 3160",
    "Belgrave South, VIC 3160",
    "Belgravia, NSW 2800",
    "Belhus, WA 6069",
    "Belimbla Park, NSW 2570",
    "Belivah, QLD 4207",
    "Bell, QLD 4408",
    "Bell, NSW 2786",
    "Bell Bay, TAS 7253",
    "Bell Park, VIC 3215",
    "Bell Post Hill, VIC 3215",
    "Bella Creek, QLD 4570",
    "Bella Vista, NSW 2153",
    "Bellamack, NT 0832",
    "Bellambi, NSW 2518",
    "Bellangry, NSW 2446",
    "Bellara, QLD 4507",
    "Bellarine, VIC 3221",
    "Bellata, NSW 2397",
    "Bellawongarah, NSW 2535",
    "Bellbird, NSW 2325",
    "Bellbird Creek, VIC 3889",
    "Bellbird Heights, NSW 2325",
    "Bellbird Park, QLD 4300",
    "Bellbowrie, QLD 4070",
    "Bellbrae, VIC 3228",
    "Bellbridge, VIC 3691",
    "Bellbrook, NSW 2440",
    "Bellellen, VIC 3381",
    "Bellenden Ker, QLD 4871",
    "Bellerive, TAS 7018",
    "Bellevue, WA 6056",
    "Bellevue, QLD 4871",
    "Bellevue Heights, SA 5050",
    "Bellevue Hill, NSW 2023",
    "Bellfield, VIC 3381",
    "Bellfield, VIC 3081",
    "Bellfield, QLD 4822",
    "Belli Park, QLD 4562",
    "Bellimbopinni, NSW 2440",
    "Bellingen, NSW 2454",
    "Bellingham, TAS 7254",
    "Bellmere, QLD 4510",
    "Bellmount Forest, NSW 2581",
    "Bells Beach, VIC 3228",
    "Bells Bridge, QLD 4570",
    "Bells Creek, QLD 4551",
    "Bellthorpe, QLD 4514",
    "Belltrees, NSW 2337",
    "Belmont, WA 6984",
    "Belmont, QLD 4153",
    "Belmont, WA 6104",
    "Belmont, NSW 2280",
    "Belmont, VIC 3216",
    "Belmont North, NSW 2280",
    "Belmont South, NSW 2280",
    "Belmore, NSW 2192",
    "Belmore River, NSW 2440",
    "Belmunda, QLD 4740",
    "Beloka, NSW 2628",
    "Belowra, NSW 2545",
    "Belrose, NSW 2085",
    "Belrose West, NSW 2085",
    "Beltana, SA 5730",
    "Belton, SA 5432",
    "Belvedere, QLD 4860",
    "Belvedere Park, VIC 3198",
    "Belvidere, SA 5255",
    "Belyuen, NT 0822",
    "Bemboka, NSW 2550",
    "Bemerside, QLD 4850",
    "Bemm River, VIC 3889",
    "Ben Buckley, NSW 2850",
    "Ben Bullen, NSW 2790",
    "Ben Lomond, NSW 2365",
    "Ben Lomond, TAS 7212",
    "Bena, VIC 3946",
    "Benair, QLD 4610",
    "Benalla, VIC 3671",
    "Benalla, VIC 3672",
    "Benalla West, VIC 3672",
    "Benambra, VIC 3900",
    "Benandarah, NSW 2536",
    "Benaraby, QLD 4680",
    "Benarkin, QLD 4306",
    "Benarkin North, QLD 4306",
    "Benayeo, VIC 3319",
    "Benbournie, SA 5453",
    "Bencubbin, WA 6477",
    "Bend Of Islands, VIC 3097",
    "Bendalong, NSW 2539",
    "Bendemeer, NSW 2355",
    "Bendick Murrell, NSW 2803",
    "Bendigo, VIC 3552",
    "Bendigo, VIC 3550",
    "Bendigo Forward, VIC 3551",
    "Bendigo South, VIC 3550",
    "Bendoc, VIC 3888",
    "Bendolba, NSW 2420",
    "Bendoura, NSW 2622",
    "Benerembah, NSW 2680",
    "Bengalla, NSW 2333",
    "Benger, WA 6223",
    "Bengworden, VIC 3875",
    "Benholme, QLD 4754",
    "Beni, NSW 2830",
    "Benjaberring, WA 6463",
    "Benjeroop, VIC 3579",
    "Benjinup, WA 6255",
    "Benloch, VIC 3435",
    "Bennett Springs, WA 6063",
    "Bennetts Green, NSW 2290",
    "Bennettswood, VIC 3125",
    "Bennison, VIC 3960",
    "Benobble, QLD 4275",
    "Benowa, QLD 4217",
    "Bensville, NSW 2251",
    "Bentleigh, VIC 3204",
    "Bentleigh East, VIC 3165",
    "Bentley, WA 6982",
    "Bentley, NSW 2480",
    "Bentley, WA 6102",
    "Bentley Park, QLD 4869",
    "Bentley South, WA 6102",
    "Benwerrin, VIC 3235",
    "Berajondo, QLD 4674",
    "Berala, NSW 2141",
    "Berambing, NSW 2758",
    "Berat, QLD 4362",
    "Beremboke, VIC 3342",
    "Beresfield, NSW 2322",
    "Beresford, WA 6530",
    "Bergalia, NSW 2537",
    "Bergen, QLD 4353",
    "Berkeley, NSW 2506",
    "Berkeley Vale, NSW 2261",
    "Berkshire Park, NSW 2765",
    "Berkshire Valley, WA 6510",
    "Berlang, NSW 2622",
    "Bermagui, NSW 2546",
    "Bernier Island, WA 6701",
    "Berowra, NSW 2081",
    "Berowra Creek, NSW 2082",
    "Berowra Heights, NSW 2082",
    "Berowra Waters, NSW 2082",
    "Berrambool, NSW 2548",
    "Berrara, NSW 2540",
    "Berremangra, NSW 2582",
    "Berri, SA 5343",
    "Berrico, NSW 2422",
    "Berridale, NSW 2628",
    "Berriedale, TAS 7011",
    "Berrigal, NSW 2390",
    "Berrigan, NSW 2712",
    "Berrilee, NSW 2159",
    "Berrima, NSW 2577",
    "Berrimah, NT 0828",
    "Berrimah, NT 0828",
    "Berrimal, VIC 3518",
    "Berrinba, QLD 4117",
    "Berringa, VIC 3351",
    "Berringama, VIC 3691",
    "Berringer Lake, NSW 2539",
    "Berriwillock, VIC 3531",
    "Berry, NSW 2535",
    "Berry Jerry, NSW 2701",
    "Berry Mountain, NSW 2535",
    "Berry Park, NSW 2321",
    "Berry Springs, NT 0838",
    "Berry Springs, NT 0838",
    "Berrybank, VIC 3323",
    "Berrys Creek, VIC 3953",
    "Berserker, QLD 4701",
    "Berthong, NSW 2594",
    "Bertram, WA 6167",
    "Berwick, VIC 3806",
    "Beryl, NSW 2852",
    "Bessiebelle, VIC 3304",
    "Beswick, NT 0852",
    "Beswick Creek, NT 0852",
    "Bet Bet, VIC 3472",
    "Bete Bolong, VIC 3888",
    "Bete Bolong North, VIC 3888",
    "Bethanga, VIC 3691",
    "Bethania, QLD 4205",
    "Bethany, SA 5352",
    "Bethel, SA 5373",
    "Bethungra, NSW 2590",
    "Betley, VIC 3472",
    "Beulah, TAS 7306",
    "Beulah, VIC 3395",
    "Beulah Park, SA 5067",
    "Bevendale, NSW 2581",
    "Beverford, VIC 3590",
    "Beveridge, VIC 3753",
    "Beverley, WA 6304",
    "Beverley, SA 5009",
    "Beverley Park, NSW 2217",
    "Beverly Hills, NSW 2209",
    "Bewong, NSW 2540",
    "Bexhill, NSW 2480",
    "Bexley, NSW 2207",
    "Bexley North, NSW 2207",
    "Bexley South, NSW 2207",
    "Biala, NSW 2581",
    "Biarra, QLD 4313",
    "Bibaringa, SA 5118",
    "Bibbenluke, NSW 2632",
    "Biboohra, QLD 4880",
    "Bibra Lake, WA 6163",
    "Bicheno, TAS 7215",
    "Bickley, WA 6076",
    "Bickley Vale, NSW 2570",
    "Bicton, WA 6157",
    "Biddaddaba, QLD 4275",
    "Biddelia, WA 6260",
    "Biddeston, QLD 4401",
    "Biddon, NSW 2827",
    "Bidgeemia, NSW 2642",
    "Bidwill, QLD 4650",
    "Bidwill, NSW 2770",
    "Bielsdown Hills, NSW 2453",
    "Big Bend, SA 5238",
    "Big Desert, VIC 3490",
    "Big Grove, WA 6330",
    "Big Hill, NSW 2579",
    "Big Hill, VIC 3555",
    "Big Hill, VIC 3231",
    "Big Jacks Creek, NSW 2339",
    "Big Pats Creek, VIC 3799",
    "Big Ridge, NSW 2330",
    "Big Springs, NSW 2650",
    "Big Yengo, NSW 2330",
    "Bigga, NSW 2583",
    "Biggara, VIC 3707",
    "Biggenden, QLD 4621",
    "Biggera Waters, QLD 4216",
    "Biggs Flat, SA 5153",
    "Bilambil, NSW 2486",
    "Bilambil Heights, NSW 2486",
    "Bilbarin, WA 6375",
    "Bilbul, NSW 2680",
    "Bilgola, NSW 2107",
    "Bilinga, QLD 4225",
    "Bilingurr, WA 6725",
    "Billa Billa, QLD 4390",
    "Billabong, VIC 3858",
    "Billeroy, NSW 2829",
    "Billiatt, SA 5311",
    "Billilingra, NSW 2630",
    "Billimari, NSW 2804",
    "Billinudgel, NSW 2483",
    "Billys Creek, NSW 2453",
    "Billywillinga, NSW 2795",
    "Biloela, QLD 4715",
    "Bilpin, NSW 2758",
    "Bilyana, QLD 4854",
    "Bimberi, NSW 2611",
    "Bimbi, NSW 2810",
    "Bimbijy, WA 6472",
    "Bimbimbie, NSW 2536",
    "Bimbourie, VIC 3533",
    "Binalong, NSW 2584",
    "Binalong Bay, TAS 7216",
    "Binbee, QLD 4805",
    "Binda, NSW 2583",
    "Bindera, NSW 2422",
    "Bindi, VIC 3896",
    "Bindi Bindi, WA 6574",
    "Bindoon, WA 6502",
    "Bindoon Training Area, WA 6502",
    "Binduli, WA 6430",
    "Bingara, NSW 2404",
    "Bingeebeebra Creek, NSW 2469",
    "Bingegang, QLD 4702",
    "Bingie, NSW 2537",
    "Bingil Bay, QLD 4852",
    "Binginwarri, VIC 3966",
    "Bingleburra, NSW 2311",
    "Bingo Munjie, VIC 3898",
    "Biniguy, NSW 2399",
    "Binjari, NT 0852",
    "Binjour, QLD 4625",
    "Binjura, NSW 2630",
    "Binna Burra, NSW 2479",
    "Binna Burra, QLD 4211",
    "Binnaway, NSW 2395",
    "Binningup, WA 6233",
    "Binnu, WA 6532",
    "Binnum, SA 5262",
    "Binya, NSW 2665",
    "Biraganbil, NSW 2852",
    "Birchgrove, NSW 2041",
    "Birchip, VIC 3483",
    "Birchip West, VIC 3483",
    "Birchmont, WA 6214",
    "Birchmore, SA 5223",
    "Birchs Bay, TAS 7162",
    "Birdsville, QLD 4482",
    "Birdum, NT 0852",
    "Birdwood, SA 5234",
    "Birdwood, NSW 2446",
    "Birdwoodton, VIC 3505",
    "Birganbigil, NSW 2710",
    "Birkalla, QLD 4854",
    "Birkdale, QLD 4159",
    "Birkenhead, SA 5015",
    "Birmingham Gardens, NSW 2287",
    "Birnam, QLD 4285",
    "Birnam, QLD 4352",
    "Birralee, TAS 7303",
    "Birregurra, VIC 3242",
    "Birriwa, NSW 2844",
    "Birrong, NSW 2143",
    "Birtinya, QLD 4575",
    "Bishops Bridge, NSW 2326",
    "Bishopsbourne, TAS 7301",
    "Bithramere, NSW 2340",
    "Bittern, VIC 3918",
    "Black Creek, NSW 2729",
    "Black Creek, NSW 2439",
    "Black Duck Creek, QLD 4343",
    "Black Forest, SA 5035",
    "Black Head, NSW 2430",
    "Black Hill, SA 5353",
    "Black Hill, NSW 2322",
    "Black Hill, VIC 3350",
    "Black Hills, TAS 7140",
    "Black Hollow, NSW 2828",
    "Black Jack, QLD 4820",
    "Black Jungle, NT 0822",
    "Black Mountain, NSW 2365",
    "Black Mountain, ACT 2601",
    "Black Mountain, QLD 4563",
    "Black Point, SA 5571",
    "Black Range, NSW 2550",
    "Black Range, VIC 3381",
    "Black River, QLD 4818",
    "Black River, TAS 7321",
    "Black Rock, SA 5431",
    "Black Rock, VIC 3193",
    "Black Rock North, VIC 3193",
    "Black Snake, QLD 4600",
    "Black Springs, NSW 2787",
    "Black Springs, SA 5413",
    "Blackall, QLD 4472",
    "Blackalls Park, NSW 2283",
    "Blackbull, QLD 4871",
    "Blackburn, VIC 3130",
    "Blackburn North, VIC 3130",
    "Blackburn South, VIC 3130",
    "Blackbutt, NSW 2529",
    "Blackbutt, QLD 4306",
    "Blackbutt North, QLD 4306",
    "Blackbutt South, QLD 4306",
    "Blackdown, QLD 4702",
    "Blackett, NSW 2770",
    "Blackfellows Caves, SA 5291",
    "Blackfellows Creek, SA 5201",
    "Blackford, SA 5275",
    "Blackheath, NSW 2785",
    "Blackheath, VIC 3401",
    "Blackmans Bay, TAS 7052",
    "Blackmans Flat, NSW 2790",
    "Blackmans Point, NSW 2444",
    "Blackmore, NT 0822",
    "Blackrock, QLD 4850",
    "Blacks Beach, QLD 4740",
    "Blacksmiths, NSW 2281",
    "Blacksoil, QLD 4306",
    "Blackstone, QLD 4304",
    "Blackstone Heights, TAS 7250",
    "Blacktown, NSW 2148",
    "Blacktown Westpoint, NSW 2148",
    "Blackville, NSW 2343",
    "Blackwall, TAS 7275",
    "Blackwall, NSW 2256",
    "Blackwarry, VIC 3844",
    "Blackwater, QLD 4717",
    "Blackwood, VIC 3458",
    "Blackwood, SA 5051",
    "Blackwood Creek, TAS 7301",
    "Blackwood Forest, VIC 3992",
    "Blair Athol, NSW 2560",
    "Blair Athol, SA 5084",
    "Blair Athol West, SA 5084",
    "Blairgowrie, VIC 3942",
    "Blairmore, QLD 4625",
    "Blairmount, NSW 2559",
    "Blakebrook, NSW 2480",
    "Blakehurst, NSW 2221",
    "Blakeview, SA 5114",
    "Blakeville, VIC 3342",
    "Blakiston, SA 5250",
    "Blakney Creek, NSW 2581",
    "Blampied, VIC 3364",
    "Blanche Harbor, SA 5700",
    "Blanchetown, SA 5357",
    "Blanchview, QLD 4352",
    "Bland, NSW 2721",
    "Blandford, NSW 2338",
    "Blantyre, QLD 4310",
    "Blaxcell, NSW 2142",
    "Blaxland, NSW 2774",
    "Blaxland, QLD 4405",
    "Blaxland East, NSW 2774",
    "Blaxlands Creek, NSW 2460",
    "Blaxlands Ridge, NSW 2758",
    "Blayney, NSW 2799",
    "Blenheim, QLD 4341",
    "Blenheim Road, NSW 2113",
    "Blessington, TAS 7212",
    "Bletchley, SA 5255",
    "Blewitt Springs, SA 5171",
    "Bli Bli, QLD 4560",
    "Bligh Park, NSW 2756",
    "Blighty, NSW 2713",
    "Blind Bight, VIC 3980",
    "Blinman, SA 5730",
    "Bloomfield, QLD 4895",
    "Bloomsbury, QLD 4799",
    "Blowering, NSW 2720",
    "Blowhard, VIC 3352",
    "Blue Bay, NSW 2261",
    "Blue Haven, NSW 2262",
    "Blue Hills, QLD 4818",
    "Blue Knob, NSW 2480",
    "Blue Mountain, QLD 4737",
    "Blue Mountain Heights, QLD 4350",
    "Blue Nobby, NSW 2408",
    "Blue Rocks, TAS 7255",
    "Blue Vale, NSW 2380",
    "Bluewater, QLD 4818",
    "Bluewater Park, QLD 4818",
    "Blueys Beach, NSW 2428",
    "Bluff, QLD 4702",
    "Bluff Beach, SA 5575",
    "Bluff Point, WA 6530",
    "Blumont, TAS 7260",
    "Blyth, SA 5462",
    "Blythdale, QLD 4455",
    "Blythewood, WA 6208",
    "Bo Peep, VIC 3351",
    "Boallia, WA 6280",
    "Boambee, NSW 2450",
    "Boambee East, NSW 2452",
    "Boambolo, NSW 2582",
    "Boat Harbour, NSW 2480",
    "Boat Harbour, NSW 2316",
    "Boat Harbour, TAS 7321",
    "Boat Harbour Beach, TAS 7321",
    "Boatswain Point, SA 5275",
    "Bobadah, NSW 2825",
    "Bobalong, WA 6320",
    "Bobin, NSW 2429",
    "Bobinawarrah, VIC 3678",
    "Bobs Creek, NSW 2443",
    "Bobs Farm, NSW 2316",
    "Bobundara, NSW 2630",
    "Bochara, VIC 3301",
    "Bockelberg, SA 5655",
    "Boco, NSW 2631",
    "Bocoble, NSW 2850",
    "Bocobra, NSW 2865",
    "Boconnoc Park, SA 5453",
    "Bodalla, NSW 2545",
    "Bodallin, WA 6424",
    "Bodangora, NSW 2820",
    "Boddington, WA 6390",
    "Boeill Creek, NSW 2739",
    "Bogan, NSW 2825",
    "Bogan, NSW 2839",
    "Bogan Gate, NSW 2876",
    "Bogandilla, QLD 4425",
    "Bogangar, NSW 2488",
    "Bogee, NSW 2849",
    "Boggabilla, NSW 2409",
    "Boggabri, NSW 2382",
    "Bogie, QLD 4805",
    "Bogong, VIC 3699",
    "Bogong Peaks Wilderness, NSW 2720",
    "Bohena Creek, NSW 2390",
    "Bohle, QLD 4818",
    "Bohle Plains, QLD 4817",
    "Bohnock, NSW 2430",
    "Boho, VIC 3669",
    "Boho South, VIC 3669",
    "Boigbeat, VIC 3531",
    "Boigu Island, QLD 4875",
    "Boilup, WA 6394",
    "Boinka, VIC 3490",
    "Boisdale, VIC 3860",
    "Bokal, WA 6392",
    "Bokarina, QLD 4575",
    "Bolangum, VIC 3387",
    "Bolaro, NSW 2629",
    "Bolgart, WA 6568",
    "Bolinda, VIC 3432",
    "Bolivar, SA 5110",
    "Bolivia, NSW 2372",
    "Bollier, QLD 4570",
    "Bollon, QLD 4488",
    "Bolong, NSW 2540",
    "Bolto, SA 5238",
    "Bolton, VIC 3546",
    "Bolton Point, NSW 2283",
    "Bolwarra, VIC 3305",
    "Bolwarra, NSW 2320",
    "Bolwarra, QLD 4871",
    "Bolwarra Heights, NSW 2320",
    "Bolwarrah, VIC 3352",
    "Bom Bom, NSW 2460",
    "Bomaderry, NSW 2541",
    "Bombah Point, NSW 2423",
    "Bombala, NSW 2632",
    "Bombay, NSW 2622",
    "Bombeeta, QLD 4871",
    "Bombira, NSW 2850",
    "Bombo, NSW 2533",
    "Bombowlee, NSW 2720",
    "Bombowlee Creek, NSW 2720",
    "Bomen, NSW 2650",
    "Bomera, NSW 2357",
    "Bon Accord, QLD 4625",
    "Bona Vista, VIC 3820",
    "Bonalbo, NSW 2469",
    "Bonang, VIC 3888",
    "Bonbeach, VIC 3196",
    "Bond University, QLD 4229",
    "Bondi, NSW 2026",
    "Bondi Beach, NSW 2026",
    "Bondi Forest, NSW 2632",
    "Bondi Junction, NSW 1355",
    "Bondi Junction, NSW 2022",
    "Bondi Junction Plaza, NSW 2022",
    "Bondoola, QLD 4703",
    "Bonegilla, VIC 3691",
    "Boneo, VIC 3939",
    "Bongaree, QLD 4507",
    "Bongeen, QLD 4356",
    "Bonn, VIC 3561",
    "Bonnells Bay, NSW 2264",
    "Bonner, ACT 2914",
    "Bonnet Bay, NSW 2226",
    "Bonnet Hill, TAS 7053",
    "Bonnie Doon, VIC 3720",
    "Bonnie Doon, QLD 4873",
    "Bonnie Rock, WA 6479",
    "Bonniefield, WA 6525",
    "Bonny Hills, NSW 2445",
    "Bonnyrigg, NSW 2177",
    "Bonnyrigg Heights, NSW 2177",
    "Bonogin, QLD 4213",
    "Bonshaw, NSW 2361",
    "Bonshaw, QLD 4385",
    "Bonshaw, VIC 3352",
    "Bonville, NSW 2441",
    "Bony Mountain, QLD 4370",
    "Bonython, ACT 2905",
    "Booborowie, SA 5417",
    "Boobyalla, TAS 7264",
    "Boodarie, WA 6722",
    "Boodarockin, WA 6423",
    "Boodua, QLD 4401",
    "Booerie Creek, NSW 2480",
    "Boogan, QLD 4871",
    "Booie, QLD 4610",
    "Book Book, NSW 2650",
    "Bookaar, VIC 3260",
    "Bookabie, SA 5690",
    "Bookara, WA 6525",
    "Booker Bay, NSW 2257",
    "Bookham, NSW 2582",
    "Bookookoorara, NSW 2372",
    "Bookpurnong, SA 5333",
    "Bookram, NSW 2460",
    "Bool Lagoon, SA 5271",
    "Boola, VIC 3825",
    "Boolambayte, NSW 2423",
    "Boolarong, VIC 3960",
    "Boolaroo, NSW 2284",
    "Boolarra, VIC 3870",
    "Boolarra South, VIC 3870",
    "Boolboonda, QLD 4671",
    "Boolburra, QLD 4702",
    "Boolcarroll, NSW 2388",
    "Boole Poole, VIC 3880",
    "Booleroo Centre, SA 5482",
    "Boolgun, SA 5330",
    "Booligal, NSW 2711",
    "Boolijah, NSW 2540",
    "Boolite, VIC 3392",
    "Boomahnoomoonah, VIC 3728",
    "Boomanoomana, NSW 2712",
    "Boomer Bay, TAS 7177",
    "Boomerang Beach, NSW 2428",
    "Boomey, NSW 2866",
    "Boomi, NSW 2405",
    "Boomi Creek, NSW 2476",
    "Boompa, QLD 4621",
    "Boona Mount, NSW 2877",
    "Boonah, QLD 4310",
    "Boonah, VIC 3235",
    "Boonal, NSW 2409",
    "Boonanarring, WA 6503",
    "Boonara, QLD 4601",
    "Boonarga, QLD 4413",
    "Boondall, QLD 4034",
    "Boondandilla, QLD 4406",
    "Boondooma, QLD 4613",
    "Boonenne, QLD 4610",
    "Boonerdo, SA 5633",
    "Boonoo Boonoo, NSW 2372",
    "Boonooroo, QLD 4650",
    "Boonooroo Plains, QLD 4650",
    "Boorabbin, WA 6429",
    "Boorabee Park, NSW 2480",
    "Booragoon, WA 6154",
    "Booragoon, WA 6954",
    "Booragul, NSW 2284",
    "Booral, QLD 4655",
    "Booral, NSW 2425",
    "Booralaming, WA 6475",
    "Booran Road Po, VIC 3163",
    "Boorara, WA 6431",
    "Boorara Brook, WA 6262",
    "Boorcan, VIC 3265",
    "Boorga, NSW 2652",
    "Boorganna, NSW 2429",
    "Boorhaman, VIC 3678",
    "Boorhaman East, VIC 3678",
    "Boorhaman North, VIC 3685",
    "Boorolite, VIC 3723",
    "Boorolong, NSW 2350",
    "Booroobin, QLD 4552",
    "Boorook, NSW 2372",
    "Boorool, VIC 3953",
    "Boorooma, NSW 2650",
    "Booroorban, NSW 2710",
    "Boorowa, NSW 2586",
    "Boors Plain, SA 5554",
    "Boort, VIC 3537",
    "Boosey, VIC 3730",
    "Bootawa, NSW 2430",
    "Bootenal, WA 6532",
    "Boothenba, NSW 2830",
    "Boothendarra, WA 6521",
    "Booti Booti, NSW 2428",
    "Booubyjan, QLD 4601",
    "Booval, QLD 4304",
    "Booval Bc, QLD 4304",
    "Booval Fair, QLD 4304",
    "Booyal, QLD 4671",
    "Booyong, NSW 2480",
    "Bora Ridge, NSW 2471",
    "Borah Creek, NSW 2346",
    "Borallon, QLD 4306",
    "Boralma, VIC 3682",
    "Borambil, NSW 2343",
    "Borambola, NSW 2650",
    "Boranup, WA 6286",
    "Borden, WA 6338",
    "Border Ranges, NSW 2474",
    "Border Store, NT 0822",
    "Borderdale, WA 6320",
    "Bordertown, SA 5268",
    "Bordertown South, SA 5268",
    "Boree, NSW 2800",
    "Boree Creek, NSW 2652",
    "Boreen Point, QLD 4565",
    "Borenore, NSW 2800",
    "Bornes Hill, VIC 3379",
    "Bornholm, WA 6330",
    "Boro, NSW 2622",
    "Boronia, VIC 3155",
    "Boronia Heights, QLD 4124",
    "Boronia Park, NSW 2111",
    "Bororen, QLD 4678",
    "Borrika, SA 5309",
    "Borroloola, NT 0854",
    "Borung, VIC 3518",
    "Boscabel, WA 6394",
    "Bossley Park, NSW 2176",
    "Bostobrick, NSW 2453",
    "Bostocks Creek, VIC 3260",
    "Boston, SA 5607",
    "Botanic Ridge, VIC 3977",
    "Botany, NSW 1455",
    "Botany, NSW 2019",
    "Bothwell, TAS 7030",
    "Botobolar, NSW 2850",
    "Bottle Creek, NSW 2469",
    "Bouddi, NSW 2251",
    "Boulder, WA 6432",
    "Boulder Creek, QLD 4714",
    "Bouldercombe, QLD 4702",
    "Boulia, QLD 4829",
    "Boundain, WA 6312",
    "Boundary Bend, VIC 3599",
    "Bourbah, NSW 2828",
    "Bourke, NSW 2840",
    "Bourke Street, VIC 9999",
    "Bourkelands, NSW 2650",
    "Bournda, NSW 2548",
    "Bournewood, NSW 2868",
    "Bouvard, WA 6211",
    "Bovell, WA 6280",
    "Bow Bowing, NSW 2566",
    "Bow Bridge, WA 6333",
    "Bowan Park, NSW 2864",
    "Bowden, SA 5007",
    "Bowelling, WA 6225",
    "Bowen, QLD 4805",
    "Bowen Hills, QLD 4006",
    "Bowen Mountain, NSW 2753",
    "Bowenfels, NSW 2790",
    "Bowenvale, VIC 3465",
    "Bowenville, QLD 4404",
    "Bower, SA 5374",
    "Bowes, WA 6535",
    "Boweya, VIC 3675",
    "Boweya North, VIC 3675",
    "Bowgada, WA 6623",
    "Bowhill, SA 5238",
    "Bowillia, SA 5461",
    "Bowling Alley Point, NSW 2340",
    "Bowman, NSW 2422",
    "Bowman Farm, NSW 2422",
    "Bowmans, SA 5550",
    "Bowmans Creek, NSW 2330",
    "Bowmans Forest, VIC 3735",
    "Bowna, NSW 2644",
    "Bowning, NSW 2582",
    "Bowral, NSW 2576",
    "Bowraville, NSW 2449",
    "Bowser, VIC 3678",
    "Box Head, NSW 2257",
    "Box Hill, VIC 3128",
    "Box Hill, NSW 2765",
    "Box Hill Central, VIC 3128",
    "Box Hill North, VIC 3129",
    "Box Hill South, VIC 3128",
    "Box Ridge, NSW 2357",
    "Boxers Creek, NSW 2580",
    "Boxwood, VIC 3725",
    "Boxwood Hill, WA 6338",
    "Boya, WA 6056",
    "Boyanup, WA 6237",
    "Boyatup, WA 6450",
    "Boydtown, NSW 2551",
    "Boyer, TAS 7140",
    "Boyerine, WA 6316",
    "Boyland, QLD 4275",
    "Boyne Island, QLD 4680",
    "Boyne Valley, QLD 4680",
    "Boynedale, QLD 4680",
    "Boyneside, QLD 4610",
    "Boynewood, QLD 4626",
    "Boyup Brook, WA 6244",
    "Brabham, WA 6055",
    "Bracalba, QLD 4512",
    "Bracewell, QLD 4695",
    "Bracken Ridge, QLD 4017",
    "Bracknell, TAS 7302",
    "Bradbury, NSW 2560",
    "Bradbury, SA 5153",
    "Braddon, ACT 2612",
    "Bradford, VIC 3463",
    "Bradshaw, NT 0852",
    "Bradvale, VIC 3361",
    "Brady Creek, SA 5381",
    "Bradys Lake, TAS 7140",
    "Braefield, NSW 2339",
    "Braemar, NSW 2575",
    "Braemar Bay, NSW 2628",
    "Braemeadows, QLD 4850",
    "Braemore, QLD 4313",
    "Braeside, VIC 3195",
    "Brahma Lodge, SA 5109",
    "Braidwood, NSW 2622",
    "Braitling, NT 0870",
    "Bramfield, SA 5670",
    "Bramley, WA 6285",
    "Bramston Beach, QLD 4871",
    "Branch Creek, QLD 4625",
    "Branchview, QLD 4352",
    "Branditt, VIC 3630",
    "Brandon, QLD 4808",
    "Brandon Park, VIC 3150",
    "Brandum, TAS 7304",
    "Brandy Creek, VIC 3821",
    "Brandy Creek, QLD 4800",
    "Brandy Hill, NSW 2324",
    "Branxholm, TAS 7261",
    "Branxholme, VIC 3302",
    "Branxton, NSW 2335",
    "Branyan, QLD 4670",
    "Brassall, QLD 4305",
    "Braunstone, NSW 2460",
    "Bravington, VIC 3821",
    "Brawboy, NSW 2337",
    "Bray, SA 5276",
    "Bray Park, NSW 2484",
    "Bray Park, QLD 4500",
    "Braybrook, VIC 3019",
    "Braybrook North, VIC 3019",
    "Brays Creek, NSW 2484",
    "Brayton, NSW 2579",
    "Brazier, WA 6251",
    "Breadalbane, NSW 2581",
    "Breadalbane, TAS 7258",
    "Breadalbane, QLD 4800",
    "Breakaway, QLD 4825",
    "Breakaway Creek, VIC 3303",
    "Breakfast Creek, NSW 2849",
    "Breakfast Point, NSW 2137",
    "Breakwater, VIC 3219",
    "Bream Beach, NSW 2540",
    "Bream Creek, TAS 7175",
    "Breamlea, VIC 3227",
    "Bredbo, NSW 2626",
    "Breddan, QLD 4820",
    "Breelong, NSW 2827",
    "Breera, WA 6503",
    "Breeza, NSW 2381",
    "Bremer, QLD 4305",
    "Bremer Bay, WA 6338",
    "Brenanah, VIC 3517",
    "Brenda, NSW 2831",
    "Brenda Park, SA 5320",
    "Brendale, QLD 4500",
    "Brendale Bc, QLD 4500",
    "Brentford Square, VIC 3131",
    "Brentwood, WA 6153",
    "Brentwood, SA 5575",
    "Breona, TAS 7304",
    "Breton Bay, WA 6043",
    "Bretti, NSW 2422",
    "Brewarrina, NSW 2839",
    "Brewongle, NSW 2795",
    "Brewster, VIC 3352",
    "Briagolong, VIC 3860",
    "Briar Hill, VIC 3088",
    "Briarbrook, NSW 2365",
    "Bribbaree, NSW 2594",
    "Bribie Island, QLD 4507",
    "Bribie Island North, QLD 4507",
    "Bridge Creek, VIC 3723",
    "Bridgeman Downs, QLD 4035",
    "Bridgenorth, TAS 7277",
    "Bridges, QLD 4561",
    "Bridgetown, WA 6255",
    "Bridgewater, TAS 7030",
    "Bridgewater, VIC 3516",
    "Bridgewater, SA 5155",
    "Bridgewater North, VIC 3516",
    "Bridgewater On Loddon, VIC 3516",
    "Bridgman, NSW 2330",
    "Bridport, TAS 7262",
    "Brierfield, NSW 2454",
    "Brigadoon, WA 6069",
    "Brigalow, QLD 4412",
    "Bright, SA 5381",
    "Bright, VIC 3741",
    "Brightly, QLD 4741",
    "Brighton, TAS 7030",
    "Brighton, SA 5048",
    "Brighton, QLD 4017",
    "Brighton, VIC 3186",
    "Brighton East, VIC 3187",
    "Brighton Eventide, QLD 4017",
    "Brighton Nathan Street, QLD 4017",
    "Brighton North, VIC 3186",
    "Brighton Road, VIC 3184",
    "Brighton-le-sands, NSW 2216",
    "Brightview, QLD 4311",
    "Brightwaters, NSW 2264",
    "Brigooda, QLD 4613",
    "Bril Bril, NSW 2441",
    "Brim, VIC 3391",
    "Brimbago, SA 5267",
    "Brimbin, NSW 2430",
    "Brimboal, VIC 3312",
    "Brimin, VIC 3685",
    "Brimpaen, VIC 3401",
    "Brindabella, NSW 2611",
    "Brinerville, NSW 2441",
    "Bringalbert, VIC 3319",
    "Bringalily, QLD 4357",
    "Bringelly, NSW 2556",
    "Bringelly, NSW 2171",
    "Bringenbrong, NSW 3707",
    "Bringo, WA 6532",
    "Brinkin, NT 0810",
    "Brinkin, NT 0810",
    "Brinkley, SA 5253",
    "Brinkworth, SA 5464",
    "Brinsmead, QLD 4870",
    "Brisbane, QLD 4000",
    "Brisbane Adelaide Street, QLD 4000",
    "Brisbane City, QLD 4000",
    "Brisbane Gpo, QLD 4000",
    "Brisbane Grove, NSW 2580",
    "Brisbane Market, QLD 4106",
    "Brisk Bay, QLD 4805",
    "Brit Brit, VIC 3315",
    "Brittons Swamp, TAS 7330",
    "Broadbeach, QLD 4218",
    "Broadbeach Waters, QLD 4218",
    "Broadford, VIC 3658",
    "Broadlands, VIC 3875",
    "Broadmarsh, TAS 7030",
    "Broadmeadow, NSW 2292",
    "Broadmeadows, VIC 3047",
    "Broadmeadows, TAS 7330",
    "Broadmere, QLD 4420",
    "Broadview, SA 5083",
    "Broadwater, VIC 3301",
    "Broadwater, NSW 2549",
    "Broadwater, WA 6280",
    "Broadwater, QLD 4380",
    "Broadwater, NSW 2472",
    "Broadway, NSW 2581",
    "Broadway, NSW 1401",
    "Broadway, NSW 2007",
    "Broadway Nedlands, WA 6009",
    "Broadwood, WA 6430",
    "Brobenah, NSW 2705",
    "Brocklehurst, NSW 2830",
    "Brocklesby, NSW 2642",
    "Brockley, NSW 2365",
    "Brockman, WA 6701",
    "Brodies Plains, NSW 2360",
    "Brodribb River, VIC 3888",
    "Brogans Creek, NSW 2848",
    "Brogers Creek, NSW 2535",
    "Brogo, NSW 2550",
    "Broke, NSW 2330",
    "Broke, WA 6398",
    "Broken Creek, VIC 3673",
    "Broken Head, NSW 2481",
    "Broken Hill, NSW 2880",
    "Broken Hill North, NSW 2880",
    "Broken Hill West, NSW 2880",
    "Broken River, QLD 4757",
    "Brombin, NSW 2446",
    "Bromelton, QLD 4285",
    "Bromley, VIC 3472",
    "Brompton, SA 5007",
    "Bronte, NSW 2024",
    "Bronte Park, TAS 7140",
    "Brookdale, NSW 2656",
    "Brookdale, WA 6112",
    "Brooker, SA 5607",
    "Brookfield, NSW 2420",
    "Brookfield, VIC 3338",
    "Brookfield, QLD 4069",
    "Brookhampton, WA 6239",
    "Brookhill, QLD 4816",
    "Brooklana, NSW 2450",
    "Brooklands, QLD 4615",
    "Brooklet, NSW 2479",
    "Brooklyn, NSW 2083",
    "Brooklyn, TAS 7320",
    "Brooklyn, VIC 3012",
    "Brooklyn Park, SA 5032",
    "Brookong, NSW 2656",
    "Brooks Bay, TAS 7116",
    "Brookside Centre, QLD 4053",
    "Brookstead, QLD 4364",
    "Brookton, WA 6306",
    "Brookvale, NSW 2100",
    "Brookville, VIC 3896",
    "Brookwater, QLD 4300",
    "Brooloo, QLD 4570",
    "Brooman, NSW 2538",
    "Broome, WA 6725",
    "Broomehill, WA 6318",
    "Broomehill East, WA 6318",
    "Broomehill Village, WA 6318",
    "Broomehill West, WA 6318",
    "Broomfield, VIC 3364",
    "Brooms Head, NSW 2463",
    "Brooweena, QLD 4620",
    "Broughams Gate, NSW 2880",
    "Broughton, VIC 3418",
    "Broughton, NSW 2535",
    "Broughton, QLD 4820",
    "Broughton River Valley, SA 5454",
    "Broughton Vale, NSW 2535",
    "Broughton Village, NSW 2534",
    "Broulee, NSW 2537",
    "Brovinia, QLD 4626",
    "Brown Beach, SA 5222",
    "Brown Hill, WA 6431",
    "Brown Hill, VIC 3350",
    "Brown Hill Creek, SA 5062",
    "Brown Range, WA 6701",
    "Brownlow, SA 5374",
    "Brownlow Hill, NSW 2570",
    "Brownlow Ki, SA 5223",
    "Browns Creek, NSW 2799",
    "Browns Mountain, NSW 2540",
    "Browns Plains, QLD 4118",
    "Browns Plains, VIC 3685",
    "Browns Plains Bc, QLD 4118",
    "Brownsville, NSW 2530",
    "Broxburn, QLD 4356",
    "Bruah, NSW 2830",
    "Bruarong, VIC 3749",
    "Bruce, SA 5433",
    "Bruce, ACT 2617",
    "Bruce Rock, WA 6418",
    "Brucedale, NSW 2650",
    "Brucknell, VIC 3268",
    "Bruie Plains, NSW 2875",
    "Bruinbun, NSW 2795",
    "Brukunga, SA 5252",
    "Brumby, VIC 3885",
    "Brumby Plains, NSW 2476",
    "Brunchilly, NT 0861",
    "Brundee, NSW 2540",
    "Brungle, NSW 2722",
    "Brungle Creek, NSW 2722",
    "Brunkerville, NSW 2323",
    "Brunswick, VIC 3056",
    "Brunswick, WA 6224",
    "Brunswick East, VIC 3057",
    "Brunswick Heads, NSW 2483",
    "Brunswick Lower, VIC 3056",
    "Brunswick North, VIC 3056",
    "Brunswick South, VIC 3055",
    "Brunswick West, VIC 3055",
    "Brush Creek, QLD 4387",
    "Brushgrove, NSW 2460",
    "Brushy Creek, NSW 2365",
    "Bruthen, VIC 3885",
    "Bryden, QLD 4312",
    "Brymaroo, QLD 4403",
    "Buangla, NSW 2540",
    "Buangor, VIC 3375",
    "Buaraba, QLD 4311",
    "Buaraba South, QLD 4311",
    "Bucasia, QLD 4750",
    "Bucca, QLD 4670",
    "Bucca, NSW 2450",
    "Bucca Wauka, NSW 2429",
    "Buccan, QLD 4207",
    "Buccarumbi, NSW 2460",
    "Buchan, VIC 3885",
    "Buchan South, VIC 3885",
    "Buchanan, SA 5374",
    "Buchanan, QLD 4816",
    "Buchanan, NSW 2323",
    "Buchanan, NT 0852",
    "Buchfelde, SA 5118",
    "Buckajo, NSW 2550",
    "Buckaroo, NSW 2850",
    "Buckenbowra, NSW 2536",
    "Buckenderra, NSW 2630",
    "Buckendoon, NSW 2472",
    "Bucketty, NSW 2250",
    "Buckingham, SA 5270",
    "Buckingham, WA 6225",
    "Buckingham, QLD 4825",
    "Buckland, QLD 4722",
    "Buckland, TAS 7190",
    "Buckland, VIC 3740",
    "Buckland, WA 6401",
    "Buckland Park, SA 5120",
    "Buckleboo, SA 5641",
    "Buckley, VIC 3240",
    "Buckley Swamp, VIC 3301",
    "Buckra Bendinni, NSW 2449",
    "Buckrabanyule, VIC 3525",
    "Budawang, NSW 2622",
    "Buddabadah, NSW 2825",
    "Budden, NSW 2849",
    "Budderoo, NSW 2535",
    "Buddina, QLD 4575",
    "Buddong, NSW 2720",
    "Buderim, QLD 4556",
    "Budgee, QLD 4359",
    "Budgee Budgee, NSW 2850",
    "Budgee Budgee, VIC 3862",
    "Budgeree, VIC 3870",
    "Budgerum East, VIC 3579",
    "Budgewoi, NSW 2262",
    "Budgewoi Peninsula, NSW 2262",
    "Budgong, NSW 2577",
    "Buff Point, NSW 2262",
    "Buffalo, VIC 3958",
    "Buffalo Creek, NT 0812",
    "Buffalo River, VIC 3737",
    "Bugaldie, NSW 2357",
    "Bugle Hut, SA 5311",
    "Bugle Ranges, SA 5251",
    "Builyan, QLD 4680",
    "Bukali, QLD 4630",
    "Bukalong, NSW 2632",
    "Bukkulla, NSW 2360",
    "Bulahdelah, NSW 2423",
    "Bulart, VIC 3314",
    "Buldah, VIC 3890",
    "Bulee, NSW 2622",
    "Bulga, NSW 2330",
    "Bulga, VIC 3586",
    "Bulga Forest, NSW 2429",
    "Bulgana, VIC 3377",
    "Bulgarra, WA 6714",
    "Bulgary, NSW 2650",
    "Bulgun, QLD 4854",
    "Bulimba, QLD 4171",
    "Bull Creek, WA 6149",
    "Bull Creek, SA 5157",
    "Bulla, VIC 3428",
    "Bulla, NSW 2835",
    "Bulla Creek, NSW 2594",
    "Bullabulling, WA 6429",
    "Bullaburra, NSW 2784",
    "Bullagreen, NSW 2824",
    "Bullagreen, NSW 2831",
    "Bullaharre, VIC 3266",
    "Bullarah, NSW 2400",
    "Bullaring, WA 6373",
    "Bullarook, VIC 3352",
    "Bullarto, VIC 3461",
    "Bullarto South, VIC 3461",
    "Bullatale, NSW 2710",
    "Bullawa Creek, NSW 2390",
    "Bullawarra, QLD 4492",
    "Bullcamp, QLD 4615",
    "Bulldog, NSW 2469",
    "Bulleen, VIC 3105",
    "Bulleen South, VIC 3105",
    "Bullengarook, VIC 3437",
    "Buller, WA 6532",
    "Bulleringa, QLD 4871",
    "Bullfinch, WA 6484",
    "Bulli, NSW 2516",
    "Bulli Creek, QLD 4357",
    "Bulliac, NSW 2422",
    "Bullio, NSW 2575",
    "Bullioh, VIC 3700",
    "Bullock Hills, WA 6317",
    "Bulloo Downs, QLD 4492",
    "Bullsbrook, WA 6084",
    "Bullumwaal, VIC 3875",
    "Bullyard, QLD 4671",
    "Bulman Weemol, NT 0852",
    "Buln Buln, VIC 3821",
    "Buln Buln East, VIC 3821",
    "Bulong, WA 6431",
    "Bulwer, QLD 4025",
    "Bulyee, WA 6306",
    "Bulyeroi, NSW 2387",
    "Bumbaldry, NSW 2794",
    "Bumbalong, NSW 2626",
    "Bumberrah, VIC 3902",
    "Bumberry, NSW 2870",
    "Bumbunga, SA 5520",
    "Bunbartha, VIC 3634",
    "Bunburra, QLD 4310",
    "Bunbury, WA 6230",
    "Bunbury, WA 6231",
    "Bunbury, SA 5266",
    "Bundabah, NSW 2324",
    "Bundaberg, QLD 4670",
    "Bundaberg Central, QLD 4670",
    "Bundaberg East, QLD 4670",
    "Bundaberg North, QLD 4670",
    "Bundaberg South, QLD 4670",
    "Bundaberg West, QLD 4670",
    "Bundaburrah, NSW 2871",
    "Bundagen, NSW 2454",
    "Bundalaguah, VIC 3851",
    "Bundaleer Gardens, SA 5491",
    "Bundaleer North, SA 5491",
    "Bundall, QLD 4217",
    "Bundall Bc, QLD 4217",
    "Bundalong, VIC 3730",
    "Bundalong South, VIC 3730",
    "Bundamba, QLD 4304",
    "Bundanoon, NSW 2578",
    "Bundanoon, WA 6522",
    "Bundara, VIC 3898",
    "Bundarra, NSW 2359",
    "Bundeena, NSW 2230",
    "Bundella, NSW 2343",
    "Bundemar, NSW 2823",
    "Bundewallah, NSW 2535",
    "Bundey, SA 5320",
    "Bunding, VIC 3342",
    "Bundjalung, NSW 2473",
    "Bundook, NSW 2422",
    "Bundoora, VIC 3083",
    "Bundure, NSW 2700",
    "Bung Bong, VIC 3465",
    "Bungaba, NSW 2852",
    "Bungabbee, NSW 2480",
    "Bungadoo, QLD 4671",
    "Bungador, VIC 3260",
    "Bungal, VIC 3334",
    "Bungalally, VIC 3401",
    "Bungalora, NSW 2486",
    "Bungalow, QLD 4870",
    "Bungama, SA 5540",
    "Bungarby, NSW 2630",
    "Bungaree, TAS 7256",
    "Bungaree, SA 5464",
    "Bungaree, VIC 3352",
    "Bungawalbin, NSW 2469",
    "Bungeet, VIC 3726",
    "Bungeet West, VIC 3726",
    "Bungendore, NSW 2621",
    "Bungeworgorai, QLD 4455",
    "Bungil, QLD 4455",
    "Bungil, VIC 3691",
    "Bunglegumbie, NSW 2830",
    "Bungonia, NSW 2580",
    "Bungowannah, NSW 2640",
    "Bunguluke, VIC 3527",
    "Bungundarra, QLD 4703",
    "Bungunya, QLD 4494",
    "Bungwahl, NSW 2423",
    "Buniche, WA 6353",
    "Buninyong, VIC 3357",
    "Bunjil, WA 6623",
    "Bunjurgen, QLD 4310",
    "Bunkers Hill, VIC 3352",
    "Bunnaloo, NSW 2731",
    "Bunnan, NSW 2337",
    "Buntine, WA 6613",
    "Bunya, QLD 4055",
    "Bunya Creek, QLD 4655",
    "Bunya Mountains, QLD 4405",
    "Bunyah, NSW 2429",
    "Bunyan, NSW 2630",
    "Bunyip, VIC 3815",
    "Bunyip North, VIC 3815",
    "Buragwonduc, VIC 3858",
    "Burakin, WA 6467",
    "Buraminya, WA 6452",
    "Buranda, QLD 4102",
    "Burbank, QLD 4156",
    "Burcher, NSW 2671",
    "Burdell, QLD 4818",
    "Burdett, SA 5253",
    "Bureen, NSW 2328",
    "Burekup, WA 6227",
    "Burges, WA 6302",
    "Burgowan, QLD 4659",
    "Burkes Bridge, VIC 3568",
    "Burkes Flat, VIC 3475",
    "Burketown, QLD 4830",
    "Burleigh, QLD 4822",
    "Burleigh Bc, QLD 4220",
    "Burleigh Heads, QLD 4220",
    "Burleigh Town, QLD 4220",
    "Burleigh Waters, QLD 4220",
    "Burlong, WA 6401",
    "Burma Road, WA 6532",
    "Burnbank, VIC 3371",
    "Burncluith, QLD 4413",
    "Burnett Creek, QLD 4310",
    "Burnett Heads, QLD 4670",
    "Burnewang, VIC 3558",
    "Burnie, TAS 7915",
    "Burnie, TAS 7916",
    "Burnie, TAS 7917",
    "Burnie, TAS 7918",
    "Burnie, TAS 7919",
    "Burnie, TAS 7920",
    "Burnie, TAS 7921",
    "Burnie, TAS 7320",
    "Burnley, VIC 3121",
    "Burnley North, VIC 3121",
    "Burns Beach, WA 6028",
    "Burns Creek, TAS 7212",
    "Burnsfield, SA 5520",
    "Burnside, VIC 3023",
    "Burnside, WA 6285",
    "Burnside, QLD 4560",
    "Burnside, SA 5066",
    "Burnside Heights, VIC 3023",
    "Burnt Bridge, NSW 2440",
    "Burnt Yards, NSW 2792",
    "Buronga, NSW 2739",
    "Burpengary, QLD 4505",
    "Burpengary East, QLD 4505",
    "Burra, NSW 2653",
    "Burra, SA 5417",
    "Burra, NSW 2620",
    "Burra, NSW 2620",
    "Burra Creek, NSW 2722",
    "Burra Eastern Districts, SA 5417",
    "Burrabadine, NSW 2830",
    "Burraboi, NSW 2732",
    "Burracoppin, WA 6421",
    "Burradoo, NSW 2576",
    "Burraga, NSW 2795",
    "Burragate, NSW 2550",
    "Burramboot, VIC 3559",
    "Burramine, VIC 3730",
    "Burramine South, VIC 3730",
    "Burran Rock, WA 6490",
    "Burrandana, NSW 2650",
    "Burraneer, NSW 2230",
    "Burrangong, NSW 2594",
    "Burrapine, NSW 2447",
    "Burrawang, NSW 2577",
    "Burrell Creek, NSW 2429",
    "Burren Junction, NSW 2386",
    "Burrier, NSW 2540",
    "Burrill Lake, NSW 2539",
    "Burringbar, NSW 2483",
    "Burrinjuck, NSW 2582",
    "Burroway, NSW 2821",
    "Burrowye, VIC 3709",
    "Burrum, QLD 4659",
    "Burrum Heads, QLD 4659",
    "Burrum River, QLD 4659",
    "Burrum Town, QLD 4659",
    "Burrumbeet, VIC 3352",
    "Burrumbuttock, NSW 2642",
    "Burrundie, NT 0822",
    "Burrundulla, NSW 2850",
    "Burrungule, SA 5291",
    "Burrup, WA 6714",
    "Burswood, WA 6100",
    "Burt Plain, NT 0872",
    "Burton, SA 5110",
    "Burton, QLD 4742",
    "Burua, QLD 4680",
    "Burwood, NSW 2134",
    "Burwood, NSW 1805",
    "Burwood, VIC 3125",
    "Burwood East, VIC 3151",
    "Burwood Heights, NSW 2136",
    "Burwood Heights, VIC 3151",
    "Burwood North, NSW 2134",
    "Busby, NSW 2168",
    "Busbys Flat, NSW 2469",
    "Bushells Ridge, NSW 2259",
    "Bushfield, VIC 3281",
    "Bushland Beach, QLD 4818",
    "Bushley, QLD 4702",
    "Bushy Park, VIC 3860",
    "Bushy Park, TAS 7140",
    "Busselton, WA 6280",
    "Butchers Creek, QLD 4885",
    "Butchers Ridge, VIC 3885",
    "Bute, SA 5560",
    "Butler, WA 6036",
    "Butler, SA 5605",
    "Butlers Falls, NSW 2830",
    "Butlers Gorge, TAS 7140",
    "Buttaba, NSW 2283",
    "Buttai, NSW 2323",
    "Butterwick, NSW 2321",
    "Buxton, VIC 3711",
    "Buxton, QLD 4660",
    "Buxton, NSW 2571",
    "Byabarra, NSW 2446",
    "Byadbo Wilderness, NSW 2628",
    "Byaduk, VIC 3301",
    "Byaduk North, VIC 3300",
    "Byangum, NSW 2484",
    "Byawatha, VIC 3678",
    "Bybera, QLD 4387",
    "Byee, QLD 4605",
    "Byellee, QLD 4680",
    "Byfield, QLD 4703",
    "Byford, WA 6122",
    "Bylands, VIC 3762",
    "Bylong, NSW 2849",
    "Bymount, QLD 4455",
    "Byng, NSW 2800",
    "Bynoe, NT 0822",
    "Bynoe Harbour, NT 0822",
    "Byrneside, VIC 3617",
    "Byrnestown, QLD 4625",
    "Byrock, NSW 2831",
    "Byron Bay, NSW 2481",
    "Byrrill Creek, NSW 2484",
    "Bywong, NSW 2621",
    "Cabanandra, VIC 3888",
    "Cabarita, NSW 2137",
    "Cabarita, VIC 3505",
    "Cabarita Beach, NSW 2488",
    "Cabarlah, QLD 4352",
    "Cabbage Tree, VIC 3364",
    "Cabbage Tree Creek, VIC 3889",
    "Cabbage Tree Island, NSW 2477",
    "Cabbage Tree Island, NSW 2430",
    "Cable Beach, WA 6726",
    "Caboolture, QLD 4510",
    "Caboolture Bc, QLD 4510",
    "Caboolture South, QLD 4510",
    "Caboonbah, QLD 4312",
    "Cabramatta, NSW 2166",
    "Cabramatta West, NSW 2166",
    "Cabramurra, NSW 2629",
    "Cadell, SA 5321",
    "Cadell Lagoon, SA 5321",
    "Cadello, VIC 3442",
    "Cadgee, NSW 2545",
    "Cadgee, SA 5271",
    "Cadia, NSW 2800",
    "Cadoux, WA 6466",
    "Caerleon, NSW 2850",
    "Caffey, QLD 4343",
    "Caffreys Flat, NSW 2424",
    "Caiguna, WA 6443",
    "Cainbable, QLD 4285",
    "Cairdbeign, QLD 4722",
    "Cairncross, NSW 2446",
    "Cairnlea, VIC 3023",
    "Cairns, QLD 4870",
    "Cairns Bay, TAS 7116",
    "Cairns Central, QLD 4870",
    "Cairns City, QLD 4870",
    "Cairns North, QLD 4870",
    "Cairns Orchid Plaza, QLD 4870",
    "Calala, NSW 2340",
    "Calamia, NSW 2460",
    "Calamvale, QLD 4116",
    "Calarie, NSW 2871",
    "Calavos, QLD 4670",
    "Calca, SA 5671",
    "Calcium, QLD 4816",
    "Calder, TAS 7325",
    "Calder Park, VIC 3037",
    "Caldermeade, VIC 3984",
    "Caldervale, QLD 4478",
    "Calderwood, NSW 2527",
    "Caldwell, NSW 2710",
    "Calen, QLD 4798",
    "Calga, NSW 2250",
    "Calgoa, QLD 4570",
    "Calico Creek, QLD 4570",
    "Califat, NSW 2729",
    "California Gully, VIC 3556",
    "Calimo, NSW 2710",
    "Calingiri, WA 6569",
    "Calingunee, QLD 4390",
    "Caliph, SA 5310",
    "Calista, WA 6167",
    "Calivil, VIC 3573",
    "Caljie, WA 6302",
    "Callaghan, NSW 2308",
    "Callaghans Creek, NSW 2422",
    "Callala Bay, NSW 2540",
    "Callala Beach, NSW 2540",
    "Callandoon, QLD 4390",
    "Callawadda, VIC 3387",
    "Callcup, WA 6260",
    "Callemondah, QLD 4680",
    "Callide, QLD 4715",
    "Callignee, VIC 3844",
    "Callignee North, VIC 3844",
    "Callignee South, VIC 3844",
    "Callington, SA 5254",
    "Calliope, QLD 4680",
    "Calliope, NSW 2462",
    "Calomba, SA 5501",
    "Caloola, NSW 2795",
    "Caloote, SA 5254",
    "Caloundra, QLD 4551",
    "Caloundra Bc, QLD 4551",
    "Caloundra West, QLD 4551",
    "Calrossie, VIC 3971",
    "Caltowie, SA 5490",
    "Caltowie North, SA 5490",
    "Caltowie West, SA 5490",
    "Calulu, VIC 3875",
    "Calvert, NT 0862",
    "Calvert, QLD 4340",
    "Calwell, ACT 2905",
    "Camballin, WA 6728",
    "Cambalong, NSW 2632",
    "Cambarville, VIC 3779",
    "Camberwell, NSW 2330",
    "Camberwell, VIC 8627",
    "Camberwell, VIC 3124",
    "Camberwell East, VIC 3126",
    "Camberwell North, VIC 3124",
    "Camberwell South, VIC 3124",
    "Camberwell West, VIC 3124",
    "Cambewarra, NSW 2540",
    "Cambewarra Village, NSW 2540",
    "Camboon, QLD 4719",
    "Camboon, NSW 2849",
    "Cambooya, QLD 4358",
    "Cambra, NSW 2420",
    "Cambrai, SA 5353",
    "Cambrian Hill, VIC 3352",
    "Cambridge, TAS 7170",
    "Cambridge, QLD 4822",
    "Cambridge Gardens, NSW 2747",
    "Cambridge Gulf, WA 6743",
    "Cambridge Park, NSW 2747",
    "Cambridge Plateau, NSW 2469",
    "Cambroon, QLD 4552",
    "Camdale, TAS 7320",
    "Camden, NSW 2570",
    "Camden Head, NSW 2443",
    "Camden Park, SA 5038",
    "Camden Park, NSW 2570",
    "Camden South, NSW 2570",
    "Camellia, NSW 2142",
    "Camena, TAS 7316",
    "Cameron Park, NSW 2285",
    "Camerons Creek, NSW 2359",
    "Camillo, WA 6111",
    "Camira, NSW 2469",
    "Camira, QLD 4300",
    "Cammeray, NSW 2062",
    "Camoola, QLD 4730",
    "Camooweal, QLD 4828",
    "Camp Creek, NT 0822",
    "Camp Creek, NSW 4385",
    "Camp Creek, QLD 4871",
    "Camp Hill, QLD 4152",
    "Camp Mountain, QLD 4520",
    "Campania, TAS 7026",
    "Campaspe, QLD 4820",
    "Campbell, ACT 2612",
    "Campbell Creek, QLD 4625",
    "Campbell Town, TAS 7210",
    "Campbellfield, VIC 3061",
    "Campbells Bridge, VIC 3387",
    "Campbells Creek, VIC 3451",
    "Campbells Forest, VIC 3556",
    "Campbells Pocket, QLD 4521",
    "Campbelltown, VIC 3364",
    "Campbelltown, NSW 2560",
    "Campbelltown, SA 5074",
    "Campbelltown North, NSW 2560",
    "Camperdown, NSW 2050",
    "Camperdown, NSW 1450",
    "Camperdown, VIC 3260",
    "Campoona, SA 5640",
    "Campsie, NSW 2194",
    "Campvale, NSW 2318",
    "Campwin Beach, QLD 4737",
    "Cams Wharf, NSW 2281",
    "Canada Bay, NSW 2046",
    "Canadian, VIC 3350",
    "Canadian Lead, NSW 2850",
    "Canaga, QLD 4413",
    "Canal Creek, QLD 4702",
    "Canary Island, VIC 3537",
    "Canbelego, NSW 2835",
    "Canberra, ACT 2600",
    "Canberra, ACT 2601",
    "Canberra Airport, ACT 2609",
    "Canberra Bc, ACT 2610",
    "Cancanning, WA 6315",
    "Candelo, NSW 2550",
    "Cane, WA 6710",
    "Cangai, NSW 2460",
    "Cania, QLD 4630",
    "Caniaba, NSW 2480",
    "Caniambo, VIC 3630",
    "Canina, QLD 4570",
    "Canley Heights, NSW 2166",
    "Canley Vale, NSW 2166",
    "Cann River, VIC 3890",
    "Canna, WA 6627",
    "Cannawigara, SA 5268",
    "Cannie, VIC 3540",
    "Cannindah, QLD 4630",
    "Canning Bridge Applecross, WA 6153",
    "Canning Creek, QLD 4357",
    "Canning Mills, WA 6111",
    "Canning Vale, WA 6155",
    "Canning Vale East, WA 6155",
    "Canning Vale South, WA 6155",
    "Cannington, WA 6980",
    "Cannington, WA 6987",
    "Cannington, WA 6107",
    "Canningvale, QLD 4370",
    "Cannon Creek, QLD 4310",
    "Cannon Creek, QLD 4380",
    "Cannon Hill, QLD 4170",
    "Cannon Valley, QLD 4800",
    "Cannons Creek, VIC 3977",
    "Cannonvale, QLD 9944",
    "Cannonvale, QLD 4802",
    "Cannum, VIC 3393",
    "Canobolas, NSW 2800",
    "Canoelands, NSW 2157",
    "Canonba, NSW 2825",
    "Canoona, QLD 4702",
    "Canowie, SA 5419",
    "Canowie Belt, SA 5420",
    "Canowindra, NSW 2804",
    "Canteen Creek, NT 0872",
    "Canterbury, NSW 2193",
    "Canterbury, VIC 3126",
    "Canton Beach, NSW 2263",
    "Canunda, SA 5291",
    "Canungra, QLD 4275",
    "Canyonleigh, NSW 2577",
    "Capalaba, QLD 4157",
    "Capalaba Bc, QLD 4157",
    "Caparra, NSW 2429",
    "Cape Arid, WA 6452",
    "Cape Barren Island, TAS 7257",
    "Cape Borda, SA 5223",
    "Cape Bridgewater, VIC 3305",
    "Cape Burney, WA 6532",
    "Cape Clear, VIC 3351",
    "Cape Cleveland, QLD 4810",
    "Cape Conran, VIC 3888",
    "Cape Conway, QLD 4800",
    "Cape Douglas, SA 5291",
    "Cape Gloucester, QLD 4800",
    "Cape Hillsborough, QLD 4740",
    "Cape Jaffa, SA 5275",
    "Cape Jervis, SA 5204",
    "Cape Le Grand, WA 6450",
    "Cape Moreton, QLD 4025",
    "Cape Otway, VIC 3233",
    "Cape Paterson, VIC 3995",
    "Cape Portland, TAS 7264",
    "Cape Range National Park, WA 6707",
    "Cape Schanck, VIC 3939",
    "Cape Tribulation, QLD 4873",
    "Cape Woolamai, VIC 3925",
    "Capeen Creek, NSW 2469",
    "Capel, WA 6271",
    "Capel River, WA 6271",
    "Capella, QLD 4723",
    "Capels Crossing, VIC 3579",
    "Capertee, NSW 2846",
    "Capital Hill, ACT 2600",
    "Capoompeta, NSW 2371",
    "Capricorn, WA 6642",
    "Captain Creek, QLD 4677",
    "Captains Flat, NSW 2623",
    "Captains Mountain, QLD 4357",
    "Caraban, WA 6041",
    "Carabooda, WA 6033",
    "Carabost, NSW 2650",
    "Carag Carag, VIC 3623",
    "Caragabal, NSW 2810",
    "Caralue, SA 5641",
    "Caralulup, VIC 3371",
    "Caramut, VIC 3274",
    "Carani, WA 6566",
    "Carapooee, VIC 3477",
    "Carapooee West, VIC 3477",
    "Carapook, VIC 3312",
    "Caravonica, QLD 4878",
    "Carawa, SA 5680",
    "Carbine Creek, QLD 4723",
    "Carbla, WA 6701",
    "Carboor, VIC 3678",
    "Carbrook, QLD 4130",
    "Carbunup River, WA 6280",
    "Carcalgong, NSW 2850",
    "Carcoar, NSW 2791",
    "Carcuma, SA 5301",
    "Cardiff, WA 6225",
    "Cardiff, NSW 2285",
    "Cardiff Heights, NSW 2285",
    "Cardiff South, NSW 2285",
    "Cardigan, VIC 3352",
    "Cardigan Village, VIC 3352",
    "Cardinia, VIC 3978",
    "Cardross, VIC 3496",
    "Cardstone, QLD 4854",
    "Cardup, WA 6122",
    "Cardwell, QLD 4849",
    "Carew, SA 5270",
    "Carey Bay, NSW 2283",
    "Carey Gully, SA 5144",
    "Carey Park, WA 6230",
    "Cargerie, VIC 3334",
    "Cargo, NSW 2800",
    "Carina, VIC 3512",
    "Carina, QLD 4152",
    "Carina Heights, QLD 4152",
    "Carinda, NSW 2831",
    "Carindale, QLD 4152",
    "Carine, WA 6020",
    "Caringal, VIC 3825",
    "Caringbah, NSW 2229",
    "Caringbah, NSW 1495",
    "Caringbah South, NSW 2229",
    "Carisbrook, VIC 3464",
    "Carlaminda, NSW 2630",
    "Carlingford, NSW 2118",
    "Carlingford Court, NSW 2118",
    "Carlingford North, NSW 2118",
    "Carlisle, WA 6101",
    "Carlisle North, WA 6101",
    "Carlisle River, VIC 3239",
    "Carlisle South, WA 6101",
    "Carlotta, WA 6275",
    "Carlsruhe, VIC 3442",
    "Carlton, TAS 7173",
    "Carlton, VIC 3053",
    "Carlton, NSW 2218",
    "Carlton North, VIC 3054",
    "Carlton River, TAS 7173",
    "Carlton South, VIC 3053",
    "Carlyle, VIC 3685",
    "Carmel, WA 6076",
    "Carmila, QLD 4739",
    "Carmoo, QLD 4852",
    "Carnamah, WA 6517",
    "Carnarvon, WA 6701",
    "Carnegie, VIC 3163",
    "Carnes Hill, NSW 2171",
    "Carneys Creek, QLD 4310",
    "Carngham, VIC 3351",
    "Carnham, NSW 2460",
    "Carole Park, QLD 4300",
    "Caroline, SA 5291",
    "Caroline Springs, VIC 3023",
    "Carool, NSW 2486",
    "Caroona, NSW 2343",
    "Carpendale, QLD 4344",
    "Carpendeit, VIC 3260",
    "Carpentaria, QLD 4823",
    "Carpenter Rocks, SA 5291",
    "Carrabin, WA 6423",
    "Carrabolla, NSW 2311",
    "Carrai, NSW 2440",
    "Carrajung, VIC 3844",
    "Carrajung Lower, VIC 3844",
    "Carrajung South, VIC 3844",
    "Carramar, WA 6031",
    "Carramar, NSW 2163",
    "Carranballac, VIC 3361",
    "Carrandotta, QLD 4825",
    "Carrara, QLD 4211",
    "Carrarang, WA 6532",
    "Carrathool, NSW 2711",
    "Carrawabbity, NSW 2871",
    "Carrick, NSW 2580",
    "Carrick, TAS 7291",
    "Carrickalinga, SA 5204",
    "Carrieton, SA 5432",
    "Carrington, NSW 2294",
    "Carrington, QLD 4883",
    "Carrington, NSW 2324",
    "Carrington Falls, NSW 2577",
    "Carroll, NSW 2340",
    "Carrolls Creek, NSW 2372",
    "Carrolup, WA 6317",
    "Carron, VIC 3480",
    "Carrowbrook, NSW 2330",
    "Carrs Creek, NSW 2460",
    "Carrs Island, NSW 2460",
    "Carrs Peninsular, NSW 2460",
    "Carruchan, QLD 4816",
    "Carrum, VIC 3197",
    "Carrum Downs, VIC 3201",
    "Carseldine, QLD 4034",
    "Carss Park, NSW 2221",
    "Carstairs, QLD 4806",
    "Carters Ridge, QLD 4563",
    "Cartmeticup, WA 6316",
    "Cartwright, NSW 2168",
    "Cartwrights Hill, NSW 2650",
    "Carwarp, VIC 3494",
    "Carwell, NSW 2849",
    "Carwoola, NSW 2620",
    "Cascade, WA 6450",
    "Cascade, NSW 2453",
    "Casey, TAS 7151",
    "Casey, ACT 2913",
    "Cashmere, QLD 4500",
    "Cashmore, VIC 3305",
    "Casino, NSW 2470",
    "Cassilis, VIC 3898",
    "Cassilis, NSW 2329",
    "Cassini, SA 5223",
    "Cassowary, QLD 4873",
    "Castambul, SA 5076",
    "Castaways Beach, QLD 4567",
    "Castella, VIC 3777",
    "Casterton, VIC 3311",
    "Castle Cove, NSW 2069",
    "Castle Creek, VIC 3691",
    "Castle Creek, QLD 4715",
    "Castle Donnington, VIC 3585",
    "Castle Doyle, NSW 2350",
    "Castle Forbes Bay, TAS 7116",
    "Castle Hill, QLD 4810",
    "Castle Hill, NSW 2154",
    "Castle Hill, NSW 1765",
    "Castle Rock, NSW 2333",
    "Castlecrag, NSW 2068",
    "Castlemaine, VIC 3450",
    "Castlereagh, NSW 2749",
    "Castletown, WA 6450",
    "Castra, TAS 7315",
    "Casuarina, NT 0810",
    "Casuarina, NT 0811",
    "Casuarina, WA 6167",
    "Casuarina, NSW 2487",
    "Casula, NSW 2170",
    "Casula Mall, NSW 2170",
    "Cataby, WA 6507",
    "Catalina, NSW 2536",
    "Catani, VIC 3981",
    "Cataract, NSW 2560",
    "Cathcart, VIC 3377",
    "Cathcart, NSW 2632",
    "Catherine Field, NSW 2557",
    "Catherine Hill Bay, NSW 2281",
    "Cathkin, VIC 3714",
    "Cathundral, NSW 2823",
    "Cattai, NSW 2756",
    "Catterick, WA 6255",
    "Cattle Creek, QLD 4407",
    "Cattle Creek, NSW 2339",
    "Cattle Creek, QLD 4626",
    "Catumnal, VIC 3537",
    "Caulfield, VIC 3162",
    "Caulfield East, VIC 3145",
    "Caulfield Junction, VIC 3161",
    "Caulfield North, VIC 3161",
    "Caulfield South, VIC 3162",
    "Caurnamont, SA 5238",
    "Causeway, ACT 2604",
    "Causeway Lake, QLD 4703",
    "Cavan, SA 5094",
    "Cavan, NSW 2582",
    "Caveat, VIC 3660",
    "Cavenagh, SA 5422",
    "Cavendish, VIC 3314",
    "Caversham, WA 6055",
    "Caves Beach, NSW 2281",
    "Caveside, TAS 7304",
    "Caveton, SA 5291",
    "Cawarral, QLD 4702",
    "Cawdor, NSW 2570",
    "Cawdor, QLD 4352",
    "Cawongla, NSW 2474",
    "Cecil Hills, NSW 2171",
    "Cecil Park, NSW 2178",
    "Cecil Plains, QLD 4407",
    "Cedar Brush Creek, NSW 2259",
    "Cedar Creek, QLD 4520",
    "Cedar Creek, NSW 2484",
    "Cedar Creek, QLD 4207",
    "Cedar Creek, NSW 2325",
    "Cedar Grove, QLD 4285",
    "Cedar Party, NSW 2429",
    "Cedar Pocket, QLD 4570",
    "Cedar Point, NSW 2474",
    "Cedar Vale, QLD 4285",
    "Cedarton, QLD 4514",
    "Ceduna, SA 5690",
    "Cells River, NSW 2424",
    "Cement Mills, QLD 4352",
    "Centenary Heights, QLD 4350",
    "Centennial Park, WA 6330",
    "Centennial Park, NSW 2021",
    "Central Colo, NSW 2756",
    "Central Macdonald, NSW 2775",
    "Central Mangrove, NSW 2250",
    "Central Park, VIC 3145",
    "Central Plateau, TAS 7304",
    "Central Plaza, QLD 4001",
    "Central Queensland University, QLD 4701",
    "Central Tilba, NSW 2546",
    "Ceratodus, QLD 4627",
    "Ceres, VIC 3221",
    "Cervantes, WA 6511",
    "Cessnock, NSW 2325",
    "Cessnock West, NSW 2325",
    "Cethana, TAS 7306",
    "Chadstone, VIC 3148",
    "Chadstone Centre, VIC 3148",
    "Chadwick, WA 6450",
    "Chaelundi, NSW 2460",
    "Chaffey, SA 5341",
    "Chahpingah, QLD 4610",
    "Chain Of Lagoons, TAS 7215",
    "Chain Of Ponds, SA 5231",
    "Chain Valley Bay, NSW 2259",
    "Chakola, NSW 2630",
    "Chambers Flat, QLD 4133",
    "Chambigne, NSW 2460",
    "Champion Lakes, WA 6111",
    "Chances Plain, QLD 4413",
    "Chandada, SA 5680",
    "Chandler, QLD 4155",
    "Chandler, WA 6490",
    "Chandlers Creek, VIC 3890",
    "Chandlers Hill, SA 5159",
    "Changerup, WA 6394",
    "Channel Island, NT 0822",
    "Channybearup, WA 6260",
    "Chapel Flat, VIC 3352",
    "Chapel Hill, SA 5153",
    "Chapel Hill, QLD 4069",
    "Chapel Street North, VIC 3141",
    "Chapman, ACT 2611",
    "Chapman Bore, SA 5253",
    "Chapman Hill, WA 6280",
    "Chapple Vale, VIC 3239",
    "Charam, VIC 3318",
    "Charbon, NSW 2848",
    "Charleroi, VIC 3695",
    "Charles Darwin, NT 0820",
    "Charles Darwin, NT 0822",
    "Charles Darwin University, NT 0815",
    "Charles Darwin University, NT 0909",
    "Charles Sturt University, NSW 2795",
    "Charles Sturt University, NSW 2678",
    "Charleston, SA 5244",
    "Charlestown, NSW 2290",
    "Charlestown, QLD 4608",
    "Charleville, QLD 4470",
    "Charley Creek, WA 6239",
    "Charleys Forest, NSW 2622",
    "Charlotte, NT 0822",
    "Charlotte Bay, NSW 2428",
    "Charlotte Cove, TAS 7112",
    "Charlton, NSW 2795",
    "Charlton, VIC 3525",
    "Charlton, QLD 4350",
    "Charlton Gully, SA 5607",
    "Charlwood, QLD 4309",
    "Charmhaven, NSW 2263",
    "Charnwood, ACT 2615",
    "Charra, SA 5690",
    "Charters Towers, QLD 4820",
    "Chasm Creek, TAS 7321",
    "Chatham, NSW 2430",
    "Chatham Valley, NSW 2787",
    "Chatsbury, NSW 2580",
    "Chatswood, NSW 2057",
    "Chatswood, NSW 2067",
    "Chatswood West, NSW 2067",
    "Chatsworth, NSW 2469",
    "Chatsworth, VIC 3379",
    "Chatsworth, QLD 4570",
    "Cheepie, QLD 4475",
    "Cheero Point, NSW 2083",
    "Chelmer, QLD 4068",
    "Chelmsford, QLD 4606",
    "Chelona, QLD 4740",
    "Chelsea, VIC 3196",
    "Chelsea Heights, VIC 3196",
    "Cheltenham, SA 5014",
    "Cheltenham, VIC 3192",
    "Cheltenham, NSW 2119",
    "Cheltenham, QLD 4627",
    "Cheltenham East, VIC 3192",
    "Cheltenham North, VIC 3192",
    "Chepstowe, VIC 3351",
    "Cherbourg, QLD 4605",
    "Chermside, QLD 4032",
    "Chermside Bc, QLD 4032",
    "Chermside Centre, QLD 4032",
    "Chermside South, QLD 4032",
    "Chermside West, QLD 4032",
    "Cherokee, VIC 3434",
    "Cherrilong, VIC 3874",
    "Cherry Creek, QLD 4306",
    "Cherry Gardens, SA 5157",
    "Cherry Gully, QLD 4370",
    "Cherry Tree Hill, NSW 2360",
    "Cherry Tree Pool, WA 6395",
    "Cherrybrook, NSW 2126",
    "Cherrypool, VIC 3401",
    "Cherryville, SA 5134",
    "Cherwell, QLD 4660",
    "Cheshunt, VIC 3678",
    "Cheshunt South, VIC 3678",
    "Chesney Vale, VIC 3725",
    "Chester Hill, NSW 2162",
    "Chetwynd, VIC 3312",
    "Chevallum, QLD 4555",
    "Chevron Island, QLD 4217",
    "Chewko, QLD 4880",
    "Chewton, VIC 3451",
    "Chewton Bushlands, VIC 3451",
    "Cheynes, WA 6328",
    "Chichester, NSW 2420",
    "Chichester, WA 6751",
    "Chidlow, WA 6556",
    "Chifley, NSW 2036",
    "Chifley, ACT 2606",
    "Chigwell, TAS 7011",
    "Chilcotts Creek, NSW 2339",
    "Chilcotts Grass, NSW 2480",
    "Childers, VIC 3824",
    "Childers, QLD 4660",
    "Chilla Well, NT 0872",
    "Chillagoe, QLD 4871",
    "Chillingham, NSW 2484",
    "Chillingollah, VIC 3585",
    "Chilpenunda, SA 5660",
    "Chiltern, VIC 3683",
    "Chiltern Valley, VIC 3683",
    "Chinaman Wells, SA 5573",
    "Chinangin, VIC 3544",
    "Chinbingina, SA 5680",
    "Chinchilla, QLD 4413",
    "Chinderah, NSW 2487",
    "Chinghee Creek, QLD 4285",
    "Chinkapook, VIC 3546",
    "Chinnock, NSW 2550",
    "Chintin, VIC 3756",
    "Chippendale, NSW 2008",
    "Chipping Norton, NSW 2170",
    "Chirn Park, QLD 4215",
    "Chirnside, QLD 4723",
    "Chirnside Park, VIC 3116",
    "Chirrip, VIC 3525",
    "Chisholm, NSW 2322",
    "Chisholm, ACT 2905",
    "Chiswick, NSW 2046",
    "Chittaway Bay, NSW 2261",
    "Chittaway Point, NSW 2261",
    "Chittering, WA 6084",
    "Chocolyn, VIC 3260",
    "Chorregon, QLD 4730",
    "Chowan Creek, NSW 2484",
    "Chowerup, WA 6244",
    "Christie Downs, SA 5164",
    "Christies Beach, SA 5165",
    "Christies Beach North, SA 5165",
    "Christmas Creek, QLD 4285",
    "Christmas Hills, VIC 3775",
    "Christmas Hills, TAS 7330",
    "Christmas Island, WA 6798",
    "Chudleigh, TAS 7304",
    "Chullora, NSW 2190",
    "Chum Creek, VIC 3777",
    "Church Point, NSW 2105",
    "Churchable, QLD 4311",
    "Churchill, VIC 3842",
    "Churchill, QLD 4305",
    "Churchill Island, VIC 3925",
    "Churchlands, WA 6018",
    "Chute, VIC 3373",
    "Chuwar, QLD 4306",
    "Ciccone, NT 0870",
    "Cinnabar, QLD 4600",
    "City, ACT 2601",
    "City Beach, WA 6015",
    "City Delivery Centre, WA 6000",
    "City East, QLD 4002",
    "City West Campus, SA 5000",
    "Civic Square, ACT 2608",
    "Clackline, WA 6564",
    "Clagiraba, QLD 4211",
    "Clairview, QLD 4741",
    "Clandulla, NSW 2848",
    "Clapham, SA 5062",
    "Clara Creek, QLD 4468",
    "Claravale, NT 0822",
    "Claraville, QLD 4871",
    "Clare, NSW 2711",
    "Clare, QLD 4807",
    "Clare, SA 5453",
    "Claredale, QLD 4807",
    "Claremont, TAS 7011",
    "Claremont, WA 6910",
    "Claremont, WA 6010",
    "Claremont Meadows, NSW 2747",
    "Claremont North, WA 6010",
    "Clarence, NSW 2790",
    "Clarence Gardens, SA 5039",
    "Clarence Park, SA 5034",
    "Clarence Point, TAS 7270",
    "Clarence Town, NSW 2321",
    "Clarendon, QLD 4311",
    "Clarendon, SA 5157",
    "Clarendon, NSW 2756",
    "Clarendon, VIC 3352",
    "Clarendon Vale, TAS 7019",
    "Clarenza, NSW 2460",
    "Claretown, VIC 3352",
    "Clareville, NSW 2107",
    "Clarinda, VIC 3169",
    "Clarke Creek, QLD 4705",
    "Clarkefield, VIC 3430",
    "Clarkes Hill, VIC 3352",
    "Clarkson, WA 6030",
    "Claude Road, TAS 7306",
    "Claverton, QLD 4471",
    "Clay Wells, SA 5280",
    "Clayfield, QLD 4011",
    "Claymore, NSW 2559",
    "Claypans, SA 5238",
    "Clayton, VIC 3168",
    "Clayton, SA 5256",
    "Clayton Bay, SA 5256",
    "Clayton South, VIC 3169",
    "Clear Creek, NSW 2795",
    "Clear Island Waters, QLD 4226",
    "Clear Lake, VIC 3409",
    "Clear Mountain, QLD 4500",
    "Clear Range, NSW 2620",
    "Clearfield, NSW 2469",
    "Clearview, SA 5085",
    "Cleary, WA 6472",
    "Cleaverville, WA 6714",
    "Cleland, SA 5152",
    "Clemant, QLD 4816",
    "Clematis, VIC 3782",
    "Clements Gap, SA 5523",
    "Clemton Park, NSW 2206",
    "Clergate, NSW 2800",
    "Clermont, QLD 4721",
    "Cleve, SA 5640",
    "Cleveland, NSW 2530",
    "Cleveland, TAS 7211",
    "Cleveland, QLD 4163",
    "Clifden, NSW 2460",
    "Clifford, QLD 4427",
    "Clifford Gardens, QLD 4350",
    "Cliftleigh, NSW 2321",
    "Clifton, NSW 2515",
    "Clifton, QLD 4361",
    "Clifton, WA 6211",
    "Clifton Beach, TAS 7020",
    "Clifton Beach, QLD 4879",
    "Clifton Creek, VIC 3875",
    "Clifton Grove, NSW 2800",
    "Clifton Hill, VIC 3068",
    "Clifton Springs, VIC 3222",
    "Clinton, QLD 4680",
    "Clinton, SA 5570",
    "Clinton Centre, SA 5570",
    "Clintonvale, QLD 4370",
    "Cloisters Square Po, WA 6850",
    "Clonbinane, VIC 3658",
    "Cloncurry, QLD 4824",
    "Clontarf, QLD 4357",
    "Clontarf, NSW 2093",
    "Clontarf, QLD 4019",
    "Clontarf Beach, QLD 4019",
    "Closeburn, QLD 4520",
    "Clothiers Creek, NSW 2484",
    "Clouds Creek, NSW 2453",
    "Clovass, NSW 2480",
    "Clovelly, NSW 2031",
    "Clovelly Park, SA 5042",
    "Clovelly West, NSW 2031",
    "Clover Flat, VIC 3315",
    "Cloverdale, WA 6985",
    "Cloverdale, WA 6105",
    "Cloverlea, VIC 3822",
    "Cloyna, QLD 4605",
    "Cluan, TAS 7303",
    "Club Terrace, VIC 3889",
    "Cluden, QLD 4811",
    "Clumber, QLD 4309",
    "Clunes, NSW 2480",
    "Clunes, VIC 3370",
    "Clybucca, NSW 2440",
    "Clyde, NSW 2142",
    "Clyde, VIC 3978",
    "Clyde North, VIC 3978",
    "Clydebank, VIC 3851",
    "Clydesdale, NSW 2330",
    "Clydesdale, VIC 3461",
    "Coal Creek, QLD 4312",
    "Coal Point, NSW 2283",
    "Coalbank, QLD 4352",
    "Coalcliff, NSW 2508",
    "Coaldale, NSW 2460",
    "Coalfalls, QLD 4305",
    "Coalstoun Lakes, QLD 4621",
    "Coalville, VIC 3825",
    "Coasters Retreat, NSW 2108",
    "Coatesville, VIC 3165",
    "Cobains, VIC 3851",
    "Cobaki, NSW 2486",
    "Cobaki Lakes, NSW 2486",
    "Cobar, NSW 2835",
    "Cobar Park, NSW 2790",
    "Cobargo, NSW 2550",
    "Cobark, NSW 2422",
    "Cobaw, VIC 3442",
    "Cobbadah, NSW 2347",
    "Cobbannah, VIC 3862",
    "Cobberas, VIC 3900",
    "Cobbitty, NSW 2570",
    "Cobbs Hill, QLD 4605",
    "Cobden, VIC 3266",
    "Cobdogla, SA 5346",
    "Coblinine, WA 6317",
    "Cobourg, NT 0822",
    "Cobraball, QLD 4703",
    "Cobram, VIC 3643",
    "Cobram, VIC 3644",
    "Cobram East, VIC 3644",
    "Cobramunga, NSW 2732",
    "Cobrico, VIC 3266",
    "Cobungra, VIC 3898",
    "Coburg, VIC 3058",
    "Coburg North, VIC 3058",
    "Coburn, WA 6532",
    "Cocamba, VIC 3546",
    "Cocata, SA 5654",
    "Cochranes Creek, VIC 3475",
    "Cockaleechie, SA 5631",
    "Cockatoo, QLD 4419",
    "Cockatoo, VIC 3781",
    "Cockatoo Island, WA 6731",
    "Cockatoo Valley, SA 5351",
    "Cockburn, SA 5440",
    "Cockburn Central, WA 6164",
    "Cocklebiddy, WA 6443",
    "Cockwhy, NSW 2539",
    "Coconut Grove, NT 0810",
    "Coconut Grove, NT 0810",
    "Coconut Island, QLD 4875",
    "Coconuts, QLD 4860",
    "Cocoroc, VIC 3030",
    "Codjatotine, WA 6308",
    "Codrington, NSW 2471",
    "Codrington, VIC 3285",
    "Coen, QLD 4871",
    "Coes Creek, QLD 4560",
    "Coffee Camp, NSW 2480",
    "Coffin Bay, SA 5607",
    "Coffs Harbour, NSW 2450",
    "Coffs Harbour Jetty, NSW 2450",
    "Coffs Harbour Plaza, NSW 2450",
    "Coggan, NSW 2849",
    "Coghills Creek, VIC 3364",
    "Cogra Bay, NSW 2083",
    "Cohuna, VIC 3568",
    "Coila, NSW 2537",
    "Coimadai, VIC 3340",
    "Cokum, VIC 3542",
    "Colac, VIC 3250",
    "Colac Colac, VIC 3707",
    "Colac East, VIC 3250",
    "Colac West, VIC 3250",
    "Colbinabbin, VIC 3559",
    "Colbrook, VIC 3342",
    "Cold Harbour, WA 6302",
    "Coldstream, NSW 2462",
    "Coldstream, VIC 3770",
    "Coleambally, NSW 2707",
    "Colebatch, SA 5266",
    "Colebee, NSW 2761",
    "Colebrook, TAS 7027",
    "Coledale, NSW 2515",
    "Coleraine, VIC 3315",
    "Coles, SA 5272",
    "Coles Bay, TAS 7215",
    "Coles Creek, QLD 4570",
    "Colevale, QLD 4808",
    "Coleyville, QLD 4307",
    "Colignan, VIC 3494",
    "Colinroobie, NSW 2700",
    "Colinton, NSW 2626",
    "Colinton, QLD 4306",
    "Collanilling, WA 6315",
    "Collarenebri, NSW 2833",
    "Collaroy, NSW 2097",
    "Collaroy, QLD 4707",
    "Collaroy Beach, NSW 2097",
    "Collaroy Plateau, NSW 2097",
    "Collector, NSW 2581",
    "College Grove, WA 6230",
    "College Park, SA 5069",
    "College View, QLD 4343",
    "Collendina, NSW 2646",
    "Collerina, NSW 2839",
    "Collett Creek, NT 0822",
    "Colley, SA 5671",
    "Collie, NSW 2827",
    "Collie, WA 6225",
    "Collie Burn, WA 6225",
    "Collingullie, NSW 2650",
    "Collingwood, NSW 2850",
    "Collingwood, VIC 3066",
    "Collingwood Heights, WA 6330",
    "Collingwood North, VIC 3066",
    "Collingwood Park, WA 6330",
    "Collingwood Park, QLD 4301",
    "Collins, WA 6260",
    "Collins Creek, NSW 2474",
    "Collins Street East, VIC 8003",
    "Collins Street West, VIC 8007",
    "Collins Street West, VIC 8007",
    "Collinsfield, SA 5555",
    "Collinsvale, TAS 7012",
    "Collinsville, QLD 4804",
    "Collinsville, SA 5418",
    "Collinswood, SA 5081",
    "Colliver, VIC 3630",
    "Collombatti, NSW 2440",
    "Collum Collum, NSW 2460",
    "Colly Blue, NSW 2343",
    "Colo, NSW 2795",
    "Colo, NSW 2756",
    "Colo Heights, NSW 2756",
    "Colo Vale, NSW 2575",
    "Colonel Light Gardens, SA 5041",
    "Colongra, NSW 2262",
    "Colosseum, QLD 4677",
    "Colton, SA 5670",
    "Columbia, QLD 4820",
    "Columboola, QLD 4415",
    "Colyton, NSW 2760",
    "Comara, NSW 2440",
    "Comaum, SA 5277",
    "Combaning, NSW 2666",
    "Combara, NSW 2829",
    "Comberton, NSW 2540",
    "Combienbar, VIC 3889",
    "Combo, NSW 2330",
    "Comboyne, NSW 2429",
    "Come By Chance, NSW 2832",
    "Comerong Island, NSW 2540",
    "Comet, QLD 4702",
    "Commissariat Point, SA 5710",
    "Commissioners Creek, NSW 2484",
    "Commissioners Flat, QLD 4514",
    "Commodine, WA 6311",
    "Como, WA 6152",
    "Como, QLD 4571",
    "Como, WA 6952",
    "Como, NSW 2226",
    "Comobella, NSW 2820",
    "Comoon Loop, QLD 4858",
    "Compton, SA 5291",
    "Cona Creek, QLD 4722",
    "Conara, TAS 7211",
    "Conargo, NSW 2710",
    "Concongella, VIC 3384",
    "Concord, NSW 2137",
    "Concord Repatriation Hospital, NSW 2139",
    "Concord West, NSW 2138",
    "Concordia, SA 5118",
    "Condah, VIC 3303",
    "Condah Swamp, VIC 3286",
    "Condamine, QLD 4416",
    "Condamine Farms, QLD 4357",
    "Condamine Plains, QLD 4352",
    "Condell Park, NSW 2200",
    "Conder, ACT 2906",
    "Condingup, WA 6450",
    "Condobolin, NSW 2877",
    "Condon, QLD 4815",
    "Condong, NSW 2484",
    "Condowie, SA 5464",
    "Coneac, NSW 2422",
    "Congarinni, NSW 2447",
    "Congarinni North, NSW 2447",
    "Congewai, NSW 2325",
    "Congo, NSW 2537",
    "Congupna, VIC 3633",
    "Conimbia, NSW 2829",
    "Coningham, TAS 7054",
    "Coniston, NSW 2500",
    "Conjola, NSW 2539",
    "Conjola Park, NSW 2539",
    "Conjuboy, QLD 4871",
    "Conmurra, SA 5272",
    "Connellan, NT 0870",
    "Connells Point, NSW 2221",
    "Connellys Marsh, TAS 7173",
    "Connewarre, VIC 3227",
    "Connewirricoo, VIC 3318",
    "Connolly, WA 6027",
    "Conondale, QLD 4552",
    "Constitution Hill, NSW 2145",
    "Consuelo, QLD 4702",
    "Contine, WA 6311",
    "Conway, QLD 4800",
    "Conway Beach, QLD 4800",
    "Coober Pedy, SA 5723",
    "Coobowie, SA 5583",
    "Coochiemudlo Island, QLD 4184",
    "Coochin, QLD 4310",
    "Coochin Creek, QLD 4519",
    "Coodanup, WA 6210",
    "Cooee, TAS 7320",
    "Cooee Bay, QLD 4703",
    "Cooeeimbardi, QLD 4313",
    "Coogee, NSW 2034",
    "Coogee, WA 6166",
    "Coojar, VIC 3315",
    "Cook, SA 5710",
    "Cook, ACT 2614",
    "Cookamidgera, NSW 2870",
    "Cookardinia, NSW 2650",
    "Cooke Plains, SA 5261",
    "Cookernup, WA 6220",
    "Cooks Gap, NSW 2850",
    "Cooks Hill, NSW 2300",
    "Cooks Myalls, NSW 2870",
    "Cooktown, QLD 4895",
    "Coolabah, NSW 2831",
    "Coolabine, QLD 4574",
    "Coolabunia, QLD 4610",
    "Coolac, NSW 2727",
    "Cooladar Hill, WA 6638",
    "Cooladdi, QLD 4479",
    "Coolagolite, NSW 2550",
    "Coolah, NSW 2843",
    "Coolalinga, NT 0839",
    "Coolamon, NSW 2701",
    "Coolana, QLD 4311",
    "Coolangatta, QLD 4225",
    "Coolangatta, NSW 2535",
    "Coolangubra, NSW 2550",
    "Coolaroo, VIC 3048",
    "Coolatai, NSW 2402",
    "Coolbaggie, NSW 2830",
    "Coolbellup, WA 6163",
    "Coolbie, QLD 4850",
    "Coolbinia, WA 6050",
    "Coolcalalaya, WA 6532",
    "Cooleman, NSW 2611",
    "Cooleys Creek, NSW 2729",
    "Coolgardie, NSW 2478",
    "Coolgardie, WA 6429",
    "Coolillie, SA 5670",
    "Cooljarloo, WA 6507",
    "Coolmunda, QLD 4387",
    "Coolongolook, NSW 2423",
    "Cooloola, QLD 4580",
    "Cooloola Cove, QLD 4580",
    "Cooloolabin, QLD 4560",
    "Cooloongup, WA 6168",
    "Coolringdon, NSW 2630",
    "Cooltong, SA 5341",
    "Coolum Beach, QLD 4573",
    "Coolumbooka, NSW 2632",
    "Coolumburra, NSW 2622",
    "Coolup, WA 6214",
    "Cooma, NSW 2630",
    "Cooma, VIC 3616",
    "Cooma North, NSW 2630",
    "Coomalbidgup, WA 6450",
    "Coomalie Creek, NT 0822",
    "Coomandook, SA 5261",
    "Coomba Bay, NSW 2428",
    "Coomba Park, NSW 2428",
    "Coombabah, QLD 4216",
    "Coombadjha, NSW 2460",
    "Coombe, SA 5267",
    "Coombell, NSW 2470",
    "Coomberdale, WA 6512",
    "Coomboona, VIC 3629",
    "Coomealla, NSW 2717",
    "Coomera, QLD 4209",
    "Coominglah, QLD 4630",
    "Coominglah Forest, QLD 4630",
    "Coominya, QLD 4311",
    "Coomoo, QLD 4702",
    "Coomoo Coomoo, NSW 2343",
    "Coomoora, VIC 3461",
    "Coomooroo, SA 5431",
    "Coomrith, QLD 4422",
    "Coomunga, SA 5607",
    "Coonabarabran, NSW 2357",
    "Coonabidgee, WA 6503",
    "Coonalpyn, SA 5265",
    "Coonamble, NSW 2829",
    "Coonambula, QLD 4626",
    "Coonamia, SA 5540",
    "Coonarr, QLD 4670",
    "Coonawarra, NT 0820",
    "Coonawarra, SA 5263",
    "Coondle, WA 6566",
    "Coondoo, QLD 4570",
    "Coongbar, NSW 2469",
    "Coongoola, QLD 4490",
    "Coongulla, VIC 3860",
    "Coonooer Bridge, VIC 3477",
    "Coonooer West, VIC 3477",
    "Cooperabung, NSW 2441",
    "Coopernook, NSW 2426",
    "Coopers Creek, VIC 3825",
    "Coopers Creek, SA 5731",
    "Coopers Gully, NSW 2550",
    "Coopers Plains, QLD 4108",
    "Coopers Shoot, NSW 2479",
    "Cooplacurripa, NSW 2424",
    "Coorabell, NSW 2479",
    "Coorabie, SA 5690",
    "Coorada, QLD 4420",
    "Cooran, QLD 4569",
    "Cooranbong, NSW 2265",
    "Cooriemungle, VIC 3268",
    "Cooroibah, QLD 4565",
    "Coorong, SA 5264",
    "Cooroo Lands, QLD 4860",
    "Coorooman, QLD 4702",
    "Coorow, WA 6515",
    "Cooroy, QLD 4563",
    "Cooroy Mountain, QLD 4563",
    "Coorparoo, QLD 4151",
    "Coorparoo Bc, QLD 4151",
    "Coorumba, QLD 4860",
    "Coorumbene, QLD 4702",
    "Cootamundra, NSW 2590",
    "Cootharaba, QLD 4565",
    "Cootra, SA 5650",
    "Cootralantra, NSW 2628",
    "Coowonga, QLD 4702",
    "Cooya Beach, QLD 4873",
    "Cooya Pooya, WA 6714",
    "Cooyal, NSW 2850",
    "Cooyar, QLD 4402",
    "Copacabana, NSW 2251",
    "Cope, NSW 2852",
    "Cope Cope, VIC 3480",
    "Copeland, NSW 2422",
    "Copeton, NSW 2360",
    "Copeville, SA 5308",
    "Copley, SA 5732",
    "Copley, WA 6562",
    "Copmanhurst, NSW 2460",
    "Coppabella, QLD 4741",
    "Coppabella, NSW 2644",
    "Copperhannia, NSW 2795",
    "Copping, TAS 7174",
    "Coquette Point, QLD 4860",
    "Cora Lynn, VIC 3814",
    "Corack, VIC 3480",
    "Corack East, VIC 3480",
    "Coragulac, VIC 3249",
    "Coraki, NSW 2471",
    "Coral Bank, VIC 3691",
    "Coral Bay, WA 6701",
    "Coral Cove, QLD 4670",
    "Coralie, QLD 4871",
    "Coralville, NSW 2443",
    "Coramba, NSW 2450",
    "Corang, NSW 2622",
    "Corangula, NSW 2440",
    "Corbie Hill, NSW 2705",
    "Cordalba, QLD 4660",
    "Cordeaux, NSW 2526",
    "Cordeaux Heights, NSW 2526",
    "Cordelia, QLD 4850",
    "Cordillo Downs, SA 5731",
    "Coree, NSW 2710",
    "Coreen, NSW 2646",
    "Corella, QLD 4570",
    "Corfield, QLD 4733",
    "Corinda, QLD 4075",
    "Corindhap, VIC 3352",
    "Corindi Beach, NSW 2456",
    "Corinella, VIC 3984",
    "Corinella, NSW 2871",
    "Coringa, QLD 4621",
    "Corinna, TAS 7321",
    "Corinthia, WA 6426",
    "Corio, VIC 3214",
    "Corlette, NSW 2315",
    "Corndale, NSW 2480",
    "Corndale, VIC 3311",
    "Corndale, QLD 4610",
    "Cornella, VIC 3551",
    "Corney Town, NSW 2790",
    "Cornishtown, VIC 3683",
    "Cornubia, QLD 4130",
    "Cornwall, TAS 7215",
    "Cornwall, QLD 4455",
    "Cornwallis, NSW 2756",
    "Corny Point, SA 5575",
    "Corobimilla, NSW 2700",
    "Coromandel East, SA 5157",
    "Coromandel Valley, SA 5051",
    "Coronet Bay, VIC 3984",
    "Corop, VIC 3559",
    "Corop West, VIC 3558",
    "Cororooke, VIC 3254",
    "Corowa, NSW 2646",
    "Corrabare, NSW 2325",
    "Corrigin, WA 6375",
    "Corrimal, NSW 2518",
    "Corrimal East, NSW 2518",
    "Corringle, VIC 3888",
    "Corrong, NSW 2711",
    "Corrowong, NSW 2633",
    "Corryong, VIC 3707",
    "Cortlinye, SA 5641",
    "Corunna, NSW 2546",
    "Corunnun, VIC 3249",
    "Cosgrove, QLD 4818",
    "Cosgrove, VIC 3631",
    "Cosgrove South, VIC 3631",
    "Cosmo Newbery, WA 6440",
    "Cossack, WA 6720",
    "Cossack, NT 0850",
    "Costello, NT 0872",
    "Costerfield, VIC 3523",
    "Cotham, VIC 3101",
    "Cotswold, VIC 3465",
    "Cotswold Hills, QLD 4350",
    "Cottage Point, NSW 2084",
    "Cottesloe, WA 6911",
    "Cottesloe, WA 6011",
    "Cottles Bridge, VIC 3099",
    "Cotton Tree, QLD 4558",
    "Cottonvale, NSW 4375",
    "Cottonvale, QLD 4375",
    "Couch Beach, SA 5575",
    "Cougal, NSW 2474",
    "Coulson, QLD 4310",
    "Coulta, SA 5607",
    "Countegany, NSW 2630",
    "Courabyra, NSW 2653",
    "Couradda, NSW 2390",
    "Couragago, NSW 2720",
    "Couridjah, NSW 2571",
    "Courtenay, WA 6288",
    "Couta Rocks, TAS 7330",
    "Coutts Crossing, NSW 2460",
    "Coverty, QLD 4613",
    "Cow Bay, QLD 4873",
    "Cow Flat, NSW 2795",
    "Cowa, VIC 3862",
    "Cowabbie, NSW 2652",
    "Cowalellup, WA 6336",
    "Cowalla, WA 6503",
    "Cowan, NSW 2081",
    "Cowan Cowan, QLD 4025",
    "Cowandilla, SA 5033",
    "Cowangie, VIC 3506",
    "Cowaramup, WA 6284",
    "Cowcowing, WA 6485",
    "Cowell, SA 5602",
    "Cowes, VIC 3922",
    "Cowirra, SA 5238",
    "Cowley, QLD 4871",
    "Cowley Beach, QLD 4871",
    "Cowley Creek, QLD 4871",
    "Cowleys Creek, VIC 3268",
    "Cowper, NSW 2460",
    "Cowra, NSW 2794",
    "Cowrie Point, TAS 7321",
    "Cowwarr, VIC 3857",
    "Cox Peninsula, NT 0822",
    "Coxs Creek, NSW 2849",
    "Coxs Crown, NSW 2849",
    "Coyrecup, WA 6317",
    "Crabbes Creek, NSW 2483",
    "Crabtree, TAS 7109",
    "Crace, ACT 2911",
    "Crackenback, NSW 2627",
    "Cracow, QLD 4719",
    "Cradle Mountain, TAS 7306",
    "Cradoc, TAS 7109",
    "Cradock, SA 5432",
    "Crafers, SA 5152",
    "Crafers West, SA 5152",
    "Craigburn Farm, SA 5051",
    "Craigie, WA 6025",
    "Craigie, VIC 3465",
    "Craigie, NSW 2632",
    "Craigieburn, VIC 3064",
    "Craiglie, QLD 4877",
    "Craigmore, SA 5114",
    "Craignish, QLD 4655",
    "Cramphorne, WA 6420",
    "Cramps Bay, TAS 7030",
    "Cranbourne, VIC 3977",
    "Cranbourne East, VIC 3977",
    "Cranbourne North, VIC 3977",
    "Cranbourne South, VIC 3977",
    "Cranbourne West, VIC 3977",
    "Cranbrook, WA 6321",
    "Cranbrook, TAS 7190",
    "Cranbrook, QLD 4814",
    "Cranebrook, NSW 2749",
    "Crangan Bay, NSW 2259",
    "Cranley, QLD 4350",
    "Craven, NSW 2422",
    "Craven Plateau, NSW 2422",
    "Crawford, QLD 4610",
    "Crawford River, NSW 2423",
    "Crawley, WA 6009",
    "Crawney, NSW 2338",
    "Crayfish Creek, TAS 7321",
    "Crediton, QLD 4757",
    "Creek Junction, VIC 3669",
    "Creewah, NSW 2631",
    "Creightons Creek, VIC 3666",
    "Cremorne, TAS 7024",
    "Cremorne, QLD 4740",
    "Cremorne, NSW 2090",
    "Cremorne, VIC 3121",
    "Cremorne Junction, NSW 2090",
    "Cremorne Point, NSW 2090",
    "Crescent, SA 5341",
    "Crescent Head, NSW 2440",
    "Cressbrook, QLD 4313",
    "Cressbrook Creek, QLD 4355",
    "Cresswell Downs, NT 0862",
    "Cressy, TAS 7302",
    "Cressy, VIC 3322",
    "Crestmead, QLD 4132",
    "Crestmead Dc, QLD 4132",
    "Crestwood, NSW 2620",
    "Creswell, NT 0852",
    "Creswick, VIC 3363",
    "Creswick North, VIC 3363",
    "Crib Point, VIC 3919",
    "Crimea, QLD 4816",
    "Cringila, NSW 2502",
    "Crinum, QLD 4723",
    "Croftby, QLD 4310",
    "Crohamhurst, QLD 4519",
    "Croker Island, NT 0822",
    "Croki, NSW 2430",
    "Cromarty, QLD 4809",
    "Cromer, SA 5235",
    "Cromer, NSW 2099",
    "Cromer, VIC 3193",
    "Cronulla, NSW 2230",
    "Crooble, NSW 2400",
    "Croobyar, NSW 2539",
    "Crookayan, VIC 3858",
    "Crooked Brook, WA 6236",
    "Crooked Corner, NSW 2583",
    "Crooked River, VIC 3862",
    "Crookwell, NSW 2583",
    "Croom, NSW 2527",
    "Croppa Creek, NSW 2411",
    "Cross Roads, NSW 2850",
    "Cross Roads, VIC 3373",
    "Cross Roads, SA 5558",
    "Crossdale, QLD 4312",
    "Crosslands, NSW 2446",
    "Crossley, VIC 3283",
    "Crossman, WA 6390",
    "Crossover, VIC 3821",
    "Croudace Bay, NSW 2280",
    "Crowdy Bay National Park, NSW 2443",
    "Crowdy Head, NSW 2427",
    "Crowea, WA 6258",
    "Crowlands, VIC 3377",
    "Crowley Vale, QLD 4342",
    "Crownthorpe, QLD 4605",
    "Crows Nest, QLD 4355",
    "Crows Nest, NSW 2065",
    "Crows Nest, NSW 1582",
    "Crows Nest, NSW 1585",
    "Crowther, NSW 2803",
    "Crowther Island, NSW 2460",
    "Croxton East, VIC 3301",
    "Croydon, NSW 2132",
    "Croydon, VIC 3136",
    "Croydon, QLD 4871",
    "Croydon, SA 5008",
    "Croydon Hills, VIC 3136",
    "Croydon North, VIC 3136",
    "Croydon Park, NSW 2133",
    "Croydon Park, SA 5008",
    "Croydon Park South, SA 5008",
    "Croydon South, VIC 3136",
    "Crudine, NSW 2795",
    "Crymelon, VIC 3393",
    "Cryna, QLD 4285",
    "Cryon, NSW 2832",
    "Crystal Brook, SA 5523",
    "Crystal Brook, QLD 4800",
    "Crystal Creek, VIC 3714",
    "Crystal Creek, QLD 4816",
    "Crystal Creek, NSW 2484",
    "Crystal Waters, QLD 4552",
    "Crystalbrook, QLD 4871",
    "Cuballing, WA 6311",
    "Cubba, NSW 2835",
    "Cubbine, WA 6383",
    "Cuckoo, TAS 7260",
    "Cudal, NSW 2864",
    "Cudgee, VIC 3265",
    "Cudgegong, NSW 2850",
    "Cudgel, NSW 2700",
    "Cudgen, NSW 2487",
    "Cudgera Creek, NSW 2484",
    "Cudgewa, VIC 3705",
    "Cudlee Creek, SA 5232",
    "Cudmirrah, NSW 2540",
    "Cue, WA 6640",
    "Culburra, SA 5261",
    "Culburra Beach, NSW 2540",
    "Culcairn, NSW 2660",
    "Culgoa, VIC 3530",
    "Culham, WA 6566",
    "Culla, VIC 3315",
    "Cullacabardee, WA 6067",
    "Cullalla, WA 6503",
    "Cullen, VIC 3568",
    "Cullen Bay, NT 0820",
    "Cullen Bay, NT 0820",
    "Cullen Bullen, NSW 2790",
    "Cullenbone, NSW 2850",
    "Cullendore, NSW 2372",
    "Cullerin, NSW 2581",
    "Cullinane, QLD 4860",
    "Cullivel, NSW 2645",
    "Cullulleraine, VIC 3496",
    "Culmaran Creek, NSW 2469",
    "Cultana, SA 5600",
    "Cumbalum, NSW 2478",
    "Cumbandry, NSW 2852",
    "Cumberland Park, SA 5041",
    "Cumberland Reach, NSW 2756",
    "Cumbijowa, NSW 2871",
    "Cumbo, NSW 2850",
    "Cumboogle, NSW 2830",
    "Cumborah, NSW 2832",
    "Cummins, SA 5631",
    "Cumnock, NSW 2867",
    "Cundare, VIC 3251",
    "Cundare North, VIC 3251",
    "Cundeelee, WA 6434",
    "Cunderdin, WA 6407",
    "Cundinup, WA 6275",
    "Cundle Flat, NSW 2424",
    "Cundletown, NSW 2430",
    "Cundumbul, NSW 2866",
    "Cungena, SA 5660",
    "Cungulla, QLD 4816",
    "Cunjardine, WA 6401",
    "Cunjurong Point, NSW 2539",
    "Cunliffe, SA 5554",
    "Cunnamulla, QLD 4490",
    "Cunningham, QLD 4370",
    "Cunningham, SA 5571",
    "Cunninyeuk, NSW 2734",
    "Cunyarie, SA 5641",
    "Cuprona, TAS 7316",
    "Curban, NSW 2827",
    "Curdies River, VIC 3268",
    "Curdievale, VIC 3268",
    "Curl Curl, NSW 2096",
    "Curlew Waters, NSW 2672",
    "Curlewis, VIC 3222",
    "Curlewis, NSW 2381",
    "Curlwaa, NSW 2648",
    "Curra, QLD 4570",
    "Curra Creek, NSW 2820",
    "Currabubula, NSW 2342",
    "Curragh, NSW 2795",
    "Currajah, QLD 4871",
    "Currajong, QLD 4812",
    "Currambine, WA 6028",
    "Curramore, NSW 2533",
    "Curramore, QLD 4552",
    "Curramulka, SA 5580",
    "Currans Hill, NSW 2567",
    "Currarong, NSW 2540",
    "Currawang, NSW 2580",
    "Currawarna, NSW 2650",
    "Curraweela, NSW 2580",
    "Currency Creek, SA 5214",
    "Curricabark, NSW 2422",
    "Currie, TAS 7256",
    "Currimundi, QLD 4551",
    "Currowan, NSW 2536",
    "Currumbin, QLD 4223",
    "Currumbin Valley, QLD 4223",
    "Currumbin Waters, QLD 4223",
    "Curtin, ACT 2605",
    "Curtis Island, QLD 4680",
    "Curyo, VIC 3483",
    "Cushnie, QLD 4608",
    "Custon, SA 5269",
    "Cutella, QLD 4352",
    "Cuthbert, WA 6330",
    "Cuttabri, NSW 2388",
    "Cuttaburra, QLD 4490",
    "Cuttagee, NSW 2546",
    "Cuttlefish Bay, SA 5222",
    "Cygnet, TAS 7112",
    "Cygnet River, SA 5223",
    "Cynthia, QLD 4627",
    "Cypress Gardens, QLD 4357",
    "D'aguilar, QLD 4514",
    "D'estrees Bay, SA 5223",
    "Daadenning Creek, WA 6410",
    "Dabee, NSW 2849",
    "Daceyville, NSW 2032",
    "Dadswells Bridge, VIC 3385",
    "Daggar Hills, WA 6638",
    "Daglish, WA 6008",
    "Dagmar, QLD 4873",
    "Dagun, QLD 4570",
    "Daguragu, NT 0852",
    "Daintree, QLD 4873",
    "Dairy Flat, NSW 2474",
    "Dairy Plains, TAS 7304",
    "Dairymans Plains, NSW 2630",
    "Daisy Hill, QLD 4127",
    "Daisy Hill, VIC 3465",
    "Dajarra, QLD 4825",
    "Dakabin, QLD 4503",
    "Dakenba, QLD 4715",
    "Dalbeg, QLD 4807",
    "Dalby, QLD 4405",
    "Dalcouth, QLD 4380",
    "Dale, WA 6304",
    "Dales Creek, VIC 3341",
    "Daleys Point, NSW 2257",
    "Dalga, QLD 4630",
    "Dalgety, NSW 2628",
    "Daliak, WA 6302",
    "Dalkeith, WA 6009",
    "Dalkey, SA 5461",
    "Dallarnil, QLD 4621",
    "Dallas, VIC 3047",
    "Dalma, QLD 4702",
    "Dalmeny, NSW 2546",
    "Dalmore, VIC 3981",
    "Dalmorton, NSW 2460",
    "Dalrymple Creek, QLD 4850",
    "Dalrymple Heights, QLD 4757",
    "Dalswinton, NSW 2328",
    "Dalton, NSW 2581",
    "Daltons Bridge, VIC 3568",
    "Dalveen, QLD 4374",
    "Dalwallinu, WA 6609",
    "Dalwogon, QLD 4415",
    "Dalwood, NSW 2477",
    "Dalwood, NSW 2335",
    "Daly, NT 0822",
    "Daly River, NT 0822",
    "Daly Waters, NT 0852",
    "Dalyellup, WA 6230",
    "Dalyenong, VIC 3477",
    "Dalysford, QLD 4671",
    "Dalyston, VIC 3992",
    "Dalyup, WA 6450",
    "Damascus, QLD 4671",
    "Damper Creek, QLD 4849",
    "Dampier, WA 6713",
    "Dampier Archipelago, WA 6713",
    "Dampier Peninsula, WA 6725",
    "Danbulla, QLD 4872",
    "Dandaloo, NSW 2823",
    "Dandanning, WA 6479",
    "Dandaragan, WA 6507",
    "Dandenong, VIC 3175",
    "Dandenong East, VIC 3175",
    "Dandenong North, VIC 3175",
    "Dandenong Plaza, VIC 3175",
    "Dandenong South, VIC 3164",
    "Dandenong South, VIC 3175",
    "Danderoo, QLD 4370",
    "Dandongadale, VIC 3737",
    "Dandry, NSW 2357",
    "Dangar, NSW 2309",
    "Dangar Island, NSW 2083",
    "Dangarsleigh, NSW 2350",
    "Dangelong, NSW 2630",
    "Dangin, WA 6383",
    "Dangore, QLD 4610",
    "Dapto, NSW 2530",
    "Daradgee, QLD 4860",
    "Darawank, NSW 2428",
    "Darbalara, NSW 2722",
    "Darbys Falls, NSW 2793",
    "Darch, WA 6065",
    "Dardadine, WA 6392",
    "Dardanup, WA 6236",
    "Dardanup West, WA 6236",
    "Dareton, NSW 2717",
    "Dargal Road, QLD 4455",
    "Dargan, NSW 2786",
    "Dargo, VIC 3862",
    "Dark Corner, NSW 2795",
    "Darkan, WA 6392",
    "Darke Peak, SA 5642",
    "Darkes Forest, NSW 2508",
    "Darkwood, NSW 2454",
    "Darley, VIC 3340",
    "Darlimurla, VIC 3871",
    "Darling, VIC 3145",
    "Darling Downs, WA 6122",
    "Darling Heights, QLD 4350",
    "Darling Point, NSW 2027",
    "Darling South, VIC 3145",
    "Darlinghurst, NSW 1300",
    "Darlinghurst, NSW 2010",
    "Darlington, NSW 2330",
    "Darlington, QLD 4285",
    "Darlington, SA 5047",
    "Darlington, WA 6070",
    "Darlington, VIC 3271",
    "Darlington, NSW 2008",
    "Darlington Point, NSW 2706",
    "Darlow, NSW 2729",
    "Darnley Island, QLD 4875",
    "Darnum, VIC 3822",
    "Daroobalgie, NSW 2871",
    "Darra, QLD 4076",
    "Darradup, WA 6275",
    "Darraweit Guim, VIC 3756",
    "Darriman, VIC 3851",
    "Dartbrook, NSW 2336",
    "Dartmoor, VIC 3304",
    "Dartmoor, WA 6532",
    "Dartmouth, VIC 3701",
    "Dartnall, WA 6320",
    "Darts Creek, QLD 4695",
    "Daruka, NSW 2340",
    "Darwin, NT 0800",
    "Darwin International Airport, NT 0820",
    "Darwin River, NT 0841",
    "Darwin River, NT 0841",
    "Darwin River Dam, NT 0822",
    "Datatine, WA 6317",
    "Dauan Island, QLD 4875",
    "Davenport, NT 0872",
    "Davenport, WA 6230",
    "Daveson, QLD 4855",
    "Daveyston, SA 5355",
    "Davidson, NSW 2085",
    "Davis, TAS 7151",
    "Davis Creek, NSW 2336",
    "Davistown, NSW 2251",
    "Davoren Park, SA 5113",
    "Davoren Park North, SA 5113",
    "Davoren Park South, SA 5113",
    "Daw Park, SA 5041",
    "Dawes Point, NSW 2000",
    "Dawesley, SA 5252",
    "Dawesville, WA 6211",
    "Dawson, SA 5422",
    "Dawson, VIC 3858",
    "Dayboro, QLD 4521",
    "Daydream Island, QLD 4741",
    "Daylesford, VIC 3460",
    "Daymar, QLD 4497",
    "Daysdale, NSW 2646",
    "Dayton, WA 6055",
    "De Grey, WA 6722",
    "De Mole River, SA 5223",
    "Deagon, QLD 4017",
    "Deakin, ACT 2600",
    "Deakin University, VIC 3217",
    "Deakin West, ACT 2600",
    "Dean, VIC 3363",
    "Dean Park, NSW 2761",
    "Deanmill, WA 6258",
    "Deans Marsh, VIC 3235",
    "Deauville, NSW 2443",
    "Debenham, NSW 2446",
    "Deception Bay, QLD 4508",
    "Deddick Valley, VIC 3888",
    "Deddington, TAS 7212",
    "Dederang, VIC 3691",
    "Dedin, QLD 4873",
    "Dee, TAS 7140",
    "Dee Why, NSW 2099",
    "Deebing Heights, QLD 4306",
    "Deep Bay, TAS 7112",
    "Deep Creek, NSW 2469",
    "Deep Creek, QLD 4625",
    "Deep Creek, NSW 2440",
    "Deep Creek, NSW 2460",
    "Deep Creek, SA 5204",
    "Deep Lead, VIC 3385",
    "Deepdale, WA 6532",
    "Deepdene, WA 6290",
    "Deepdene, VIC 3103",
    "Deepwater, NSW 2371",
    "Deepwater, QLD 4674",
    "Deepwater, SA 5266",
    "Deer Park, VIC 3023",
    "Deer Park East, VIC 3022",
    "Deer Park North, VIC 3023",
    "Deer Vale, NSW 2453",
    "Deeragun, QLD 4818",
    "Deeral, QLD 4871",
    "Degarra, QLD 4895",
    "Degilbo, QLD 4621",
    "Delacombe, VIC 3356",
    "Delahey, VIC 3037",
    "Delamere, NT 0852",
    "Delamere, SA 5204",
    "Delan, QLD 4671",
    "Delaneys Creek, QLD 4514",
    "Delatite, VIC 3723",
    "Delburn, VIC 3871",
    "Delegate, NSW 2633",
    "Delegate River, VIC 3888",
    "Delissaville, NT 0822",
    "Deloraine, TAS 7304",
    "Delta, QLD 4805",
    "Delungra, NSW 2403",
    "Denbarker, WA 6324",
    "Dendy, VIC 3186",
    "Denham, WA 6537",
    "Denham Court, NSW 2565",
    "Denhams Beach, NSW 2536",
    "Denial Bay, SA 5690",
    "Denicull Creek, VIC 3377",
    "Deniliquin, NSW 2710",
    "Denison, VIC 3858",
    "Denistone, NSW 2114",
    "Denistone East, NSW 2112",
    "Denistone West, NSW 2114",
    "Denman, NSW 2328",
    "Denmark, WA 6333",
    "Dennes Point, TAS 7150",
    "Dennington, VIC 3280",
    "Denver, VIC 3461",
    "Depot Beach, NSW 2536",
    "Depot Hill, QLD 4700",
    "Deptford, VIC 3875",
    "Derby, VIC 3516",
    "Derby, WA 6728",
    "Derby, TAS 7264",
    "Dereel, VIC 3352",
    "Dergholm, VIC 3312",
    "Dernancourt, SA 5075",
    "Derri Derra, QLD 4626",
    "Derrimut, VIC 3030",
    "Derrinal, VIC 3523",
    "Derrinallum, VIC 3325",
    "Derriwong, NSW 2877",
    "Derrymore, QLD 4352",
    "Derwent Bridge, TAS 7140",
    "Derwent Park, TAS 7009",
    "Desailly, QLD 4871",
    "Desert Springs, NT 0870",
    "Detention, TAS 7321",
    "Deua, NSW 2537",
    "Deua River Valley, NSW 2537",
    "Deuchar, QLD 4362",
    "Devenish, VIC 3726",
    "Devereux Creek, QLD 4753",
    "Devils Creek, WA 6630",
    "Devils Hole, NSW 2550",
    "Devils River, VIC 3714",
    "Deviot, TAS 7275",
    "Devlins Pound, SA 5330",
    "Devon Hills, TAS 7300",
    "Devon Meadows, VIC 3977",
    "Devon North, VIC 3971",
    "Devon Park, SA 5008",
    "Devon Park, QLD 4401",
    "Devonport, TAS 7310",
    "Dewars Pool, WA 6567",
    "Dewhurst, VIC 3808",
    "Dewitt, NSW 2422",
    "Dharruk, NSW 2770",
    "Dhuragoon, NSW 2733",
    "Dhurringile, VIC 3610",
    "Diamantina Lakes, QLD 4735",
    "Diamond Beach, NSW 2430",
    "Diamond Creek, VIC 3089",
    "Diamond Head, NSW 2443",
    "Diamond Tree, WA 6258",
    "Diamond Valley, QLD 4553",
    "Dianella, WA 6059",
    "Dickson, ACT 2602",
    "Dicky Beach, QLD 4551",
    "Didcot, QLD 4621",
    "Diddillibah, QLD 4559",
    "Diehard, NSW 2370",
    "Digby, VIC 3309",
    "Diggers Camp, NSW 2462",
    "Diggers Rest, VIC 3427",
    "Diggora, VIC 3561",
    "Diglum, QLD 4680",
    "Dignams Creek, NSW 2546",
    "Dilkoon, NSW 2460",
    "Dilpurra, NSW 2734",
    "Dilston, TAS 7252",
    "Dimboola, VIC 3414",
    "Dimbulah, QLD 4872",
    "Dindiloa, WA 6532",
    "Dingabledinga, SA 5172",
    "Dingee, VIC 3571",
    "Dingley Village, VIC 3172",
    "Dingo, QLD 4702",
    "Dingo Beach, QLD 4800",
    "Dingo Forest, NSW 2429",
    "Dingo Pocket, QLD 4854",
    "Dingup, WA 6258",
    "Dingwall, VIC 3579",
    "Dinmore, QLD 4303",
    "Dinner Plain, VIC 3898",
    "Dinninup, WA 6244",
    "Dinoga, NSW 2404",
    "Direk, SA 5110",
    "Dirk Hartog Island, WA 6537",
    "Dirnaseer, NSW 2666",
    "Dirnbir, QLD 4625",
    "Dirranbandi, QLD 4486",
    "Dirty Creek, NSW 2460",
    "Dismal Swamp, SA 5291",
    "Dittmer, QLD 4800",
    "Diwan, QLD 4873",
    "Dixalea, QLD 4702",
    "Dixie, VIC 3265",
    "Dixie, QLD 4871",
    "Dixons Creek, VIC 3775",
    "Dixvale, WA 6258",
    "Djarawong, QLD 4854",
    "Djiru, QLD 4852",
    "Djuan, QLD 4352",
    "Djugun, WA 6725",
    "Dobie, VIC 3377",
    "Dobies Bight, NSW 2470",
    "Docker, VIC 3678",
    "Docker River, NT 0872",
    "Dockers Plains, VIC 3678",
    "Docklands, VIC 8012",
    "Docklands, VIC 3008",
    "Doctor Creek, QLD 4352",
    "Doctor George Mountain, NSW 2550",
    "Doctors Flat, VIC 3895",
    "Doctors Gap, NSW 2790",
    "Doctors Point, TAS 7304",
    "Doctors Rocks, TAS 7325",
    "Dodges Ferry, TAS 7173",
    "Dog Rocks, NSW 2795",
    "Dolans Bay, NSW 2229",
    "Dollar, VIC 3871",
    "Dolls Point, NSW 2219",
    "Dollys Flat, NSW 2429",
    "Dolphin Heads, QLD 4740",
    "Dolphin Point, NSW 2539",
    "Dolphin Sands, TAS 7190",
    "Domain Road Po, VIC 3141",
    "Dombarton, NSW 2530",
    "Domville, QLD 4357",
    "Don, TAS 7310",
    "Don Valley, VIC 3139",
    "Donald, VIC 3480",
    "Donald Creek, NSW 2350",
    "Doncaster, VIC 3108",
    "Doncaster East, VIC 3109",
    "Doncaster Heights, VIC 3109",
    "Dondingalong, NSW 2440",
    "Dongara, WA 6525",
    "Dongolocking, WA 6315",
    "Donnelly River, WA 6258",
    "Donnellyville, NSW 2447",
    "Donnybrook, QLD 4510",
    "Donnybrook, VIC 3064",
    "Donnybrook, WA 6239",
    "Donovans, SA 5291",
    "Donvale, VIC 3111",
    "Dooboobetic, VIC 3478",
    "Doodenanning, WA 6383",
    "Doodlakine, WA 6411",
    "Dooen, VIC 3401",
    "Dookie, VIC 3646",
    "Dookie College, VIC 3647",
    "Doolandella, QLD 4077",
    "Doolbi, QLD 4660",
    "Doomadgee, QLD 4830",
    "Doon Doon, NSW 2484",
    "Doonan, QLD 4562",
    "Doonbah, NSW 2473",
    "Doongul, QLD 4620",
    "Doonside, NSW 2767",
    "Dooralong, NSW 2259",
    "Dora Creek, NSW 2264",
    "Doreen, VIC 3754",
    "Dorodong, VIC 3312",
    "Dorre Island, WA 6701",
    "Dorrigo, NSW 2453",
    "Dorrigo Mountain, NSW 2453",
    "Dorroughby, NSW 2480",
    "Dorset Vale, SA 5157",
    "Dotswood, QLD 4820",
    "Double Bay, NSW 2028",
    "Double Bay, NSW 1360",
    "Double Bridges, VIC 3893",
    "Doubleview, WA 6018",
    "Doubtful Creek, NSW 2470",
    "Doughboy, QLD 4671",
    "Douglas, QLD 4354",
    "Douglas, QLD 4814",
    "Douglas, VIC 3409",
    "Douglas Park, NSW 2569",
    "Douglas Point, SA 5600",
    "Douglas Point South, SA 5600",
    "Douglas River, TAS 7215",
    "Douglas-daly, NT 0822",
    "Dover, TAS 7117",
    "Dover Gardens, SA 5048",
    "Dover Heights, NSW 2030",
    "Doveton, VIC 3177",
    "Dowerin, WA 6461",
    "Dowling, SA 5555",
    "Dowlingville, SA 5555",
    "Downer, ACT 2602",
    "Downlands, TAS 7320",
    "Downsfield, QLD 4570",
    "Downside, NSW 2650",
    "Dows Creek, QLD 4754",
    "Dowsing Point, TAS 7010",
    "Doyalson, NSW 2262",
    "Doyalson North, NSW 2262",
    "Doyles Creek, NSW 2330",
    "Doyles River, NSW 2446",
    "Drake, NSW 2469",
    "Drake Village, NSW 2469",
    "Draper, QLD 4520",
    "Drayton, QLD 4350",
    "Drayton North, QLD 4350",
    "Dreeite, VIC 3249",
    "Dreeite South, VIC 3249",
    "Drewvale, QLD 4116",
    "Driffield, VIC 3840",
    "Drik Drik, VIC 3304",
    "Drildool, NSW 2386",
    "Drillham, QLD 4424",
    "Drillham South, QLD 4424",
    "Drinan, QLD 4671",
    "Dripstone, NSW 2820",
    "Driver, NT 0830",
    "Driver, NT 0830",
    "Dromana, VIC 3936",
    "Drome, WA 6330",
    "Dromedary, TAS 7030",
    "Dromedary, QLD 4718",
    "Dropmore, VIC 3660",
    "Drouin, VIC 3818",
    "Drouin East, VIC 3818",
    "Drouin South, VIC 3818",
    "Drouin West, VIC 3818",
    "Drumanure, VIC 3636",
    "Drumborg, VIC 3304",
    "Drumcondra, VIC 3215",
    "Drummartin, VIC 3570",
    "Drummond, VIC 3461",
    "Drummond Cove, WA 6532",
    "Drummond North, VIC 3446",
    "Drummondslope, QLD 4724",
    "Drummoyne, NSW 2047",
    "Drummoyne, NSW 1470",
    "Drung, VIC 3401",
    "Dry Creek, SA 5094",
    "Dry Creek, NSW 2337",
    "Dry Diggings, VIC 3461",
    "Dry Plain, NSW 2630",
    "Dryandra, WA 6311",
    "Drysdale, VIC 3222",
    "Drysdale River, WA 6740",
    "Duaringa, QLD 4712",
    "Dubbo, NSW 2830",
    "Dubbo East, NSW 2830",
    "Dubbo Grove, NSW 2830",
    "Dubbo West, NSW 2830",
    "Dublin, SA 5501",
    "Duchembegarra, VIC 3409",
    "Duchess, QLD 4825",
    "Duck Creek, NSW 2469",
    "Duck Ponds, SA 5607",
    "Duckenfield, NSW 2321",
    "Duckinwilla, QLD 4650",
    "Ducklo, QLD 4405",
    "Duckmaloi, NSW 2787",
    "Dudawa, WA 6519",
    "Dudinin, WA 6363",
    "Dudley, NSW 2290",
    "Dudley East, SA 5222",
    "Dudley Park, WA 6210",
    "Dudley Park, SA 5008",
    "Dudley West, SA 5222",
    "Duffy, ACT 2611",
    "Duffys Forest, NSW 2084",
    "Dugandan, QLD 4310",
    "Duingal, QLD 4671",
    "Dukin, WA 6475",
    "Dulacca, QLD 4425",
    "Dulbelling, WA 6383",
    "Dulcot, TAS 7025",
    "Dulguigan, NSW 2484",
    "Dulong, QLD 4560",
    "Dululu, QLD 4702",
    "Dulwich, SA 5065",
    "Dulwich Hill, NSW 2203",
    "Dulyalbin, WA 6425",
    "Dum Dum, NSW 2484",
    "Dumaresq, NSW 2350",
    "Dumaresq Island, NSW 2430",
    "Dumaresq Valley, NSW 2372",
    "Dumbalk, VIC 3956",
    "Dumbalk North, VIC 3956",
    "Dumbarton, WA 6566",
    "Dumberning, WA 6312",
    "Dumbleton, QLD 4740",
    "Dumbleyung, WA 6350",
    "Dumbudgery, NSW 2460",
    "Dumgree, QLD 4715",
    "Dumosa, VIC 3527",
    "Dumpy Creek, QLD 4702",
    "Dunach, VIC 3371",
    "Dunalley, TAS 7177",
    "Dunbible, NSW 2484",
    "Dunbogan, NSW 2443",
    "Duncan, SA 5223",
    "Duncans Creek, NSW 2340",
    "Duncraig, WA 6023",
    "Dundarrah, QLD 4625",
    "Dundas, WA 6443",
    "Dundas, QLD 4306",
    "Dundas, NSW 2117",
    "Dundas Valley, NSW 2117",
    "Dundathu, QLD 4650",
    "Dundee, NSW 2370",
    "Dundee Beach, NT 0840",
    "Dundee Beach, NT 0840",
    "Dundee Downs, NT 0840",
    "Dundee Forest, NT 0840",
    "Dundonnell, VIC 3271",
    "Dundowran, QLD 4655",
    "Dundowran Beach, QLD 4655",
    "Dundula, QLD 4740",
    "Dundurrabin, NSW 2453",
    "Dunearn, VIC 3175",
    "Dunedoo, NSW 2844",
    "Dungarubba, NSW 2480",
    "Dungay, NSW 2484",
    "Dungeree, NSW 2849",
    "Dungog, NSW 2420",
    "Dungowan, NSW 2340",
    "Dunk, QLD 4852",
    "Dunkeld, VIC 3294",
    "Dunkeld, QLD 4465",
    "Dunkeld, NSW 2795",
    "Dunkirk, VIC 3630",
    "Dunlop, ACT 2615",
    "Dunluce, VIC 3472",
    "Dunmarra, NT 0852",
    "Dunmora, QLD 4650",
    "Dunmore, QLD 4407",
    "Dunmore, NSW 2529",
    "Dunn Rock, WA 6355",
    "Dunneworthy, VIC 3377",
    "Dunnrock, QLD 4740",
    "Dunnstown, VIC 3352",
    "Dunolly, NSW 2330",
    "Dunolly, VIC 3472",
    "Dunoon, NSW 2480",
    "Dunorlan, TAS 7304",
    "Dunrobin, QLD 4728",
    "Dunrobin, VIC 3312",
    "Duns Creek, NSW 2321",
    "Dunsborough, WA 6281",
    "Duntroon, ACT 2600",
    "Dunville Loop, NSW 2849",
    "Dunwich, QLD 4183",
    "Durack, QLD 4077",
    "Durack, WA 6743",
    "Durack, NT 0830",
    "Durack, NT 0830",
    "Durah, QLD 4413",
    "Dural, NSW 2330",
    "Dural, NSW 2158",
    "Duramana, NSW 2795",
    "Duranbah, NSW 2487",
    "Duranillin, WA 6393",
    "Durawah, WA 6532",
    "Durdidwarrah, VIC 3342",
    "Durham Downs, QLD 4454",
    "Durham Downs, SA 5731",
    "Durham Lead, VIC 3352",
    "Durham Ox, VIC 3576",
    "Duri, NSW 2344",
    "Duroby, NSW 2486",
    "Durong, QLD 4610",
    "Durong South, QLD 4610",
    "Durran Durra, NSW 2622",
    "Durras North, NSW 2536",
    "Durren Durren, NSW 2259",
    "Dutson, VIC 3851",
    "Dutson Downs, VIC 3851",
    "Dutton, SA 5356",
    "Dutton East, SA 5356",
    "Dutton Park, QLD 4102",
    "Dutton River, QLD 4821",
    "Dutton Way, VIC 3305",
    "Duval, NSW 2350",
    "Duverney, VIC 3323",
    "Dwarda, WA 6308",
    "Dwellingup, WA 6213",
    "Dyers Crossing, NSW 2429",
    "Dykehead, QLD 4626",
    "Dynevor, QLD 4492",
    "Dynnyrne, TAS 7005",
    "Dyraaba, NSW 2470",
    "Dyrring, NSW 2330",
    "Dysart, TAS 7030",
    "Dysart, QLD 4745",
    "Eagle Bay, WA 6281",
    "Eagle Farm, QLD 4009",
    "Eagle Farm Bc, QLD 4009",
    "Eagle Heights, QLD 4271",
    "Eagle On The Hill, SA 5150",
    "Eagle Point, VIC 3878",
    "Eagle Vale, NSW 2558",
    "Eagleby, QLD 4207",
    "Eaglefield, QLD 4742",
    "Eaglehawk, VIC 3556",
    "Eaglehawk Neck, TAS 7179",
    "Eaglehawk North, VIC 3556",
    "Eaglemont, VIC 3084",
    "Eagleton, NSW 2324",
    "Earlston, VIC 3669",
    "Earlville, QLD 4870",
    "Earlville Bc, QLD 4870",
    "Earlwood, NSW 2206",
    "East Albury, NSW 2640",
    "East Arm, NT 0822",
    "East Arnhem, NT 0822",
    "East Augusta, WA 6290",
    "East Bairnsdale, VIC 3875",
    "East Ballidu, WA 6606",
    "East Ballina, NSW 2478",
    "East Barron, QLD 4883",
    "East Bendigo, VIC 3550",
    "East Beverley, WA 6304",
    "East Bowes, WA 6535",
    "East Bowral, NSW 2576",
    "East Branxton, NSW 2335",
    "East Brisbane, QLD 4169",
    "East Bunbury, WA 6230",
    "East Cam, TAS 7321",
    "East Cannington, WA 6107",
    "East Carnarvon, WA 6701",
    "East Chapman, WA 6532",
    "East Cooyar, QLD 4353",
    "East Coraki, NSW 2471",
    "East Corrimal, NSW 2518",
    "East Creek, QLD 4871",
    "East Damboring, WA 6608",
    "East Deep Creek, QLD 4570",
    "East Devonport, TAS 7310",
    "East End, QLD 4695",
    "East Feluga, QLD 4854",
    "East Fremantle, WA 6158",
    "East Geelong, VIC 3219",
    "East Gosford, NSW 2250",
    "East Greenmount, QLD 4359",
    "East Gresford, NSW 2311",
    "East Haldon, QLD 4343",
    "East Hills, NSW 2213",
    "East Innisfail, QLD 4860",
    "East Ipswich, QLD 4305",
    "East Jindabyne, NSW 2627",
    "East Kangaloon, NSW 2576",
    "East Kempsey, NSW 2440",
    "East Killara, NSW 2071",
    "East Kurrajong, NSW 2758",
    "East Launceston, TAS 7250",
    "East Lindfield, NSW 2070",
    "East Lismore, NSW 2480",
    "East Lynne, NSW 2536",
    "East Lyons River, WA 6705",
    "East Mackay, QLD 4740",
    "East Maitland, NSW 2323",
    "East Melbourne, VIC 8002",
    "East Melbourne, VIC 3002",
    "East Moonta, SA 5558",
    "East Munglinup, WA 6450",
    "East Murchison, WA 6640",
    "East Nabawa, WA 6532",
    "East Nanango, QLD 4615",
    "East Nannup, WA 6275",
    "East Newdegate, WA 6355",
    "East Palmerston, QLD 4860",
    "East Perth, WA 6892",
    "East Perth, WA 6004",
    "East Pingelly, WA 6308",
    "East Point, NT 0820",
    "East Popanyinning, WA 6309",
    "East Ridgley, TAS 7321",
    "East Rockingham, WA 6168",
    "East Russell, QLD 4861",
    "East Ryde, NSW 2113",
    "East Sale, VIC 3852",
    "East Seaham, NSW 2324",
    "East Side, NT 0870",
    "East Tamworth, NSW 2340",
    "East Toowoomba, QLD 4350",
    "East Trinity, QLD 4871",
    "East Victoria Park, WA 6981",
    "East Victoria Park, WA 6101",
    "East Wagga Wagga, NSW 2650",
    "East Wangaratta, VIC 3678",
    "East Warburton, VIC 3799",
    "East Wardell, NSW 2477",
    "East Wickepin, WA 6370",
    "East Yuna, WA 6532",
    "Eastbrook, WA 6260",
    "Eastern Creek, NSW 2766",
    "Eastern Heights, QLD 4305",
    "Eastern View, VIC 3231",
    "Eastgardens, NSW 2036",
    "Eastlakes, NSW 2018",
    "Eastville, VIC 3463",
    "Eastwood, VIC 3875",
    "Eastwood, SA 5063",
    "Eastwood, NSW 2122",
    "Eaton, NT 0820",
    "Eaton, QLD 4860",
    "Eaton, WA 6232",
    "Eatons Hill, QLD 4037",
    "Eatonsville, NSW 2460",
    "Eba, SA 5320",
    "Eba Anchorage, SA 5680",
    "Ebbw Vale, QLD 4304",
    "Ebden, VIC 3691",
    "Ebenezer, SA 5355",
    "Ebenezer, QLD 4340",
    "Ebenezer, NSW 2756",
    "Ebor, NSW 2453",
    "Eccleston, NSW 2311",
    "Echuca, VIC 3564",
    "Echuca South, VIC 3564",
    "Echuca Village, VIC 3564",
    "Echuca West, VIC 3564",
    "Echunga, SA 5153",
    "Ecklin South, VIC 3265",
    "Edderton, NSW 2333",
    "Eddington, VIC 3472",
    "Eddystone, TAS 7264",
    "Eddystone Point, TAS 7264",
    "Eden, NSW 2551",
    "Eden Creek, NSW 2474",
    "Eden Hill, WA 6054",
    "Eden Hills, SA 5050",
    "Eden Park, VIC 3757",
    "Eden Valley, SA 5235",
    "Edenhope, VIC 3318",
    "Edens Landing, QLD 4207",
    "Edensor Park, NSW 2176",
    "Edenville, NSW 2474",
    "Edgcumbe Beach, TAS 7321",
    "Edge Hill, QLD 4870",
    "Edgecliff, NSW 2027",
    "Edgecombe, VIC 3444",
    "Edgeroi, NSW 2390",
    "Edgewater, WA 6027",
    "Edgeworth, NSW 2285",
    "Edi, VIC 3678",
    "Edi Upper, VIC 3678",
    "Edillilie, SA 5630",
    "Edinburgh, SA 5111",
    "Edinburgh Raaf, SA 5111",
    "Edith, NSW 2787",
    "Edith, NT 0852",
    "Edith Creek, TAS 7330",
    "Edithburgh, SA 5583",
    "Edithvale, VIC 3196",
    "Edmondson Park, NSW 2174",
    "Edmonton, QLD 4869",
    "Edrom, NSW 2551",
    "Edward River, QLD 4871",
    "Edwardstown, SA 5039",
    "Eenaweena, NSW 2824",
    "Eerwah Vale, QLD 4562",
    "Eganstown, VIC 3461",
    "Eganu, WA 6515",
    "Egg Lagoon, TAS 7256",
    "Eggs And Bacon Bay, TAS 7112",
    "Eglinton, WA 6034",
    "Eglinton, NSW 2795",
    "Egypt, QLD 4344",
    "Eidsvold, QLD 4627",
    "Eidsvold East, QLD 4627",
    "Eidsvold West, QLD 4627",
    "Eight Mile Creek, QLD 4807",
    "Eight Mile Creek, SA 5291",
    "Eight Mile Plains, QLD 4113",
    "Eighteen Mile, NSW 2460",
    "Eighty Mile Beach, WA 6725",
    "Eildon, VIC 3713",
    "Eimeo, QLD 4740",
    "Einasleigh, QLD 4871",
    "El Arish, QLD 4855",
    "Elabbin, WA 6490",
    "Elachbutting, WA 6479",
    "Elaine, VIC 3334",
    "Elaman Creek, QLD 4552",
    "Elands, NSW 2429",
    "Elanora, QLD 4221",
    "Elanora Heights, NSW 2101",
    "Elbow Valley, QLD 4370",
    "Elcombe, NSW 2404",
    "Elderslie, NSW 2335",
    "Elderslie, TAS 7030",
    "Elderslie, NSW 2570",
    "Eldorado, VIC 3746",
    "Electra, QLD 4670",
    "Electrona, TAS 7054",
    "Eleebana, NSW 2282",
    "Elermore Vale, NSW 2287",
    "Elevated Plains, VIC 3461",
    "Elgin, WA 6237",
    "Elgin Vale, QLD 4615",
    "Eli Waters, QLD 4655",
    "Elimbah, QLD 4516",
    "Elingamite, VIC 3266",
    "Elingamite North, VIC 3266",
    "Elizabeth, SA 5112",
    "Elizabeth Bay, NSW 2011",
    "Elizabeth Beach, NSW 2428",
    "Elizabeth Downs, SA 5113",
    "Elizabeth East, SA 5112",
    "Elizabeth Grove, SA 5112",
    "Elizabeth Hills, NSW 2171",
    "Elizabeth North, SA 5113",
    "Elizabeth Park, SA 5113",
    "Elizabeth South, SA 5112",
    "Elizabeth Town, TAS 7304",
    "Elizabeth Vale, SA 5112",
    "Elizabeth West, SA 5113",
    "Ellalong, NSW 2325",
    "Elland, NSW 2460",
    "Ellangowan, QLD 4361",
    "Ellangowan, NSW 2470",
    "Ellaswood, VIC 3875",
    "Elleker, WA 6330",
    "Ellen Grove, QLD 4078",
    "Ellenborough, NSW 2446",
    "Ellenbrook, WA 6069",
    "Ellenbrook East, WA 6069",
    "Ellendale, WA 6532",
    "Ellendale, TAS 7140",
    "Ellerbeck, QLD 4816",
    "Ellerslie, NSW 2648",
    "Ellerslie, NSW 2729",
    "Ellerslie, VIC 3265",
    "Ellerston, NSW 2337",
    "Ellesmere, QLD 4610",
    "Elliminyt, VIC 3250",
    "Ellinbank, VIC 3821",
    "Ellinjaa, QLD 4886",
    "Ellinthorp, QLD 4362",
    "Elliott, NT 0862",
    "Elliott, QLD 4670",
    "Elliott, TAS 7325",
    "Elliott Heads, QLD 4670",
    "Ellis Beach, QLD 4879",
    "Ellis Lane, NSW 2570",
    "Elliston, SA 5670",
    "Elmhurst, VIC 3469",
    "Elmore, VIC 3558",
    "Elong Elong, NSW 2831",
    "Elphinstone, VIC 3448",
    "Elphinstone, QLD 4361",
    "Elphinstone, QLD 4742",
    "Elrington, NSW 2325",
    "Elrundie, NT 0822",
    "Elsey, NT 0852",
    "Elsey Station, NT 0852",
    "Elsmore, NSW 2360",
    "Elsternwick, VIC 3185",
    "Eltham, NSW 2480",
    "Eltham, VIC 3095",
    "Eltham North, VIC 3095",
    "Elvina Bay, NSW 2105",
    "Elwomple, SA 5260",
    "Elwood, VIC 3184",
    "Embleton, WA 6062",
    "Emerald, QLD 4720",
    "Emerald, VIC 3782",
    "Emerald Beach, NSW 2456",
    "Emerald Hill, NSW 2380",
    "Emerton, NSW 2770",
    "Emita, TAS 7255",
    "Emmaville, NSW 2371",
    "Empire Bay, NSW 2257",
    "Empire Vale, NSW 2478",
    "Emu, VIC 3475",
    "Emu Bay, SA 5223",
    "Emu Creek, QLD 4355",
    "Emu Creek, VIC 3551",
    "Emu Downs, SA 5381",
    "Emu Flat, WA 6431",
    "Emu Flat, SA 5453",
    "Emu Heights, NSW 2750",
    "Emu Heights, TAS 7320",
    "Emu Park, QLD 4710",
    "Emu Plains, NSW 2750",
    "Emu Point, WA 6330",
    "Emu Swamp, NSW 2800",
    "Emu Vale, QLD 4371",
    "Emungalan, NT 0850",
    "Encounter Bay, SA 5211",
    "Endeavour Falls, QLD 4895",
    "Endeavour Hills, VIC 3802",
    "Endrick, NSW 2622",
    "Eneabba, WA 6518",
    "Enfield, NSW 2136",
    "Enfield, SA 5085",
    "Enfield, VIC 3352",
    "Enfield Plaza, SA 5085",
    "Enfield South, NSW 2133",
    "Engadine, NSW 2233",
    "Engawala, NT 0872",
    "England Creek, QLD 4306",
    "Englefield, VIC 3407",
    "English Town, TAS 7212",
    "Englorie Park, NSW 2560",
    "Enmore, NSW 2042",
    "Enmore, NSW 2350",
    "Enngonia, NSW 2840",
    "Ennuin, WA 6484",
    "Enochs Point, VIC 3723",
    "Enoggera, QLD 4051",
    "Enoggera Reservoir, QLD 4520",
    "Ensay, VIC 3895",
    "Ensay North, VIC 3895",
    "Environa, NSW 2620",
    "Ephraim Island, QLD 4216",
    "Eppalock, VIC 3551",
    "Epping, VIC 3076",
    "Epping, NSW 1710",
    "Epping, NSW 1712",
    "Epping, NSW 2121",
    "Epping Forest, TAS 7211",
    "Epsom, QLD 4741",
    "Epsom, VIC 3551",
    "Eradu, WA 6532",
    "Eradu South, WA 6532",
    "Erakala, QLD 4740",
    "Eraring, NSW 2264",
    "Ercildoune, VIC 3352",
    "Eremerang, NSW 2877",
    "Erica, VIC 3825",
    "Erigolia, NSW 2669",
    "Erin Vale, NSW 2663",
    "Erina, NSW 2250",
    "Erina Fair, NSW 2250",
    "Erina Heights, NSW 2260",
    "Erindale, SA 5066",
    "Erindale Centre, ACT 2903",
    "Erith, SA 5461",
    "Erldunda, NT 0872",
    "Ermington, NSW 1700",
    "Ermington, NSW 2115",
    "Ernabella, SA 0872",
    "Ernestina, QLD 4730",
    "Eromanga, QLD 4480",
    "Erowal Bay, NSW 2540",
    "Erriba, TAS 7310",
    "Errinundra, VIC 3889",
    "Errowanbang, NSW 2791",
    "Erskine, WA 6210",
    "Erskine, SA 5422",
    "Erskine Park, NSW 2759",
    "Erskineville, NSW 2043",
    "Erub, QLD 4875",
    "Erudgere, NSW 2850",
    "Eschol, NSW 2830",
    "Eschol Park, NSW 2558",
    "Esk, QLD 4312",
    "Esk, NSW 2472",
    "Eskdale, QLD 4312",
    "Eskdale, VIC 3701",
    "Esmeralda, QLD 4871",
    "Esmond, VIC 3730",
    "Esperance, WA 6450",
    "Essendon, VIC 3040",
    "Essendon Fields, VIC 3041",
    "Essendon North, VIC 3041",
    "Essendon West, VIC 3040",
    "Essington, NSW 2795",
    "Estella, NSW 2650",
    "Ethelton, SA 5015",
    "Etmilyn, WA 6213",
    "Etna Creek, QLD 4702",
    "Eton, QLD 4741",
    "Ettalong Beach, NSW 2257",
    "Ettamogah, NSW 2640",
    "Ettrema, NSW 2540",
    "Ettrick, SA 5253",
    "Ettrick, NSW 2474",
    "Etty Bay, QLD 4858",
    "Euabalong, NSW 2877",
    "Euabalong West, NSW 2877",
    "Eubenangee, QLD 4860",
    "Euberta, NSW 2650",
    "Euchareena, NSW 2866",
    "Eucla, WA 6443",
    "Eucumbene, NSW 2628",
    "Eudlo, QLD 4554",
    "Eudunda, SA 5374",
    "Eugenana, TAS 7310",
    "Eugowra, NSW 2806",
    "Eukey, QLD 4380",
    "Eulah Creek, NSW 2390",
    "Euleilah, QLD 4674",
    "Eulo, QLD 4491",
    "Eulomogo, NSW 2830",
    "Eumamurrin, QLD 4455",
    "Eumemmerring, VIC 3177",
    "Eumundi, QLD 4562",
    "Eumungerie, NSW 2831",
    "Eunanoreenya, NSW 2650",
    "Eungai Creek, NSW 2441",
    "Eungai Rail, NSW 2441",
    "Eungella, NSW 2484",
    "Eungella, QLD 4757",
    "Eungella Dam, QLD 4757",
    "Eungella Hinterland, QLD 4741",
    "Eurack, VIC 3251",
    "Euramo, QLD 4854",
    "Eurardy, WA 6532",
    "Eureka, NSW 2480",
    "Eureka, QLD 4660",
    "Eureka, VIC 3350",
    "Eurelia, SA 5431",
    "Eurimbla, NSW 2867",
    "Eurimbula, QLD 4677",
    "Euroa, VIC 3666",
    "Eurobin, VIC 3739",
    "Eurobodalla, NSW 2545",
    "Euroka, NSW 2440",
    "Euroley, NSW 2700",
    "Euromina, SA 5454",
    "Eurong, QLD 4581",
    "Eurongilly, NSW 2663",
    "Eurunderee, NSW 2850",
    "Euston, NSW 2737",
    "Euthulla, QLD 4455",
    "Eva Valley, NT 0822",
    "Evandale, TAS 7212",
    "Evandale, SA 5069",
    "Evans Head, NSW 2473",
    "Evans Landing, QLD 4874",
    "Evans Plains, NSW 2795",
    "Evansford, VIC 3371",
    "Evanslea, QLD 4356",
    "Evanston, SA 5116",
    "Evanston Gardens, SA 5116",
    "Evanston Park, SA 5116",
    "Evanston South, SA 5116",
    "Evatt, ACT 2617",
    "Eveleigh, NSW 1430",
    "Eveleigh, NSW 2015",
    "Evelyn, QLD 4888",
    "Everard Central, SA 5461",
    "Everard Park, SA 5035",
    "Evergreen, QLD 4352",
    "Eversley, VIC 3377",
    "Everton, VIC 3678",
    "Everton Hills, QLD 4053",
    "Everton Park, QLD 4053",
    "Everton Upper, VIC 3678",
    "Eviron, NSW 2484",
    "Ewingar, NSW 2469",
    "Ewingsdale, NSW 2481",
    "Ewlyamartup, WA 6317",
    "Exeter, NSW 2579",
    "Exeter, TAS 7275",
    "Exeter, SA 5019",
    "Exford, VIC 3338",
    "Exmouth, WA 6707",
    "Exmouth Gulf, WA 6707",
    "Export Park, SA 5950",
    "Exton, TAS 7303",
    "Eynesbury, VIC 3338",
    "Fadden, ACT 2904",
    "Failford, NSW 2430",
    "Fairbank, VIC 3951",
    "Fairbridge, WA 6208",
    "Fairdale, QLD 4606",
    "Fairfield, QLD 4103",
    "Fairfield, NSW 2165",
    "Fairfield, VIC 3078",
    "Fairfield, NSW 1860",
    "Fairfield East, NSW 2165",
    "Fairfield Gardens, QLD 4103",
    "Fairfield Heights, NSW 2165",
    "Fairfield West, NSW 2165",
    "Fairhaven, VIC 3231",
    "Fairholme, NSW 2871",
    "Fairley, VIC 3579",
    "Fairlight, NSW 2094",
    "Fairney View, QLD 4306",
    "Fairview Park, SA 5126",
    "Fairy Bower, QLD 4700",
    "Fairy Dell, VIC 3875",
    "Fairy Dell, VIC 3561",
    "Fairy Hill, NSW 2470",
    "Fairy Meadow, NSW 2519",
    "Fairymead, QLD 4670",
    "Falbrook, NSW 2330",
    "Falcon, WA 6210",
    "Falconer, NSW 2365",
    "Falls Creek, NSW 2540",
    "Falls Creek, VIC 3699",
    "Falmouth, TAS 7215",
    "False Bay, SA 5600",
    "Fannie Bay, NT 0820",
    "Fannie Bay, NT 0820",
    "Far Meadow, NSW 2535",
    "Faraday, VIC 3451",
    "Fargunyah, NSW 2656",
    "Farina, SA 5733",
    "Farleigh, QLD 4741",
    "Farley, NSW 2320",
    "Farm Beach, SA 5607",
    "Farmborough Heights, NSW 2526",
    "Farnborough, QLD 4703",
    "Farnham, NSW 2820",
    "Farnsfield, QLD 4660",
    "Farrants Hill, NSW 2484",
    "Farrar, NT 0830",
    "Farrar, NT 0830",
    "Farrar, NT 0830",
    "Farrars Creek, QLD 4481",
    "Farrell Flat, SA 5416",
    "Farrer, ACT 2607",
    "Farringdon, NSW 2622",
    "Fassifern, NSW 2283",
    "Fassifern, QLD 4309",
    "Fassifern Valley, QLD 4309",
    "Faulconbridge, NSW 2776",
    "Faulkland, NSW 2422",
    "Fawcett, VIC 3714",
    "Fawcetts Plain, NSW 2474",
    "Fawkner, VIC 3060",
    "Fawkner East, VIC 3060",
    "Fawkner North, VIC 3060",
    "Federal, NSW 2480",
    "Federal, QLD 4568",
    "Felixstow, SA 5070",
    "Felton, QLD 4358",
    "Felton South, QLD 4358",
    "Feluga, QLD 4854",
    "Fennell Bay, NSW 2283",
    "Fentonbury, TAS 7140",
    "Fentons Creek, VIC 3518",
    "Ferguson, VIC 3237",
    "Ferguson, WA 6236",
    "Fern Bay, NSW 2295",
    "Fern Gully, NSW 2330",
    "Fern Hill, VIC 3458",
    "Fern Tree, TAS 7054",
    "Fernances, NSW 2775",
    "Fernbank, VIC 3864",
    "Fernbank Creek, NSW 2444",
    "Fernbrook, NSW 2453",
    "Ferndale, WA 6148",
    "Ferndale, VIC 3821",
    "Ferney, QLD 4650",
    "Fernhill, NSW 2519",
    "Fernihurst, VIC 3518",
    "Fernlees, QLD 4702",
    "Fernleigh, NSW 2479",
    "Fernmount, NSW 2454",
    "Fernshaw, VIC 3778",
    "Fernside, NSW 2480",
    "Ferntree Gully, VIC 3156",
    "Fernvale, NSW 2484",
    "Fernvale, QLD 4306",
    "Ferny Creek, VIC 3786",
    "Ferny Glen, QLD 4275",
    "Ferny Grove, QLD 4055",
    "Ferny Hills, QLD 4055",
    "Ferodale, NSW 2318",
    "Ferryden Park, SA 5010",
    "Feysville, WA 6431",
    "Ficks Crossing, QLD 4606",
    "Fiddletown, NSW 2159",
    "Field, SA 5265",
    "Fielding, QLD 4825",
    "Fiery Flat, VIC 3518",
    "Fifield, NSW 2875",
    "Fifteen Mile, QLD 4352",
    "Fig Tree Pocket, QLD 4069",
    "Figtree, NSW 2525",
    "Fimiston, WA 6432",
    "Finch Hatton, QLD 4756",
    "Findon, SA 5023",
    "Findon Creek, NSW 2474",
    "Fine Flower, NSW 2460",
    "Fingal, TAS 7214",
    "Fingal, VIC 3939",
    "Fingal Bay, NSW 2315",
    "Fingal Head, NSW 2487",
    "Finke, NT 0872",
    "Finlay Vale, QLD 4873",
    "Finley, NSW 2713",
    "Finnie, QLD 4350",
    "Finniss, SA 5255",
    "Finniss Valley, NT 0822",
    "Finucane, WA 6722",
    "Firefly, NSW 2429",
    "Firle, SA 5070",
    "Fischer, SA 5502",
    "Fish Creek, VIC 3959",
    "Fish Point, VIC 3585",
    "Fisher, SA 5354",
    "Fisher, ACT 2611",
    "Fisher, QLD 4825",
    "Fisherman Bay, SA 5522",
    "Fishermans Bay, NSW 2316",
    "Fishermans Paradise, NSW 2539",
    "Fishermans Pocket, QLD 4570",
    "Fishermans Reach, NSW 2441",
    "Fishers Hill, NSW 2421",
    "Fishery Falls, QLD 4871",
    "Fishing Point, NSW 2283",
    "Fiskville, VIC 3342",
    "Fitzgerald, WA 6337",
    "Fitzgerald, TAS 7140",
    "Fitzgerald Bay, SA 5600",
    "Fitzgerald Creek, QLD 4860",
    "Fitzgerald River National Park, WA 6346",
    "Fitzgeralds Mount, NSW 2799",
    "Fitzgeralds Valley, NSW 2795",
    "Fitzgibbon, QLD 4018",
    "Fitzroy, VIC 3065",
    "Fitzroy, SA 5082",
    "Fitzroy Crossing, WA 6765",
    "Fitzroy Falls, NSW 2577",
    "Fitzroy Island, QLD 4871",
    "Fitzroy North, VIC 3068",
    "Five Dock, NSW 2046",
    "Five Miles, SA 5238",
    "Five Ways, NSW 2825",
    "Flaggy Creek, VIC 3875",
    "Flagstaff, VIC 3465",
    "Flagstaff Hill, SA 5159",
    "Flagstone Creek, QLD 4344",
    "Flametree, QLD 4802",
    "Flamingo Beach, VIC 3851",
    "Flat Tops, NSW 2420",
    "Flaxley, SA 5153",
    "Flaxman Valley, SA 5353",
    "Flaxman Valley, SA 5235",
    "Flaxton, QLD 4560",
    "Fleming, NT 0822",
    "Flemington, VIC 3031",
    "Fletcher, NSW 2287",
    "Fletcher, QLD 4381",
    "Fletcher Creek, QLD 4714",
    "Fleurbaix, QLD 4375",
    "Flinders, NSW 2529",
    "Flinders, VIC 3929",
    "Flinders Chase, SA 5223",
    "Flinders Lane, VIC 8009",
    "Flinders Park, SA 5025",
    "Flinders University, SA 5042",
    "Flinders View, QLD 4305",
    "Flint, WA 6302",
    "Flinton, QLD 4422",
    "Flintstone, TAS 7030",
    "Flora Hill, VIC 3550",
    "Floraville, NSW 2280",
    "Floreat, WA 6014",
    "Florence Bay, QLD 4819",
    "Florentine, TAS 7140",
    "Florey, ACT 2615",
    "Florina, NT 0852",
    "Flowerdale, VIC 3717",
    "Flowerdale, TAS 7325",
    "Flowerpot, TAS 7163",
    "Flowery Gully, TAS 7270",
    "Fly Creek, NT 0822",
    "Flying Fish Point, QLD 4860",
    "Flying Fox, QLD 4275",
    "Flying Fox, NT 0852",
    "Flynn, NT 0870",
    "Flynn, VIC 3844",
    "Flynn, WA 6302",
    "Flynn, ACT 2615",
    "Flynns Beach, NSW 2444",
    "Flynns Creek, VIC 3844",
    "Footscray, VIC 3011",
    "Forbes, NSW 2871",
    "Forbes, VIC 3764",
    "Forbes Creek, NSW 2621",
    "Forbes River, NSW 2446",
    "Forbesdale, NSW 2422",
    "Forcett, TAS 7173",
    "Forde, ACT 2914",
    "Fords, SA 5373",
    "Fords Bridge, NSW 2840",
    "Fordsdale, QLD 4343",
    "Fordwich, NSW 2330",
    "Foreshores, QLD 4678",
    "Forest, TAS 7330",
    "Forest Creek, QLD 4873",
    "Forest Glen, NSW 2157",
    "Forest Glen, QLD 4556",
    "Forest Grove, WA 6286",
    "Forest Grove, NSW 2795",
    "Forest Hill, WA 6324",
    "Forest Hill, VIC 3131",
    "Forest Hill, NSW 2651",
    "Forest Hill, QLD 4342",
    "Forest Lake, QLD 4078",
    "Forest Land, NSW 2372",
    "Forest Lodge, NSW 2037",
    "Forest Range, SA 5139",
    "Forest Reefs, NSW 2798",
    "Forest Ridge, QLD 4357",
    "Forestdale, QLD 4118",
    "Forester, TAS 7260",
    "Foresthome, QLD 4850",
    "Forestvale, QLD 4465",
    "Forestville, SA 5035",
    "Forestville, NSW 2087",
    "Forge Creek, VIC 3875",
    "Formartin, QLD 4404",
    "Forrest, VIC 3236",
    "Forrest, WA 6434",
    "Forrest, ACT 2603",
    "Forrest Beach, QLD 4850",
    "Forrest Beach, WA 6271",
    "Forrestania, WA 6359",
    "Forrestdale, WA 6112",
    "Forresters Beach, NSW 2260",
    "Forrestfield, WA 6058",
    "Forreston, SA 5233",
    "Forsayth, QLD 4871",
    "Forster, SA 5238",
    "Forster, NSW 2428",
    "Forster Shopping Village, NSW 2428",
    "Fortescue, TAS 7182",
    "Fortescue, WA 6716",
    "Forth, TAS 7310",
    "Forthside, TAS 7310",
    "Fortis Creek, NSW 2460",
    "Fortitude Valley, QLD 4006",
    "Fortitude Valley Bc, QLD 4006",
    "Forty Mile, QLD 4872",
    "Fossilbrook, QLD 4871",
    "Foster, VIC 3960",
    "Foster North, VIC 3960",
    "Fosters Valley, NSW 2795",
    "Fosterton, NSW 2420",
    "Fosterville, VIC 3557",
    "Foul Bay, SA 5577",
    "Foulden, QLD 4740",
    "Fountain, SA 5607",
    "Fountain Gate, VIC 3805",
    "Fountaindale, NSW 2258",
    "Four Corners, NSW 2716",
    "Four Mile Creek, TAS 7215",
    "Four Mile Creek, NSW 2323",
    "Four Mile Creek, NSW 2800",
    "Four Ways, QLD 4824",
    "Fowlers Bay, SA 5690",
    "Fowlers Gap, NSW 2880",
    "Fox, SA 5272",
    "Foxdale, QLD 4800",
    "Foxground, NSW 2534",
    "Foxhow, VIC 3323",
    "Frahns, SA 5238",
    "Framlingham, VIC 3265",
    "Framlingham East, VIC 3265",
    "Frances, SA 5262",
    "Francois Peron National Park, WA 6537",
    "Frankfield, QLD 4721",
    "Frankford, TAS 7275",
    "Frankland River, WA 6396",
    "Franklin, TAS 7113",
    "Franklin, ACT 2913",
    "Franklinford, VIC 3461",
    "Franklyn, SA 5421",
    "Frankston, VIC 3199",
    "Frankston East, VIC 3199",
    "Frankston Heights, VIC 3199",
    "Frankston North, VIC 3200",
    "Frankston South, VIC 3199",
    "Frankton, SA 5374",
    "Fraser, ACT 2615",
    "Fraser Island, QLD 4581",
    "Fraser Range, WA 6443",
    "Frayville, SA 5238",
    "Frazer Park, NSW 2259",
    "Frazers Creek, NSW 2446",
    "Frazerview, QLD 4309",
    "Fredericksfield, QLD 4806",
    "Frederickton, NSW 2440",
    "Freds Pass, NT 0822",
    "Freeburgh, VIC 3741",
    "Freeburn Island, NSW 2464",
    "Freeling, SA 5372",
    "Freemans, NSW 2259",
    "Freemans Reach, NSW 2756",
    "Freemans Waterhole, NSW 2323",
    "Freemantle, NSW 2795",
    "Freestone, QLD 4370",
    "Fregon, SA 0872",
    "Fremantle, WA 6160",
    "Fremantle, WA 6959",
    "French Island, VIC 3921",
    "French Park, NSW 2655",
    "Frenches Creek, QLD 4310",
    "Frenchman Bay, WA 6330",
    "Frenchmans, VIC 3384",
    "Frenchs Forest, NSW 2086",
    "Frenchs Forest, NSW 1639",
    "Frenchs Forest, NSW 1640",
    "Frenchs Forest East, NSW 2086",
    "Frenchville, QLD 4701",
    "Freshwater, NSW 2096",
    "Freshwater, QLD 4870",
    "Freshwater Creek, VIC 3216",
    "Freshwater Point, QLD 4737",
    "Frewville, SA 5063",
    "Freycinet, TAS 7215",
    "Friday Pocket, QLD 4855",
    "Friendly Beaches, TAS 7215",
    "Frog Rock, NSW 2850",
    "Frogmore, NSW 2586",
    "Frogs Hollow, NSW 2550",
    "Fryerstown, VIC 3451",
    "Frying Pan, NSW 2630",
    "Fulham, SA 5024",
    "Fulham, QLD 4313",
    "Fulham, VIC 3851",
    "Fulham Gardens, SA 5024",
    "Fullarton, SA 5063",
    "Fullerton, NSW 2583",
    "Fullerton Cove, NSW 2318",
    "Fumina, VIC 3825",
    "Fumina South, VIC 3825",
    "Furner, SA 5280",
    "Furnissdale, WA 6209",
    "Furracabad, NSW 2370",
    "Fyans Creek, VIC 3381",
    "Fyansford, VIC 3221",
    "Fyshwick, ACT 2609",
    "Gabalong, WA 6574",
    "Gabbadah, WA 6041",
    "Gabbadah, WA 6042",
    "Gabbin, WA 6476",
    "Gadara, NSW 2720",
    "Gadgarra, QLD 4884",
    "Gaeta, QLD 4671",
    "Gaffneys Creek, VIC 3723",
    "Gagebrook, TAS 7030",
    "Gailes, QLD 4300",
    "Gainsborough, VIC 3822",
    "Gainsford, QLD 4702",
    "Gairdner, WA 6337",
    "Gairloch, QLD 4850",
    "Gala Vale, NSW 2716",
    "Galambine, NSW 2850",
    "Galga, SA 5308",
    "Galiwinku, NT 0822",
    "Galloways Hill, QLD 4171",
    "Galong, NSW 2585",
    "Galore, NSW 2650",
    "Galston, NSW 2159",
    "Gamboola, QLD 4871",
    "Ganbenang, NSW 2790",
    "Gangat, NSW 2422",
    "Ganmain, NSW 2702",
    "Gannawarra, VIC 3568",
    "Gap Ridge, WA 6714",
    "Gapsted, VIC 3737",
    "Gapuwiyak, NT 0880",
    "Garah, NSW 2405",
    "Garbutt, QLD 4814",
    "Garbutt Bc, QLD 4814",
    "Garbutt East, QLD 4814",
    "Garden City, VIC 3207",
    "Garden Island, WA 6168",
    "Garden Island, SA 5960",
    "Garden Island Creek, TAS 7112",
    "Garden Suburb, NSW 2289",
    "Gardenvale, VIC 3185",
    "Gardners Bay, TAS 7112",
    "Garema, NSW 2871",
    "Garfield, VIC 3814",
    "Garfield, QLD 4728",
    "Garfield North, VIC 3814",
    "Gargett, QLD 4741",
    "Garibaldi, VIC 3352",
    "Garie, NSW 2232",
    "Garland, NSW 2797",
    "Garland Valley, NSW 2330",
    "Garnant, QLD 4702",
    "Garners Beach, QLD 4852",
    "Garoo, NSW 2340",
    "Garra, NSW 2866",
    "Garradunga, QLD 4860",
    "Garran, ACT 2605",
    "Garrawalt, QLD 4850",
    "Garthowen, NSW 2345",
    "Garvoc, VIC 3265",
    "Gascoyne Junction, WA 6705",
    "Gascoyne River, WA 6705",
    "Gateshead, NSW 2290",
    "Gateway Island, VIC 3691",
    "Gatton, QLD 4343",
    "Gatton College, QLD 4345",
    "Gatum, VIC 3407",
    "Gaven, QLD 4211",
    "Gawler, SA 5118",
    "Gawler, TAS 7315",
    "Gawler Belt, SA 5118",
    "Gawler East, SA 5118",
    "Gawler River, SA 5118",
    "Gawler South, SA 5118",
    "Gawler West, SA 5118",
    "Gayndah, QLD 4625",
    "Gaythorne, QLD 4051",
    "Gazette, VIC 3289",
    "Gearys Flat, NSW 2446",
    "Geebung, QLD 4034",
    "Geegully Creek, WA 6728",
    "Geehi, NSW 2642",
    "Geelong, VIC 3220",
    "Geelong North, VIC 3215",
    "Geelong West, VIC 3218",
    "Geeveston, TAS 7116",
    "Geham, QLD 4352",
    "Geilston Bay, TAS 7015",
    "Gelantipy, VIC 3885",
    "Gellibrand, VIC 3239",
    "Gellibrand Lower, VIC 3237",
    "Gelliondale, VIC 3971",
    "Gelorup, WA 6230",
    "Gelston Park, NSW 2650",
    "Gemalla, NSW 2795",
    "Gembrook, VIC 3783",
    "Gemini Mountains, QLD 4721",
    "Gemmells, SA 5255",
    "Geneva, NSW 2474",
    "Genoa, VIC 3891",
    "Gentle Annie, VIC 3833",
    "Geographe, WA 6280",
    "George Street, QLD 4003",
    "George Town, TAS 7253",
    "Georges Creek, NSW 2365",
    "Georges Creek, VIC 3700",
    "Georges Hall, NSW 2198",
    "Georges Plains, NSW 2795",
    "Georgetown, NSW 2298",
    "Georgetown, QLD 4871",
    "Georgetown, SA 5472",
    "Georgica, NSW 2480",
    "Georgina, WA 6532",
    "Georgina, QLD 4825",
    "Gepps Cross, SA 5094",
    "Gerahmin, VIC 3546",
    "Geraldton, WA 6530",
    "Geraldton Po, WA 6531",
    "Gerang Gerung, VIC 3418",
    "Gerangamete, VIC 3249",
    "Geranium, SA 5301",
    "Geranium Plains, SA 5381",
    "Gerard, SA 5343",
    "German Creek, SA 5280",
    "German Flat, SA 5280",
    "Germania, VIC 3387",
    "Germantown, VIC 3741",
    "Germantown, QLD 4871",
    "Germein Bay, SA 5495",
    "Gerogery, NSW 2642",
    "Gerrigerrup, VIC 3289",
    "Gerringong, NSW 2534",
    "Gerroa, NSW 2534",
    "Geurie, NSW 2818",
    "Ghan, NT 0872",
    "Gheerulla, QLD 4574",
    "Gherang, VIC 3240",
    "Gheringhap, VIC 3331",
    "Ghin Ghin, VIC 3717",
    "Ghinghinda, QLD 4420",
    "Ghinni Ghi, NSW 2474",
    "Ghinni Ghinni, NSW 2430",
    "Ghoolendaadi, NSW 2380",
    "Ghooli, WA 6426",
    "Giants Creek, NSW 2328",
    "Gibb, WA 6743",
    "Gibberagee, NSW 2469",
    "Gibraltar Range, NSW 2370",
    "Gibson, WA 6448",
    "Gibson Desert North, WA 0872",
    "Gibson Desert South, WA 0872",
    "Gidgegannup, WA 6083",
    "Gidginbung, NSW 2666",
    "Gidley, NSW 2340",
    "Gidya, QLD 4824",
    "Giffard, VIC 3851",
    "Giffard West, VIC 3851",
    "Gifford Hill, SA 5253",
    "Gigoomgan, QLD 4620",
    "Gil Gil, VIC 3480",
    "Gilbert River, QLD 4871",
    "Gilberton, VIC 3072",
    "Gilberton, QLD 4208",
    "Gilberton, SA 5081",
    "Gilderoy, VIC 3797",
    "Gilead, NSW 2560",
    "Giles Corner, SA 5411",
    "Gilgai, NSW 2360",
    "Gilgandra, NSW 2827",
    "Gilgering, WA 6302",
    "Gilgooma, NSW 2829",
    "Gilgunnia, NSW 2835",
    "Gilldora, QLD 4570",
    "Gillen, NT 0870",
    "Gillenbah, NSW 2700",
    "Gillentown, SA 5453",
    "Gilles Plains, SA 5086",
    "Gilletts Ridge, NSW 2462",
    "Gillieston, VIC 3616",
    "Gillieston Heights, NSW 2321",
    "Gillimanning, WA 6308",
    "Gillingarra, WA 6510",
    "Gillman, SA 5013",
    "Gillum, VIC 3858",
    "Gilmandyke, NSW 2795",
    "Gilmore, NSW 2720",
    "Gilmore, ACT 2905",
    "Gilroyd, WA 6701",
    "Gilston, QLD 4211",
    "Gin Gin, NSW 2823",
    "Gin Gin, QLD 4671",
    "Gindie, QLD 4702",
    "Gindoran, QLD 4676",
    "Gineroi, NSW 2404",
    "Gingerah, WA 6725",
    "Ginghi, NSW 2849",
    "Gingin, WA 6503",
    "Ginginup, WA 6503",
    "Gingkin, NSW 2787",
    "Ginninderra Village, ACT 2913",
    "Ginoondan, QLD 4625",
    "Gipsy Point, VIC 3891",
    "Giralang, ACT 2617",
    "Girards Hill, NSW 2480",
    "Girgarre, VIC 3624",
    "Girgarre East, VIC 3616",
    "Girilambone, NSW 2831",
    "Giro, NSW 2422",
    "Girral, NSW 2669",
    "Girralong, NSW 2449",
    "Girraween, NSW 2145",
    "Girraween, NT 0836",
    "Girraween, NT 0836",
    "Girraween, QLD 4382",
    "Girrawheen, WA 6064",
    "Giru, QLD 4809",
    "Girvan, NSW 2425",
    "Gisborne, VIC 3437",
    "Gisborne South, VIC 3437",
    "Givelda, QLD 4670",
    "Gladesville, NSW 1675",
    "Gladesville, NSW 2111",
    "Gladfield, VIC 3575",
    "Gladfield, QLD 4370",
    "Gladstone, QLD 4680",
    "Gladstone, NSW 2440",
    "Gladstone, TAS 7264",
    "Gladstone, SA 5473",
    "Gladstone Bc, QLD 4680",
    "Gladstone City, QLD 4680",
    "Gladstone Harbour, QLD 4680",
    "Gladstone Park, VIC 3043",
    "Gladstone South, QLD 4680",
    "Gladstone-city, QLD 4680",
    "Gladysdale, VIC 3797",
    "Glamorgan Vale, QLD 4306",
    "Glan Devon, QLD 4615",
    "Glandore, SA 5037",
    "Glanmire, NSW 2795",
    "Glanmire, QLD 4570",
    "Glanville, SA 5015",
    "Glass House Mountains, QLD 4518",
    "Glastonbury, QLD 4570",
    "Glaziers Bay, TAS 7109",
    "Glebe, TAS 7000",
    "Glebe, NSW 2037",
    "Gledhow, WA 6330",
    "Gleeson Hill, SA 5520",
    "Glen Alice, NSW 2849",
    "Glen Allen, NSW 2631",
    "Glen Allyn, QLD 4885",
    "Glen Alpine, NSW 2560",
    "Glen Alvie, VIC 3979",
    "Glen Aplin, QLD 4381",
    "Glen Ayr, NSW 2850",
    "Glen Boughton, QLD 4871",
    "Glen Cairn, QLD 4342",
    "Glen Creek, VIC 3691",
    "Glen Davis, NSW 2846",
    "Glen Echo, QLD 4570",
    "Glen Eden, QLD 4680",
    "Glen Elgin, NSW 2370",
    "Glen Esk, QLD 4312",
    "Glen Fergus, NSW 2630",
    "Glen Forbes, VIC 3990",
    "Glen Forrest, WA 6071",
    "Glen Huntly, VIC 3163",
    "Glen Huon, TAS 7109",
    "Glen Innes, NSW 2370",
    "Glen Iris, VIC 3146",
    "Glen Iris, WA 6230",
    "Glen Isla, QLD 4800",
    "Glen Martin, NSW 2321",
    "Glen Mervyn, WA 6239",
    "Glen Nevis, NSW 2365",
    "Glen Niven, QLD 4377",
    "Glen Oak, NSW 2320",
    "Glen Osmond, SA 5064",
    "Glen Park, VIC 3352",
    "Glen Russell, QLD 4880",
    "Glen Ruth, QLD 4872",
    "Glen Valley, VIC 3898",
    "Glen Ward, NSW 2422",
    "Glen Waverley, VIC 3150",
    "Glen William, NSW 2321",
    "Glen Wills, VIC 3898",
    "Glenaire, VIC 3238",
    "Glenaladale, VIC 3864",
    "Glenalbyn, VIC 3517",
    "Glenalta, SA 5052",
    "Glenarbon, QLD 4385",
    "Glenaroua, VIC 3764",
    "Glenaubyn, QLD 4424",
    "Glenaven, QLD 4355",
    "Glenbar, QLD 4620",
    "Glenbawn, NSW 2337",
    "Glenbrae, VIC 3352",
    "Glenbrook, NSW 2773",
    "Glenburn, VIC 3717",
    "Glenburnie, SA 5291",
    "Glencoe, NSW 2365",
    "Glencoe, SA 5291",
    "Glencoe, QLD 4352",
    "Glencoe, WA 6316",
    "Glencoe West, SA 5291",
    "Glendale, QLD 4711",
    "Glendale, NSW 2285",
    "Glendalough, WA 6016",
    "Glendambo, SA 5710",
    "Glendaruel, VIC 3363",
    "Glenden, QLD 4743",
    "Glendenning, NSW 2761",
    "Glendevie, TAS 7109",
    "Glendon, NSW 2330",
    "Glendon Brook, NSW 2330",
    "Glendonald, VIC 3364",
    "Gleneagle, QLD 4285",
    "Glenelg, NSW 2810",
    "Glenelg, SA 5045",
    "Glenelg East, SA 5045",
    "Glenelg Jetty Road, SA 5045",
    "Glenelg North, SA 5045",
    "Glenelg South, SA 5045",
    "Glenella, QLD 4740",
    "Glenellen, NSW 2642",
    "Glenfalloch, VIC 3858",
    "Glenfern, QLD 4515",
    "Glenfern, TAS 7140",
    "Glenferrie South, VIC 3122",
    "Glenfield, NSW 2167",
    "Glenfield, WA 6532",
    "Glenfield Park, NSW 2650",
    "Glenfyne, VIC 3266",
    "Glengala, VIC 3020",
    "Glengallan, QLD 4370",
    "Glengarrie, NSW 2486",
    "Glengarry, TAS 7275",
    "Glengarry, VIC 3854",
    "Glengarry North, VIC 3854",
    "Glengarry West, VIC 3854",
    "Glengerra, NSW 2830",
    "Glengower, VIC 3370",
    "Glengowrie, SA 5044",
    "Glenhaughton, QLD 4420",
    "Glenhaven, NSW 2156",
    "Glenhope, VIC 3444",
    "Glenhope East, VIC 3522",
    "Gleniffer, NSW 2454",
    "Glenisla, VIC 3314",
    "Glenlee, VIC 3418",
    "Glenlee, QLD 4711",
    "Glenleigh, QLD 4630",
    "Glenlofty, VIC 3469",
    "Glenlogie, VIC 3469",
    "Glenloth, VIC 3527",
    "Glenloth East, VIC 3527",
    "Glenluce, VIC 3451",
    "Glenlusk, TAS 7012",
    "Glenlynn, WA 6256",
    "Glenlyon, VIC 3461",
    "Glenlyon, QLD 4380",
    "Glenmaggie, VIC 3858",
    "Glenmoral, QLD 4719",
    "Glenmore, VIC 3340",
    "Glenmore, NSW 2570",
    "Glenmore Park, NSW 2745",
    "Glenmorgan, QLD 4423",
    "Glennies Creek, NSW 2330",
    "Glenning Valley, NSW 2261",
    "Glenora, TAS 7140",
    "Glenoran, WA 6258",
    "Glenorchy, QLD 4650",
    "Glenorchy, TAS 7010",
    "Glenorchy, VIC 3385",
    "Glenore Grove, QLD 4342",
    "Glenorie, NSW 2157",
    "Glenormiston North, VIC 3265",
    "Glenormiston South, VIC 3265",
    "Glenpatrick, VIC 3469",
    "Glenquarie, NSW 2564",
    "Glenquarry, NSW 2576",
    "Glenrae, QLD 4626",
    "Glenreagh, NSW 2450",
    "Glenridding, NSW 2330",
    "Glenrock, QLD 4605",
    "Glenrock, NSW 2337",
    "Glenrowan, VIC 3675",
    "Glenrowan West, VIC 3675",
    "Glenroy, SA 5277",
    "Glenroy, VIC 3046",
    "Glenroy, NSW 2653",
    "Glenroy, QLD 4702",
    "Glenroy, NSW 2640",
    "Glenside, SA 5065",
    "Glenthompson, VIC 3293",
    "Glenthorne, NSW 2430",
    "Glentromie, WA 6509",
    "Glenugie, NSW 2460",
    "Glenunga, SA 5064",
    "Glenvale, QLD 4350",
    "Glenview, QLD 4553",
    "Glenwood, QLD 4570",
    "Glenwood, NSW 2768",
    "Glenworth Valley, NSW 2250",
    "Globe Derby Park, SA 5110",
    "Glomar Beach, VIC 3851",
    "Glossodia, NSW 2756",
    "Glossop, SA 5344",
    "Gloucester, NSW 2422",
    "Gloucester Tops, NSW 2422",
    "Glyde Point, NT 0822",
    "Glynde, SA 5070",
    "Glynde Plaza, SA 5070",
    "Gnangara, WA 6077",
    "Gnarabup, WA 6285",
    "Gnarwarre, VIC 3221",
    "Gnoorea, WA 6714",
    "Gnotuk, VIC 3260",
    "Gnowangerup, WA 6335",
    "Gnowellen, WA 6328",
    "Goat Island, NSW 2477",
    "Gobarralong, NSW 2727",
    "Gobarup, VIC 3559",
    "Gobbagombalin, NSW 2650",
    "Gobur, VIC 3719",
    "Gocup, NSW 2720",
    "Godfreys Creek, NSW 2586",
    "Godwin Beach, QLD 4511",
    "Gogango, QLD 4702",
    "Gogeldrie, NSW 2705",
    "Gol Gol, NSW 2738",
    "Golconda, TAS 7254",
    "Gold Coast, QLD 4217",
    "Golden Bay, WA 6174",
    "Golden Beach, QLD 4551",
    "Golden Beach, VIC 3851",
    "Golden Fleece, QLD 4621",
    "Golden Grove, SA 5125",
    "Golden Grove Village, SA 5125",
    "Golden Gully, VIC 3555",
    "Golden Heights, SA 5322",
    "Golden Point, VIC 3451",
    "Golden Point, VIC 3465",
    "Golden Point, VIC 3350",
    "Golden Square, VIC 3555",
    "Golden Valley, TAS 7304",
    "Goldie, VIC 3435",
    "Goldsborough, QLD 4865",
    "Goldsborough, VIC 3472",
    "Gollan, NSW 2820",
    "Golspie, NSW 2580",
    "Gomersal, SA 5352",
    "Gong Gong, VIC 3352",
    "Gongolgon, NSW 2839",
    "Gonn, NSW 2732",
    "Gonn Crossing, VIC 3579",
    "Goobarragandra, NSW 2720",
    "Gooburrum, QLD 4670",
    "Good Hope, NSW 2582",
    "Good Hope Landing, SA 5330",
    "Good Night, QLD 4671",
    "Goodar, QLD 4390",
    "Goode Beach, WA 6330",
    "Goodger, QLD 4610",
    "Goodlands, WA 6468",
    "Goodmans Ford, NSW 2575",
    "Goodna, QLD 4300",
    "Goodnight, NSW 2736",
    "Goodooga, NSW 2831",
    "Goodwood, TAS 7010",
    "Goodwood, SA 5034",
    "Goodwood, QLD 4660",
    "Goodwood Island, NSW 2469",
    "Googa Creek, QLD 4306",
    "Googong, NSW 2620",
    "Goolboo, QLD 4856",
    "Goolgowi, NSW 2652",
    "Goolhi, NSW 2379",
    "Goolma, NSW 2852",
    "Goolman, QLD 4306",
    "Goolmangar, NSW 2480",
    "Gooloogong, NSW 2805",
    "Goolwa, SA 5214",
    "Goolwa Beach, SA 5214",
    "Goolwa North, SA 5214",
    "Goolwa South, SA 5214",
    "Goomalibee, VIC 3673",
    "Goomalling, WA 6460",
    "Goomally, QLD 4702",
    "Goomarin, WA 6415",
    "Goombargana, NSW 2646",
    "Goomboorian, QLD 4570",
    "Goombungee, QLD 4354",
    "Goomburra, QLD 4362",
    "Goomeri, QLD 4601",
    "Goomeribong, QLD 4601",
    "Goon Nure, VIC 3875",
    "Goondi, QLD 4860",
    "Goondi Bend, QLD 4860",
    "Goondi Hill, QLD 4860",
    "Goondiwindi, QLD 4390",
    "Goonellabah, NSW 2480",
    "Goonengerry, NSW 2482",
    "Goongerah, VIC 3888",
    "Goonoo Forest, NSW 2830",
    "Goonoo Goonoo, NSW 2340",
    "Goonumbla, NSW 2870",
    "Gooram, VIC 3666",
    "Gooramadda, VIC 3685",
    "Goorambat, VIC 3725",
    "Goorangoola, NSW 2330",
    "Goorganga Creek, QLD 4800",
    "Goorganga Plains, QLD 4800",
    "Goorianawa, NSW 2396",
    "Goornong, VIC 3557",
    "Gooroc, VIC 3477",
    "Gooroolba, QLD 4625",
    "Gooseberry Hill, WA 6076",
    "Gootchie, QLD 4650",
    "Goovigen, QLD 4702",
    "Goowarra, QLD 4702",
    "Gorae, VIC 3305",
    "Gorae West, VIC 3305",
    "Goranba, QLD 4421",
    "Gordon, TAS 7150",
    "Gordon, NSW 2072",
    "Gordon, VIC 3345",
    "Gordon, ACT 2906",
    "Gordon Park, QLD 4031",
    "Gordonbrook, QLD 4610",
    "Gordonvale, QLD 4865",
    "Gore, QLD 4352",
    "Gorge Creek, NSW 2469",
    "Gorge Rock, WA 6375",
    "Gormandale, VIC 3873",
    "Gormans Hill, NSW 2795",
    "Gormanston, TAS 7466",
    "Gorokan, NSW 2263",
    "Goroke, VIC 3412",
    "Gorrie, WA 6556",
    "Goschen, VIC 3585",
    "Gosford, NSW 2250",
    "Gosford West, NSW 2250",
    "Gosforth, NSW 2320",
    "Goshen, TAS 7216",
    "Gosnells, WA 6990",
    "Gosnells, WA 6110",
    "Gosse, SA 5223",
    "Gostwyck, NSW 2358",
    "Goughs Bay, VIC 3723",
    "Goulburn, NSW 2580",
    "Goulburn Island, NT 0822",
    "Goulburn North, NSW 2580",
    "Goulburn Weir, VIC 3608",
    "Gould Creek, SA 5114",
    "Goulds Country, TAS 7216",
    "Gouldsville, NSW 2330",
    "Gove, NT 0880",
    "Gowan, NSW 2795",
    "Gowanbrae, VIC 3043",
    "Gowanford, VIC 3544",
    "Gowang, NSW 2357",
    "Gowangardie, VIC 3669",
    "Gowar East, VIC 3477",
    "Gower, VIC 3451",
    "Gowrie, NSW 2330",
    "Gowrie, NSW 2340",
    "Gowrie, ACT 2904",
    "Gowrie Junction, QLD 4352",
    "Gowrie Little Plain, QLD 4352",
    "Gowrie Mountain, QLD 4350",
    "Gowrie Park, TAS 7306",
    "Gowrie Station, QLD 4470",
    "Goyder, SA 5461",
    "Grabben Gullen, NSW 2583",
    "Grabine, NSW 2583",
    "Grace Plains, SA 5502",
    "Gracemere, QLD 4702",
    "Gracetown, WA 6284",
    "Graceville, QLD 4075",
    "Graceville East, QLD 4075",
    "Gradys Creek, NSW 2474",
    "Grafton, NSW 2460",
    "Grafton West, NSW 2460",
    "Gragin, NSW 2403",
    "Grahams Creek, QLD 4650",
    "Grahamstown, NSW 2729",
    "Grahamvale, VIC 3631",
    "Graman, NSW 2360",
    "Grampians, VIC 3314",
    "Granadilla, QLD 4855",
    "Grand Ridge, VIC 3870",
    "Grand Secret, QLD 4820",
    "Grandchester, QLD 4340",
    "Grange, SA 5022",
    "Grange, QLD 4051",
    "Granite Flat, VIC 3525",
    "Granite Rock, VIC 3875",
    "Granite Vale, QLD 4815",
    "Grantham, QLD 4347",
    "Granton, TAS 7030",
    "Grants Beach, NSW 2445",
    "Grantville, VIC 3984",
    "Granville, NSW 2142",
    "Granville, QLD 4650",
    "Granville, NSW 1830",
    "Granville, NSW 1831",
    "Granville, WA 6503",
    "Granville Harbour, TAS 7469",
    "Granya, VIC 3701",
    "Grapetree, QLD 4352",
    "Grasmere, NSW 2570",
    "Grass Flat, VIC 3409",
    "Grass Patch, WA 6446",
    "Grass Valley, WA 6403",
    "Grassdale, QLD 4405",
    "Grassdale, VIC 3302",
    "Grassmere, VIC 3281",
    "Grasstree Beach, QLD 4740",
    "Grasstree Hill, TAS 7017",
    "Grassy, TAS 7256",
    "Grassy Head, NSW 2441",
    "Grattai, NSW 2850",
    "Gravelly Beach, TAS 7276",
    "Gravesend, NSW 2401",
    "Grawlin, NSW 2871",
    "Gray, TAS 7215",
    "Gray, NT 0830",
    "Gray, NT 0830",
    "Grays Bridge, VIC 3477",
    "Grays Gate, QLD 4357",
    "Grays Point, NSW 2232",
    "Graytown, VIC 3608",
    "Gre Gre, VIC 3477",
    "Gre Gre North, VIC 3477",
    "Gre Gre South, VIC 3477",
    "Great Bay, TAS 7150",
    "Great Keppel Island, QLD 4700",
    "Great Mackerel Beach, NSW 2108",
    "Great Marlow, NSW 2460",
    "Great Sandy Strait, QLD 4655",
    "Great Southern, VIC 3685",
    "Great Western, VIC 3374",
    "Gredgwin, VIC 3537",
    "Green Cape, NSW 2551",
    "Green Creek, NSW 2338",
    "Green Fields, SA 5107",
    "Green Forest, NSW 2471",
    "Green Gully, NSW 2850",
    "Green Gully, VIC 3462",
    "Green Head, WA 6514",
    "Green Hill, QLD 4865",
    "Green Hills, NSW 2365",
    "Green Hills, NSW 2730",
    "Green Hills, NSW 2323",
    "Green Hills Range, SA 5153",
    "Green Island, QLD 4871",
    "Green Patch, SA 5607",
    "Green Pigeon, NSW 2474",
    "Green Point, NSW 2251",
    "Green Point, NSW 2428",
    "Green Range, WA 6328",
    "Green Valley, WA 6330",
    "Green Valley, NSW 2168",
    "Greenacre, NSW 2190",
    "Greenacres, SA 5086",
    "Greenbank, QLD 4124",
    "Greenbanks, SA 5253",
    "Greenbushes, WA 6254",
    "Greendale, NSW 2550",
    "Greendale, NSW 2745",
    "Greendale, VIC 3341",
    "Greenethorpe, NSW 2809",
    "Greenfield Park, NSW 2176",
    "Greenfields, WA 6210",
    "Greengrove, NSW 2250",
    "Greenhill, VIC 3444",
    "Greenhill, SA 5140",
    "Greenhill, NSW 2440",
    "Greenhills, WA 6302",
    "Greenlake, QLD 4701",
    "Greenlands, NSW 2330",
    "Greenlands, NSW 2631",
    "Greenlands, QLD 4380",
    "Greenleigh, NSW 2620",
    "Greenmount, QLD 4359",
    "Greenmount, WA 6056",
    "Greenmount, QLD 4751",
    "Greenock, SA 5360",
    "Greenough, WA 6532",
    "Greenridge, NSW 2471",
    "Greens Beach, TAS 7270",
    "Greens Creek, VIC 3387",
    "Greens Creek, QLD 4570",
    "Greensborough, VIC 3088",
    "Greenslopes, QLD 4120",
    "Greenup, QLD 4387",
    "Greenvale, QLD 4816",
    "Greenvale, VIC 3059",
    "Greenview, QLD 4606",
    "Greenwald, VIC 3304",
    "Greenway, ACT 2900",
    "Greenways, SA 5272",
    "Greenways Landing, SA 5354",
    "Greenwell Point, NSW 2540",
    "Greenwich, NSW 2065",
    "Greenwich Park, NSW 2580",
    "Greenwith, SA 5125",
    "Greenwood, WA 6024",
    "Greenwood, WA 6924",
    "Greenwood, QLD 4401",
    "Greenwoods Valley, WA 6405",
    "Greg Greg, NSW 2642",
    "Gregadoo, NSW 2650",
    "Gregors Creek, QLD 4313",
    "Gregory, QLD 4830",
    "Gregory, WA 6535",
    "Gregory, NT 0852",
    "Gregory Downs, QLD 4830",
    "Gregory Hills, NSW 2557",
    "Gregory River, QLD 4660",
    "Gregory River, QLD 4800",
    "Greigs Flat, NSW 2549",
    "Grenfell, NSW 2810",
    "Grenville, VIC 3352",
    "Gresford, NSW 2311",
    "Greta, NSW 2334",
    "Greta, VIC 3675",
    "Greta Main, NSW 2325",
    "Greta South, VIC 3675",
    "Greta West, VIC 3675",
    "Gretna, TAS 7140",
    "Grevillia, NSW 2474",
    "Grey, WA 6521",
    "Grey River, VIC 3221",
    "Greycliffe, QLD 4715",
    "Greymare, QLD 4370",
    "Greys Plain, WA 6701",
    "Greystanes, NSW 2145",
    "Greythorn, VIC 3104",
    "Griffin, QLD 4503",
    "Griffith, ACT 2603",
    "Griffith, NSW 2680",
    "Griffith East, NSW 2680",
    "Griffith University, QLD 4222",
    "Grimwade, WA 6253",
    "Grindelwald, TAS 7277",
    "Gringegalgona, VIC 3315",
    "Gritjurk, VIC 3315",
    "Grogan, NSW 2666",
    "Groganville, QLD 4871",
    "Grong Grong, NSW 2652",
    "Groomsville, QLD 4352",
    "Grose Vale, NSW 2753",
    "Grose Wold, NSW 2753",
    "Grosmont, QLD 4419",
    "Grosses Plain, NSW 2627",
    "Grosvenor, QLD 4627",
    "Grosvenor Place, NSW 1216",
    "Grosvenor Place, NSW 1217",
    "Grosvenor Place, NSW 1218",
    "Grosvenor Place, NSW 1219",
    "Grosvenor Place, NSW 1220",
    "Grove, TAS 7109",
    "Grovedale, VIC 3216",
    "Grovedale East, VIC 3216",
    "Growee, NSW 2849",
    "Gruyere, VIC 3770",
    "Guanaba, QLD 4210",
    "Guerilla Bay, NSW 2536",
    "Guilderton, WA 6041",
    "Guildford, WA 6055",
    "Guildford, NSW 2161",
    "Guildford, VIC 3451",
    "Guildford, NSW 1848",
    "Guildford, TAS 7321",
    "Guildford, WA 6935",
    "Guildford West, NSW 2161",
    "Gulargambone, NSW 2828",
    "Gulf Creek, NSW 2347",
    "Gulfview Heights, SA 5096",
    "Gulgong, NSW 2852",
    "Gulliver, QLD 4812",
    "Gulmarrad, NSW 2463",
    "Gulnare, SA 5471",
    "Guluguba, QLD 4418",
    "Gulung Mardrulk, NT 0852",
    "Gum Creek, SA 5417",
    "Gum Flat, NSW 2360",
    "Gum Scrub, NSW 2441",
    "Gumbalie, NSW 2840",
    "Gumble, NSW 2865",
    "Gumdale, QLD 4154",
    "Gumeracha, SA 5233",
    "Gumlow, QLD 4815",
    "Gumlu, QLD 4805",
    "Gumly Gumly, NSW 2652",
    "Gumma, NSW 2447",
    "Gunalda, QLD 4570",
    "Gunbalanya, NT 0822",
    "Gunbar, NSW 2711",
    "Gunbower, VIC 3566",
    "Gundagai, NSW 2722",
    "Gundamulda, NSW 2347",
    "Gundaring, WA 6315",
    "Gundaroo, NSW 2620",
    "Gundary, NSW 2580",
    "Gunderbooka, NSW 2840",
    "Gunderman, NSW 2775",
    "Gundiah, QLD 4650",
    "Gundowring, VIC 3691",
    "Gundy, NSW 2337",
    "Gungahlin, ACT 2912",
    "Gungal, NSW 2333",
    "Gungalman, NSW 2829",
    "Gungaloon, QLD 4620",
    "Gunn, NT 0832",
    "Gunn, NT 0832",
    "Gunn Point, NT 0822",
    "Gunnawarra, QLD 4872",
    "Gunnedah, NSW 2380",
    "Gunnewin, QLD 4455",
    "Gunning, NSW 2581",
    "Gunning Gap, NSW 2871",
    "Gunningbland, NSW 2876",
    "Gunningrah, NSW 2632",
    "Gunns Plains, TAS 7315",
    "Gunpowder, QLD 4825",
    "Guntawang, NSW 2852",
    "Gununa, QLD 4871",
    "Gunyah, VIC 3960",
    "Gunyangara, NT 0880",
    "Gunyarra, QLD 4800",
    "Gunyidi, WA 6513",
    "Gurgeena, QLD 4626",
    "Gurindji, NT 0852",
    "Gurley, NSW 2398",
    "Gurnang, NSW 2787",
    "Gurra Gurra, SA 5343",
    "Gurranang, NSW 2460",
    "Gurrundah, NSW 2581",
    "Gurulmundi, QLD 4415",
    "Gutha, WA 6623",
    "Guthalungra, QLD 4805",
    "Guyong, NSW 2798",
    "Guyra, NSW 2365",
    "Guys Forest, VIC 3709",
    "Guys Hill, VIC 3807",
    "Gwabegar, NSW 2356",
    "Gwambegwine, QLD 4420",
    "Gwambygine, WA 6302",
    "Gwandalan, NSW 2259",
    "Gwelup, WA 6018",
    "Gwindinup, WA 6237",
    "Gwynneville, NSW 2500",
    "Gymbowen, VIC 3401",
    "Gymea, NSW 2227",
    "Gymea Bay, NSW 2227",
    "Gympie, QLD 4570",
    "Haasts Bluff, NT 0872",
    "Habana, QLD 4740",
    "Haberfield, NSW 2045",
    "Hackett, ACT 2602",
    "Hacketts Gully, WA 6076",
    "Hackham, SA 5163",
    "Hackham West, SA 5163",
    "Hacklins Corner, SA 5454",
    "Hackney, SA 5069",
    "Hacks Ferry, NSW 2441",
    "Haddon, VIC 3351",
    "Haden, QLD 4353",
    "Hadfield, VIC 3046",
    "Hadspen, TAS 7290",
    "Hagley, TAS 7292",
    "Hahndorf, SA 5245",
    "Haigslea, QLD 4306",
    "Hail Creek, QLD 4742",
    "Haines, SA 5223",
    "Halbury, SA 5461",
    "Hale, NT 0872",
    "Halekulani, NSW 2262",
    "Halfway Creek, NSW 2460",
    "Haliday Bay, QLD 4740",
    "Halidon, SA 5309",
    "Halifax, QLD 4850",
    "Halifax Street, SA 5000",
    "Hall, ACT 2618",
    "Hall, ACT 2618",
    "Hallam, VIC 3803",
    "Hallelujah Hills, SA 5381",
    "Hallett, SA 5419",
    "Hallett Cove, SA 5158",
    "Hallidays Point, NSW 2430",
    "Hallora, VIC 3818",
    "Halloran, NSW 2259",
    "Halls Creek, WA 6770",
    "Halls Creek, NSW 2346",
    "Halls Gap, VIC 3381",
    "Halls Head, WA 6210",
    "Hallston, VIC 3953",
    "Hallsville, NSW 2340",
    "Halton, NSW 2311",
    "Haly Creek, QLD 4610",
    "Hambidge, SA 5642",
    "Hambledon Hill, NSW 2330",
    "Hamel, WA 6215",
    "Hamelin Bay, WA 6288",
    "Hamelin Pool, WA 6532",
    "Hamersley, WA 6022",
    "Hamersley Range, WA 6716",
    "Hamilton, VIC 3300",
    "Hamilton, NSW 2303",
    "Hamilton, SA 5373",
    "Hamilton, QLD 4007",
    "Hamilton, TAS 7140",
    "Hamilton Central, QLD 4007",
    "Hamilton Creek, QLD 4714",
    "Hamilton East, NSW 2303",
    "Hamilton Hill, WA 6163",
    "Hamilton Hill, WA 6963",
    "Hamilton Island, QLD 4803",
    "Hamilton North, NSW 2292",
    "Hamilton Plains, QLD 4800",
    "Hamilton South, NSW 2303",
    "Hamilton Valley, NSW 2641",
    "Hamley, SA 5558",
    "Hamley Bridge, SA 5401",
    "Hamlyn Heights, VIC 3215",
    "Hamlyn Terrace, NSW 2259",
    "Hammond, SA 5431",
    "Hammond Park, WA 6164",
    "Hammondville, NSW 2170",
    "Hampden, SA 5374",
    "Hampden, QLD 4741",
    "Hampden Hall, NSW 2440",
    "Hampshire, TAS 7321",
    "Hampstead Gardens, SA 5086",
    "Hampton, NSW 2790",
    "Hampton, VIC 3188",
    "Hampton, QLD 4352",
    "Hampton East, VIC 3188",
    "Hampton North, VIC 3188",
    "Hampton Park, VIC 3976",
    "Hanging Rock, NSW 2340",
    "Hanging Rock, NSW 2577",
    "Hanleys Creek, NSW 2420",
    "Hannaford, QLD 4406",
    "Hannam Vale, NSW 2443",
    "Hannans, WA 6430",
    "Hannans Po, WA 6433",
    "Hansborough, SA 5374",
    "Hanson, SA 5417",
    "Hansonville, VIC 3675",
    "Hanwood, NSW 2680",
    "Happy Valley, VIC 3360",
    "Happy Valley, QLD 4655",
    "Happy Valley, SA 5159",
    "Happy Valley, VIC 3549",
    "Happy Valley, QLD 4825",
    "Harcourt, VIC 3453",
    "Harcourt North, VIC 3453",
    "Harden, NSW 2587",
    "Hardwicke Bay, SA 5575",
    "Hardy, SA 5422",
    "Hardys Bay, NSW 2257",
    "Harefield, NSW 2650",
    "Harford, TAS 7307",
    "Hargraves, NSW 2850",
    "Harkaway, VIC 3806",
    "Harlaxton, QLD 4350",
    "Harlin, QLD 4306",
    "Harman, ACT 2600",
    "Harmers Haven, VIC 3995",
    "Harolds Cross, NSW 2622",
    "Harparary, NSW 2390",
    "Harper Creek, QLD 4552",
    "Harpers Hill, NSW 2321",
    "Harrami, QLD 4630",
    "Harriet, QLD 4625",
    "Harriet River, SA 5223",
    "Harrietville, VIC 3741",
    "Harrington, NSW 2427",
    "Harrington Park, NSW 2567",
    "Harris Park, NSW 2150",
    "Harris River, WA 6225",
    "Harrisdale, WA 6112",
    "Harrismith, WA 6361",
    "Harrison, ACT 2914",
    "Harristown, QLD 4350",
    "Harrisville, QLD 4307",
    "Harrogate, SA 5244",
    "Harrow, VIC 3317",
    "Harston, VIC 3616",
    "Hart, NT 0872",
    "Hart, SA 5464",
    "Hart Range, NT 0872",
    "Hartley, NSW 2790",
    "Hartley, SA 5255",
    "Hartley Vale, NSW 2790",
    "Hartwell, VIC 3124",
    "Hartwood, NSW 2710",
    "Hartys Plains, NSW 2446",
    "Harvey, WA 6220",
    "Harwood, NSW 2465",
    "Haslam, SA 5680",
    "Hassall Grove, NSW 2761",
    "Hassans Walls, NSW 2790",
    "Hastings, TAS 7109",
    "Hastings, VIC 3915",
    "Hastings, WA 6308",
    "Hastings Point, NSW 2489",
    "Hat Head, NSW 2440",
    "Hatfield, NSW 2715",
    "Hatherleigh, SA 5280",
    "Hattah, VIC 3501",
    "Hatter Hill, WA 6356",
    "Hatton Vale, QLD 4341",
    "Havelock, VIC 3465",
    "Haven, VIC 3401",
    "Havenview, TAS 7320",
    "Havilah, NSW 2850",
    "Havilah, VIC 3737",
    "Hawker, SA 5434",
    "Hawker, ACT 2614",
    "Hawkesbury Heights, NSW 2777",
    "Hawkesdale, VIC 3287",
    "Hawkhurst, VIC 3862",
    "Hawkins Creek, QLD 4850",
    "Hawks Nest, NSW 2324",
    "Hawksburn, VIC 3142",
    "Hawkwood, QLD 4626",
    "Hawley Beach, TAS 7307",
    "Hawson, SA 5607",
    "Hawthorn, SA 5062",
    "Hawthorn, VIC 3122",
    "Hawthorn East, VIC 3123",
    "Hawthorn North, VIC 3122",
    "Hawthorn West, VIC 3122",
    "Hawthorndene, SA 5051",
    "Hawthorne, QLD 4171",
    "Hay, NSW 2711",
    "Hay, WA 6333",
    "Hay Flat, SA 5204",
    "Hay Point, QLD 4740",
    "Hay South, NSW 2711",
    "Hay Valley, SA 5252",
    "Hayborough, SA 5211",
    "Haydens Bog, VIC 3888",
    "Hayes, TAS 7140",
    "Hayes Creek, NT 0822",
    "Hayes Gap, NSW 2850",
    "Hayman Island, QLD 4801",
    "Haymarket, NSW 1208",
    "Haymarket, NSW 1236",
    "Haymarket, NSW 1237",
    "Haymarket, NSW 1238",
    "Haymarket, NSW 1239",
    "Haymarket, NSW 1240",
    "Haymarket, NSW 2000",
    "Haynes, WA 6112",
    "Haystack, NSW 2469",
    "Hayters Hill, NSW 2481",
    "Haywards Bay, NSW 2530",
    "Hazel Park, VIC 3966",
    "Hazelbrook, NSW 2779",
    "Hazeldean, QLD 4515",
    "Hazeldene, VIC 3658",
    "Hazelgrove, NSW 2787",
    "Hazelmere, WA 6055",
    "Hazelvale, WA 6333",
    "Hazelwood, VIC 3840",
    "Hazelwood North, VIC 3840",
    "Hazelwood Park, SA 5066",
    "Hazelwood South, VIC 3840",
    "Hazledean, QLD 4741",
    "Headington Hill, QLD 4361",
    "Healesville, VIC 3777",
    "Healesville Main Street, VIC 3777",
    "Healesville Post Shop, VIC 3777",
    "Healy, QLD 4825",
    "Heath Hill, VIC 3981",
    "Heathcote, NSW 2233",
    "Heathcote, VIC 3523",
    "Heathcote Junction, VIC 3758",
    "Heathcote South, VIC 3523",
    "Heatherbrae, NSW 2324",
    "Heatherton, VIC 3202",
    "Heathfield, SA 5153",
    "Heathmere, VIC 3305",
    "Heathmont, VIC 3135",
    "Heathpool, SA 5068",
    "Heathridge, WA 6027",
    "Heathwood, VIC 3134",
    "Heathwood, QLD 4110",
    "Heathwood Df, QLD 4110",
    "Heatley, QLD 4814",
    "Hebden, NSW 2330",
    "Hebel, QLD 4486",
    "Hebersham, NSW 2770",
    "Heckenberg, NSW 2168",
    "Hectorville, SA 5073",
    "Heddon Greta, NSW 2321",
    "Hedley, VIC 3967",
    "Heidelberg, VIC 3084",
    "Heidelberg Heights, VIC 3081",
    "Heidelberg Rgh, VIC 3081",
    "Heidelberg West, VIC 3081",
    "Heifer Station, NSW 2460",
    "Helen Springs, NT 0862",
    "Helena Valley, WA 6056",
    "Helens Hill, QLD 4850",
    "Helensburgh, NSW 2508",
    "Helensvale, QLD 4212",
    "Helensvale Town Centre, QLD 4212",
    "Helenvale, QLD 4895",
    "Helidon, QLD 4344",
    "Helidon Spa, QLD 4344",
    "Hellyer, TAS 7321",
    "Hemmant, QLD 4174",
    "Henderson, WA 6166",
    "Hendon, SA 5014",
    "Hendon, QLD 4362",
    "Hendra, QLD 4011",
    "Henley, NSW 2111",
    "Henley Beach, SA 5022",
    "Henley Beach South, SA 5022",
    "Henley Brook, WA 6055",
    "Henrietta, TAS 7325",
    "Hensley Park, VIC 3301",
    "Henty, VIC 3312",
    "Henty, NSW 2658",
    "Henty, WA 6236",
    "Hepburn, VIC 3461",
    "Hepburn Springs, VIC 3461",
    "Herbert, NT 0836",
    "Herberton, QLD 4887",
    "Herdsman, WA 6017",
    "Herdsmans Cove, TAS 7030",
    "Hereford Hall, NSW 2622",
    "Heritage Park, QLD 4118",
    "Hermannsburg, NT 0872",
    "Hermidale, NSW 2831",
    "Hermit Park, QLD 4812",
    "Hermitage, TAS 7030",
    "Hermitage Flat, NSW 2790",
    "Hernani, NSW 2453",
    "Herne Hill, WA 6056",
    "Herne Hill, VIC 3218",
    "Hernes Oak, VIC 3825",
    "Heron Island, QLD 4680",
    "Herons Creek, NSW 2443",
    "Herrick, TAS 7264",
    "Herron, WA 6211",
    "Herston, QLD 4006",
    "Hervey Bay, QLD 4655",
    "Hervey Range, QLD 4817",
    "Hesket, VIC 3442",
    "Hesse, VIC 3321",
    "Hester, WA 6255",
    "Hester Brook, WA 6255",
    "Hewett, SA 5118",
    "Hexham, NSW 2322",
    "Hexham, VIC 3273",
    "Heybridge, TAS 7316",
    "Heyfield, VIC 3858",
    "Heytesbury Lower, VIC 3268",
    "Heywood, VIC 3304",
    "Hiamdale, VIC 3847",
    "Hiawatha, VIC 3971",
    "Hibernia, QLD 4723",
    "Hickety, WA 6532",
    "Hickeys Creek, NSW 2440",
    "Hidden Valley, NT 0822",
    "Hidden Valley, QLD 4816",
    "Hidden Valley, VIC 3756",
    "Hidden Valley, QLD 4703",
    "Hideaway Bay, QLD 4800",
    "Higgins, ACT 2615",
    "Higginsville, WA 6443",
    "High Camp, VIC 3764",
    "High Range, NSW 2575",
    "High Wycombe, WA 6057",
    "Highbury, SA 5089",
    "Highbury, WA 6313",
    "Highbury, QLD 4871",
    "Highclere, TAS 7321",
    "Highcroft, TAS 7183",
    "Higher Macdonald, NSW 2775",
    "Highett, VIC 3190",
    "Highfields, NSW 2289",
    "Highfields, QLD 4352",
    "Highgate, SA 5063",
    "Highgate, WA 6003",
    "Highgate Hill, QLD 4101",
    "Highgrove, QLD 4352",
    "Highland Park, QLD 4211",
    "Highland Plains, QLD 4454",
    "Highland Plains, QLD 4401",
    "Highland Valley, SA 5255",
    "Highlands, VIC 3660",
    "Highpoint City, VIC 3032",
    "Highton, VIC 3216",
    "Highvale, QLD 4520",
    "Highworth, QLD 4560",
    "Hilbert, WA 6112",
    "Hilgay, VIC 3315",
    "Hill End, NSW 2850",
    "Hill End, VIC 3825",
    "Hill River, WA 6521",
    "Hill River, SA 5453",
    "Hill Top, NSW 2628",
    "Hill Top, NSW 2575",
    "Hillarys, WA 6025",
    "Hillarys, WA 6923",
    "Hillbank, SA 5112",
    "Hillcrest, QLD 4118",
    "Hillcrest, TAS 7320",
    "Hillcrest, VIC 3351",
    "Hillcrest, SA 5086",
    "Hilldale, NSW 2420",
    "Hilldene, VIC 3660",
    "Hillgrove, NSW 2650",
    "Hillgrove, NSW 2350",
    "Hillier, SA 5116",
    "Hillman, WA 6168",
    "Hillsborough, NSW 2290",
    "Hillsborough, NSW 2320",
    "Hillsdale, NSW 2036",
    "Hillside, VIC 3875",
    "Hillside, NSW 2036",
    "Hillside, VIC 3037",
    "Hillside, WA 6312",
    "Hillston, NSW 2675",
    "Hilltown, SA 5455",
    "Hillview, QLD 4285",
    "Hillville, NSW 2430",
    "Hillvue, NSW 2340",
    "Hillwood, TAS 7252",
    "Hilton, WA 6163",
    "Hilton, SA 5033",
    "Hilton Plaza, SA 5033",
    "Hinchinbrook, QLD 4849",
    "Hinchinbrook, NSW 2168",
    "Hincks, SA 5603",
    "Hindmarsh, WA 6462",
    "Hindmarsh, SA 5007",
    "Hindmarsh Island, SA 5214",
    "Hindmarsh Tiers, SA 5202",
    "Hindmarsh Valley, SA 5211",
    "Hines Hill, WA 6413",
    "Hinnomunjie, VIC 3898",
    "Hinton, NSW 2321",
    "Hirstglen, QLD 4359",
    "Hithergreen, WA 6280",
    "Hivesville, QLD 4612",
    "Hmas Albatross, NSW 2540",
    "Hmas Cerberus, VIC 3920",
    "Hmas Creswell, ACT 2540",
    "Hmas Harman, ACT 2600",
    "Hmas Kuttabul, NSW 2011",
    "Hmas Penguin, NSW 2091",
    "Hmas Platypus, NSW 2060",
    "Hmas Rushcutters, NSW 2027",
    "Hmas Waterhen, NSW 2060",
    "Hmas Watson, NSW 2030",
    "Hobart, TAS 7000",
    "Hobart, TAS 7001",
    "Hobart, TAS 7800",
    "Hobart, TAS 7802",
    "Hobart, TAS 7803",
    "Hobart, TAS 7804",
    "Hobart, TAS 7805",
    "Hobart, TAS 7806",
    "Hobart, TAS 7807",
    "Hobart, TAS 7808",
    "Hobart, TAS 7809",
    "Hobart, TAS 7810",
    "Hobart, TAS 7811",
    "Hobart, TAS 7812",
    "Hobart, TAS 7813",
    "Hobart, TAS 7814",
    "Hobart, TAS 7823",
    "Hobart, TAS 7824",
    "Hobart, TAS 7827",
    "Hobart, TAS 7828",
    "Hobart, TAS 7829",
    "Hobart, TAS 7845",
    "Hobart, TAS 7850",
    "Hobartville, QLD 4724",
    "Hobartville, NSW 2753",
    "Hobbys Yards, NSW 2795",
    "Hocking, WA 6065",
    "Hoddles Creek, VIC 3139",
    "Hoddys Well, WA 6566",
    "Hodgleigh, QLD 4610",
    "Hodgson, QLD 4455",
    "Hodgson Vale, QLD 4352",
    "Hoffman, WA 6220",
    "Hogarth Range, NSW 2469",
    "Holbrook, NSW 2644",
    "Holden Hill, SA 5088",
    "Holder, ACT 2611",
    "Holder, SA 5330",
    "Holder Siding, SA 5330",
    "Holgate, NSW 2250",
    "Holland Park, QLD 4121",
    "Holland Park East, QLD 4121",
    "Holland Park West, QLD 4121",
    "Hollands Landing, VIC 3862",
    "Holleton, WA 6426",
    "Hollisdale, NSW 2446",
    "Hollow Tree, TAS 7140",
    "Holloways Beach, QLD 4878",
    "Hollydeen, NSW 2328",
    "Hollywell, QLD 4216",
    "Holmes, NT 0812",
    "Holmesglen, VIC 3148",
    "Holmesville, NSW 2286",
    "Holmview, QLD 4207",
    "Holmwood, WA 6522",
    "Holroyd, NSW 2142",
    "Holroyd River, QLD 4871",
    "Holsworthy, NSW 2173",
    "Holt, ACT 2615",
    "Holt Rock, WA 6355",
    "Holts Flat, NSW 2631",
    "Holtze, NT 0829",
    "Holwell, TAS 7275",
    "Holyoake, WA 6213",
    "Home Hill, QLD 4806",
    "Home Island Cocos (keeling) Islands, WA 6799",
    "Home Rule, NSW 2850",
    "Homebush, NSW 2140",
    "Homebush, QLD 4740",
    "Homebush, VIC 3465",
    "Homebush South, NSW 2140",
    "Homebush West, NSW 2140",
    "Homeleigh, NSW 2474",
    "Homerton, VIC 3304",
    "Homestead, QLD 4816",
    "Homewood, VIC 3717",
    "Honeybugle, NSW 2825",
    "Honeywood, TAS 7017",
    "Honiton, SA 5576",
    "Hook Island, QLD 4741",
    "Hookswood, QLD 4415",
    "Hope Forest, SA 5172",
    "Hope Gap, SA 5555",
    "Hope Island, QLD 4212",
    "Hope Vale, QLD 4895",
    "Hope Valley, SA 5090",
    "Hope Valley, WA 6165",
    "Hopefield, NSW 2646",
    "Hopeland, QLD 4413",
    "Hopeland, WA 6125",
    "Hopetoun, WA 6348",
    "Hopetoun, VIC 3396",
    "Hopetoun Gardens, VIC 3162",
    "Hopetoun Park, VIC 3340",
    "Hopkins Creek, NSW 2484",
    "Hoppers Crossing, VIC 3029",
    "Hordern Vale, VIC 3238",
    "Horfield, VIC 3567",
    "Horn Island, QLD 4875",
    "Hornet Bank, QLD 4420",
    "Horningsea Park, NSW 2171",
    "Hornsby, NSW 2077",
    "Hornsby, NSW 1630",
    "Hornsby Heights, NSW 2077",
    "Hornsby Westfield, NSW 1635",
    "Hornsdale, SA 5491",
    "Horrocks, WA 6535",
    "Horse Camp, QLD 4671",
    "Horse Creek, QLD 4714",
    "Horse Station Creek, NSW 2474",
    "Horseshoe Bay, QLD 4819",
    "Horseshoe Bend, NSW 2320",
    "Horseshoe Creek, NSW 2474",
    "Horseshoe Lagoon, QLD 4809",
    "Horsfield Bay, NSW 2256",
    "Horsham, VIC 3400",
    "Horsham, VIC 3402",
    "Horsham West, VIC 3400",
    "Horsley, NSW 2530",
    "Horsley Park, NSW 2175",
    "Horsley Park, NSW 2164",
    "Horsnell Gully, SA 5141",
    "Horton, QLD 4660",
    "Hoskin Corner, SA 5461",
    "Hoskinstown, NSW 2621",
    "Hotham, NT 0822",
    "Hotham Heights, VIC 3741",
    "Hotham Hill, VIC 3051",
    "Hotspur, VIC 3303",
    "Houghton, SA 5131",
    "Houston, VIC 3128",
    "Houtman Abrolhos, WA 6530",
    "Hove, SA 5048",
    "Hovea, WA 6071",
    "Hovells Creek, NSW 2794",
    "Howard, QLD 4659",
    "Howard Springs, NT 0835",
    "Howard Springs, NT 0835",
    "Howards Grass, NSW 2480",
    "Howatharra, WA 6532",
    "Howden, TAS 7054",
    "Howell, NSW 2360",
    "Howes Creek, VIC 3723",
    "Howes Valley, NSW 2330",
    "Howick, NSW 2330",
    "Howick, WA 6450",
    "Howitt, QLD 4890",
    "Howitt Plains, VIC 3858",
    "Howlong, NSW 2643",
    "Howqua, VIC 3723",
    "Howqua Hills, VIC 3723",
    "Howqua Inlet, VIC 3723",
    "Howrah, TAS 7018",
    "Howth, TAS 7316",
    "Hoxton Park, NSW 2171",
    "Hoya, QLD 4310",
    "Hoyleton, SA 5453",
    "Huddleston, SA 5523",
    "Hudson, QLD 4860",
    "Hugh, NT 0872",
    "Hughenden, QLD 4821",
    "Hughes, NT 0822",
    "Hughes, ACT 2605",
    "Hughesdale, VIC 3166",
    "Hull Heads, QLD 4854",
    "Hulongine, WA 6460",
    "Humbug Scrub, SA 5114",
    "Hume, ACT 2620",
    "Humeburn, QLD 4490",
    "Humevale, VIC 3757",
    "Humphery, QLD 4625",
    "Humpty Doo, NT 0836",
    "Humpty Doo, NT 0836",
    "Humula, NSW 2652",
    "Hunchy, QLD 4555",
    "Hungerford, SA 5222",
    "Hungerford, QLD 4493",
    "Hungerford, NSW 2840",
    "Hunter, VIC 3558",
    "Hunters Hill, NSW 2110",
    "Hunterston, VIC 3971",
    "Hunterview, NSW 2330",
    "Huntfield Heights, SA 5163",
    "Huntingdale, VIC 3166",
    "Huntingdale, WA 6110",
    "Huntingdon, NSW 2446",
    "Huntingfield, TAS 7055",
    "Huntingwood, NSW 2148",
    "Huntley, NSW 2530",
    "Huntley, NSW 2800",
    "Huntleys Cove, NSW 2111",
    "Huntleys Point, NSW 2111",
    "Huntly, VIC 3551",
    "Huntly North, VIC 3551",
    "Huon, VIC 3695",
    "Huon Creek, VIC 3691",
    "Huonbrook, NSW 2482",
    "Huonville, TAS 7109",
    "Hurlstone Park, NSW 2193",
    "Hurricane, QLD 4871",
    "Hurstbridge, VIC 3099",
    "Hurstville, NSW 1493",
    "Hurstville, NSW 2220",
    "Hurstville Bc, NSW 1481",
    "Hurstville Grove, NSW 2220",
    "Hurstville Westfield, NSW 2220",
    "Huskisson, NSW 2540",
    "Hutt Street, SA 5000",
    "Hutton Creek, QLD 4454",
    "Hyams Beach, NSW 2540",
    "Hyde Park, QLD 4812",
    "Hyde Park, SA 5061",
    "Hyde Park Castletown, QLD 4812",
    "Hyden, WA 6359",
    "Hyland Park, NSW 2448",
    "Hynam, SA 5262",
    "Hyndmans Creek, NSW 2446",
    "Icy Creek, VIC 3833",
    "Ida Bay, TAS 7109",
    "Idalia, QLD 4811",
    "Ideraway, QLD 4625",
    "Iguana Creek, VIC 3875",
    "Ikewa, WA 6522",
    "Ilarwill, NSW 2463",
    "Ilbilbie, QLD 4738",
    "Ilford, NSW 2850",
    "Ilfracombe, QLD 4727",
    "Ilkley, QLD 4554",
    "Illabarook, VIC 3351",
    "Illabo, NSW 2590",
    "Illaroo, NSW 2540",
    "Illawarra, VIC 3381",
    "Illawong, NSW 2234",
    "Illinbah, QLD 4275",
    "Illowa, VIC 3282",
    "Ilparpa, NT 0870",
    "Iluka, WA 6028",
    "Iluka, NSW 2466",
    "Image Flat, QLD 4560",
    "Imanpa, NT 0872",
    "Imbil, QLD 4570",
    "Inala, QLD 4077",
    "Inala East, QLD 4077",
    "Inala Heights, QLD 4077",
    "Indee, WA 6721",
    "Indented Head, VIC 3223",
    "Indi, NSW 2642",
    "Indigo Valley, VIC 3688",
    "Indooroopilly, QLD 4068",
    "Indooroopilly Centre, QLD 4068",
    "Indulkana, SA 0872",
    "Ingebirah, NSW 2627",
    "Inggarda, WA 6701",
    "Ingham, QLD 4850",
    "Ingle Farm, SA 5098",
    "Ingleburn, NSW 2565",
    "Ingleburn, NSW 1890",
    "Ingleburn Milpo, NSW 2174",
    "Inglehope, WA 6213",
    "Ingleside, NSW 2101",
    "Inglestone, QLD 4422",
    "Inglewood, WA 6052",
    "Inglewood, VIC 3517",
    "Inglewood, SA 5133",
    "Inglewood, QLD 4387",
    "Inglewood, WA 6932",
    "Ingliston, VIC 3342",
    "Ingoldsby, QLD 4343",
    "Ininti Store, NT 0872",
    "Injinoo, QLD 4876",
    "Injune, QLD 4454",
    "Inkerman, QLD 4806",
    "Inkerman, SA 5550",
    "Inkerman, VIC 3472",
    "Inkpen, WA 6302",
    "Inkster, SA 5680",
    "Inman Valley, SA 5211",
    "Innaloo, WA 6018",
    "Innaloo, WA 6918",
    "Innamincka, SA 5731",
    "Innawanga, WA 6751",
    "Innes Park, QLD 4670",
    "Innes View, NSW 2429",
    "Inneston, SA 5577",
    "Innisfail, QLD 4860",
    "Innisfail Estate, QLD 4860",
    "Innisplain, QLD 4285",
    "Innot Hot Springs, QLD 4872",
    "Inskip, QLD 4581",
    "Interlaken, TAS 7030",
    "Inverbrackie, SA 5244",
    "Inverell, NSW 2360",
    "Invergordon, VIC 3636",
    "Invergordon, NSW 2422",
    "Invergowrie, NSW 2350",
    "Inverlaw, QLD 4610",
    "Inverleigh, VIC 3321",
    "Inverloch, VIC 3996",
    "Invermay, VIC 3352",
    "Invermay, TAS 7248",
    "Invermay Park, VIC 3350",
    "Inverness, QLD 4703",
    "Iona, VIC 3815",
    "Ipswich, QLD 4305",
    "Iraak, VIC 3494",
    "Iredale, QLD 4344",
    "Irishtown, TAS 7330",
    "Irishtown, VIC 3451",
    "Irishtown, WA 6401",
    "Irlpme, NT 0870",
    "Iron Baron, SA 5600",
    "Iron Gates, NSW 2473",
    "Iron Knob, SA 5601",
    "Iron Pot Creek, NSW 2474",
    "Ironbank, SA 5153",
    "Ironbark, QLD 4306",
    "Ironbark, NSW 2347",
    "Ironbark, VIC 3550",
    "Irongate, QLD 4356",
    "Ironmungy, NSW 2630",
    "Ironpot, QLD 4701",
    "Ironpot, QLD 4610",
    "Ironstone, SA 5222",
    "Irrewarra, VIC 3249",
    "Irrewillipe, VIC 3249",
    "Irrewillipe East, VIC 3249",
    "Irvinebank, QLD 4887",
    "Irvingdale, QLD 4404",
    "Irvington, NSW 2470",
    "Irwin, WA 6525",
    "Irymple, VIC 3498",
    "Irymple, NSW 2835",
    "Isaacs, ACT 2607",
    "Isabella, NSW 2795",
    "Isabella Plains, ACT 2905",
    "Isis Central, QLD 4660",
    "Isis River, QLD 4660",
    "Isisford, QLD 4731",
    "Isla, QLD 4719",
    "Island Beach, SA 5222",
    "Island Plantation, QLD 4650",
    "Isle Of Capri, QLD 4217",
    "Islington, NSW 2296",
    "Israelite Bay, WA 6452",
    "Isseka, WA 6535",
    "Ivanhoe, VIC 8576",
    "Ivanhoe, VIC 3079",
    "Ivanhoe, NSW 2878",
    "Ivanhoe East, VIC 3079",
    "Ivanhoe North, VIC 3079",
    "Iveragh, QLD 4680",
    "Ivory Creek, QLD 4313",
    "Jabiru, NT 0886",
    "Jabuk, SA 5301",
    "Jacana, VIC 3047",
    "Jack River, VIC 3971",
    "Jackadgery, NSW 2460",
    "Jackass Flat, VIC 3556",
    "Jackeys Marsh, TAS 7304",
    "Jackitup, WA 6335",
    "Jacks Creek, NSW 2390",
    "Jackson, QLD 4426",
    "Jackson North, QLD 4426",
    "Jackson South, QLD 4426",
    "Jacksons Flat, NSW 2469",
    "Jacky Bulbin Flat, NSW 2463",
    "Jacob Creek, VIC 3825",
    "Jacobs Well, QLD 4208",
    "Jacup, WA 6337",
    "Jaffa, QLD 4855",
    "Jaggan, QLD 4885",
    "Jagumba, NSW 2642",
    "Jagungal Wilderness, NSW 2642",
    "Jalbarragup, WA 6275",
    "Jaloran, WA 6315",
    "Jam Jerrup, VIC 3984",
    "Jamberoo, NSW 2533",
    "Jambin, QLD 4702",
    "Jamboree Heights, QLD 4074",
    "James Cook University, QLD 4811",
    "James Creek, NSW 2463",
    "James Well, SA 5571",
    "Jamestown, SA 5491",
    "Jamieson, SA 5640",
    "Jamieson, VIC 3723",
    "Jamison Centre, ACT 2614",
    "Jamisontown, NSW 2750",
    "Jan Juc, VIC 3228",
    "Jancourt, VIC 3266",
    "Jancourt East, VIC 3266",
    "Jandabup, WA 6077",
    "Jandakot, WA 6164",
    "Jandowae, QLD 4410",
    "Jane Brook, WA 6056",
    "Jannali, NSW 2226",
    "Japoonvale, QLD 4856",
    "Jardee, WA 6258",
    "Jardine, QLD 4702",
    "Jardine River, QLD 4874",
    "Jarklin, VIC 3517",
    "Jarra Creek, QLD 4854",
    "Jarrahdale, WA 6124",
    "Jarrahmond, VIC 3888",
    "Jarrahwood, WA 6275",
    "Jarvis Creek, VIC 3700",
    "Jarvisfield, QLD 4807",
    "Jaspers Brush, NSW 2535",
    "Jaunter, NSW 2787",
    "Jay Creek, NT 0872",
    "Jeebropilly, QLD 4340",
    "Jeeralang, VIC 3840",
    "Jeeralang Junction, VIC 3840",
    "Jeetho, VIC 3945",
    "Jeffcott, VIC 3480",
    "Jeffcott North, VIC 3480",
    "Jeir, NSW 2582",
    "Jelcobine, WA 6306",
    "Jellat Jellat, NSW 2550",
    "Jellinbah, QLD 4702",
    "Jemalong, NSW 2871",
    "Jembaicumbene, NSW 2622",
    "Jennacubbine, WA 6401",
    "Jennapullin, WA 6401",
    "Jennings, NSW 4383",
    "Jenolan, NSW 2790",
    "Jensen, QLD 4818",
    "Jeogla, NSW 2350",
    "Jeparit, VIC 3423",
    "Jerangle, NSW 2630",
    "Jerdacuttup, WA 6346",
    "Jeremadra, NSW 2536",
    "Jeremy, NSW 2795",
    "Jericho, QLD 4728",
    "Jericho, VIC 3825",
    "Jericho, SA 5554",
    "Jericho, TAS 7030",
    "Jerilderie, NSW 2716",
    "Jerona, QLD 4809",
    "Jerrabattgulla, NSW 2622",
    "Jerrabomberra, NSW 2619",
    "Jerramungup, WA 6337",
    "Jerrara, NSW 2533",
    "Jerrawa, NSW 2582",
    "Jerrawangala, NSW 2540",
    "Jerrong, NSW 2580",
    "Jerrys Plains, NSW 2330",
    "Jerseyville, NSW 2431",
    "Jeruk, VIC 3527",
    "Jerusalem, SA 5554",
    "Jervis Bay, ACT 2540",
    "Jervis Island, QLD 4875",
    "Jervois, SA 5259",
    "Jesmond, NSW 2299",
    "Jetsonville, TAS 7260",
    "Jewells, NSW 2280",
    "Jews Lagoon, NSW 2388",
    "Jibberding, WA 6612",
    "Jiggi, NSW 2480",
    "Jil Jil, VIC 3483",
    "Jilakin, WA 6365",
    "Jilkminggan, NT 0852",
    "Jilliby, NSW 2259",
    "Jilpanger, VIC 3409",
    "Jimboomba, QLD 4280",
    "Jimbour, QLD 4406",
    "Jimenbuen, NSW 2630",
    "Jimna, QLD 4515",
    "Jincumbilly, NSW 2631",
    "Jindabyne, NSW 2627",
    "Jindalee, QLD 4074",
    "Jindalee, WA 6036",
    "Jinden, NSW 2622",
    "Jindera, NSW 2642",
    "Jindivick, VIC 3818",
    "Jindong, WA 6280",
    "Jingalup, WA 6395",
    "Jingellic, NSW 2642",
    "Jingera, NSW 2622",
    "Jingili, NT 0810",
    "Jingili, NT 0810",
    "Jitarning, WA 6365",
    "Joadja, NSW 2575",
    "Joanna, SA 5271",
    "Jobs Gate, QLD 4490",
    "Joel Joel, VIC 3384",
    "Joel South, VIC 3384",
    "Joes Box, NSW 2469",
    "Johanna, VIC 3238",
    "Johnburgh, SA 5431",
    "Johns River, NSW 2443",
    "Johnsons Hill, QLD 4714",
    "Johnsonville, VIC 3902",
    "Johnston, NT 0832",
    "Johnstones Hill, VIC 3870",
    "Johnstown, QLD 4615",
    "Jolimont, WA 6014",
    "Jolly Nose, NSW 2445",
    "Jollys Lookout, QLD 4520",
    "Jondaryan, QLD 4403",
    "Jones Bridge, NSW 2720",
    "Jones Creek, NSW 2722",
    "Jones Creek, NSW 2830",
    "Jones Gully, QLD 4355",
    "Jones Hill, QLD 4570",
    "Jones Island, NSW 2430",
    "Joondalup, WA 6027",
    "Joondanna, WA 6060",
    "Jordan Springs, NSW 2747",
    "Jordanville, VIC 3148",
    "Josephville, QLD 4285",
    "Joskeleigh, QLD 4702",
    "Joslin, SA 5070",
    "Joyces Creek, VIC 3364",
    "Joyner, QLD 4500",
    "Jubilee Heights, QLD 4860",
    "Jubilee Pocket, QLD 4802",
    "Judbury, TAS 7109",
    "Judds Creek, NSW 2795",
    "Jugiong, NSW 2726",
    "Julago, QLD 4816",
    "Julanka Holdings, SA 5238",
    "Julatten, QLD 4871",
    "Julia, SA 5374",
    "Julia Creek, QLD 4823",
    "Julimar, WA 6567",
    "Jumbuk, VIC 3869",
    "Jumbunna, VIC 3951",
    "Juna Downs, WA 6751",
    "Junabee, QLD 4370",
    "Junction Hill, NSW 2460",
    "Junction View, QLD 4343",
    "Junction Village, VIC 3977",
    "Jundah, QLD 4736",
    "Junee, NSW 2663",
    "Junee Reefs, NSW 2666",
    "Jung, VIC 3401",
    "Jungaburra, VIC 3575",
    "Junortoun, VIC 3551",
    "Jupiter Creek, SA 5153",
    "Jurien Bay, WA 6516",
    "Kaarimba, VIC 3635",
    "Kaban, QLD 4888",
    "Kabra, QLD 4702",
    "Kadathinni, WA 6519",
    "Kadina, SA 5554",
    "Kadnook, VIC 3318",
    "Kagaru, QLD 4285",
    "Kahibah, NSW 2290",
    "Kaimkillenbun, QLD 4406",
    "Kains Flat, NSW 2850",
    "Kainton, SA 5552",
    "Kairi, QLD 4872",
    "Kakadu, NT 0822",
    "Kalamunda, WA 6076",
    "Kalamunda, WA 6926",
    "Kalanbi, SA 5690",
    "Kalang, NSW 2454",
    "Kalangadoo, SA 5278",
    "Kalannie, WA 6468",
    "Kalapa, QLD 4702",
    "Kalarka, QLD 4741",
    "Kalaru, NSW 2550",
    "Kalbar, QLD 4309",
    "Kalbarri, WA 6536",
    "Kalbarri National Park, WA 6536",
    "Kalbeeba, SA 5118",
    "Kaldoonera, SA 5655",
    "Kaleen, ACT 2617",
    "Kalgan, WA 6330",
    "Kalgoorlie, WA 6430",
    "Kalgoorlie Po, WA 6433",
    "Kalgup, WA 6280",
    "Kalimna, VIC 3909",
    "Kalimna West, VIC 3909",
    "Kalkadoon, QLD 4825",
    "Kalkallo, VIC 3064",
    "Kalkarindji, NT 0852",
    "Kalkee, VIC 3401",
    "Kalkie, QLD 4670",
    "Kalkite, NSW 2627",
    "Kallangur, QLD 4503",
    "Kallaroo, WA 6025",
    "Kallista, VIC 3791",
    "Kallora, SA 5550",
    "Kaloorup, WA 6280",
    "Kalorama, VIC 3766",
    "Kalpienung, VIC 3529",
    "Kalpowar, QLD 4630",
    "Kaltukatjara, NT 0872",
    "Kalumburu, WA 6740",
    "Kalunga, QLD 4887",
    "Kalyan, SA 5308",
    "Kamarah, NSW 2665",
    "Kamarooka, VIC 3570",
    "Kamarooka North, VIC 3571",
    "Kambah, ACT 2902",
    "Kambah Village, ACT 2902",
    "Kambalda East, WA 6442",
    "Kambalda West, WA 6442",
    "Kameruka, NSW 2550",
    "Kamerunga, QLD 4870",
    "Kamma, QLD 4865",
    "Kamona, TAS 7260",
    "Kanagulk, VIC 3401",
    "Kanahooka, NSW 2530",
    "Kanangra, NSW 2787",
    "Kancoona, VIC 3691",
    "Kandanga, QLD 4570",
    "Kandanga Creek, QLD 4570",
    "Kandos, NSW 2848",
    "Kangaloon, NSW 2576",
    "Kangarilla, SA 5157",
    "Kangaroo Creek, NSW 2460",
    "Kangaroo Flat, VIC 3555",
    "Kangaroo Flat, SA 5118",
    "Kangaroo Ground, VIC 3097",
    "Kangaroo Gully, WA 6255",
    "Kangaroo Head, SA 5222",
    "Kangaroo Inn, SA 5280",
    "Kangaroo Point, QLD 4169",
    "Kangaroo Point, NSW 2224",
    "Kangaroo Valley, NSW 2577",
    "Kangaroobie, NSW 2800",
    "Kangiara, NSW 2582",
    "Kangy Angy, NSW 2258",
    "Kanigan, QLD 4570",
    "Kanimbla, NSW 2790",
    "Kanimbla, QLD 4870",
    "Kaniva, VIC 3419",
    "Kanmantoo, SA 5252",
    "Kanni, SA 5330",
    "Kanoona, NSW 2550",
    "Kanowna, WA 6431",
    "Kanumbra, VIC 3719",
    "Kanwal, NSW 2259",
    "Kanya, VIC 3387",
    "Kanyaka, SA 5434",
    "Kanyapella, VIC 3564",
    "Kaoota, TAS 7150",
    "Kapaldo, QLD 4630",
    "Kapinnie, SA 5632",
    "Kapooka, NSW 2661",
    "Kappawanta, SA 5670",
    "Kapunda, SA 5373",
    "Kaputar, NSW 2390",
    "Karaak Flat, NSW 2429",
    "Karabar, NSW 2620",
    "Karabeal, VIC 3294",
    "Karakin, WA 6044",
    "Karalee, QLD 4306",
    "Karama, NT 0812",
    "Karama, NT 0813",
    "Karama, NT 0812",
    "Karana Downs, QLD 4306",
    "Karangi, NSW 2450",
    "Karanja, TAS 7140",
    "Karara, QLD 4352",
    "Karatta, SA 5223",
    "Karawara, WA 6152",
    "Karawatha, QLD 4117",
    "Karcultaby, SA 5654",
    "Kardella, VIC 3951",
    "Kardella South, VIC 3950",
    "Kardinya, WA 6163",
    "Kareela, NSW 2232",
    "Kariah, VIC 3260",
    "Karijini, WA 6751",
    "Karingal, VIC 3199",
    "Karingal Centre, VIC 3199",
    "Kariong, NSW 2250",
    "Karkoo, SA 5607",
    "Karlgarin, WA 6358",
    "Karlkurla, WA 6430",
    "Karloning, WA 6479",
    "Karloo, WA 6530",
    "Karnak, VIC 3401",
    "Karnup, WA 6176",
    "Karoola, TAS 7267",
    "Karoonda, SA 5307",
    "Karrabin, QLD 4306",
    "Karragarra Island, QLD 4184",
    "Karragullen, WA 6111",
    "Karrakatta, WA 6010",
    "Karrakup, WA 6122",
    "Karramindie, WA 6429",
    "Karramomus, VIC 3631",
    "Karranadgin, WA 6460",
    "Karratha, WA 6714",
    "Karratha Industrial Estate, WA 6714",
    "Karridale, WA 6288",
    "Karrinyup, WA 6018",
    "Karrinyup, WA 6921",
    "Karron, QLD 4871",
    "Karroun Hill, WA 6472",
    "Kars Springs, NSW 2337",
    "Karte, SA 5307",
    "Karuah, NSW 2324",
    "Karumba, QLD 4891",
    "Karyrie, VIC 3483",
    "Katamatite, VIC 3649",
    "Katamatite East, VIC 3649",
    "Katandra, VIC 3634",
    "Katandra West, VIC 3634",
    "Katanning, WA 6317",
    "Katarapko, SA 5343",
    "Katherine, NT 0850",
    "Katherine, NT 0852",
    "Katherine, NT 0850",
    "Katherine, NT 0851",
    "Katherine, NT 0852",
    "Katherine East, NT 0850",
    "Katherine South, NT 0850",
    "Katoomba, NSW 2780",
    "Katrine, WA 6566",
    "Katunga, VIC 3640",
    "Kauring, WA 6302",
    "Kawana, QLD 4701",
    "Kawana Island, QLD 4575",
    "Kawarren, VIC 3249",
    "Kawl Kawl, QLD 4612",
    "Kawungan, QLD 4655",
    "Kayena, TAS 7270",
    "Kayuga, NSW 2333",
    "Kealba, VIC 3021",
    "Kealy, WA 6280",
    "Kearneys Spring, QLD 4350",
    "Kearns, NSW 2558",
    "Kearsley, NSW 2325",
    "Kebaringup, WA 6335",
    "Kedron, QLD 4031",
    "Keely, VIC 3568",
    "Keepit, NSW 2340",
    "Keera, NSW 2404",
    "Keerrong, NSW 2480",
    "Keilira, SA 5275",
    "Keilor, VIC 3036",
    "Keilor Downs, VIC 3038",
    "Keilor East, VIC 3033",
    "Keilor Lodge, VIC 3038",
    "Keilor North, VIC 3036",
    "Keilor Park, VIC 3042",
    "Keinbah, NSW 2320",
    "Keiraville, NSW 2500",
    "Keith, SA 5267",
    "Keith Hall, NSW 2478",
    "Kelgoola, NSW 2849",
    "Kellalac, VIC 3393",
    "Kellerberrin, WA 6410",
    "Kellevie, TAS 7176",
    "Kellidie Bay, SA 5607",
    "Kelly, SA 5641",
    "Kellys Plains, NSW 2350",
    "Kellyville, NSW 2155",
    "Kellyville Ridge, NSW 2155",
    "Kelmscott, WA 6991",
    "Kelmscott, WA 6111",
    "Kelsey Creek, QLD 4800",
    "Kelso, TAS 7270",
    "Kelso, QLD 4815",
    "Kelso, NSW 2795",
    "Kelvin, NSW 2380",
    "Kelvin Grove, QLD 4059",
    "Kelvin View, VIC 3666",
    "Kelvinhaugh, QLD 4401",
    "Kembla Grange, NSW 2526",
    "Kembla Heights, NSW 2526",
    "Kemmis, QLD 4742",
    "Kemps Creek, NSW 2178",
    "Kempsey, NSW 2440",
    "Kempsey Msc, NSW 2442",
    "Kempton, TAS 7030",
    "Kendall, NSW 2439",
    "Kendenup, WA 6323",
    "Kenebri, NSW 2396",
    "Kenilworth, QLD 4574",
    "Kenley, VIC 3597",
    "Kenmare, VIC 3395",
    "Kenmare, WA 6316",
    "Kenmore, QLD 4069",
    "Kenmore East, QLD 4069",
    "Kenmore Hills, QLD 4069",
    "Kennaicle Creek, NSW 2449",
    "Kennedy, QLD 4816",
    "Kennedy Range, WA 6701",
    "Kennedys Creek, VIC 3239",
    "Kennett River, VIC 3221",
    "Kennington, VIC 3550",
    "Kensington, WA 6151",
    "Kensington, NSW 2033",
    "Kensington, VIC 3031",
    "Kensington, NSW 1465",
    "Kensington, QLD 4670",
    "Kensington, SA 5068",
    "Kensington Gardens, SA 5068",
    "Kensington Grove, QLD 4341",
    "Kensington Park, SA 5068",
    "Kent Town, SA 5067",
    "Kent Town, SA 5071",
    "Kentdale, WA 6333",
    "Kenthurst, NSW 2156",
    "Kentlyn, NSW 2560",
    "Kents Lagoon, QLD 4309",
    "Kents Pocket, QLD 4310",
    "Kentucky, NSW 2354",
    "Kentucky South, NSW 2354",
    "Kentville, QLD 4341",
    "Kenwick, WA 6107",
    "Keon Park, VIC 3073",
    "Kepa, SA 5259",
    "Keperra, QLD 4054",
    "Kepnock, QLD 4670",
    "Keppel Sands, QLD 4702",
    "Keppoch, SA 5271",
    "Keralup, WA 6182",
    "Kerang, VIC 3579",
    "Kerang East, VIC 3579",
    "Kerewong, NSW 2439",
    "Kergunyah, VIC 3691",
    "Kergunyah South, VIC 3691",
    "Keri Keri, NSW 2711",
    "Kernot, VIC 3979",
    "Kerrabee, NSW 2328",
    "Kerrie, VIC 3434",
    "Kerrigundi, NSW 2835",
    "Kerrimuir, VIC 3129",
    "Kerrisdale, VIC 3660",
    "Kerrs Creek, NSW 2800",
    "Kerry, QLD 4285",
    "Kersbrook, SA 5231",
    "Keswick, SA 5035",
    "Keswick Terminal, SA 5035",
    "Kettering, TAS 7155",
    "Kevington, VIC 3723",
    "Kew, VIC 3101",
    "Kew, NSW 2439",
    "Kew East, VIC 3102",
    "Kewarra Beach, QLD 4879",
    "Kewdale, WA 6105",
    "Kewell, VIC 3390",
    "Keybarbin, NSW 2469",
    "Keyneton, SA 5353",
    "Keysborough, VIC 3173",
    "Keysbrook, WA 6126",
    "Keysland, QLD 4612",
    "Khancoban, NSW 2642",
    "Khatambuhl, NSW 2429",
    "Kholo, QLD 4306",
    "Khosh Bulduk, QLD 4723",
    "Ki Ki, SA 5261",
    "Kia Ora, NSW 2422",
    "Kia Ora, QLD 4570",
    "Kiacatoo, NSW 2877",
    "Kiah, NSW 2551",
    "Kialla, VIC 3631",
    "Kialla East, VIC 3631",
    "Kialla West, VIC 3631",
    "Kiama, NSW 2533",
    "Kiama Downs, NSW 2533",
    "Kiama Heights, NSW 2533",
    "Kiamba, QLD 4560",
    "Kiana, SA 5607",
    "Kianga, NSW 2546",
    "Kianga, QLD 4718",
    "Kiar, NSW 2259",
    "Kiara, WA 6054",
    "Kiata, VIC 3418",
    "Kickabil, NSW 2830",
    "Kidaman Creek, QLD 4574",
    "Kidman Park, SA 5025",
    "Kielpa, SA 5642",
    "Kiels Mountain, QLD 4559",
    "Kielvale, NSW 2484",
    "Kiewa, VIC 3691",
    "Kikiamah, NSW 2594",
    "Kikoira, NSW 2669",
    "Kilaben Bay, NSW 2283",
    "Kilbirnie, QLD 4354",
    "Kilburn, SA 5084",
    "Kilburn North, SA 5084",
    "Kilcoy, QLD 4515",
    "Kilcummin, QLD 4721",
    "Kilcunda, VIC 3995",
    "Kilgin, NSW 2472",
    "Kilgra, NSW 2474",
    "Kilkenny, SA 5009",
    "Kilkivan, QLD 4600",
    "Killabakh, NSW 2429",
    "Killaloe, QLD 4877",
    "Killara, VIC 3312",
    "Killara, NSW 2071",
    "Killara, VIC 3691",
    "Killarney, QLD 4373",
    "Killarney, VIC 3283",
    "Killarney Heights, NSW 2087",
    "Killarney Vale, NSW 2261",
    "Killawarra, VIC 3678",
    "Killawarra, NSW 2429",
    "Killcare, NSW 2257",
    "Killcare Heights, NSW 2257",
    "Killiecrankie, TAS 7255",
    "Killiekrankie, NSW 2449",
    "Killimicat, NSW 2720",
    "Killingworth, VIC 3717",
    "Killingworth, NSW 2278",
    "Killongbutta, NSW 2795",
    "Killora, TAS 7150",
    "Kilmany, VIC 3851",
    "Kilmore, VIC 3764",
    "Kilmore East, VIC 3764",
    "Kilsyth, VIC 3137",
    "Kilsyth South, VIC 3137",
    "Kimba, SA 5641",
    "Kimberley, TAS 7304",
    "Kimberley, QLD 4873",
    "Kimbolton, WA 6728",
    "Kimbolton, VIC 3551",
    "Kimbriki, NSW 2429",
    "Kin Kin, QLD 4571",
    "Kin Kora, QLD 4680",
    "Kinbombi, QLD 4601",
    "Kinchant Dam, QLD 4741",
    "Kinchela, NSW 2440",
    "Kincora, QLD 4356",
    "Kincumber, NSW 2251",
    "Kincumber South, NSW 2251",
    "Kindee, NSW 2446",
    "Kindervale, NSW 2622",
    "Kindon, QLD 4390",
    "Kindred, TAS 7310",
    "King Ash Bay, NT 0854",
    "King Creek, NSW 2446",
    "King Island, TAS 7256",
    "King Leopold Ranges, WA 6728",
    "King River, WA 6330",
    "King Scrub, QLD 4521",
    "King Valley, VIC 3678",
    "Kingaham, QLD 4515",
    "Kingaroy, QLD 4610",
    "Kingfisher Bay Resort, QLD 4655",
    "Kingfisher Shores, NSW 2259",
    "Kinghorne, NSW 2540",
    "Kinglake, VIC 3763",
    "Kinglake Central, VIC 3757",
    "Kinglake West, VIC 3757",
    "Kingoonya, SA 5710",
    "Kingower, VIC 3517",
    "Kings Beach, QLD 4551",
    "Kings Creek, QLD 4361",
    "Kings Cross, NSW 1340",
    "Kings Forest, NSW 2487",
    "Kings Langley, NSW 2147",
    "Kings Meadows, TAS 7249",
    "Kings Park, VIC 3021",
    "Kings Park, NSW 2148",
    "Kings Park, SA 5034",
    "Kings Park, WA 6005",
    "Kings Plains, NSW 2360",
    "Kings Plains, NSW 2799",
    "Kings Point, NSW 2539",
    "Kingsbury, VIC 3083",
    "Kingscliff, NSW 2487",
    "Kingscote, SA 5223",
    "Kingsdale, NSW 2580",
    "Kingsdene, NSW 2118",
    "Kingsford, NSW 2032",
    "Kingsford, WA 6701",
    "Kingsford, SA 5118",
    "Kingsgate, NSW 2370",
    "Kingsgrove, NSW 1480",
    "Kingsgrove, NSW 2208",
    "Kingsholme, QLD 4208",
    "Kingsley, WA 6026",
    "Kingsthorpe, QLD 4400",
    "Kingston, WA 6256",
    "Kingston, QLD 4114",
    "Kingston, VIC 3364",
    "Kingston, TAS 7050",
    "Kingston, TAS 7051",
    "Kingston, ACT 2604",
    "Kingston Beach, TAS 7050",
    "Kingston On Murray, SA 5331",
    "Kingston Park, SA 5049",
    "Kingston Se, SA 5275",
    "Kingstown, NSW 2358",
    "Kingsvale, NSW 2587",
    "Kingsville, VIC 3012",
    "Kingsville West, VIC 3012",
    "Kingsway, WA 6065",
    "Kingsway West, NSW 2208",
    "Kingswood, NSW 2550",
    "Kingswood, NSW 2747",
    "Kingswood, SA 5062",
    "Kingswood, NSW 2340",
    "Kinka Beach, QLD 4703",
    "Kinkuna, QLD 4670",
    "Kinleymore, QLD 4613",
    "Kinlyside, ACT 2913",
    "Kinnabulla, VIC 3483",
    "Kinross, WA 6028",
    "Kintore, NT 0872",
    "Kinypanial, VIC 3520",
    "Kioloa, NSW 2539",
    "Kioma, QLD 4498",
    "Kiora, NSW 2537",
    "Kippa-ring, QLD 4021",
    "Kippara, NSW 2441",
    "Kippax, ACT 2615",
    "Kippaxs, NSW 2429",
    "Kippenduff, NSW 2469",
    "Kirk Rock, WA 6370",
    "Kirkcaldy, SA 5022",
    "Kirkconnell, NSW 2795",
    "Kirkham, NSW 2570",
    "Kirknie, QLD 4806",
    "Kirkstall, VIC 3283",
    "Kirkwood, QLD 4680",
    "Kirra, QLD 4225",
    "Kirrama, QLD 4872",
    "Kirrawee, NSW 2232",
    "Kirribilli, NSW 2061",
    "Kirton Point, SA 5606",
    "Kirup, WA 6251",
    "Kirwan, QLD 4817",
    "Kirwans Bridge, VIC 3608",
    "Kitchener, NSW 2325",
    "Kithbrook, VIC 3666",
    "Kitoba, QLD 4605",
    "Kiwarrak, NSW 2430",
    "Kiwirrkurra, WA 0872",
    "Kleinton, QLD 4352",
    "Klemzig, SA 5087",
    "Klori, NSW 2346",
    "Knapp Creek, QLD 4285",
    "Knebsworth, VIC 3286",
    "Knights Hill, NSW 2577",
    "Knockrow, NSW 2479",
    "Knockwood, VIC 3723",
    "Knorrit Flat, NSW 2424",
    "Knorrit Forest, NSW 2424",
    "Knowsley, VIC 3523",
    "Knox City Centre, VIC 3152",
    "Knoxfield, VIC 3180",
    "Knuckey Lagoon, NT 0828",
    "Knuckey Lagoon, NT 0828",
    "Koah, QLD 4881",
    "Koallah, VIC 3260",
    "Kobble Creek, QLD 4520",
    "Koetong, VIC 3704",
    "Kogan, QLD 4406",
    "Kogarah, NSW 1485",
    "Kogarah, NSW 1487",
    "Kogarah, NSW 2217",
    "Kogarah Bay, NSW 2217",
    "Kohinoor, SA 5223",
    "Kojaneerup South, WA 6328",
    "Kojarena, WA 6532",
    "Kojonup, WA 6395",
    "Kokeby, WA 6304",
    "Kokotungo, QLD 4702",
    "Kolonga, QLD 4671",
    "Kolora, VIC 3265",
    "Kondinin, WA 6367",
    "Kondut, WA 6605",
    "Kongal, SA 5270",
    "Kongolia, SA 5353",
    "Kongorong, SA 5291",
    "Kongwak, VIC 3951",
    "Konnongorring, WA 6603",
    "Konongwootong, VIC 3315",
    "Koo Wee Rup, VIC 3981",
    "Koo Wee Rup North, VIC 3981",
    "Kooba, NSW 2680",
    "Koojan, WA 6510",
    "Kookabookra, NSW 2370",
    "Kookynie, WA 6431",
    "Koolan Island, WA 6733",
    "Koolanooka, WA 6623",
    "Koolewong, NSW 2256",
    "Koolgera, SA 5661",
    "Koolkhan, NSW 2460",
    "Kooloonong, VIC 3597",
    "Koolpinyah, NT 0822",
    "Koolunga, SA 5464",
    "Koolyanobbing, WA 6427",
    "Koolywurtie, SA 5575",
    "Koomberkine, WA 6461",
    "Koombooloomba, QLD 4872",
    "Koonawarra, NSW 2530",
    "Koonda, VIC 3669",
    "Koondoola, WA 6064",
    "Koondrook, VIC 3580",
    "Koongal, QLD 4701",
    "Koongamia, WA 6056",
    "Koongawa, SA 5650",
    "Koonibba, SA 5690",
    "Koonoomoo, VIC 3644",
    "Koonoona, SA 5417",
    "Koonorigan, NSW 2480",
    "Koonunga, SA 5373",
    "Koonwarra, VIC 3954",
    "Koonya, TAS 7187",
    "Koonyum Range, NSW 2482",
    "Kooragang, NSW 2304",
    "Koorainghat, NSW 2430",
    "Kooralbyn, QLD 4285",
    "Kooralgin, QLD 4402",
    "Koorawatha, NSW 2807",
    "Koorda, WA 6475",
    "Kooreh, VIC 3477",
    "Koorine, SA 5279",
    "Kooringal, NSW 2650",
    "Kooringal, QLD 4025",
    "Koorlong, VIC 3501",
    "Koornalla, VIC 3844",
    "Kooroocheang, VIC 3364",
    "Koorool, VIC 3860",
    "Koorooman, VIC 3953",
    "Kooroomool, QLD 4854",
    "Kooroona, SA 5558",
    "Kooroongarra, QLD 4357",
    "Kootingal, NSW 2352",
    "Kooyong, VIC 3144",
    "Koppamurra, SA 5271",
    "Koppio, SA 5607",
    "Koraleigh, NSW 2735",
    "Korbel, WA 6415",
    "Kordabup, WA 6333",
    "Koreelah, NSW 2476",
    "Koriella, VIC 3714",
    "Korobeit, VIC 3341",
    "Koroit, VIC 3282",
    "Korong Vale, VIC 3520",
    "Koroop, VIC 3579",
    "Korora, NSW 2450",
    "Korrelocking, WA 6485",
    "Korumburra, VIC 3950",
    "Korumburra South, VIC 3950",
    "Korunye, SA 5502",
    "Korweinguboora, VIC 3461",
    "Kosciuszko, NSW 2627",
    "Kosciuszko National Park, NSW 2627",
    "Kotara, NSW 2289",
    "Kotara East, NSW 2305",
    "Kotara Fair, NSW 2289",
    "Kotara South, NSW 2289",
    "Kotta, VIC 3565",
    "Kotupna, VIC 3638",
    "Koumala, QLD 4738",
    "Kowanyama, QLD 4871",
    "Kowen Forest, ACT 2620",
    "Kowguran, QLD 4415",
    "Kowrowa, QLD 4872",
    "Koyuga, VIC 3622",
    "Krambach, NSW 2429",
    "Krawarree, NSW 2622",
    "Kremnos, NSW 2460",
    "Kringin, SA 5304",
    "Krondorf, SA 5352",
    "Krongart, SA 5278",
    "Kronkup, WA 6330",
    "Krowera, VIC 3945",
    "Kubin Village, QLD 4875",
    "Kubura, NSW 2655",
    "Kudardup, WA 6290",
    "Kudla, SA 5115",
    "Kuender, WA 6353",
    "Kuitpo, SA 5172",
    "Kuitpo Colony, SA 5172",
    "Kukerin, WA 6352",
    "Kulangoor, QLD 4560",
    "Kulgera, NT 0872",
    "Kulgun, QLD 4309",
    "Kulikup, WA 6244",
    "Kulin, WA 6365",
    "Kulin West, WA 6365",
    "Kulja, WA 6470",
    "Kulkami, SA 5307",
    "Kullogum, QLD 4660",
    "Kulnura, NSW 2250",
    "Kulpara, SA 5555",
    "Kulpi, QLD 4352",
    "Kuluin, QLD 4558",
    "Kulwin, VIC 3490",
    "Kulwin, NSW 2835",
    "Kumarina, WA 6642",
    "Kumbia, QLD 4610",
    "Kunama, NSW 2730",
    "Kunat, VIC 3585",
    "Kunda Park, QLD 4556",
    "Kundabung, NSW 2441",
    "Kundibakh, NSW 2429",
    "Kundle Kundle, NSW 2430",
    "Kungala, NSW 2460",
    "Kunghur, NSW 2484",
    "Kunghur Creek, NSW 2484",
    "Kunioon, QLD 4615",
    "Kunjin, WA 6375",
    "Kunparrka, NT 0872",
    "Kununoppin, WA 6489",
    "Kununurra, WA 6743",
    "Kunwarara, QLD 4702",
    "Kunyung, VIC 3930",
    "Kuraby, QLD 4112",
    "Kuranda, QLD 4881",
    "Kureelpa, QLD 4560",
    "Kureen, QLD 4885",
    "Kuridala, QLD 4824",
    "Kurmond, NSW 2757",
    "Kurnalpi, WA 6431",
    "Kurnell, NSW 2231",
    "Kurraba Point, NSW 2089",
    "Kurraca, VIC 3518",
    "Kurraca West, VIC 3518",
    "Kurrajong, NSW 2758",
    "Kurrajong Heights, NSW 2758",
    "Kurrajong Hills, NSW 2758",
    "Kurralta Park, SA 5037",
    "Kurrenkutten, WA 6375",
    "Kurri Kurri, NSW 2327",
    "Kurrimine Beach, QLD 4871",
    "Kurrowah, QLD 4352",
    "Kurting, VIC 3517",
    "Kurumbul, QLD 4388",
    "Kurunjang, VIC 3337",
    "Kurwongbah, QLD 4503",
    "Kuttabul, QLD 4741",
    "Kweda, WA 6306",
    "Kwelkan, WA 6490",
    "Kwinana, WA 6966",
    "Kwinana Beach, WA 6167",
    "Kwinana Town Centre, WA 6167",
    "Kwolyin, WA 6385",
    "Kyabram, VIC 3619",
    "Kyabram, VIC 3620",
    "Kyabram South, VIC 3620",
    "Kyalite, NSW 2715",
    "Kyancutta, SA 5651",
    "Kyarran, NSW 2460",
    "Kybeyan, NSW 2631",
    "Kybong, QLD 4570",
    "Kybunga, SA 5453",
    "Kybybolite, SA 5262",
    "Kyeamba, NSW 2650",
    "Kyeema, SA 5172",
    "Kyeemagh, NSW 2216",
    "Kyle Bay, NSW 2221",
    "Kyneton, VIC 3444",
    "Kyneton South, VIC 3444",
    "Kynnumboon, NSW 2484",
    "Kynuna, QLD 4823",
    "Kyogle, NSW 2474",
    "Kyoomba, QLD 4380",
    "Kyvalley, VIC 3621",
    "Kywong, NSW 2700",
    "La Perouse, NSW 2036",
    "La Trobe University, VIC 3083",
    "La Trobe University, VIC 3086",
    "Laanecoorie, VIC 3463",
    "Laang, VIC 3265",
    "Labertouche, VIC 3816",
    "Labrador, QLD 4215",
    "Laburnum, VIC 3130",
    "Laceby, VIC 3678",
    "Laceys Creek, QLD 4521",
    "Lachlan, TAS 7140",
    "Lackrana, TAS 7255",
    "Lacmalac, NSW 2720",
    "Lade Vale, NSW 2581",
    "Lady Barron, TAS 7255",
    "Ladys Pass, VIC 3523",
    "Ladysmith, NSW 2652",
    "Laen, VIC 3480",
    "Laen East, VIC 3480",
    "Laen North, VIC 3480",
    "Laffer, SA 5267",
    "Laffing Waters, NSW 2795",
    "Laggan, NSW 2583",
    "Lagoon Grass, NSW 2480",
    "Lagoon Pocket, QLD 4570",
    "Lagrange, WA 6725",
    "Laguna, NSW 2325",
    "Laguna Quays, QLD 4800",
    "Lah, VIC 3393",
    "Laharum, VIC 3401",
    "Laidley, QLD 4341",
    "Laidley Creek West, QLD 4341",
    "Laidley Heights, QLD 4341",
    "Laidley North, QLD 4341",
    "Laidley South, QLD 4341",
    "Lajamanu, NT 0852",
    "Lake Albert, NSW 2650",
    "Lake Argyle, WA 6743",
    "Lake Austin, WA 6640",
    "Lake Barrine, QLD 4884",
    "Lake Bathurst, NSW 2580",
    "Lake Bennett, NT 0822",
    "Lake Bennett, NT 0822",
    "Lake Biddy, WA 6355",
    "Lake Boga, VIC 3584",
    "Lake Bolac, VIC 3351",
    "Lake Borumba, QLD 4570",
    "Lake Brewster, NSW 2675",
    "Lake Brown, WA 6479",
    "Lake Buloke, VIC 3480",
    "Lake Bunga, VIC 3909",
    "Lake Burrendong, NSW 2820",
    "Lake Camm, WA 6355",
    "Lake Cargelligo, NSW 2672",
    "Lake Carlet, SA 5238",
    "Lake Carnegie, WA 6646",
    "Lake Cathie, NSW 2445",
    "Lake Charm, VIC 3581",
    "Lake Clarendon, QLD 4343",
    "Lake Clifton, WA 6215",
    "Lake Condah, VIC 3303",
    "Lake Conjola, NSW 2539",
    "Lake Cowal, NSW 2671",
    "Lake Darlot, WA 6438",
    "Lake Deborah, WA 6484",
    "Lake Eacham, QLD 4884",
    "Lake Eildon, VIC 3713",
    "Lake Eppalock, VIC 3551",
    "Lake Fyans, VIC 3381",
    "Lake Gardens, VIC 3355",
    "Lake George, NSW 2581",
    "Lake Goldsmith, VIC 3373",
    "Lake Grace, WA 6353",
    "Lake Haven, NSW 2263",
    "Lake Heights, NSW 2502",
    "Lake Hiawatha, NSW 2462",
    "Lake Hinds, WA 6603",
    "Lake Hume Village, NSW 3691",
    "Lake Illawarra, NSW 2528",
    "Lake Innes, NSW 2446",
    "Lake Jasper, WA 6260",
    "Lake King, WA 6356",
    "Lake Leake, TAS 7210",
    "Lake Lonsdale, VIC 3381",
    "Lake Macdonald, QLD 4563",
    "Lake Mackay, NT 0872",
    "Lake Manchester, QLD 4306",
    "Lake Margaret, TAS 7467",
    "Lake Margarette, WA 6475",
    "Lake Marmal, VIC 3525",
    "Lake Mary, QLD 4703",
    "Lake Meran, VIC 3579",
    "Lake Monduran, QLD 4671",
    "Lake Moodemere, VIC 3685",
    "Lake Muir, WA 6258",
    "Lake Mundi, VIC 3312",
    "Lake Munmorah, NSW 2259",
    "Lake Ninan, WA 6603",
    "Lake Plains, SA 5255",
    "Lake Powell, VIC 3597",
    "Lake Proserpine, QLD 4800",
    "Lake Rowan, VIC 3727",
    "Lake Sorell, TAS 7030",
    "Lake St Clair, TAS 7140",
    "Lake Tabourie, NSW 2539",
    "Lake Tinaroo, QLD 4872",
    "Lake Toolbrunup, WA 6320",
    "Lake Tyers, VIC 3887",
    "Lake Tyers Beach, VIC 3909",
    "Lake Tyrrell, VIC 3533",
    "Lake View, SA 5555",
    "Lake Wellington, VIC 3851",
    "Lake Wells, WA 6440",
    "Lake Wendouree, VIC 3350",
    "Lake Wongan, VIC 3373",
    "Lake Wyangan, NSW 2680",
    "Lakefield, QLD 4871",
    "Lakeland, QLD 4871",
    "Lakeland Downs, QLD 4871",
    "Lakelands, WA 6180",
    "Lakelands, NSW 2282",
    "Lakemba, NSW 2195",
    "Lakes Creek, QLD 4701",
    "Lakes Entrance, VIC 3909",
    "Lakeside, QLD 4621",
    "Lakesland, NSW 2572",
    "Lakewood, NSW 2443",
    "Lakewood, WA 6431",
    "Lal Lal, VIC 3352",
    "Lalalty, NSW 3644",
    "Lalbert, VIC 3542",
    "Lalla, TAS 7267",
    "Lalor, VIC 3075",
    "Lalor Park, NSW 2147",
    "Lalor Plaza, VIC 3075",
    "Lamb Island, QLD 4184",
    "Lamb Range, QLD 4870",
    "Lambells Lagoon, NT 0822",
    "Lambs Valley, NSW 2335",
    "Lambs Valley, NSW 2370",
    "Lambton, NSW 2299",
    "Lameroo, SA 5302",
    "Lamington, WA 6430",
    "Lamington, QLD 4285",
    "Lamington National Park, QLD 4275",
    "Lammermoor, QLD 4703",
    "Lamplough, VIC 3352",
    "Lancaster, VIC 3620",
    "Lance Creek, VIC 3995",
    "Lancefield, VIC 3435",
    "Lancelin, WA 6044",
    "Landers Shoot, QLD 4555",
    "Landervale, NSW 2652",
    "Landsborough, QLD 4550",
    "Landsborough, VIC 3384",
    "Landsborough West, VIC 3384",
    "Landsdale, WA 6065",
    "Lane Cove, NSW 2066",
    "Lane Cove, NSW 1595",
    "Lane Cove, NSW 1597",
    "Lane Cove North, NSW 2066",
    "Lane Cove West, NSW 2066",
    "Lanefield, QLD 4340",
    "Lanena, TAS 7275",
    "Lang Lang, VIC 3984",
    "Lang Lang East, VIC 3984",
    "Langdons Hill, VIC 3363",
    "Lange, WA 6330",
    "Langford, WA 6147",
    "Langhorne Creek, SA 5255",
    "Langi Kal Kal, VIC 3352",
    "Langi Logan, VIC 3377",
    "Langkoop, VIC 3318",
    "Langley, QLD 4630",
    "Langley, VIC 3444",
    "Langley Vale, NSW 2426",
    "Langlo, QLD 4470",
    "Langs Landing, SA 5354",
    "Langsborough, VIC 3971",
    "Langshaw, QLD 4570",
    "Langwarrin, VIC 3910",
    "Langwarrin South, VIC 3911",
    "Langwarrin South, VIC 3910",
    "Lanitza, NSW 2460",
    "Lankeys Creek, NSW 2644",
    "Lannercost, QLD 4850",
    "Lansdowne, NSW 2163",
    "Lansdowne, QLD 4478",
    "Lansdowne, NSW 2430",
    "Lansdowne, NT 0850",
    "Lansdowne Forest, NSW 2430",
    "Lansell Plaza, VIC 3555",
    "Lanskey, QLD 4825",
    "Lansvale, NSW 2166",
    "Lapoinya, TAS 7325",
    "Lapstone, NSW 2773",
    "Lara, VIC 3212",
    "Laramba, NT 0872",
    "Larapinta, NT 0870",
    "Larapinta, QLD 4110",
    "Laravale, QLD 4285",
    "Larbert, NSW 2622",
    "Lardner, VIC 3821",
    "Largs, NSW 2320",
    "Largs Bay, SA 5016",
    "Largs North, SA 5016",
    "Lark Hill, QLD 4306",
    "Larnook, NSW 2480",
    "Larpent, VIC 3249",
    "Larrakeyah, NT 0820",
    "Larrakeyah, NT 0820",
    "Larralea, VIC 3325",
    "Larras Lee, NSW 2866",
    "Larrimah, NT 0852",
    "Lascelles, VIC 3487",
    "Latham, ACT 2615",
    "Latham, WA 6616",
    "Lathlain, WA 6100",
    "Latrobe, TAS 7307",
    "Lauderdale, TAS 7021",
    "Laughtondale, NSW 2775",
    "Launceston, TAS 7901",
    "Launceston, TAS 7902",
    "Launceston, TAS 7903",
    "Launceston, TAS 7904",
    "Launceston, TAS 7905",
    "Launceston, TAS 7906",
    "Launceston, TAS 7907",
    "Launceston, TAS 7908",
    "Launceston, TAS 7909",
    "Launceston, TAS 7922",
    "Launceston, TAS 7923",
    "Launceston, TAS 7250",
    "Launching Place, VIC 3139",
    "Laura, QLD 4871",
    "Laura, SA 5480",
    "Laura Bay, SA 5680",
    "Laurel Hill, NSW 2649",
    "Laurie Park, SA 5271",
    "Laurieton, NSW 2443",
    "Lauriston, VIC 3444",
    "Lavadia, NSW 2462",
    "Lavelle, QLD 4357",
    "Lavender Bay, NSW 2060",
    "Lavers Hill, VIC 3238",
    "Laverstock, NSW 2582",
    "Laverton, VIC 3028",
    "Laverton, WA 6440",
    "Laverton North, VIC 3026",
    "Lavington, NSW 2641",
    "Law Courts, VIC 8010",
    "Lawes, QLD 4343",
    "Lawgi Dawes, QLD 4716",
    "Lawitta, TAS 7140",
    "Lawler, VIC 3480",
    "Lawloit, VIC 3418",
    "Lawn Hill, QLD 4825",
    "Lawnton, QLD 4501",
    "Lawrence, NSW 2460",
    "Lawrence, VIC 3364",
    "Lawson, NSW 2783",
    "Lawson, ACT 2617",
    "Leabrook, SA 5068",
    "Leadville, NSW 2844",
    "Leafdale, QLD 4606",
    "Leaghur, VIC 3537",
    "Leanyer, NT 0812",
    "Leanyer, NT 0812",
    "Learmonth, VIC 3352",
    "Learmonth, WA 6707",
    "Leasingham, SA 5452",
    "Leawood Gardens, SA 5150",
    "Lebrina, TAS 7254",
    "Leconfield, NSW 2335",
    "Leda, WA 6170",
    "Ledcourt, VIC 3385",
    "Ledge Point, WA 6043",
    "Lee Creek, NSW 2849",
    "Lee Point, NT 0810",
    "Leederville, WA 6900",
    "Leederville, WA 6902",
    "Leederville, WA 6903",
    "Leederville, WA 6007",
    "Leeka, TAS 7255",
    "Leeman, WA 6514",
    "Leeming, WA 6149",
    "Leeton, NSW 2705",
    "Leets Vale, NSW 2775",
    "Leeuwin, WA 6290",
    "Leeville, NSW 2470",
    "Lefroy, TAS 7252",
    "Lefthand Branch, QLD 4343",
    "Legana, TAS 7277",
    "Legerwood, TAS 7263",
    "Legume, NSW 2476",
    "Leichardt, VIC 3516",
    "Leichhardt, NSW 2040",
    "Leichhardt, QLD 4305",
    "Leigh Creek, VIC 3352",
    "Leigh Creek, SA 5731",
    "Leighton, SA 5417",
    "Leinster, WA 6437",
    "Leitchville, VIC 3567",
    "Leith, TAS 7315",
    "Lemington, NSW 2330",
    "Lemnos, VIC 3631",
    "Lemon Tree, NSW 2259",
    "Lemon Tree Passage, NSW 2319",
    "Lemont, TAS 7120",
    "Lemontree, QLD 4357",
    "Len Waters Estate, NSW 2171",
    "Lenaghan, NSW 2322",
    "Lenah Valley, TAS 7008",
    "Leneva, VIC 3691",
    "Lennard Brook, WA 6503",
    "Lennox Head, NSW 2478",
    "Lenswood, SA 5240",
    "Leonards Hill, VIC 3461",
    "Leonay, NSW 2750",
    "Leongatha, VIC 3953",
    "Leongatha North, VIC 3953",
    "Leongatha South, VIC 3953",
    "Leonora, WA 6438",
    "Leopold, VIC 3224",
    "Leppington, NSW 2179",
    "Lerderderg, VIC 3458",
    "Lerida, NSW 2581",
    "Lerida, NSW 2835",
    "Leschenault, WA 6233",
    "Lesley, WA 6111",
    "Leslie, QLD 4370",
    "Leslie Dam, QLD 4370",
    "Leslie Manor, VIC 3260",
    "Leslie Vale, TAS 7054",
    "Lesmurdie, WA 6076",
    "Lethbridge, VIC 3332",
    "Lethbridge Park, NSW 2770",
    "Lethebrook, QLD 4800",
    "Leumeah, NSW 2560",
    "Leura, NSW 2780",
    "Levendale, TAS 7120",
    "Levenstrath, NSW 2460",
    "Lewinsbrook, NSW 2311",
    "Lewis Ponds, NSW 2800",
    "Lewisham, TAS 7173",
    "Lewisham, NSW 2049",
    "Lewiston, SA 5501",
    "Lexia, WA 6079",
    "Lexton, VIC 3352",
    "Leyburn, QLD 4365",
    "Leycester, NSW 2480",
    "Leydens Hill, QLD 4714",
    "Liawenee, TAS 7030",
    "Liberty Grove, NSW 2138",
    "Licola, VIC 3858",
    "Licola North, VIC 3858",
    "Lidcombe, NSW 2141",
    "Lidcombe, NSW 1825",
    "Lidcombe, NSW 1826",
    "Lidcombe North, NSW 2141",
    "Liddell, NSW 2333",
    "Lidsdale, NSW 2790",
    "Lidster, NSW 2800",
    "Liena, TAS 7304",
    "Lietinna, TAS 7260",
    "Liffey, TAS 7301",
    "Light Pass, SA 5355",
    "Lighthouse Beach, NSW 2444",
    "Lightning Ridge, NSW 2834",
    "Lightsview, SA 5085",
    "Lileah, TAS 7330",
    "Lilli Pilli, NSW 2229",
    "Lilli Pilli, NSW 2536",
    "Lillian Rock, NSW 2480",
    "Lillico, VIC 3820",
    "Lillico, TAS 7310",
    "Lillicur, VIC 3371",
    "Lillimur, VIC 3420",
    "Lilliput, VIC 3682",
    "Lilydale, TAS 7268",
    "Lilydale, NSW 2460",
    "Lilydale, VIC 3140",
    "Lilydale, QLD 4344",
    "Lilyfield, NSW 2040",
    "Lilyvale, NSW 2508",
    "Lilyvale, QLD 4723",
    "Lilyvale, QLD 4352",
    "Lima, VIC 3673",
    "Lima East, VIC 3673",
    "Lima South, VIC 3673",
    "Limbri, NSW 2352",
    "Lime Lake, WA 6315",
    "Limeburners Creek, NSW 2444",
    "Limeburners Creek, NSW 2324",
    "Limekilns, NSW 2795",
    "Limerick, NSW 2583",
    "Limestone, VIC 3717",
    "Limestone, NSW 2361",
    "Limestone, QLD 4714",
    "Limestone Creek, QLD 4701",
    "Limestone Ridges, QLD 4305",
    "Limevale, QLD 4384",
    "Limmen, NT 0852",
    "Limpinwood, NSW 2484",
    "Linburn, NSW 2850",
    "Lincoln National Park, SA 5607",
    "Lindeman Island, QLD 4741",
    "Linden, NSW 2778",
    "Linden, QLD 4490",
    "Linden Park, SA 5065",
    "Lindendale, NSW 2480",
    "Lindenow, VIC 3865",
    "Lindenow South, VIC 3875",
    "Lindesay, NSW 2347",
    "Lindesay Creek, NSW 2476",
    "Lindfield, NSW 2070",
    "Lindfield West, NSW 2070",
    "Lindifferon, NSW 2710",
    "Lindisfarne, TAS 7015",
    "Lindley, SA 5320",
    "Lindsay, VIC 3312",
    "Lindsay Point, VIC 3496",
    "Linfarne, WA 6258",
    "Linga, VIC 3509",
    "Linley Point, NSW 2066",
    "Linthorpe, QLD 4356",
    "Linton, VIC 3360",
    "Linville, QLD 4306",
    "Linwood, SA 5410",
    "Lionsville, NSW 2460",
    "Liparoo, VIC 3549",
    "Lipson, SA 5607",
    "Lisarow, NSW 2250",
    "Lisle, TAS 7260",
    "Lismore, NSW 2480",
    "Lismore, VIC 3324",
    "Lismore Heights, NSW 2480",
    "Lissner, QLD 4820",
    "Liston, NSW 2372",
    "Litchfield, VIC 3480",
    "Litchfield Park, NT 0822",
    "Lithgow, NSW 2790",
    "Little Back Creek, NSW 2474",
    "Little Bay, NSW 2036",
    "Little Billabong, NSW 2644",
    "Little Desert, VIC 3418",
    "Little Douglas, SA 5607",
    "Little Forest, NSW 2538",
    "Little Grove, WA 6330",
    "Little Hampton, VIC 3458",
    "Little Hartley, NSW 2790",
    "Little Italy, WA 6355",
    "Little Jacks Creek, NSW 2339",
    "Little Jilliby, NSW 2259",
    "Little Lonsdale Street, VIC 8011",
    "Little Mountain, QLD 4551",
    "Little Mulgrave, QLD 4865",
    "Little Pelican, NSW 2281",
    "Little Pine Lagoon, TAS 7140",
    "Little Plain, NSW 2360",
    "Little River, NSW 2720",
    "Little River, NSW 2868",
    "Little River, VIC 3211",
    "Little Sandy Desert, WA 6646",
    "Little Swanport, TAS 7190",
    "Little Topar, NSW 2880",
    "Little Wobby, NSW 2256",
    "Littlehampton, SA 5250",
    "Littleton, NSW 2790",
    "Liverpool, NSW 2170",
    "Liverpool, NSW 1871",
    "Liverpool South, NSW 2170",
    "Liverpool Westfield, NSW 2170",
    "Livingstone, NT 0822",
    "Lizard, QLD 4871",
    "Llanarth, NSW 2795",
    "Llanarth, QLD 4820",
    "Llandilo, NSW 2747",
    "Llanelly, VIC 3551",
    "Llangothlin, NSW 2365",
    "Llowalong, VIC 3862",
    "Lloyd, NSW 2650",
    "Lloyd Creek, NT 0822",
    "Loadstone, NSW 2474",
    "Lobethal, SA 5241",
    "Loccota, TAS 7255",
    "Loch, VIC 3945",
    "Loch Lomond, QLD 4370",
    "Loch Sport, VIC 3851",
    "Loch Valley, VIC 3833",
    "Lochaber, SA 5271",
    "Lochiel, NSW 2549",
    "Lochiel, SA 5510",
    "Lochinvar, NSW 2321",
    "Lock, SA 5633",
    "Lockhart, QLD 4871",
    "Lockhart, NSW 2656",
    "Lockier, WA 6522",
    "Lockington, VIC 3563",
    "Lockleys, SA 5032",
    "Lockridge, WA 6054",
    "Lockrose, QLD 4342",
    "Locksley, VIC 3665",
    "Locksley, NSW 2795",
    "Lockwood, VIC 3551",
    "Lockwood South, VIC 3551",
    "Lockyer, WA 6330",
    "Lockyer, QLD 4344",
    "Lockyer Waters, QLD 4311",
    "Loddon Vale, VIC 3575",
    "Loftus, NSW 2232",
    "Loftville, NSW 2480",
    "Logan, VIC 3475",
    "Logan Central, QLD 4114",
    "Logan City Bc, QLD 4114",
    "Logan Reserve, QLD 4133",
    "Logan Village, QLD 4207",
    "Loganholme, QLD 4129",
    "Loganholme Bc, QLD 4129",
    "Loganlea, QLD 4131",
    "Logans Crossing, NSW 2439",
    "Logie Brae, NSW 2713",
    "Loira, TAS 7275",
    "Lol Gray, WA 6311",
    "London Lakes, TAS 7140",
    "Londonderry, WA 6429",
    "Londonderry, NSW 2753",
    "Londrigan, VIC 3678",
    "Lonesome Creek, QLD 4719",
    "Long Beach, NSW 2536",
    "Long Flat, NSW 2446",
    "Long Flat, SA 5253",
    "Long Flat, QLD 4570",
    "Long Forest, VIC 3340",
    "Long Gully, VIC 3550",
    "Long Island, QLD 4741",
    "Long Jetty, NSW 2261",
    "Long Plain, NSW 2360",
    "Long Plain, NSW 2629",
    "Long Plains, SA 5501",
    "Long Pocket, QLD 4850",
    "Long Point, NSW 2330",
    "Long Point, NSW 2564",
    "Long Reach, TAS 7253",
    "Longarm, NSW 2347",
    "Longerenong, VIC 3401",
    "Longford, TAS 7301",
    "Longford, VIC 3851",
    "Longlea, VIC 3551",
    "Longley, TAS 7150",
    "Longreach, QLD 4730",
    "Longreach, NSW 2540",
    "Longueville, NSW 2066",
    "Longwarry, VIC 3816",
    "Longwarry North, VIC 3816",
    "Longwood, VIC 3665",
    "Longwood, SA 5153",
    "Longwood East, VIC 3666",
    "Lonnavale, TAS 7109",
    "Lonsdale, SA 5160",
    "Loombah, NSW 2867",
    "Loomberah, NSW 2340",
    "Loongana, TAS 7315",
    "Loorana, TAS 7256",
    "Lord Howe Island, NSW 2898",
    "Lords Hill, NSW 2632",
    "Lorinna, TAS 7306",
    "Lorn, NSW 2320",
    "Lorne, VIC 3232",
    "Lorne, NSW 2439",
    "Lorquon, VIC 3418",
    "Lort River, WA 6447",
    "Lost River, NSW 2583",
    "Lostock, NSW 2311",
    "Lota, QLD 4179",
    "Lottah, TAS 7216",
    "Lotus Creek, QLD 4705",
    "Louisa Creek, NSW 2469",
    "Louth, NSW 2840",
    "Louth Bay, SA 5607",
    "Louth Park, NSW 2320",
    "Lovedale, NSW 2325",
    "Loveday, SA 5345",
    "Lovely Banks, VIC 3221",
    "Lovett Bay, NSW 2105",
    "Low Head, TAS 7253",
    "Low Isles, QLD 4873",
    "Lowan Vale, SA 5268",
    "Lowanna, NSW 2450",
    "Lowbank, SA 5330",
    "Lowden, WA 6240",
    "Lower Acacia Creek, NSW 2476",
    "Lower Bago, NSW 2730",
    "Lower Barrington, TAS 7306",
    "Lower Beechmont, QLD 4211",
    "Lower Belford, NSW 2335",
    "Lower Beulah, TAS 7306",
    "Lower Boro, NSW 2580",
    "Lower Bottle Creek, NSW 2469",
    "Lower Broughton, SA 5522",
    "Lower Chittering, WA 6084",
    "Lower Cowley, QLD 4871",
    "Lower Creek, NSW 2440",
    "Lower Cressbrook, QLD 4313",
    "Lower Daintree, QLD 4873",
    "Lower Duck Creek, NSW 2469",
    "Lower Dyraaba, NSW 2470",
    "Lower Hermitage, SA 5131",
    "Lower Hotham, WA 6390",
    "Lower Inman Valley, SA 5211",
    "Lower King, WA 6330",
    "Lower Lewis Ponds, NSW 2800",
    "Lower Light, SA 5501",
    "Lower Longley, TAS 7109",
    "Lower Macdonald, NSW 2775",
    "Lower Mangrove, NSW 2250",
    "Lower Marshes, TAS 7030",
    "Lower Mitcham, SA 5062",
    "Lower Moira, VIC 3639",
    "Lower Mount Walker, QLD 4340",
    "Lower Norton, VIC 3401",
    "Lower Pappinbarra, NSW 2446",
    "Lower Peacock, NSW 2469",
    "Lower Plenty, VIC 3093",
    "Lower Portland, NSW 2756",
    "Lower Sandy Bay, TAS 7005",
    "Lower Snug, TAS 7054",
    "Lower Southgate, NSW 2460",
    "Lower Tenthill, QLD 4343",
    "Lower Tully, QLD 4854",
    "Lower Turners Marsh, TAS 7267",
    "Lower Wattle Grove, TAS 7109",
    "Lower Wilmot, TAS 7310",
    "Lower Wonga, QLD 4570",
    "Lowesby, QLD 4702",
    "Lowesdale, NSW 2646",
    "Lowestoff, QLD 4723",
    "Lowlands, WA 6330",
    "Lowmead, QLD 4676",
    "Lowood, QLD 4311",
    "Lowther, NSW 2790",
    "Loxford, NSW 2326",
    "Loxton, SA 5333",
    "Loxton North, SA 5333",
    "Loy Yang, VIC 3844",
    "Loyetea, TAS 7316",
    "Lubeck, VIC 3385",
    "Lucas Heights, NSW 2234",
    "Lucaston, TAS 7109",
    "Lucinda, QLD 4850",
    "Lucindale, SA 5272",
    "Lucknow, VIC 3875",
    "Lucknow, NSW 2800",
    "Lucky Bay, SA 5602",
    "Lucyvale, VIC 3691",
    "Luddenham, NSW 2745",
    "Ludlow, WA 6280",
    "Ludmilla, NT 0820",
    "Lue, NSW 2850",
    "Lugarno, NSW 2210",
    "Lughrata, TAS 7255",
    "Luina, TAS 7321",
    "Lulworth, TAS 7252",
    "Lumeah, QLD 4478",
    "Lumeah, WA 6395",
    "Lumholtz, QLD 4849",
    "Lunawanna, TAS 7150",
    "Lundavra, QLD 4390",
    "Lune River, TAS 7109",
    "Lurg, VIC 3673",
    "Lurnea, NSW 2170",
    "Luscombe, QLD 4207",
    "Luskintyre, NSW 2321",
    "Lutana, TAS 7009",
    "Lutwyche, QLD 4030",
    "Lyal, VIC 3444",
    "Lyalls Mill, WA 6225",
    "Lygon Street North, VIC 3057",
    "Lymington, TAS 7109",
    "Lymwood, TAS 7256",
    "Lynam, QLD 4818",
    "Lynbrook, VIC 3975",
    "Lynchs Creek, NSW 2474",
    "Lyndhurst, QLD 4871",
    "Lyndhurst, VIC 3975",
    "Lyndhurst, SA 5731",
    "Lyndhurst, NSW 2797",
    "Lyndhurst, NSW 2350",
    "Lyndoch, SA 5351",
    "Lyndon, WA 6701",
    "Lyndside, QLD 4871",
    "Lyneham, ACT 2602",
    "Lynford, QLD 4342",
    "Lynton, SA 5062",
    "Lynwood, WA 6147",
    "Lynwood, NSW 2477",
    "Lyons, NT 0810",
    "Lyons, VIC 3304",
    "Lyons, QLD 4124",
    "Lyons, ACT 2606",
    "Lyons, NT 0810",
    "Lyonville, VIC 3461",
    "Lyra, QLD 4382",
    "Lyrup, SA 5343",
    "Lysterfield, VIC 3156",
    "Lysterfield South, VIC 3156",
    "Lytton, QLD 4178",
    "Ma Ma Creek, QLD 4347",
    "Maadi, QLD 4855",
    "Maalan, QLD 4886",
    "Maaoupe, SA 5277",
    "Maaroom, QLD 4650",
    "Mabins Well, NSW 2716",
    "Mabuiag Island, QLD 4875",
    "Macalister, QLD 4406",
    "Macalister Range, QLD 4871",
    "Macarthur, ACT 2904",
    "Macarthur, VIC 3286",
    "Macarthur Square, NSW 2560",
    "Macclesfield, SA 5153",
    "Macclesfield, VIC 3782",
    "Macdonald Park, SA 5121",
    "Macdonalds Creek, NSW 2339",
    "Macedon, VIC 3440",
    "Macfarlane, QLD 4478",
    "Macgillivray, SA 5223",
    "Macgregor, QLD 4109",
    "Macgregor, ACT 2615",
    "Machans Beach, QLD 4878",
    "Machine Creek, QLD 4695",
    "Mackay, QLD 4740",
    "Mackay, QLD 9943",
    "Mackay Bc, QLD 4740",
    "Mackay Caneland, QLD 4740",
    "Mackay Harbour, QLD 4740",
    "Mackay North, QLD 4740",
    "Mackay South, QLD 4740",
    "Mackay West, QLD 4740",
    "Mackenzie, QLD 4702",
    "Mackenzie, QLD 4156",
    "Mackenzie River, QLD 4705",
    "Macknade, QLD 4850",
    "Macks Creek, VIC 3971",
    "Macksville, NSW 2447",
    "Maclagan, QLD 4352",
    "Maclean, NSW 2463",
    "Macleay Island, QLD 4184",
    "Macleod, WA 6701",
    "Macleod, VIC 3085",
    "Macleod West, VIC 3085",
    "Macmasters Beach, NSW 2251",
    "Macorna, VIC 3579",
    "Macorna North, VIC 3568",
    "Macquarie, TAS 7151",
    "Macquarie, ACT 2614",
    "Macquarie Centre, NSW 2113",
    "Macquarie Fields, NSW 2564",
    "Macquarie Heads, TAS 7468",
    "Macquarie Hills, NSW 2285",
    "Macquarie Island, TAS 7151",
    "Macquarie Links, NSW 2565",
    "Macquarie Marshes, NSW 2831",
    "Macquarie Park, NSW 2113",
    "Macquarie Pass, NSW 2577",
    "Macquarie Plains, TAS 7140",
    "Macquarie University, NSW 2109",
    "Macrossan, QLD 4816",
    "Macs Cove, VIC 3723",
    "Madalya, VIC 3971",
    "Maddens Plains, NSW 2508",
    "Maddingley, VIC 3340",
    "Maddington, WA 6989",
    "Maddington, WA 6109",
    "Madeley, WA 6065",
    "Madora Bay, WA 6210",
    "Madura, WA 6443",
    "Mafeking, VIC 3379",
    "Maffra, NSW 2630",
    "Maffra, VIC 3860",
    "Maffra West Upper, VIC 3859",
    "Magarey, SA 5280",
    "Magdala, SA 5400",
    "Magenta, WA 6355",
    "Magenta, NSW 2261",
    "Maggea, SA 5311",
    "Magill, SA 5072",
    "Magill North, SA 5072",
    "Magill South, SA 5072",
    "Magitup, WA 6338",
    "Magnetic Island, QLD 4819",
    "Magnolia, QLD 4650",
    "Magometon, NSW 2829",
    "Magpie, VIC 3352",
    "Magra, TAS 7140",
    "Mahogany Creek, WA 6072",
    "Mahomets Flats, WA 6530",
    "Maianbar, NSW 2230",
    "Maida Vale, WA 6057",
    "Maiden Gully, VIC 3551",
    "Maidenhead, QLD 4385",
    "Maidenwell, QLD 4615",
    "Maidstone, VIC 3012",
    "Mailors Flat, VIC 3275",
    "Maimuru, NSW 2594",
    "Main Arm, NSW 2482",
    "Main Beach, QLD 4217",
    "Main Creek, NSW 2420",
    "Main Lead, VIC 3373",
    "Main Ridge, VIC 3928",
    "Maindample, VIC 3723",
    "Maintongoon, VIC 3714",
    "Mairjimmy, NSW 2716",
    "Maison Dieu, NSW 2330",
    "Maitland, WA 6714",
    "Maitland, NSW 2320",
    "Maitland, SA 5573",
    "Maitland Bar, NSW 2850",
    "Maitland North, NSW 2320",
    "Maitland Vale, NSW 2320",
    "Major Plains, VIC 3725",
    "Majorca, VIC 3465",
    "Majors Creek, NSW 2622",
    "Majors Creek, QLD 4816",
    "Majura, ACT 2609",
    "Makin, SA 5267",
    "Malabaine, WA 6401",
    "malabar, NSW 2036",
    "Malabugilmah, NSW 2460",
    "Malaga, WA 6090",
    "Malaga, WA 6944",
    "Malak, NT 0812",
    "Malak, NT 0812",
    "Malanda, QLD 4885",
    "Malarga, QLD 4620",
    "Malbina, TAS 7140",
    "Maldon, VIC 3463",
    "Maldon, NSW 2571",
    "Malebelling, WA 6302",
    "Maleny, QLD 4552",
    "Malinong, SA 5259",
    "Mallabula, NSW 2319",
    "Mallacoota, VIC 3892",
    "Mallala, SA 5502",
    "Mallan, NSW 2734",
    "Mallanganee, NSW 2469",
    "Mallee, NSW 2738",
    "Mallee Hill, WA 6353",
    "Malling, QLD 4352",
    "Mallowa, NSW 2400",
    "Malmalling, WA 6556",
    "Malmoe, QLD 4627",
    "Malmsbury, VIC 3446",
    "Maloneys Beach, NSW 2536",
    "Malpas, SA 5311",
    "Malpas-trenton, QLD 4816",
    "Maltee, SA 5690",
    "Malu, QLD 4403",
    "Malua Bay, NSW 2536",
    "Malvern, VIC 3144",
    "Malvern, SA 5061",
    "Malvern East, VIC 3145",
    "Malvern North, VIC 3144",
    "Malyalling, WA 6370",
    "Mambourin, VIC 3024",
    "Mambray Creek, SA 5495",
    "Manahan, NSW 2200",
    "Manangatang, VIC 3546",
    "Manapouri, QLD 4361",
    "Manar, NSW 2622",
    "Manbulloo, NT 0852",
    "Manchester Square, NSW 2577",
    "Mandagery, NSW 2870",
    "Mandalay, QLD 4802",
    "Mandalong, NSW 2264",
    "Mandemar, NSW 2575",
    "Mandogalup, WA 6167",
    "Mandorah, NT 0822",
    "Mandurah, WA 6210",
    "Mandurah East, WA 6210",
    "Mandurah North, WA 6210",
    "Mandurama, NSW 2792",
    "Mandurang, VIC 3551",
    "Mandurang South, VIC 3551",
    "Maneroo, QLD 4730",
    "Mangalo, SA 5602",
    "Mangalore, VIC 3663",
    "Mangalore, TAS 7030",
    "Mangana, TAS 7214",
    "Mangerton, NSW 2500",
    "Mango Hill, QLD 4509",
    "Mangoola, NSW 2328",
    "Mangoplah, NSW 2652",
    "Mangrove Creek, NSW 2250",
    "Mangrove Mountain, NSW 2250",
    "Manifold Heights, VIC 3218",
    "Manildra, NSW 2865",
    "Manilla, NSW 2346",
    "Maningrida, NT 0822",
    "Manjimup, WA 6258",
    "Manly, NSW 1655",
    "Manly, NSW 2095",
    "Manly, QLD 4179",
    "Manly East, NSW 2095",
    "Manly Vale, NSW 2093",
    "Manly West, QLD 4179",
    "Manmanning, WA 6465",
    "Manna Hill, SA 5440",
    "Mannanarie, SA 5422",
    "Mannerim, VIC 3222",
    "Mannering Park, NSW 2259",
    "Mannibadar, VIC 3360",
    "Manning, WA 6152",
    "Manning Point, NSW 2430",
    "Manningham, SA 5086",
    "Manns Beach, VIC 3971",
    "Mannuem, QLD 4610",
    "Mannum, SA 5238",
    "Mannus, NSW 2653",
    "Manobalai, NSW 2333",
    "Manoora, SA 5414",
    "Manoora, QLD 4870",
    "Manorina, VIC 3889",
    "Mansfield, VIC 3722",
    "Mansfield, VIC 3724",
    "Mansfield, QLD 4122",
    "Mansfield Bc, QLD 4122",
    "Mansfield Park, SA 5012",
    "Manton, NSW 2582",
    "Manton, NT 0837",
    "Mantung, SA 5308",
    "Manuka, ACT 2603",
    "Manumbar, QLD 4601",
    "Manunda, QLD 4870",
    "Many Peaks, QLD 4680",
    "Manyana, NSW 2539",
    "Manypeaks, WA 6328",
    "Manyung, QLD 4605",
    "Mapleton, QLD 4560",
    "Mapoon, QLD 4874",
    "Maragle, NSW 2653",
    "Marama, SA 5307",
    "Maramie, QLD 4871",
    "Maramingo Creek, VIC 3891",
    "Marananga, SA 5355",
    "Maranboy, NT 0852",
    "Marangaroo, WA 6064",
    "Maranunga, NT 0822",
    "Maranup, WA 6256",
    "Maraylya, NSW 2765",
    "Marayong, NSW 2148",
    "Marbelup, WA 6330",
    "Marble Bar, WA 6760",
    "Marble Hill, SA 5137",
    "Marburg, QLD 4346",
    "March, NSW 2800",
    "Marchagee, WA 6515",
    "Marchmont, NSW 2582",
    "Marcollat, SA 5271",
    "Marcoola, QLD 4564",
    "Marcus Beach, QLD 4573",
    "Marcus Hill, VIC 3222",
    "Mardan, VIC 3953",
    "Mardella, WA 6125",
    "Marden, SA 5070",
    "Mardi, NSW 2259",
    "Mardie, WA 6714",
    "Mareeba, QLD 4880",
    "Marengo, VIC 3233",
    "Marengo, NSW 2453",
    "Mares Run, NSW 2422",
    "Margaret River, NT 0822",
    "Margaret River, WA 6285",
    "Margate, TAS 7054",
    "Margate, QLD 4019",
    "Margate Beach, QLD 4019",
    "Maria Creeks, QLD 4855",
    "Marian, QLD 4753",
    "Maribyrnong, VIC 3032",
    "Mariginiup, WA 6078",
    "Marinna, NSW 2663",
    "Marino, SA 5049",
    "Marion, SA 5043",
    "Marion Bay, TAS 7175",
    "Marion Bay, SA 5575",
    "Marionvale, VIC 3634",
    "Markaranka, SA 5330",
    "Marks Landing, SA 5354",
    "Marks Point, NSW 2280",
    "Markwell, NSW 2423",
    "Markwood, VIC 3678",
    "Marla, SA 5724",
    "Marlbed, VIC 3483",
    "Marlborough, QLD 4705",
    "Marlee, NSW 2429",
    "Marleston, SA 5033",
    "Marlo, VIC 3888",
    "Marlo Merrican, NSW 2446",
    "Marlow, NSW 2775",
    "Marlow Lagoon, NT 0830",
    "Marlow Lagoon, NT 0830",
    "Marlowe, NSW 2622",
    "Marmadua, QLD 4405",
    "Marmion, WA 6020",
    "Marmong Point, NSW 2284",
    "Marmor, QLD 4702",
    "Marne, WA 6608",
    "Marnoo, VIC 3387",
    "Marnoo East, VIC 3477",
    "Marnoo West, VIC 3387",
    "Marodian, QLD 4570",
    "Marola, SA 5464",
    "Marom Creek, NSW 2480",
    "Marong, VIC 3515",
    "Maroochy River, QLD 4561",
    "Maroochydore, QLD 4558",
    "Maroochydore Bc, QLD 4558",
    "Maroon, QLD 4310",
    "Maroona, VIC 3377",
    "Maroondan, QLD 4671",
    "Maroota, NSW 2756",
    "Maroubra, NSW 2035",
    "Maroubra Junction, NSW 2035",
    "Maroubra South, NSW 2035",
    "Marrabel, SA 5413",
    "Marracoonda, WA 6317",
    "Marradong, WA 6390",
    "Marrah, WA 6532",
    "Marrakai, NT 0822",
    "Marrangaroo, NSW 2790",
    "Marrar, NSW 2652",
    "Marrara, NT 0812",
    "Marrara, NT 0812",
    "Marrawah, TAS 7330",
    "Marraweeney, VIC 3669",
    "Marree, SA 5733",
    "Marrickville, NSW 1475",
    "Marrickville, NSW 2204",
    "Marrickville Metro, NSW 2204",
    "Marrickville South, NSW 2204",
    "Marrinup, WA 6213",
    "Marryatville, SA 5068",
    "Marsden, QLD 4132",
    "Marsden Park, NSW 2765",
    "Marsden Postal Depot, QLD 4132",
    "Marsfield, NSW 2122",
    "Marshall, VIC 3216",
    "Marshall Mount, NSW 2530",
    "Marshdale, NSW 2420",
    "Marshlands, QLD 4611",
    "Marthaguy, NSW 2824",
    "Marthavale, VIC 3875",
    "Martin, WA 6110",
    "Martindale, NSW 2328",
    "Martins Creek, NSW 2420",
    "Martins Creek, VIC 3888",
    "Martinsville, NSW 2265",
    "Martynvale, QLD 4870",
    "Martyville, QLD 4858",
    "Marulan, NSW 2579",
    "Marungi, VIC 3634",
    "Marvel Loch, WA 6426",
    "Maryborough, QLD 4650",
    "Maryborough, VIC 3465",
    "Maryborough West, QLD 4650",
    "Marybrook, WA 6280",
    "Maryknoll, VIC 3812",
    "Maryland, NSW 2287",
    "Maryland, NSW 4377",
    "Marys Creek, QLD 4570",
    "Marys Mount, NSW 2380",
    "Marysville, VIC 3779",
    "Maryvale, NSW 2820",
    "Maryvale, SA 5680",
    "Maryvale, VIC 3840",
    "Maryvale, QLD 4370",
    "Maryvale, QLD 4703",
    "Maryville, NSW 2293",
    "Mascot, NSW 1460",
    "Mascot, NSW 2020",
    "Maslin Beach, SA 5170",
    "Massey, VIC 3482",
    "Massey Bay, WA 6701",
    "Massie, QLD 4370",
    "Mataranka, NT 0852",
    "Matcham, NSW 2250",
    "Matheson, NSW 2370",
    "Mathinna, TAS 7214",
    "Mathoura, NSW 2710",
    "Matlock, VIC 3723",
    "Matong, NSW 2652",
    "Matraville, NSW 2036",
    "Matta Flat, SA 5554",
    "Maude, NSW 2711",
    "Maude, VIC 3331",
    "Maude, SA 5320",
    "Maudsland, QLD 4210",
    "Maules Creek, NSW 2382",
    "Mawbanna, TAS 7321",
    "Mawson, TAS 7151",
    "Mawson, ACT 2607",
    "Mawson Lakes, SA 5095",
    "Maxwell, NSW 2650",
    "Maxwelton, QLD 4822",
    "May Downs, QLD 4746",
    "Maya, WA 6614",
    "Mayanup, WA 6244",
    "Mayberry, TAS 7304",
    "Maybole, NSW 2365",
    "Maydena, TAS 7140",
    "Mayers Flat, NSW 2423",
    "Mayfield, NSW 2580",
    "Mayfield, NSW 2304",
    "Mayfield, NSW 2787",
    "Mayfield, TAS 7248",
    "Mayfield, SA 5454",
    "Mayfield, NSW 2540",
    "Mayfield East, NSW 2304",
    "Mayfield North, NSW 2304",
    "Mayfield West, NSW 2304",
    "Maylands, WA 6051",
    "Maylands, SA 5069",
    "Maylands, WA 6931",
    "Mayrung, NSW 2710",
    "Mays Hill, NSW 2145",
    "Mayvale, NSW 2347",
    "Mcalinden, WA 6225",
    "Mcarthur, NT 0852",
    "Mcbean Pound, SA 5357",
    "Mcbeath, WA 6770",
    "Mccallum, SA 5267",
    "Mccracken, SA 5211",
    "Mccrae, VIC 3938",
    "Mccullys Gap, NSW 2333",
    "Mccutcheon, QLD 4856",
    "Mcdesme, QLD 4807",
    "Mcdougalls Hill, NSW 2330",
    "Mcdowall, QLD 4053",
    "Mcewens Beach, QLD 4740",
    "Mcgraths Hill, NSW 2756",
    "Mcharg Creek, SA 5157",
    "Mcilwraith, QLD 4671",
    "Mcintosh Creek, QLD 4570",
    "Mcintyre, VIC 3472",
    "Mckail, WA 6330",
    "Mckees Hill, NSW 2480",
    "Mckellar, ACT 2617",
    "Mckellars Park, NSW 2790",
    "Mckenzie Creek, VIC 3401",
    "Mckenzie Hill, VIC 3451",
    "Mckinlay, QLD 4823",
    "Mckinnon, VIC 3204",
    "Mclaren Flat, SA 5171",
    "Mclaren Vale, SA 5171",
    "Mcleans Ridges, NSW 2480",
    "Mcleods Shoot, NSW 2479",
    "Mcloughlins Beach, VIC 3874",
    "Mcmahons Creek, VIC 3799",
    "Mcmahons Point, NSW 2060",
    "Mcmahons Reef, NSW 2587",
    "Mcmillans, VIC 3568",
    "Mcminns Lagoon, NT 0822",
    "Mead, VIC 3568",
    "Meadow, WA 6532",
    "Meadow Creek, VIC 3678",
    "Meadow Flat, NSW 2795",
    "Meadow Heights, VIC 3048",
    "Meadow Springs, WA 6210",
    "Meadowbank, NSW 2114",
    "Meadowbank, TAS 7140",
    "Meadowbrook, QLD 4131",
    "Meadows, SA 5201",
    "Meadowvale, QLD 4670",
    "Meandarra, QLD 4422",
    "Meander, TAS 7304",
    "Meatian, VIC 3585",
    "Mebbin, NSW 2484",
    "Mebul, NSW 2852",
    "Meckering, WA 6405",
    "Meda, WA 6728",
    "Medina, WA 6167",
    "Medindie, SA 5081",
    "Medindie Gardens, SA 5081",
    "Medlow Bath, NSW 2780",
    "Medlyn, VIC 3478",
    "Medowie, NSW 2318",
    "Medway, NSW 2820",
    "Medway, NSW 2577",
    "Meekatharra, WA 6642",
    "Meeking, WA 6392",
    "Meelon, WA 6208",
    "Meenaar, WA 6401",
    "Meeniyan, VIC 3956",
    "Meering West, VIC 3579",
    "Meerlieu, VIC 3862",
    "Meerschaum Vale, NSW 2477",
    "Meerup, WA 6262",
    "Megalong, NSW 2785",
    "Megan, NSW 2453",
    "Meikleville Hill, QLD 4703",
    "Melaleuca, WA 6079",
    "Melawondi, QLD 4570",
    "Melba, ACT 2615",
    "Melbergen, NSW 2669",
    "Melbourne, VIC 3004",
    "Melbourne, VIC 3000",
    "Melbourne Airport, VIC 3045",
    "Melbourne University, VIC 3052",
    "Meldale, QLD 4510",
    "Melinga, NSW 2430",
    "Mella, TAS 7330",
    "Mellong, NSW 2756",
    "Mellool, NSW 2734",
    "Melrose, SA 5483",
    "Melrose, TAS 7310",
    "Melrose, QLD 4613",
    "Melrose Park, SA 5039",
    "Melrose Park, NSW 2114",
    "Melton, SA 5555",
    "Melton, VIC 3337",
    "Melton Mowbray, TAS 7030",
    "Melton South, VIC 3338",
    "Melton West, VIC 3337",
    "Melville, WA 6156",
    "Melville, NSW 2320",
    "Melville, WA 6956",
    "Melville Forest, VIC 3315",
    "Melwood, VIC 3875",
    "Memagong, NSW 2594",
    "Memana, TAS 7255",
    "Memerambi, QLD 4610",
    "Mena Creek, QLD 4871",
    "Mena Park, VIC 3373",
    "Menah, NSW 2850",
    "Menai, NSW 2234",
    "Menai Central, NSW 2234",
    "Menangle, NSW 2568",
    "Menangle Park, NSW 2563",
    "Mendooran, NSW 2842",
    "Mengha, TAS 7330",
    "Menindee, NSW 2879",
    "Meningie, SA 5264",
    "Meningie East, SA 5264",
    "Meningie West, SA 5264",
    "Menora, WA 6050",
    "Mentmore, QLD 4798",
    "Mentone, VIC 3194",
    "Mentone East, VIC 3194",
    "Menzies, SA 5223",
    "Menzies, WA 6436",
    "Menzies, QLD 4825",
    "Menzies Creek, VIC 3159",
    "Mepunga, VIC 3277",
    "Mepunga East, VIC 3277",
    "Mepunga West, VIC 3277",
    "Merah North, NSW 2388",
    "Merbein, VIC 3505",
    "Merbein South, VIC 3505",
    "Merbein West, VIC 3505",
    "Mercunda, SA 5308",
    "Meredith, VIC 3333",
    "Mereenie, NT 0872",
    "Merewether, NSW 2291",
    "Merewether Heights, NSW 2291",
    "Merghiny, SA 5690",
    "Meribah, SA 5311",
    "Meridan Plains, QLD 4551",
    "Merilup, WA 6352",
    "Merimbula, NSW 2548",
    "Merinda, QLD 4805",
    "Meringandan, QLD 4352",
    "Meringandan West, QLD 4352",
    "Meringo, NSW 2537",
    "Meringur, VIC 3496",
    "Merino, VIC 3310",
    "Merivale, WA 6450",
    "Merkanooka, WA 6625",
    "Merlwood, QLD 4605",
    "Merlynston, VIC 3058",
    "Mermaid Beach, QLD 4218",
    "Mermaid Waters, QLD 4218",
    "Mernda, VIC 3754",
    "Mernot, NSW 2422",
    "Meroo, NSW 2850",
    "Meroo Meadow, NSW 2540",
    "Merotherie, NSW 2852",
    "Merredin, WA 6415",
    "Merriang, VIC 3737",
    "Merriang South, VIC 3737",
    "Merriangaah, NSW 2632",
    "Merricks, VIC 3916",
    "Merricks Beach, VIC 3926",
    "Merricks North, VIC 3926",
    "Merricumbene, NSW 2622",
    "Merrigal, NSW 2827",
    "Merrigum, VIC 3618",
    "Merrijig, VIC 3875",
    "Merrijig, VIC 3723",
    "Merrill, NSW 2581",
    "Merrimac, QLD 4226",
    "Merrimu, VIC 3340",
    "Merrinee, VIC 3496",
    "Merriton, SA 5523",
    "Merritts Creek, QLD 4352",
    "Merriwa, WA 6030",
    "Merriwa, NSW 2329",
    "Merriwagga, NSW 2652",
    "Merryburn, QLD 4854",
    "Merrygoen, NSW 2831",
    "Merrylands, NSW 2160",
    "Merrylands West, NSW 2160",
    "Merryvale, QLD 4340",
    "Mersey Forest, TAS 7304",
    "Merseylea, TAS 7305",
    "Merton, VIC 3715",
    "Merty Merty, SA 5731",
    "Meru, WA 6530",
    "Merungle Hill, NSW 2705",
    "Meryla, NSW 2577",
    "Metcalfe, VIC 3448",
    "Metcalfe East, VIC 3444",
    "Metford, NSW 2323",
    "Methul, NSW 2701",
    "Metricup, WA 6280",
    "Mettler, WA 6328",
    "Metung, VIC 3904",
    "Meunna, TAS 7325",
    "Mexico, QLD 4728",
    "Mia Mia, VIC 3444",
    "Mia Mia, QLD 4754",
    "Miallo, QLD 4873",
    "Miami, QLD 4220",
    "Miamley, NSW 2873",
    "Miamoon, WA 6612",
    "Miandetta, NSW 2825",
    "Miandetta, TAS 7310",
    "Miara, QLD 4673",
    "Mica Creek, QLD 4825",
    "Micalo Island, NSW 2464",
    "Michelago, NSW 2620",
    "Mickett Creek, NT 0822",
    "Mickleham, VIC 3064",
    "Mid North Coast Msc, NSW 2442",
    "Mid Valley, VIC 3840",
    "Middle Arm, NSW 2580",
    "Middle Beach, SA 5501",
    "Middle Brook, NSW 2337",
    "Middle Brother, NSW 2443",
    "Middle Camberwell, VIC 3124",
    "Middle Cove, NSW 2068",
    "Middle Creek, VIC 3375",
    "Middle Dural, NSW 2158",
    "Middle Falbrook, NSW 2330",
    "Middle Flat, NSW 2630",
    "Middle Park, QLD 4074",
    "Middle Park, VIC 3206",
    "Middle Pocket, NSW 2483",
    "Middle Point, NT 0822",
    "Middle Ridge, QLD 4350",
    "Middle River, SA 5223",
    "Middle Swan, WA 6056",
    "Middle Tarwin, VIC 3956",
    "Middlebrook, QLD 4886",
    "Middlemount, QLD 4746",
    "Middlesex, WA 6258",
    "Middlesex, TAS 7306",
    "Middleton, SA 5213",
    "Middleton, TAS 7163",
    "Middleton, QLD 4735",
    "Middleton Beach, WA 6330",
    "Middleton Grange, NSW 2171",
    "Middlingbank, NSW 2630",
    "Midge Point, QLD 4799",
    "Midgee, QLD 4702",
    "Midgee, SA 5602",
    "Midgenoo, QLD 4854",
    "Midgeree Bar, QLD 4852",
    "Midginbil, NSW 2484",
    "Midland, WA 6056",
    "Midvale, WA 6056",
    "Midway Point, TAS 7171",
    "Miena, TAS 7030",
    "Miepoll, VIC 3666",
    "Miga Lake, VIC 3409",
    "Mighell, QLD 4860",
    "Mihi, NSW 2358",
    "Mil-lel, SA 5291",
    "Mila, NSW 2632",
    "Milabena, TAS 7325",
    "Milang, SA 5256",
    "Milawa, VIC 3678",
    "Milbong, QLD 4310",
    "Milbrodale, NSW 2330",
    "Milbrulong, NSW 2656",
    "Mildura, VIC 3500",
    "Mildura, VIC 3502",
    "Mildura Centre Plaza, VIC 3501",
    "Mildura East, VIC 3500",
    "Mildura South, VIC 3501",
    "Mildura West, VIC 3500",
    "Mile End, SA 5031",
    "Mile End South, SA 5031",
    "Milendella, SA 5237",
    "Miles, QLD 4415",
    "Miles End, QLD 4825",
    "Milford, QLD 4310",
    "Milikapiti, NT 0822",
    "Miling, WA 6575",
    "Milingimbi, NT 0822",
    "Milkers Flat, NSW 2795",
    "Mill Park, VIC 3082",
    "Millaa Millaa, QLD 4886",
    "Millah Murrah, NSW 2795",
    "Millaroo, QLD 4807",
    "Millars Well, WA 6714",
    "Millbank, QLD 4670",
    "Millbank, NSW 2440",
    "Millbridge, WA 6232",
    "Millbrook, VIC 3352",
    "Millbrook, WA 6330",
    "Millbrook, SA 5231",
    "Millchester, QLD 4820",
    "Millendon, WA 6056",
    "Miller, NSW 2168",
    "Millers Bluff, TAS 7030",
    "Millers Forest, NSW 2324",
    "Millers Point, NSW 2000",
    "Millfield, NSW 2325",
    "Millgrove, VIC 3799",
    "Millicent, SA 5280",
    "Millie, NSW 2397",
    "Millingandi, NSW 2549",
    "Millmerran, QLD 4357",
    "Millmerran Downs, QLD 4357",
    "Millmerran Woods, QLD 4357",
    "Millner, NT 0810",
    "Millner, NT 0810",
    "Milloo, VIC 3572",
    "Mills Lake, WA 6336",
    "Millstream, WA 6716",
    "Millstream, QLD 4888",
    "Millswood, SA 5034",
    "Millthorpe, NSW 2798",
    "Milltown, VIC 3304",
    "Millwood, QLD 4357",
    "Millwood, NT 0822",
    "Milman, QLD 4702",
    "Milnes Bridge, VIC 3579",
    "Milo, WA 6525",
    "Milora, QLD 4309",
    "Milpara, WA 6330",
    "Milparinka, NSW 2880",
    "Milperra, NSW 1891",
    "Milperra, NSW 2214",
    "Milroy, NSW 2850",
    "Milroy, NSW 2380",
    "Milsons Passage, NSW 2083",
    "Milsons Point, NSW 2061",
    "Milsons Point, NSW 1565",
    "Miltalie, SA 5602",
    "Milton, NSW 2538",
    "Milton, QLD 4064",
    "Milton Bc, QLD 4064",
    "Milvale, NSW 2594",
    "Milyakburra, NT 0822",
    "Mimegarra, WA 6507",
    "Mimili, SA 0872",
    "Mimosa, QLD 4702",
    "Mimosa, NSW 2666",
    "Min Min, QLD 4829",
    "Minbrie, SA 5602",
    "Minbun, QLD 4886",
    "Minburra, SA 5431",
    "Mincha, VIC 3575",
    "Mincha West, VIC 3568",
    "Minchinbury, NSW 2770",
    "Mindarabin, WA 6336",
    "Mindaribba, NSW 2320",
    "Mindarie, WA 6030",
    "Mindarie, SA 5309",
    "Mindarra, WA 6503",
    "Minden, QLD 4311",
    "Minding, WA 6315",
    "Miners Rest, VIC 3352",
    "Mingary, SA 5440",
    "Mingay, VIC 3324",
    "Mingbool, SA 5291",
    "Mingela, QLD 4816",
    "Mingenew, WA 6522",
    "Mingo, QLD 4625",
    "Mingoola, NSW 4380",
    "Mingoola, QLD 4380",
    "Minhamite, VIC 3287",
    "Minigin, WA 6312",
    "Minilya, WA 6701",
    "Minimay, VIC 3413",
    "Minimbah, NSW 2312",
    "Mininera, VIC 3351",
    "Miniyeri, NT 0852",
    "Minjah, VIC 3276",
    "Minjary, NSW 2720",
    "Minjilang, NT 0822",
    "Minlaton, SA 5575",
    "Minmi, NSW 2287",
    "Minmindie, VIC 3537",
    "Minnamoolka, QLD 4872",
    "Minnamurra, NSW 2533",
    "Minnenooka, WA 6532",
    "Minnie Downs, QLD 4478",
    "Minnie Water, NSW 2462",
    "Minnipa, SA 5654",
    "Minnivale, WA 6462",
    "Minore, NSW 2830",
    "Mintabie, SA 5724",
    "Mintaro, SA 5415",
    "Minto, VIC 3551",
    "Minto, NSW 2566",
    "Minto Heights, NSW 2566",
    "Minvalara, SA 5422",
    "Minyama, QLD 4575",
    "Minyip, VIC 3392",
    "Minyirr, WA 6725",
    "Miowera, VIC 3862",
    "Mira Mar, WA 6330",
    "Mirador, NSW 2548",
    "Miralie, VIC 3596",
    "Miram, VIC 3415",
    "Miranda, SA 5700",
    "Miranda, NSW 1490",
    "Miranda, NSW 2228",
    "Mirani, QLD 4754",
    "Mirannie, NSW 2330",
    "Mirboo, VIC 3871",
    "Mirboo North, VIC 3871",
    "Miriam Vale, QLD 4677",
    "Mirimbah, VIC 3722",
    "Miriwinni, QLD 4871",
    "Mirrabooka, WA 6061",
    "Mirrabooka, NSW 2264",
    "Mirrabooka, WA 6941",
    "Mirranatwa, VIC 3294",
    "Mirrool, NSW 2665",
    "Missabotti, NSW 2449",
    "Missen Flat, QLD 4361",
    "Missenden Road, NSW 2050",
    "Mission Beach, QLD 4852",
    "Mission River, QLD 4874",
    "Mitcham, VIC 3132",
    "Mitcham, SA 5062",
    "Mitcham North, VIC 3132",
    "Mitcham Shopping Centre, SA 5062",
    "Mitchell, QLD 4465",
    "Mitchell, NSW 2795",
    "Mitchell, NT 0832",
    "Mitchell, ACT 2911",
    "Mitchell, SA 5607",
    "Mitchell Park, VIC 3352",
    "Mitchell Park, VIC 3355",
    "Mitchell Park, SA 5043",
    "Mitchell Plateau, WA 6740",
    "Mitchells Flat, NSW 2330",
    "Mitchells Island, NSW 2430",
    "Mitchellstown, VIC 3608",
    "Mitchellville, SA 5602",
    "Mitchelton, QLD 4053",
    "Mitiamo, VIC 3573",
    "Mitre, VIC 3409",
    "Mitta Mitta, VIC 3701",
    "Mittagong, NSW 2575",
    "Mittyack, VIC 3490",
    "Miva, QLD 4570",
    "Moa Island, QLD 4875",
    "Moama, NSW 2731",
    "Moana, SA 5169",
    "Mobilong, SA 5253",
    "Mobrup, WA 6395",
    "Mocardy, WA 6603",
    "Mockinya, VIC 3401",
    "Moculta, SA 5353",
    "Modanville, NSW 2480",
    "Modbury, SA 5092",
    "Modbury Heights, SA 5092",
    "Modbury North, SA 5092",
    "Modella, VIC 3816",
    "Modewarre, VIC 3240",
    "Moe, VIC 3825",
    "Moe South, VIC 3825",
    "Moerlong, SA 5278",
    "Moffat Beach, QLD 4551",
    "Moffatdale, QLD 4605",
    "Mogareeka, NSW 2550",
    "Mogendoura, NSW 2537",
    "Moggill, QLD 4070",
    "Moggs Creek, VIC 3231",
    "Moggs Swamp, NSW 2370",
    "Mogilla, NSW 2550",
    "Moglonemby, VIC 3666",
    "Mogo, NSW 2850",
    "Mogo, NSW 2536",
    "Mogo Creek, NSW 2775",
    "Mogood, NSW 2538",
    "Mograni, NSW 2422",
    "Mogriguy, NSW 2830",
    "Mogumber, WA 6506",
    "Moil, NT 0810",
    "Moil, NT 0810",
    "Moina, TAS 7310",
    "Mokepilly, VIC 3381",
    "Mokine, WA 6401",
    "Mokup, WA 6394",
    "Molangul, QLD 4671",
    "Mole Creek, TAS 7304",
    "Mole River, NSW 2372",
    "Molendinar, QLD 4214",
    "Molesworth, VIC 3718",
    "Molesworth, TAS 7140",
    "Moleville Creek, NSW 2460",
    "Moliagul, VIC 3472",
    "Molka, VIC 3666",
    "Mollerin, WA 6475",
    "Mollongghip, VIC 3352",
    "Molloy Island, WA 6290",
    "Mollyan, NSW 2842",
    "Mollymook, NSW 2539",
    "Mollymook Beach, NSW 2539",
    "Mologa, VIC 3575",
    "Molong, NSW 2866",
    "Moltema, TAS 7304",
    "Molyullah, VIC 3673",
    "Mon Repos, QLD 4670",
    "Mona Mona, QLD 4881",
    "Mona Park, QLD 4807",
    "Mona Vale, NSW 1658",
    "Mona Vale, NSW 1660",
    "Mona Vale, NSW 2103",
    "Monak, NSW 2738",
    "Monal, QLD 4630",
    "Monaltrie, NSW 2480",
    "Monarto, SA 5254",
    "Monarto South, SA 5254",
    "Monash, ACT 2904",
    "Monash, SA 5342",
    "Monash Park, NSW 2111",
    "Monash University, VIC 3800",
    "Monbulk, VIC 3793",
    "Monbulla, SA 5277",
    "Moncrieff, ACT 2914",
    "Mondayong, NSW 2540",
    "Mondrook, NSW 2430",
    "Monduran, QLD 4671",
    "Mondure, QLD 4611",
    "Monegeetta, VIC 3433",
    "Monga, NSW 2622",
    "Mongans Bridge, VIC 3691",
    "Mongarlowe, NSW 2622",
    "Mongogarie, NSW 2470",
    "Mongolata, SA 5417",
    "Monia Gap, NSW 2675",
    "Monivae, NSW 2850",
    "Monjebup, WA 6338",
    "Monjingup, WA 6450",
    "Monkerai, NSW 2415",
    "Monkey Mia, WA 6537",
    "Monkland, QLD 4570",
    "Monogorilby, QLD 4626",
    "Monomak, VIC 3860",
    "Monomeith, VIC 3984",
    "Mons, QLD 4556",
    "Monsildale, QLD 4515",
    "Mont Albert, VIC 3127",
    "Mont Albert North, VIC 3129",
    "Montacute, SA 5134",
    "Montagu, TAS 7330",
    "Montagu Bay, TAS 7018",
    "Montana, TAS 7304",
    "Montarra, SA 5172",
    "Monteagle, NSW 2594",
    "Montecollum, NSW 2482",
    "Montefiores, NSW 2820",
    "Monteith, SA 5254",
    "Montello, TAS 7320",
    "Monterey, NSW 2217",
    "Monterey Keys, QLD 4212",
    "Montgomery, VIC 3851",
    "Montmorency, VIC 3094",
    "Monto, QLD 4630",
    "Montrose, TAS 7010",
    "Montrose, QLD 4413",
    "Montrose, QLD 4370",
    "Montrose, VIC 3765",
    "Montumana, TAS 7321",
    "Montville, QLD 4560",
    "Mooball, NSW 2483",
    "Moobi, NSW 2337",
    "Moockra, SA 5432",
    "Moodiarrup, WA 6393",
    "Moodlu, QLD 4510",
    "Moody, SA 5607",
    "Mooga, QLD 4455",
    "Moogara, TAS 7140",
    "Moogem, NSW 2370",
    "Moogerah, QLD 4309",
    "Moojebing, WA 6317",
    "Mookerawa, NSW 2820",
    "Mookima Wybra, NSW 2469",
    "Moolap, VIC 3221",
    "Moolarben, NSW 2850",
    "Moolawatana, SA 5731",
    "Moolboolaman, QLD 4671",
    "Moolerr, VIC 3477",
    "Mooliabeenee, WA 6504",
    "Moollattoo, NSW 2540",
    "Mooloo, QLD 4570",
    "Mooloolaba, QLD 4557",
    "Mooloolah, QLD 4553",
    "Mooloolah Valley, QLD 4553",
    "Moolort, VIC 3465",
    "Moolpa, NSW 2734",
    "Moombooldool, NSW 2665",
    "Moombra, QLD 4312",
    "Moomin, QLD 4887",
    "Moonah, TAS 7009",
    "Moonambel, VIC 3478",
    "Moonan Brook, NSW 2337",
    "Moonan Flat, NSW 2337",
    "Moonbah, NSW 2627",
    "Moonbi, NSW 2353",
    "Moonbria, NSW 2710",
    "Moondah, WA 6503",
    "Moondarra, VIC 3825",
    "Moondooner, QLD 4605",
    "Moondyne, WA 6567",
    "Mooneba, NSW 2440",
    "Moonee, NSW 2259",
    "Moonee Beach, NSW 2450",
    "Moonee Ponds, VIC 3039",
    "Moonee Vale, VIC 3055",
    "Moonem, NSW 2472",
    "Mooney Mooney, NSW 2083",
    "Mooney Mooney Creek, NSW 2250",
    "Moonford, QLD 4630",
    "Moongan, QLD 4714",
    "Moonie, QLD 4406",
    "Moonies Hill, WA 6320",
    "Moonlight Flat, VIC 3450",
    "Moonlight Flat, VIC 3465",
    "Moonmera, QLD 4702",
    "Moonpar, NSW 2453",
    "Moonta, SA 5558",
    "Moonta Bay, SA 5558",
    "Moonta Mines, SA 5558",
    "Moonyoonooka, WA 6532",
    "Moora, WA 6510",
    "Moora, VIC 3612",
    "Moorabbin, VIC 3189",
    "Moorabbin Airport, VIC 3194",
    "Moorabbin East, VIC 3189",
    "Moorabool, VIC 3221",
    "Moorak, SA 5291",
    "Mooral Creek, NSW 2429",
    "Mooralla, VIC 3314",
    "Moorang, QLD 4340",
    "Moorara, NSW 2648",
    "Moorbel, NSW 2804",
    "Moore, QLD 4306",
    "Moore Creek, NSW 2340",
    "Moore Park, QLD 4670",
    "Moore Park, NSW 2021",
    "Moore Park Beach, QLD 4670",
    "Moore River National Park, WA 6503",
    "Moorebank, NSW 2170",
    "Moorebank, NSW 1875",
    "Moores Pocket, QLD 4305",
    "Mooreville, TAS 7321",
    "Mooriary, WA 6522",
    "Moorilda, NSW 2795",
    "Moorilim, VIC 3610",
    "Moorina, QLD 4506",
    "Moorina, TAS 7264",
    "Moorine Rock, WA 6425",
    "Moorland, NSW 2443",
    "Moorland, QLD 4670",
    "Moorlands, SA 5301",
    "Moorleah, TAS 7325",
    "Moormbool West, VIC 3523",
    "Moornapa, VIC 3862",
    "Moorngag, VIC 3673",
    "Moorong, NSW 2650",
    "Mooroobool, QLD 4870",
    "Moorooduc, VIC 3933",
    "Moorook, SA 5332",
    "Moorook South, SA 5332",
    "Moorooka, QLD 4105",
    "Mooroolbark, VIC 3138",
    "Mooroopna, VIC 3629",
    "Mooroopna North, VIC 3629",
    "Mooroopna North West, VIC 3616",
    "Moorwatha, NSW 2640",
    "Mootatunga, SA 5307",
    "Moparrabah, NSW 2440",
    "Moppa, SA 5355",
    "Moppy, NSW 2422",
    "Moraby, QLD 4416",
    "Morago, NSW 2710",
    "Moranbah, QLD 4744",
    "Moranding, VIC 3764",
    "Morang South, VIC 3752",
    "Morangarell, NSW 2666",
    "Morangup, WA 6083",
    "Morans Crossing, NSW 2550",
    "Morass Bay, TAS 7030",
    "Morawa, WA 6623",
    "Morayfield, QLD 4506",
    "Morbinning, WA 6304",
    "Morchard, SA 5431",
    "Mordalup, WA 6258",
    "Mordialloc, VIC 3195",
    "Mordialloc North, VIC 3195",
    "Moree, NSW 2400",
    "Moree East, NSW 2400",
    "Moregatta, QLD 4886",
    "Moreland, VIC 3058",
    "Moreland West, VIC 3055",
    "Morella, QLD 4730",
    "Moresby, QLD 4871",
    "Moresby, WA 6530",
    "Morgan, SA 5320",
    "Morgan Park, QLD 4370",
    "Morgantown, WA 6701",
    "Morganville, QLD 4671",
    "Morgiana, VIC 3301",
    "Moriac, VIC 3240",
    "Moriarty, TAS 7307",
    "Morinish, QLD 4702",
    "Morinish South, QLD 4702",
    "Morisset, NSW 2264",
    "Morisset Park, NSW 2264",
    "Morley, WA 6062",
    "Morley, WA 6943",
    "Morn Hill, SA 5371",
    "Morning Bay, NSW 2105",
    "Morningside, QLD 4170",
    "Mornington, TAS 7018",
    "Mornington, WA 6221",
    "Mornington, VIC 3931",
    "Mornington, QLD 4825",
    "Mornington Island, QLD 4871",
    "Moroka, VIC 3860",
    "Mororo, NSW 2469",
    "Morpeth, NSW 2321",
    "Morphett Vale, SA 5162",
    "Morphetts Flat, SA 5320",
    "Morphettville, SA 5043",
    "Morrisons, VIC 3334",
    "Morrl Morrl, VIC 3387",
    "Mortana, SA 5671",
    "Mortdale, NSW 2223",
    "Mortlake, NSW 2137",
    "Mortlake, VIC 3272",
    "Morton, NSW 2538",
    "Morton Plains, VIC 3482",
    "Morton Vale, QLD 4343",
    "Mortons Creek, NSW 2446",
    "Morts Estate, NSW 2790",
    "Moruben, NSW 2325",
    "Morundah, NSW 2700",
    "Moruya, NSW 2537",
    "Moruya Heads, NSW 2537",
    "Morven, NSW 2370",
    "Morven, NSW 2660",
    "Morven, QLD 4468",
    "Morwell, VIC 3840",
    "Morwell East, VIC 3840",
    "Morwell Upper, VIC 3840",
    "Morwincha, QLD 4309",
    "Moseley, SA 5641",
    "Mosman, NSW 2088",
    "Mosman Park, QLD 4820",
    "Mosman Park, WA 6912",
    "Mosman Park, WA 6012",
    "Mosquito Creek, QLD 4387",
    "Mosquito Hill, SA 5214",
    "Moss Vale, NSW 2577",
    "Mossgiel, NSW 2878",
    "Mossiface, VIC 3885",
    "Mossman, QLD 4873",
    "Mossman Gorge, QLD 4873",
    "Mossy Point, NSW 2537",
    "Mothar Mountain, QLD 4570",
    "Motley, QLD 4356",
    "Moto, NSW 2426",
    "Moulamein, NSW 2733",
    "Moulden, NT 0830",
    "Moulden, NT 0830",
    "Moulyinning, WA 6351",
    "Mount Abundance, QLD 4455",
    "Mount Adams, WA 6525",
    "Mount Adrah, NSW 2729",
    "Mount Alford, QLD 4310",
    "Mount Alfred, VIC 3709",
    "Mount Alma, QLD 4680",
    "Mount Anketell, WA 6714",
    "Mount Annan, NSW 2567",
    "Mount Aquila, NSW 2820",
    "Mount Archer, QLD 4514",
    "Mount Archer, QLD 4701",
    "Mount Arthur, NSW 2820",
    "Mount Austin, NSW 2650",
    "Mount Barker, WA 6324",
    "Mount Barker, SA 5251",
    "Mount Barker Junction, SA 5251",
    "Mount Barker Springs, SA 5251",
    "Mount Barker Summit, SA 5251",
    "Mount Barney, QLD 4287",
    "Mount Beauty, VIC 3699",
    "Mount Beckworth, VIC 3363",
    "Mount Benson, SA 5275",
    "Mount Beppo, QLD 4313",
    "Mount Berryman, QLD 4341",
    "Mount Best, VIC 3960",
    "Mount Bindango, QLD 4455",
    "Mount Binga, QLD 4306",
    "Mount Bolton, VIC 3352",
    "Mount Britton, QLD 4742",
    "Mount Bruno, VIC 3675",
    "Mount Bryan, SA 5418",
    "Mount Bryan East, SA 5419",
    "Mount Budd, WA 6522",
    "Mount Buffalo, VIC 3740",
    "Mount Buller, VIC 3723",
    "Mount Bundey, NT 0822",
    "Mount Burges, WA 6429",
    "Mount Burnett, VIC 3781",
    "Mount Burr, SA 5279",
    "Mount Burrell, NSW 2484",
    "Mount Bute, VIC 3324",
    "Mount Byron, QLD 4312",
    "Mount Camel, VIC 3523",
    "Mount Cameron, VIC 3370",
    "Mount Carbine, QLD 4871",
    "Mount Caroline, WA 6410",
    "Mount Chalmers, QLD 4702",
    "Mount Charles, SA 5267",
    "Mount Charlton, QLD 4741",
    "Mount Claremont, WA 6010",
    "Mount Clarence, WA 6330",
    "Mount Clear, VIC 3350",
    "Mount Colah, NSW 2079",
    "Mount Cole, VIC 3377",
    "Mount Cole Creek, VIC 3377",
    "Mount Colliery, QLD 4370",
    "Mount Collins, NSW 2794",
    "Mount Compass, SA 5210",
    "Mount Cooke, WA 6390",
    "Mount Coolon, QLD 4804",
    "Mount Coolum, QLD 4573",
    "Mount Cooper, SA 5671",
    "Mount Cooper, NSW 2631",
    "Mount Coot-tha, QLD 4066",
    "Mount Cotton, QLD 4165",
    "Mount Cottrell, VIC 3024",
    "Mount Crawford, SA 5351",
    "Mount Crosby, QLD 4306",
    "Mount Damper, SA 5654",
    "Mount Dandenong, VIC 3767",
    "Mount Darragh, NSW 2632",
    "Mount David, NSW 2795",
    "Mount Debateable, QLD 4625",
    "Mount Dee, NSW 2320",
    "Mount Delaney, QLD 4514",
    "Mount Direction, TAS 7252",
    "Mount Doran, VIC 3334",
    "Mount Druitt, NSW 2770",
    "Mount Druitt Village, NSW 2770",
    "Mount Drummond, SA 5607",
    "Mount Dryden, VIC 3381",
    "Mount Duneed, VIC 3216",
    "Mount Dutton Bay, SA 5607",
    "Mount Eccles, VIC 3953",
    "Mount Eccles South, VIC 3953",
    "Mount Edwards, QLD 4309",
    "Mount Egerton, VIC 3352",
    "Mount Eliza, VIC 3930",
    "Mount Elliot, NSW 2250",
    "Mount Elliot, QLD 4816",
    "Mount Elphinstone, WA 6330",
    "Mount Emlyn, QLD 4357",
    "Mount Emu, VIC 3351",
    "Mount Enniskillen, QLD 4472",
    "Mount Erin, WA 6532",
    "Mount Evelyn, VIC 3796",
    "Mount Fairy, NSW 2580",
    "Mount Field, TAS 7140",
    "Mount Forbes, QLD 4340",
    "Mount Foster, NSW 2824",
    "Mount Fox, QLD 4850",
    "Mount Franklin, VIC 3461",
    "Mount French, QLD 4310",
    "Mount Frome, NSW 2850",
    "Mount Gambier, SA 5290",
    "Mount Gambier, SA 5291",
    "Mount Gambier East, SA 5291",
    "Mount Gambier West, SA 5291",
    "Mount Gardiner, QLD 4705",
    "Mount Garnet, QLD 4872",
    "Mount George, NSW 2424",
    "Mount George, SA 5155",
    "Mount Gipps, QLD 4285",
    "Mount Glasgow, VIC 3371",
    "Mount Glorious, QLD 4520",
    "Mount Gravatt, QLD 4122",
    "Mount Gravatt East, QLD 4122",
    "Mount Hallen, QLD 4312",
    "Mount Hampton, WA 6426",
    "Mount Hardey, WA 6302",
    "Mount Hardman, WA 6765",
    "Mount Harris, NSW 2824",
    "Mount Hawthorn, WA 6016",
    "Mount Hawthorn, WA 6915",
    "Mount Helen, VIC 3350",
    "Mount Helena, WA 6082",
    "Mount Hicks, TAS 7325",
    "Mount Hill, WA 6528",
    "Mount Holland, WA 6426",
    "Mount Hooghly, VIC 3472",
    "Mount Hope, NSW 2877",
    "Mount Hope, SA 5607",
    "Mount Horeb, NSW 2729",
    "Mount Horner, WA 6525",
    "Mount Hotham, VIC 3741",
    "Mount Hunter, NSW 2570",
    "Mount Hutton, NSW 2290",
    "Mount Hutton, QLD 4454",
    "Mount Irvine, NSW 2786",
    "Mount Irving, QLD 4401",
    "Mount Isa, QLD 4825",
    "Mount Isa City, QLD 4825",
    "Mount Isa East, QLD 4825",
    "Mount Jackson, WA 6426",
    "Mount Jagged, SA 5211",
    "Mount Johns, NT 0870",
    "Mount Joy, SA 5670",
    "Mount Jukes, QLD 4740",
    "Mount Julian, QLD 4800",
    "Mount Keira, NSW 2500",
    "Mount Kelly, QLD 4807",
    "Mount Kembla, NSW 2526",
    "Mount Kilcoy, QLD 4515",
    "Mount Kingiman, NSW 2539",
    "Mount Knowles, NSW 2850",
    "Mount Kuring-gai, NSW 2080",
    "Mount Kynoch, QLD 4350",
    "Mount Lambie, NSW 2790",
    "Mount Larcom, QLD 4695",
    "Mount Lawless, QLD 4625",
    "Mount Lawley, WA 6050",
    "Mount Lawley, WA 6929",
    "Mount Lewis, NSW 2190",
    "Mount Lewis, NSW 2200",
    "Mount Liebig, NT 0872",
    "Mount Light, SA 5271",
    "Mount Lindesay, WA 6333",
    "Mount Lindesay, QLD 4287",
    "Mount Lindsey, NSW 2575",
    "Mount Lloyd, TAS 7140",
    "Mount Lofty, SA 5152",
    "Mount Lofty, QLD 4350",
    "Mount Lonarch, VIC 3468",
    "Mount Louisa, QLD 4814",
    "Mount Low, QLD 4818",
    "Mount Luke, QLD 4352",
    "Mount Macarthur, QLD 4723",
    "Mount Macedon, VIC 3441",
    "Mount Mackay, QLD 4854",
    "Mount Madden, WA 6356",
    "Mount Magnet, WA 6638",
    "Mount Magnificent, SA 5210",
    "Mount Major, VIC 3646",
    "Mount Maria, QLD 4674",
    "Mount Marlow, QLD 4800",
    "Mount Marrow, QLD 4306",
    "Mount Marsden, NSW 2849",
    "Mount Marsh, NSW 2469",
    "Mount Marshall, QLD 4362",
    "Mount Martha, VIC 3934",
    "Mount Martin, QLD 4754",
    "Mount Mary, SA 5374",
    "Mount Mceuen, QLD 4606",
    "Mount Mcintyre, SA 5279",
    "Mount Mckenzie, SA 5353",
    "Mount Mee, QLD 4521",
    "Mount Mellum, QLD 4550",
    "Mount Melville, WA 6330",
    "Mount Mercer, VIC 3352",
    "Mount Mitchell, NSW 2365",
    "Mount Molar, QLD 4361",
    "Mount Molloy, QLD 4871",
    "Mount Morgan, QLD 4714",
    "Mount Moriac, VIC 3240",
    "Mount Moriah, QLD 4403",
    "Mount Mort, QLD 4340",
    "Mount Mulgrave, QLD 4871",
    "Mount Mulligan, QLD 4871",
    "Mount Murchison, QLD 4715",
    "Mount Murray, NSW 2577",
    "Mount Napier, VIC 3301",
    "Mount Nasura, WA 6112",
    "Mount Nathan, QLD 4211",
    "Mount Nebo, QLD 4520",
    "Mount Nelson, TAS 7007",
    "Mount Ney, WA 6447",
    "Mount Observation, WA 6302",
    "Mount Observation, SA 5255",
    "Mount Olive, NSW 2330",
    "Mount Olive, NSW 2787",
    "Mount Ommaney, QLD 4074",
    "Mount Osmond, SA 5064",
    "Mount Ossa, QLD 4741",
    "Mount Ousley, NSW 2519",
    "Mount Palmer, WA 6426",
    "Mount Panorama, NSW 2795",
    "Mount Pelion, QLD 4741",
    "Mount Perry, QLD 4671",
    "Mount Peter, QLD 4869",
    "Mount Pleasant, WA 6153",
    "Mount Pleasant, NSW 2519",
    "Mount Pleasant, QLD 4521",
    "Mount Pleasant, SA 5235",
    "Mount Pleasant, QLD 4740",
    "Mount Pleasant, VIC 3350",
    "Mount Pluto, QLD 4800",
    "Mount Pritchard, NSW 2170",
    "Mount Prospect, VIC 3364",
    "Mount Rankin, NSW 2795",
    "Mount Rascal, QLD 4350",
    "Mount Richmond, VIC 3305",
    "Mount Richon, WA 6112",
    "Mount Rivers, NSW 2311",
    "Mount Riverview, NSW 2774",
    "Mount Romance, WA 6333",
    "Mount Rooper, QLD 4802",
    "Mount Rowan, VIC 3352",
    "Mount Royal, NSW 2330",
    "Mount Rumney, TAS 7170",
    "Mount Russell, NSW 2360",
    "Mount Sabine, VIC 3236",
    "Mount Saint Thomas, NSW 2500",
    "Mount Samson, QLD 4520",
    "Mount Schank, SA 5291",
    "Mount Seaview, NSW 2446",
    "Mount Seymour, TAS 7120",
    "Mount Sheila, WA 6751",
    "Mount Sheridan, WA 6355",
    "Mount Sheridan, QLD 4868",
    "Mount St John, QLD 4818",
    "Mount Stanley, QLD 4306",
    "Mount Steadman, QLD 4625",
    "Mount Stirling, WA 6383",
    "Mount Stromlo, ACT 2611",
    "Mount Stuart, TAS 7000",
    "Mount Stuart, QLD 4811",
    "Mount Sturt, QLD 4370",
    "Mount Surprise, QLD 4871",
    "Mount Surround, QLD 4809",
    "Mount Sylvia, QLD 4343",
    "Mount Tabor, QLD 4370",
    "Mount Tamborine, QLD 4272",
    "Mount Tarampa, QLD 4311",
    "Mount Tarcoola, WA 6530",
    "Mount Tassie, VIC 3844",
    "Mount Taylor, VIC 3875",
    "Mount Templeton, SA 5461",
    "Mount Tenandra, NSW 2828",
    "Mount Thorley, NSW 2330",
    "Mount Tom, QLD 4677",
    "Mount Tomah, NSW 2758",
    "Mount Toolebewong, VIC 3777",
    "Mount Torrens, SA 5244",
    "Mount Tully, QLD 4380",
    "Mount Tyson, QLD 4356",
    "Mount Urah, QLD 4650",
    "Mount Vernon, NSW 2178",
    "Mount Victoria, NSW 2786",
    "Mount View, NSW 2325",
    "Mount Vincent, NSW 2850",
    "Mount Vincent, NSW 2323",
    "Mount Walker, QLD 4340",
    "Mount Walker, WA 6369",
    "Mount Walker West, QLD 4340",
    "Mount Wallace, VIC 3342",
    "Mount Warning, NSW 2484",
    "Mount Warren Park, QLD 4207",
    "Mount Warrigal, NSW 2528",
    "Mount Waverley, VIC 3149",
    "Mount Wedge, SA 5670",
    "Mount Wells, WA 6390",
    "Mount Werong, NSW 2787",
    "Mount White, NSW 2250",
    "Mount Whitestone, QLD 4347",
    "Mount Wilson, NSW 2786",
    "Mount Wyatt, QLD 4804",
    "Mount Zeil, NT 0872",
    "Mountain Bay, VIC 3723",
    "Mountain Camp, QLD 4355",
    "Mountain Creek, QLD 4557",
    "Mountain Creek, NSW 2830",
    "Mountain Creek, NSW 2644",
    "Mountain Gate, VIC 3156",
    "Mountain Lagoon, NSW 2758",
    "Mountain River, TAS 7109",
    "Mountain Top, NSW 2480",
    "Mountain View, NSW 2460",
    "Mountain View, VIC 3988",
    "Moura, QLD 4718",
    "Mourilyan, QLD 4858",
    "Mourilyan Harbour, QLD 4858",
    "Mouroubra, WA 6472",
    "Mourquong, NSW 2739",
    "Moutajup, VIC 3294",
    "Mowbray, TAS 7248",
    "Mowbray, QLD 4877",
    "Mowbray Park, NSW 2571",
    "Moy Pocket, QLD 4574",
    "Moyarra, VIC 3951",
    "Moyhall, SA 5271",
    "Moyhu, VIC 3732",
    "Moyreisk, VIC 3477",
    "Moyston, VIC 3377",
    "Mozart, NSW 2787",
    "Mp Creek, QLD 4606",
    "Muchea, WA 6501",
    "Muckadilla, QLD 4461",
    "Muckatah, VIC 3644",
    "Muckaty Station, NT 0862",
    "Muckenburra, WA 6503",
    "Muckleford, VIC 3451",
    "Muckleford South, VIC 3462",
    "Mudamuckla, SA 5680",
    "Mudgee, NSW 2850",
    "Mudgeeraba, QLD 4213",
    "Mudgegonga, VIC 3737",
    "Mudjimba, QLD 4564",
    "Mudlo, QLD 4600",
    "Mueller Ranges, WA 6770",
    "Muirhead, NT 0810",
    "Muirlea, QLD 4306",
    "Muja, WA 6225",
    "Mukinbudin, WA 6479",
    "Mulambin, QLD 4703",
    "Mulara, QLD 4703",
    "Mulataga, WA 6714",
    "Mulbring, NSW 2323",
    "Muldu, QLD 4401",
    "Mulga Bore, NT 0872",
    "Mulga Downs, WA 6751",
    "Mulgildie, QLD 4630",
    "Mulgoa, NSW 2745",
    "Mulgowie, QLD 4341",
    "Mulgrave, QLD 4807",
    "Mulgrave, VIC 3170",
    "Mulgrave, NSW 2756",
    "Mulgrave Island, QLD 4875",
    "Mulgundawa, SA 5255",
    "Mulguthrie, NSW 2877",
    "Muli Muli, NSW 2476",
    "Mulla, NSW 2825",
    "Mulla Creek, NSW 2352",
    "Mullaley, NSW 2379",
    "Mullaloo, WA 6027",
    "Mullalyup, WA 6252",
    "Mullamuddy, NSW 2850",
    "Mullaquana, SA 5600",
    "Mullaway, NSW 2456",
    "Mullengandra, NSW 2644",
    "Mullengudgery, NSW 2825",
    "Mullett Creek, QLD 4670",
    "Mullewa, WA 6630",
    "Mullingar, WA 6430",
    "Mullion, NSW 2582",
    "Mullion Creek, NSW 2800",
    "Mulloon, NSW 2622",
    "Mullumbimby, NSW 2482",
    "Mullumbimby Creek, NSW 2482",
    "Muluckine, WA 6401",
    "Mulwala, NSW 2647",
    "Mulyandry, NSW 2871",
    "Mumballup, WA 6225",
    "Mumbannar, VIC 3304",
    "Mumberkine, WA 6401",
    "Mumbil, NSW 2820",
    "Mumblebone Plain, NSW 2824",
    "Mumbulla Mountain, NSW 2550",
    "Mummel, NSW 2580",
    "Mummulgum, NSW 2469",
    "Munbilla, QLD 4309",
    "Munbura, QLD 4740",
    "Mundabullangana, WA 6721",
    "Mundallio, SA 5700",
    "Mundamia, NSW 2540",
    "Mundaring, WA 6073",
    "Mundarlo, NSW 2729",
    "Munderoo, NSW 2653",
    "Munderra, QLD 4872",
    "Mundic Creek, SA 5340",
    "Mundijong, WA 6123",
    "Mundingburra, QLD 4812",
    "Mundongo, NSW 2720",
    "Mundoo, QLD 4860",
    "Mundoo Island, SA 5214",
    "Mundoolun, QLD 4285",
    "Mundoona, VIC 3635",
    "Mundoora, SA 5555",
    "Mundowran, QLD 4626",
    "Mundrabilla, WA 6443",
    "Mundubbera, QLD 4626",
    "Mundulla, SA 5270",
    "Mundulla West, SA 5270",
    "Mungabunda, QLD 4718",
    "Mungallala, QLD 4467",
    "Mungalli, QLD 4886",
    "Mungalup, WA 6225",
    "Mungar, QLD 4650",
    "Mungay Creek, NSW 2440",
    "Munghorn, NSW 2850",
    "Mungindi, NSW 2406",
    "Mungindi, QLD 2406",
    "Munglinup, WA 6450",
    "Mungo, NSW 2715",
    "Mungo Brush, NSW 2423",
    "Mungungo, QLD 4630",
    "Mungy, QLD 4671",
    "Muniganeen, QLD 4352",
    "Munna Creek, QLD 4570",
    "Munni, NSW 2420",
    "Munno Para, SA 5115",
    "Munno Para Downs, SA 5115",
    "Munno Para West, SA 5115",
    "Munro, VIC 3862",
    "Munro Plains, QLD 4854",
    "Munruben, QLD 4125",
    "Munster, WA 6166",
    "Muntadgin, WA 6420",
    "Muntham, VIC 3315",
    "Munyabla, NSW 2658",
    "Muradup, WA 6394",
    "Murarrie, QLD 4172",
    "Murbko, SA 5320",
    "Murchison, VIC 3610",
    "Murchison, WA 6630",
    "Murchison East, VIC 3610",
    "Murchison North, VIC 3610",
    "Murdinga, SA 5607",
    "Murdoch, WA 6150",
    "Murdong, WA 6317",
    "Murdunna, TAS 7178",
    "Muresk, WA 6401",
    "Murga, NSW 2864",
    "Murgheboluc, VIC 3221",
    "Murgon, QLD 4605",
    "Murlong, SA 5642",
    "Murmungee, VIC 3747",
    "Murnungin, VIC 3544",
    "Muronbung, NSW 2830",
    "Murphys Creek, VIC 3551",
    "Murphys Creek, QLD 4352",
    "Murputja Homelands, SA 0872",
    "Murra Warra, VIC 3401",
    "Murrabit, VIC 3579",
    "Murrabit West, VIC 3579",
    "Murrah, NSW 2546",
    "Murrami, NSW 2705",
    "Murrawee, VIC 3586",
    "Murrawombie, NSW 2825",
    "Murrawong, SA 5253",
    "Murray, QLD 4814",
    "Murray Bridge, SA 5253",
    "Murray Bridge, SA 5254",
    "Murray Bridge East, SA 5253",
    "Murray Bridge North, SA 5253",
    "Murray Bridge South, SA 5253",
    "Murray Downs, NSW 2734",
    "Murray Gorge, NSW 2642",
    "Murray Island, QLD 4875",
    "Murray Town, SA 5481",
    "Murray Upper, QLD 4854",
    "Murray-sunset, VIC 3490",
    "Murraydale, VIC 3586",
    "Murrays Beach, NSW 2281",
    "Murrays Bridge, QLD 4370",
    "Murrayville, VIC 3512",
    "Murrengenburg, NSW 2622",
    "Murrigal, QLD 4854",
    "Murrin Bridge, NSW 2672",
    "Murrindal, VIC 3885",
    "Murrindindi, VIC 3717",
    "Murringo, NSW 2586",
    "Murroon, VIC 3243",
    "Murrulebale, NSW 2652",
    "Murrumba, QLD 4312",
    "Murrumba Downs, QLD 4503",
    "Murrumbateman, NSW 2582",
    "Murrumbeena, VIC 3163",
    "Murrumbidgerie, NSW 2830",
    "Murrumbo, NSW 2849",
    "Murrumbucca, NSW 2630",
    "Murrumburrah, NSW 2587",
    "Murrumujuk, NT 0822",
    "Murrurundi, NSW 2338",
    "Murtho, SA 5340",
    "Murtoa, VIC 3390",
    "Murulla, NSW 2337",
    "Murweh, QLD 4470",
    "Murwillumbah, NSW 2484",
    "Murwillumbah South, NSW 2484",
    "Muscle Creek, NSW 2333",
    "Musk, VIC 3461",
    "Musk Vale, VIC 3461",
    "Muskerry, VIC 3557",
    "Musselroe Bay, TAS 7264",
    "Muston, SA 5221",
    "Muswellbrook, NSW 2333",
    "Mutarnee, QLD 4816",
    "Mutawintji, NSW 2880",
    "Mutchilba, QLD 4872",
    "Mutdapilly, QLD 4307",
    "Mutitjulu, NT 0872",
    "Muttaburra, QLD 4732",
    "Muttama, NSW 2722",
    "Myall, VIC 3579",
    "Myall, VIC 3533",
    "Myall Creek, NSW 2403",
    "Myall Lake, NSW 2423",
    "Myall Park, QLD 4415",
    "Myall Park, NSW 2681",
    "Myalla, NSW 2630",
    "Myalla, TAS 7325",
    "Myalup, WA 6220",
    "Myamyn, VIC 3304",
    "Myara, WA 6207",
    "Myaree, WA 6154",
    "Myaree Bc, WA 6960",
    "Myers Flat, VIC 3556",
    "Mylestom, NSW 2454",
    "Mylneford, NSW 2460",
    "Mylor, SA 5153",
    "Myocum, NSW 2481",
    "Myola, VIC 3551",
    "Myola, NSW 2540",
    "Mypolonga, SA 5254",
    "Myponga, SA 5202",
    "Myponga Beach, SA 5202",
    "Myrniong, VIC 3341",
    "Myrrhee, VIC 3732",
    "Myrtle Bank, SA 5064",
    "Myrtle Bank, TAS 7259",
    "Myrtle Creek, VIC 3551",
    "Myrtle Creek, NSW 2469",
    "Myrtle Mountain, NSW 2550",
    "Myrtle Park, NSW 2713",
    "Myrtlebank, VIC 3851",
    "Myrtleford, VIC 3736",
    "Myrtleford, VIC 3737",
    "Myrtlevale, QLD 4800",
    "Myrtleville, NSW 2580",
    "Myrup, WA 6450",
    "Mysia, VIC 3518",
    "Mysterton, QLD 4812",
    "Mystery Bay, NSW 2546",
    "Mystic Park, VIC 3579",
    "Myuna Bay, NSW 2264",
    "Mywee, VIC 3641",
    "Nabageena, TAS 7330",
    "Nabawa, WA 6532",
    "Nabiac, NSW 2312",
    "Nabowla, TAS 7260",
    "Nackara, SA 5440",
    "Nadgee, NSW 2551",
    "Nadia, SA 5690",
    "Nagambie, VIC 3608",
    "Nahrunda, QLD 4570",
    "Naidia, SA 5354",
    "Nailsworth, SA 5083",
    "Nain, SA 5360",
    "Nairibin, WA 6350",
    "Nairne, SA 5252",
    "Nakara, NT 0810",
    "Nakara, NT 0810",
    "Nalangil, VIC 3249",
    "Nalinga, VIC 3646",
    "Nalkain, WA 6485",
    "Nalpa, SA 5255",
    "Nalyappa, SA 5558",
    "Nalyerlup, WA 6338",
    "Namatjira, NT 0872",
    "Namban, WA 6512",
    "Nambeelup, WA 6207",
    "Nambour, QLD 4560",
    "Nambour Bc, QLD 4560",
    "Nambour West, QLD 4560",
    "Nambrok, VIC 3847",
    "Nambucca Heads, NSW 2448",
    "Nambung, WA 6521",
    "Namoi River, NSW 2346",
    "Nana Glen, NSW 2450",
    "Nanango, QLD 4615",
    "Nanarup, WA 6330",
    "Nandaly, VIC 3533",
    "Nandowrie, QLD 4722",
    "Nanga, WA 6537",
    "Nanga Brook, WA 6215",
    "Nangana, VIC 3781",
    "Nangar, NSW 2800",
    "Nangeela, VIC 3312",
    "Nangeenan, WA 6414",
    "Nangetty, WA 6522",
    "Nangiloc, VIC 3494",
    "Nangkita, SA 5210",
    "Nangram, QLD 4416",
    "Nangus, NSW 2722",
    "Nangwarry, SA 5277",
    "Nangwee, QLD 4407",
    "Nanima, NSW 2820",
    "Nanima, NSW 2618",
    "Nankin, QLD 4701",
    "Nanneella, VIC 3561",
    "Nannup, WA 6275",
    "Nanson, WA 6532",
    "Nantawarra, SA 5550",
    "Nanum, QLD 4874",
    "Nanutarra, WA 6751",
    "Nap Nap Marra, VIC 3860",
    "Napier, WA 6330",
    "Napier Lane, NSW 2379",
    "Napoleon Reef, NSW 2795",
    "Napoleons, VIC 3352",
    "Napperby, SA 5540",
    "Napranum, QLD 4874",
    "Nar Nar Goon, VIC 3812",
    "Nar Nar Goon North, VIC 3812",
    "Naracoopa, TAS 7256",
    "Naracoorte, SA 5271",
    "Naradhan, NSW 2669",
    "Naraling, WA 6532",
    "Narangba, QLD 4504",
    "Narara, NSW 2250",
    "Narbethong, VIC 3778",
    "Nardoo Siding, QLD 4471",
    "Nareeb, VIC 3293",
    "Nareen, VIC 3315",
    "Nareewillock, VIC 3525",
    "Narellan, NSW 2567",
    "Narellan Vale, NSW 2567",
    "Narembeen, WA 6369",
    "Naremburn, NSW 2065",
    "Nariel Valley, VIC 3707",
    "Naring, VIC 3636",
    "Naringal, VIC 3277",
    "Naringal East, VIC 3277",
    "Narko, QLD 4352",
    "Narngulu, WA 6532",
    "Naroghid, VIC 3266",
    "Narooma, NSW 2546",
    "Narra Tarra, WA 6532",
    "Narrabarba, NSW 2551",
    "Narrabeen, NSW 2101",
    "Narrabri, NSW 2390",
    "Narrabri West, NSW 2390",
    "Narrabundah, ACT 2604",
    "Narraburra, NSW 2666",
    "Narracan, VIC 3824",
    "Narraloggan, WA 6302",
    "Narran Lake, NSW 2839",
    "Narrandera, NSW 2700",
    "Narrangullen, NSW 2582",
    "Narraport, VIC 3483",
    "Narrapumelap South, VIC 3293",
    "Narrawa, NSW 2583",
    "Narrawallee, NSW 2539",
    "Narraweena, NSW 2099",
    "Narrawong, VIC 3285",
    "Narre Warren, VIC 3805",
    "Narre Warren East, VIC 3804",
    "Narre Warren North, VIC 3804",
    "Narre Warren South, VIC 3805",
    "Narridy, SA 5523",
    "Narrikup, WA 6326",
    "Narrogin, WA 6312",
    "Narrogin Valley, WA 6312",
    "Narromine, NSW 2821",
    "Narrung, VIC 3597",
    "Narrung, SA 5259",
    "Narwee, NSW 2209",
    "Nashdale, NSW 2800",
    "Nashua, NSW 2479",
    "Nathalia, VIC 3638",
    "Nathan, QLD 4111",
    "Natimuk, VIC 3409",
    "National Park, TAS 7140",
    "Natone, TAS 7321",
    "Nattai, NSW 2570",
    "Natte Yallock, VIC 3465",
    "Natural Bridge, QLD 4211",
    "Naturaliste, WA 6281",
    "Naturi, SA 5259",
    "Natya, VIC 3597",
    "Naughtons Gap, NSW 2470",
    "Nauiyu, NT 0822",
    "Naval Base, WA 6165",
    "Navan, SA 5412",
    "Navarre, VIC 3384",
    "Navigators, VIC 3352",
    "Nayook, VIC 3832",
    "Neale, WA 6440",
    "Neales Flat, SA 5374",
    "Nearum, QLD 4671",
    "Neath, NSW 2326",
    "Nebea, NSW 2829",
    "Nebine, QLD 4488",
    "Nebo, QLD 4742",
    "Nectar Brook, SA 5495",
    "Nedlands, WA 6907",
    "Nedlands, WA 6909",
    "Nedlands, WA 6009",
    "Neds Corner, VIC 3496",
    "Needilup, WA 6336",
    "Needles, TAS 7304",
    "Neendaling, WA 6353",
    "Neerabup, WA 6031",
    "Neerdie, QLD 4570",
    "Neereman, VIC 3463",
    "Neergabby, WA 6503",
    "Neerim, VIC 3831",
    "Neerim East, VIC 3831",
    "Neerim Junction, VIC 3832",
    "Neerim North, VIC 3832",
    "Neerim South, VIC 3831",
    "Neika, TAS 7054",
    "Neilborough, VIC 3570",
    "Neilrex, NSW 2831",
    "Nelia, QLD 4816",
    "Nelligen, NSW 2536",
    "Nelly Bay, QLD 4819",
    "Nelse, VIC 3699",
    "Nelshaby, SA 5540",
    "Nelson, NSW 2550",
    "Nelson, NSW 2765",
    "Nelson, VIC 3292",
    "Nelson Bay, TAS 7330",
    "Nelson Bay, NSW 2315",
    "Nelsons Plains, NSW 2324",
    "Nelungaloo, NSW 2876",
    "Nemarluk, NT 0822",
    "Nembudding, WA 6485",
    "Nemingha, NSW 2340",
    "Nene Valley, SA 5291",
    "Nepabunna, SA 5732",
    "Nepean Bay, SA 5223",
    "Nerada, QLD 4860",
    "Nerang, QLD 4211",
    "Nerang Bc, QLD 4211",
    "Neranwood, QLD 4213",
    "Nericon, NSW 2680",
    "Neridup, WA 6450",
    "Nerimbera, QLD 4701",
    "Neringla, NSW 2622",
    "Nerong, NSW 2423",
    "Nerramyne, WA 6630",
    "Nerren Nerren, WA 6532",
    "Nerrena, VIC 3953",
    "Nerriga, NSW 2622",
    "Nerrigundah, NSW 2545",
    "Nerrin Nerrin, VIC 3351",
    "Nerrina, VIC 3350",
    "Nerring, VIC 3373",
    "Netherby, VIC 3418",
    "Netherby, QLD 4650",
    "Netherby, SA 5062",
    "Nethercote, NSW 2549",
    "Netherdale, QLD 4756",
    "Netherton, SA 5301",
    "Netley, SA 5037",
    "Neuarpurr, VIC 3413",
    "Neumgna, QLD 4614",
    "Neurea, NSW 2820",
    "Neurum, QLD 4514",
    "Neusa Vale, QLD 4570",
    "Neutral Bay, NSW 2089",
    "Neutral Bay Junction, NSW 2089",
    "Never Never, NSW 2453",
    "Nevertire, NSW 2831",
    "Neville, NSW 2799",
    "Nevilton, QLD 4361",
    "New Auckland, QLD 4680",
    "New Beith, QLD 4124",
    "New Berrima, NSW 2577",
    "New Brighton, NSW 2483",
    "New Buildings, NSW 2550",
    "New Chum, QLD 4303",
    "New Farm, QLD 4005",
    "New Gisborne, VIC 3438",
    "New Harbourline, QLD 4858",
    "New Italy, NSW 2472",
    "New Lambton, NSW 2305",
    "New Lambton Heights, NSW 2305",
    "New Mapoon, QLD 4876",
    "New Mexico, NSW 2346",
    "New Moonta, QLD 4671",
    "New Norcia, WA 6509",
    "New Norfolk, TAS 7140",
    "New Park, NSW 2474",
    "New Port, SA 5015",
    "New Residence, SA 5333",
    "New Town, TAS 7008",
    "New Town, SA 5554",
    "New Valley, NSW 2365",
    "New Well, SA 5357",
    "Newbold, NSW 2460",
    "Newborough, VIC 3825",
    "Newborough East, VIC 3825",
    "Newbridge, VIC 3551",
    "Newbridge, NSW 2795",
    "Newbury, VIC 3458",
    "Newcarlbeon, WA 6475",
    "Newcastle, NSW 2300",
    "Newcastle East, NSW 2300",
    "Newcastle University, NSW 2308",
    "Newcastle Waters, NT 0862",
    "Newcastle West, NSW 2302",
    "Newcomb, VIC 3219",
    "Newdegate, WA 6355",
    "Newee Creek, NSW 2447",
    "Newell, QLD 4873",
    "Newfield, VIC 3268",
    "Newham, VIC 3442",
    "Newhaven, VIC 3925",
    "Newington, VIC 3350",
    "Newington, NSW 2127",
    "Newland, SA 5223",
    "Newlands, WA 6251",
    "Newlands, QLD 4804",
    "Newlands Arm, VIC 3875",
    "Newlyn, VIC 3364",
    "Newlyn North, VIC 3364",
    "Newman, WA 6753",
    "Newmarket, QLD 4051",
    "Newmerella, VIC 3886",
    "Newnes, NSW 2790",
    "Newnes Plateau, NSW 2790",
    "Newnham, TAS 7248",
    "Newport, VIC 3015",
    "Newport, QLD 4020",
    "Newport, NSW 2106",
    "Newport Beach, NSW 2106",
    "Newry, VIC 3859",
    "Newrybar, NSW 2479",
    "Newstead, NSW 2360",
    "Newstead, QLD 4006",
    "Newstead, TAS 7250",
    "Newstead, VIC 3462",
    "Newton, SA 5074",
    "Newton Boyd, NSW 2370",
    "Newtown, NSW 2042",
    "Newtown, QLD 4305",
    "Newtown, QLD 4350",
    "Newtown, VIC 3351",
    "Newtown, VIC 3220",
    "Ngaanyatjarra-giles, WA 0872",
    "Nganmarriyanga, NT 0822",
    "Ngapala, SA 5374",
    "Ngarkat, SA 5302",
    "Ngatjan, QLD 4860",
    "Ngukurr, NT 0852",
    "Ngunnawal, ACT 2913",
    "Nhill, VIC 3418",
    "Nhulunbuy, NT 0880",
    "Nhulunbuy, NT 0881",
    "Niagara Park, NSW 2250",
    "Niangala, NSW 2354",
    "Nicholls, ACT 2913",
    "Nicholls Rivulet, TAS 7112",
    "Nichols Point, VIC 3501",
    "Nicholson, NT 0862",
    "Nicholson, QLD 4830",
    "Nicholson, VIC 3882",
    "Nickol, WA 6714",
    "Niddrie, VIC 3042",
    "Niemur, NSW 2733",
    "Nietta, TAS 7315",
    "Nightcap, NSW 2480",
    "Nightcliff, NT 0810",
    "Nightcliff, NT 0814",
    "Nightcliff, NT 0810",
    "Nikenbah, QLD 4655",
    "Nildottie, SA 5238",
    "Nile, TAS 7212",
    "Nilgen, WA 6044",
    "Nillup, WA 6288",
    "Nilma, VIC 3821",
    "Nilma North, VIC 3821",
    "Nimbin, NSW 2480",
    "Nimmitabel, NSW 2631",
    "Nimmo, NSW 2628",
    "Ninda, VIC 3533",
    "Nindaroo, QLD 4740",
    "Ninderry, QLD 4561",
    "Nindooinbah, QLD 4285",
    "Nine Mile, VIC 3518",
    "Nine Mile, QLD 4702",
    "Nine Mile Creek, QLD 4714",
    "Ningaloo, WA 6701",
    "Ningi, QLD 4511",
    "Ninnes, SA 5560",
    "Nintingbool, VIC 3351",
    "Ninyeunook, VIC 3527",
    "Nippering, WA 6350",
    "Nirimba, WA 6208",
    "Nirranda, VIC 3268",
    "Nirranda East, VIC 3268",
    "Nirranda South, VIC 3268",
    "Nitmiluk, NT 0852",
    "No 4 Branch, QLD 4856",
    "No 5 Branch, QLD 4856",
    "No 6 Branch, QLD 4859",
    "Noah, QLD 4873",
    "Noarlunga Centre, SA 5168",
    "Noarlunga Downs, SA 5168",
    "Nobby, QLD 4360",
    "Nobby Beach, QLD 4218",
    "Nobbys Creek, NSW 2484",
    "Noble Park, VIC 3174",
    "Noble Park East, VIC 3174",
    "Noble Park North, VIC 3174",
    "Nockatunga, QLD 4492",
    "Noggerup, WA 6225",
    "Nokaning, WA 6415",
    "Nolba, WA 6532",
    "Nollamara, WA 6061",
    "Nomans Lake, WA 6312",
    "Nombi, NSW 2379",
    "Nome, QLD 4816",
    "Nonda, QLD 4822",
    "Nonning, SA 5710",
    "Noojee, VIC 3833",
    "Nook, TAS 7306",
    "Noona, NSW 2835",
    "Noonamah, NT 0837",
    "Noorama, QLD 4490",
    "Noorat, VIC 3265",
    "Noorat East, VIC 3265",
    "Noorinbee, VIC 3890",
    "Noorinbee North, VIC 3890",
    "Noorindoo, QLD 4417",
    "Noorong, NSW 2732",
    "Nooroo, NSW 2415",
    "Noosa Heads, QLD 4567",
    "Noosa North Shore, QLD 4565",
    "Noosaville, QLD 4566",
    "Noosaville Bc, QLD 4566",
    "Nora Creina, SA 5276",
    "Noradjuha, VIC 3409",
    "Norah Head, NSW 2263",
    "Noranda, WA 6062",
    "Noraville, NSW 2263",
    "Nords Wharf, NSW 2281",
    "Norfolk Island, NSW 2899",
    "Norlane, VIC 3214",
    "Norley, QLD 4492",
    "Norman, QLD 4890",
    "Norman Gardens, QLD 4701",
    "Norman Park, QLD 4170",
    "Normanhurst, NSW 2076",
    "Normanton, QLD 4890",
    "Normanville, VIC 3579",
    "Normanville, SA 5204",
    "Nornalup, WA 6333",
    "Norong, VIC 3682",
    "Norpa, WA 6415",
    "Norseman, WA 6443",
    "North Adelaide, SA 5006",
    "North Adelaide Melbourne St, SA 5006",
    "North Albury, NSW 2640",
    "North Aramara, QLD 4620",
    "North Arm, QLD 4561",
    "North Arm, NSW 2484",
    "North Arm Cove, NSW 2324",
    "North Avoca, NSW 2260",
    "North Baandee, WA 6412",
    "North Balgowlah, NSW 2093",
    "North Bannister, WA 6390",
    "North Batemans Bay, NSW 2536",
    "North Beach, WA 6020",
    "North Beach, SA 5556",
    "North Beach, WA 6920",
    "North Bendigo, VIC 3550",
    "North Blackwood, VIC 3458",
    "North Boambee Valley, NSW 2450",
    "North Bodallin, WA 6424",
    "North Bondi, NSW 2026",
    "North Booborowie, SA 5417",
    "North Booval, QLD 4304",
    "North Bourke, NSW 2840",
    "North Boyanup, WA 6237",
    "North Branch, QLD 4356",
    "North Branch, QLD 4370",
    "North Brighton, SA 5048",
    "North Brother, NSW 2443",
    "North Bruny, TAS 7150",
    "North Bungunya, QLD 4494",
    "North Burngup, WA 6353",
    "North Cairns, QLD 4870",
    "North Cape, SA 5223",
    "North Cascade, WA 6445",
    "North Casino, NSW 2470",
    "North Coogee, WA 6163",
    "North Curl Curl, NSW 2099",
    "North Dandalup, WA 6207",
    "North Deep Creek, QLD 4570",
    "North Dorrigo, NSW 2453",
    "North Epping, NSW 2121",
    "North Eradu, WA 6532",
    "North Eton, QLD 4741",
    "North Fremantle, WA 6159",
    "North Geelong, VIC 3215",
    "North Gosford, NSW 2250",
    "North Greenbushes, WA 6254",
    "North Gregory, QLD 4660",
    "North Gregory, QLD 4800",
    "North Haven, SA 5018",
    "North Haven, NSW 2443",
    "North Hobart, TAS 7000",
    "North Hobart, TAS 7002",
    "North Ipswich, QLD 4305",
    "North Isis, QLD 4660",
    "North Jindong, WA 6280",
    "North Johnstone, QLD 4885",
    "North Kellerberrin, WA 6410",
    "North Kukerin, WA 6352",
    "North Kununoppin, WA 6489",
    "North Lake, WA 6163",
    "North Lake Grace, WA 6353",
    "North Lakes, QLD 4509",
    "North Lambton, NSW 2299",
    "North Lilydale, TAS 7268",
    "North Lismore, NSW 2480",
    "North Mackay, QLD 4740",
    "North Macksville, NSW 2447",
    "North Maclagan, QLD 4352",
    "North Maclean, QLD 4280",
    "North Macquarie, NSW 2527",
    "North Maleny, QLD 4552",
    "North Manly, NSW 2100",
    "North Melbourne, VIC 3051",
    "North Moonta, SA 5558",
    "North Motton, TAS 7315",
    "North Moulyinning, WA 6351",
    "North Narooma, NSW 2546",
    "North Narrabeen, NSW 2101",
    "North Nowra, NSW 2541",
    "North Parramatta, NSW 2151",
    "North Parramatta, NSW 1750",
    "North Perth, WA 6906",
    "North Perth, WA 6006",
    "North Plantations, WA 6701",
    "North Plympton, SA 5037",
    "North Richmond, NSW 2754",
    "North Road, VIC 3187",
    "North Rocks, NSW 2151",
    "North Rothbury, NSW 2335",
    "North Ryde, NSW 1671",
    "North Ryde, NSW 2113",
    "North Ryde Bc, NSW 1670",
    "North Scottsdale, TAS 7260",
    "North Shields, SA 5607",
    "North Shore, NSW 2444",
    "North Shore, VIC 3214",
    "North St Marys, NSW 2760",
    "North Star, NSW 2408",
    "North Stirlings, WA 6338",
    "North Stradbroke Island, QLD 4183",
    "North Strathfield, NSW 2137",
    "North Sydney, NSW 2055",
    "North Sydney, NSW 2059",
    "North Sydney, NSW 2060",
    "North Sydney Shoppingworld, NSW 2060",
    "North Talwood, QLD 4496",
    "North Tamborine, QLD 4272",
    "North Tammin, WA 6409",
    "North Tamworth, NSW 2340",
    "North Tivoli, QLD 4305",
    "North Toowoomba, QLD 4350",
    "North Trayning, WA 6488",
    "North Tumbulgum, NSW 2490",
    "North Turramurra, NSW 2074",
    "North Wagga Wagga, NSW 2650",
    "North Wahroonga, NSW 2076",
    "North Walpole, WA 6398",
    "North Wangaratta, VIC 3678",
    "North Ward, QLD 4810",
    "North Warrandyte, VIC 3113",
    "North West Bend, SA 5320",
    "North West Cape, WA 6707",
    "North Wialki, WA 6473",
    "North Willoughby, NSW 2068",
    "North Wollongong, NSW 2500",
    "North Wonthaggi, VIC 3995",
    "North Woodburn, NSW 2471",
    "North Yalgogrin, NSW 2671",
    "North Yelbeni, WA 6487",
    "North Yelta, SA 5558",
    "North Yeoval, NSW 2868",
    "North Yunderup, WA 6208",
    "Northam, WA 6401",
    "Northampton, WA 6535",
    "Northangera, NSW 2622",
    "Northbridge, NSW 2063",
    "Northbridge, NSW 1560",
    "Northbridge, WA 6803",
    "Northbridge, WA 6865",
    "Northbridge, WA 6003",
    "Northcliffe, WA 6262",
    "Northcote, VIC 3070",
    "Northcote South, VIC 3070",
    "Northdown, TAS 7307",
    "Northern Gully, WA 6532",
    "Northern Heights, SA 5253",
    "Northfield, SA 5085",
    "Northgate, QLD 4013",
    "Northgate, SA 5085",
    "Northhead, QLD 4871",
    "Northlakes, NT 0812",
    "Northland Centre, VIC 3072",
    "Northlands, QLD 4350",
    "Northlands Lpo, WA 6905",
    "Northmead, NSW 2152",
    "Northwood, VIC 3660",
    "Northwood, NSW 2066",
    "Norton Summit, SA 5136",
    "Norval, VIC 3377",
    "Norville, QLD 4670",
    "Norway, NSW 2787",
    "Norwell, QLD 4208",
    "Norwin, QLD 4356",
    "Norwood, TAS 7250",
    "Norwood, SA 5067",
    "Norwood Avenue Po, TAS 7250",
    "Norwood South, SA 5067",
    "Notley Hills, TAS 7275",
    "Notting Hill, VIC 3168",
    "Notts Well, SA 5357",
    "Novar Gardens, SA 5040",
    "Nowa Nowa, VIC 3887",
    "Nowendoc, NSW 2354",
    "Nowergup, WA 6032",
    "Nowhere Creek, VIC 3469",
    "Nowhere Else, TAS 7306",
    "Nowie, VIC 3585",
    "Nowley, NSW 2386",
    "Nowra, NSW 2541",
    "Nowra East, NSW 2541",
    "Nowra Hill, NSW 2540",
    "Nowra Naval Po, NSW 2540",
    "Nowra North, NSW 2541",
    "Nubba, NSW 2587",
    "Nubeena, TAS 7184",
    "Nubingerie, NSW 2831",
    "Nudgee, QLD 4014",
    "Nudgee Beach, QLD 4014",
    "Nug Nug, VIC 3737",
    "Nugadong, WA 6609",
    "Nugara, TAS 7256",
    "Nugent, TAS 7172",
    "Nuggetty, VIC 3463",
    "Nukarni, WA 6480",
    "Nukku, QLD 4306",
    "Nulkaba, NSW 2325",
    "Nulla Vale, VIC 3435",
    "Nullagine, WA 6758",
    "Nullaki, WA 6330",
    "Nullamanna, NSW 2360",
    "Nullawarre, VIC 3268",
    "Nullawarre North, VIC 3268",
    "Nullawil, VIC 3529",
    "Nullica, NSW 2551",
    "Nullo Mountain, NSW 2849",
    "Nulsen, WA 6450",
    "Numbaa, NSW 2540",
    "Number One, NSW 2424",
    "Numbla Vale, NSW 2628",
    "Numbugga, NSW 2550",
    "Numbulwar, NT 0822",
    "Numburindi, NT 0822",
    "Numeralla, NSW 2630",
    "Numinbah, NSW 2484",
    "Numinbah Valley, QLD 4211",
    "Numulgi, NSW 2480",
    "Numurkah, VIC 3636",
    "Nunamara, TAS 7259",
    "Nunawading, VIC 3131",
    "Nunawading Bc, VIC 3110",
    "Nundah, QLD 4012",
    "Nunderi, NSW 2484",
    "Nundle, NSW 2340",
    "Nundroo, SA 5690",
    "Nundubbermere, QLD 4380",
    "Nungarin, WA 6490",
    "Nungatta, NSW 2551",
    "Nungatta South, NSW 2551",
    "Nungurner, VIC 3909",
    "Nunierra, WA 6630",
    "Nunile, WA 6566",
    "Nunjikompita, SA 5680",
    "Nunniong, VIC 3896",
    "Nurcoung, VIC 3401",
    "Nurenmerenmong, NSW 2649",
    "Nuriootpa, SA 5355",
    "Nurom, SA 5523",
    "Nurrabiel, VIC 3401",
    "Nurragi, SA 5256",
    "Nurran, VIC 3888",
    "Nutfield, VIC 3099",
    "Nutgrove, QLD 4352",
    "Nyabing, WA 6341",
    "Nyah, VIC 3594",
    "Nyah West, VIC 3595",
    "Nyapari, SA 0872",
    "Nyarrin, VIC 3533",
    "Nychum, QLD 4871",
    "Nyerimilang, VIC 3909",
    "Nyirripi, NT 0872",
    "Nymagee, NSW 2831",
    "Nymboida, NSW 2460",
    "Nyngan, NSW 2825",
    "Nyora, VIC 3987",
    "Nyora, NSW 2646",
    "Nyrang Creek, NSW 2804",
    "Nyrraby, VIC 3585",
    "O'bil Bil, QLD 4626",
    "O'briens Hill, QLD 4860",
    "O'connell, QLD 4680",
    "O'connell, NSW 2795",
    "O'connor, WA 6163",
    "O'connor, ACT 2602",
    "O'halloran Hill, SA 5158",
    "O'malley, ACT 2606",
    "O'sullivan Beach, SA 5166",
    "Oak Beach, QLD 4877",
    "Oak Flats, NSW 2529",
    "Oak Park, VIC 3046",
    "Oak Valley, QLD 4811",
    "Oakajee, WA 6532",
    "Oakbank, SA 5243",
    "Oakdale, QLD 4605",
    "Oakdale, NSW 2570",
    "Oakden, SA 5086",
    "Oakdowns, TAS 7019",
    "Oakenden, QLD 4741",
    "Oakey, QLD 4401",
    "Oakey Creek, QLD 4714",
    "Oakford, WA 6121",
    "Oakhampton, NSW 2320",
    "Oakhampton Heights, NSW 2320",
    "Oakhurst, QLD 4650",
    "Oakhurst, NSW 2761",
    "Oaklands, NSW 2646",
    "Oaklands Junction, VIC 3063",
    "Oaklands Park, SA 5046",
    "Oakleigh, VIC 3166",
    "Oakleigh East, VIC 3166",
    "Oakleigh South, VIC 3167",
    "Oakley, WA 6208",
    "Oaks, TAS 7303",
    "Oaks Estate, ACT 2620",
    "Oakvale, VIC 3540",
    "Oakview, QLD 4600",
    "Oakville, NSW 2765",
    "Oakwood, NSW 2360",
    "Oakwood, QLD 4670",
    "Oaky Creek, QLD 4285",
    "Oaky Park, NSW 2790",
    "Oallen, NSW 2622",
    "Oatlands, TAS 7120",
    "Oatlands, NSW 2117",
    "Oatley, NSW 2223",
    "Ob Flat, SA 5291",
    "Oban, NSW 2365",
    "Obanvale, NSW 2330",
    "Oberina, QLD 4417",
    "Oberne Creek, NSW 2650",
    "Oberon, NSW 2787",
    "Obi Obi, QLD 4574",
    "Obley, NSW 2868",
    "Obum Obum, QLD 4309",
    "Ocean Beach, WA 6333",
    "Ocean Grange, VIC 3880",
    "Ocean Grove, VIC 3226",
    "Ocean Reef, WA 6027",
    "Ocean Shores, NSW 2483",
    "Ocean View, QLD 4521",
    "Ocean Vista, TAS 7320",
    "Oenpelli, NT 0822",
    "Officer, VIC 3809",
    "Officer South, VIC 3809",
    "Ogilvie, WA 6535",
    "Ogmore, QLD 4706",
    "Ogunbil, NSW 2340",
    "Okeden, QLD 4613",
    "Olary, SA 5440",
    "Old Adaminaby, NSW 2629",
    "Old Bar, NSW 2430",
    "Old Beach, TAS 7017",
    "Old Bonalbo, NSW 2469",
    "Old Calperum, SA 5341",
    "Old Cooranga, QLD 4626",
    "Old Erowal Bay, NSW 2540",
    "Old Grevillia, NSW 2474",
    "Old Guildford, NSW 2161",
    "Old Junee, NSW 2652",
    "Old Koreelah, NSW 2476",
    "Old Mill, NSW 2369",
    "Old Noarlunga, SA 5168",
    "Old Plains, WA 6569",
    "Old Reynella, SA 5161",
    "Old Station, NSW 2440",
    "Old Tallangatta, VIC 3701",
    "Old Teal Flat, SA 5238",
    "Old Toongabbie, NSW 2146",
    "Oldbury, WA 6121",
    "Oldina, TAS 7325",
    "Olinda, NSW 2849",
    "Olinda, VIC 3788",
    "Olney, NSW 2325",
    "Olympic Dam, SA 5725",
    "Omadale, NSW 2337",
    "Oman Ama, QLD 4352",
    "Ombersley, VIC 3241",
    "Omeo, VIC 3898",
    "Omeo Valley, VIC 3898",
    "Ondit, VIC 3249",
    "One Mile, NSW 2316",
    "One Mile, QLD 4305",
    "One Tree, NSW 2711",
    "One Tree Hill, SA 5114",
    "Ongerup, WA 6336",
    "Onkaparinga Hills, SA 5163",
    "Onslow, WA 6710",
    "Oodla Wirra, SA 5422",
    "Oodnadatta, SA 5734",
    "Oolong, NSW 2581",
    "Ooma, NSW 2871",
    "Oombabeer, QLD 4718",
    "Oombulgurri, WA 6740",
    "Oonah, TAS 7325",
    "Oonoonba, QLD 4811",
    "Ooralea, QLD 4740",
    "Oorindi, QLD 4824",
    "Ootha, NSW 2875",
    "Opalton, QLD 4735",
    "Ophir, NSW 2800",
    "Opossum Bay, TAS 7023",
    "Ora Banda, WA 6431",
    "Orallo, QLD 4455",
    "Oran Park, NSW 2570",
    "Orana, WA 6330",
    "Orange, NSW 2800",
    "Orange Creek, QLD 4715",
    "Orange East, NSW 2800",
    "Orange Grove, NSW 2380",
    "Orange Grove, WA 6109",
    "Orange Hill, QLD 4455",
    "Orange Springs, WA 6503",
    "Orangeville, NSW 2570",
    "Orbost, VIC 3888",
    "Orchard Hills, NSW 2748",
    "Orchid Beach, QLD 4581",
    "Orchid Valley, WA 6394",
    "Ord River, WA 6770",
    "Orelia, WA 6167",
    "Orford, TAS 7190",
    "Orford, VIC 3284",
    "Orielton, TAS 7172",
    "Orient, QLD 4850",
    "Orient Point, NSW 2540",
    "Orion, QLD 4722",
    "Orkabie, QLD 4741",
    "Ormeau, QLD 4208",
    "Ormeau Hills, QLD 4208",
    "Ormiston, QLD 4160",
    "Ormond, VIC 3204",
    "Orroroo, SA 5431",
    "Orrvale, VIC 3631",
    "Orton Park, NSW 2795",
    "Osborne, QLD 4806",
    "Osborne, SA 5017",
    "Osborne, NSW 2656",
    "Osborne Park, WA 6017",
    "Osborne Park, WA 6916",
    "Osborne Park, WA 6917",
    "Osbornes Flat, VIC 3749",
    "Osbornes Flat, VIC 3691",
    "Osmaston, TAS 7303",
    "Osmington, WA 6285",
    "Osterley, NSW 2324",
    "Osterley, TAS 7140",
    "Oswald, NSW 2321",
    "Otago, TAS 7017",
    "Otford, NSW 2508",
    "Ottaba, QLD 4313",
    "Ottoway, SA 5013",
    "Oura, NSW 2650",
    "Ourimbah, NSW 2258",
    "Ournie, NSW 2640",
    "Ouse, TAS 7140",
    "Outer Harbor, SA 5018",
    "Outtrim, VIC 3951",
    "Ouyen, VIC 3490",
    "Ovens, VIC 3738",
    "Overland Corner, SA 5330",
    "Ovingham, SA 5082",
    "Owanyilla, QLD 4650",
    "Owen, SA 5460",
    "Owens Creek, QLD 4741",
    "Owens Gap, NSW 2337",
    "Oxenford, QLD 4210",
    "Oxford, QLD 4742",
    "Oxford Falls, NSW 2100",
    "Oxley, NSW 2711",
    "Oxley, QLD 4075",
    "Oxley, NSW 2824",
    "Oxley, VIC 3678",
    "Oxley, ACT 2903",
    "Oxley Flats, VIC 3678",
    "Oxley Island, NSW 2430",
    "Oxley Park, NSW 2760",
    "Oxley Vale, NSW 2340",
    "Oyster Bay, NSW 2225",
    "Oyster Cove, TAS 7150",
    "Oyster Cove, NSW 2318",
    "Oyster Creek, QLD 4674",
    "Ozenkadnook, VIC 3413",
    "Paaratte, VIC 3268",
    "Pacific Fair, QLD 4218",
    "Pacific Haven, QLD 4659",
    "Pacific Heights, QLD 4703",
    "Pacific Palms, NSW 2428",
    "Pacific Paradise, QLD 4564",
    "Pacific Pines, QLD 4211",
    "Packers Camp, QLD 4865",
    "Packsaddle, NSW 2880",
    "Padbury, WA 6025",
    "Paddington, QLD 4064",
    "Paddington, NSW 2021",
    "Paddys Flat, NSW 2469",
    "Paddys Flat, NSW 2632",
    "Paddys Green, QLD 4880",
    "Paddys River, NSW 2653",
    "Paddys River, NSW 2577",
    "Padstow, NSW 2211",
    "Padstow Heights, NSW 2211",
    "Padthaway, SA 5271",
    "Paechtown, SA 5245",
    "Pagans Flat, NSW 2469",
    "Page, ACT 2614",
    "Pages Creek, NSW 2337",
    "Pages Flat, SA 5172",
    "Pages River, NSW 2338",
    "Paget, QLD 4740",
    "Pagewood, NSW 2035",
    "Painswick, VIC 3551",
    "Paisley, SA 5357",
    "Pakenham, VIC 3810",
    "Pakenham South, VIC 3810",
    "Pakenham Upper, VIC 3810",
    "Palana, TAS 7255",
    "Palarang, NSW 2632",
    "Palen Creek, QLD 4287",
    "Palerang, NSW 2622",
    "Palgarup, WA 6258",
    "Paling Yards, NSW 2580",
    "Paling Yards, NSW 2795",
    "Palkagee, SA 5670",
    "Pallal, NSW 2404",
    "Pallamallawa, NSW 2399",
    "Pallamana, SA 5254",
    "Pallara, QLD 4110",
    "Pallarenda, QLD 4810",
    "Pallas Street Maryborough, QLD 4650",
    "Pallinup, WA 6335",
    "Palm Beach, QLD 4221",
    "Palm Beach, NSW 2108",
    "Palm Cove, QLD 4879",
    "Palm Grove, NSW 2258",
    "Palm Grove, QLD 4800",
    "Palm Island, QLD 4816",
    "Palmdale, WA 6328",
    "Palmdale, NSW 2258",
    "Palmer, QLD 4871",
    "Palmer, SA 5237",
    "Palmer, WA 6225",
    "Palmers Channel, NSW 2463",
    "Palmers Island, NSW 2463",
    "Palmers Oaky, NSW 2795",
    "Palmerston, QLD 4860",
    "Palmerston, NT 0830",
    "Palmerston, NT 0831",
    "Palmerston, ACT 2913",
    "Palmtree, QLD 4352",
    "Palmvale, NSW 2484",
    "Palmview, QLD 4553",
    "Palmwoods, QLD 4555",
    "Palmwoods, NSW 2482",
    "Palmyra, WA 6157",
    "Palmyra, QLD 4751",
    "Palmyra, WA 6957",
    "Paloona, TAS 7310",
    "Paluma, QLD 4816",
    "Palumpa, NT 0822",
    "Pamayu, NT 0862",
    "Pambula, NSW 2549",
    "Pambula Beach, NSW 2549",
    "Pampas, QLD 4352",
    "Pampoolah, NSW 2430",
    "Pan Ban, NSW 2648",
    "Panania, NSW 2213",
    "Paney, SA 5652",
    "Pangee, NSW 2825",
    "Panitya, SA 5641",
    "Panitya, VIC 3512",
    "Panmure, VIC 3265",
    "Pannawonica, WA 6716",
    "Panorama, SA 5041",
    "Pantapin, WA 6384",
    "Panton Hill, VIC 3759",
    "Panuara, NSW 2800",
    "Pappinbarra, NSW 2446",
    "Papunya, NT 0872",
    "Para, NSW 2648",
    "Para Hills, SA 5096",
    "Para Hills West, SA 5096",
    "Para Vista, SA 5093",
    "Paraburdoo, WA 6754",
    "Parachilna, SA 5730",
    "Paracombe, SA 5132",
    "Paradise, NSW 2360",
    "Paradise, TAS 7306",
    "Paradise, SA 5075",
    "Paradise, WA 6236",
    "Paradise, VIC 3477",
    "Paradise Beach, VIC 3851",
    "Paradise Point, QLD 4216",
    "Paradise Waters, QLD 4217",
    "Parafield, SA 5106",
    "Parafield Airport, SA 5106",
    "Parafield Gardens, SA 5107",
    "Paralowie, SA 5108",
    "Paramatta, SA 5558",
    "Parap, NT 0804",
    "Parap, NT 0820",
    "Parap, NT 0820",
    "Paraparap, VIC 3240",
    "Paratoo, SA 5422",
    "Parattah, TAS 7120",
    "Parawa, SA 5203",
    "Pardoo, WA 6721",
    "Parham, SA 5501",
    "Parilla, SA 5303",
    "Paringa, SA 5340",
    "Paringi, NSW 2738",
    "Paris Creek, SA 5201",
    "Park Avenue, QLD 4701",
    "Park Grove, TAS 7320",
    "Park Holme, SA 5043",
    "Park Orchards, VIC 3114",
    "Park Ridge, QLD 4125",
    "Park Ridge South, QLD 4125",
    "Parkdale, VIC 3195",
    "Parker Range, WA 6426",
    "Parkerville, WA 6081",
    "Parkes, NSW 2870",
    "Parkes, ACT 2600",
    "Parkesbourne, NSW 2580",
    "Parkeston, WA 6434",
    "Parkfield, WA 6233",
    "Parkham, TAS 7304",
    "Parkhurst, QLD 4702",
    "Parkinson, QLD 4115",
    "Parklands, WA 6180",
    "Parklands, QLD 4560",
    "Parklands, TAS 7320",
    "Parklea, NSW 2768",
    "Parknook, QLD 4417",
    "Parkside, SA 5063",
    "Parkside, QLD 4825",
    "Parkville, VIC 3052",
    "Parkville, NSW 2337",
    "Parkwood, WA 6147",
    "Parkwood, QLD 4214",
    "Parliament House, ACT 2600",
    "Parliament House, NSW 2000",
    "Parliament House, SA 5000",
    "Parma, NSW 2540",
    "Parmelia, WA 6167",
    "Parnaroo, SA 5422",
    "Parndana, SA 5220",
    "Parrakie, SA 5301",
    "Parramatta, NSW 2150",
    "Parramatta, NSW 1740",
    "Parramatta, NSW 1741",
    "Parramatta, NSW 2123",
    "Parramatta, NSW 2124",
    "Parramatta Park, QLD 4870",
    "Parramatta Westfield, NSW 2150",
    "Parrawe, TAS 7321",
    "Parraweena, NSW 2339",
    "Parrearra, QLD 4575",
    "Parryville, WA 6333",
    "Paruna, SA 5311",
    "Parwan, VIC 3340",
    "Pasadena, SA 5042",
    "Paschendale, VIC 3315",
    "Pascoe Vale, VIC 3044",
    "Pascoe Vale South, VIC 3044",
    "Pasha, QLD 4721",
    "Paskeville, SA 5552",
    "Pastoria, VIC 3444",
    "Pastoria East, VIC 3444",
    "Pata, SA 5333",
    "Patchewollock, VIC 3491",
    "Patchs Beach, NSW 2478",
    "Paterson, QLD 4570",
    "Paterson, NSW 2421",
    "Patersonia, TAS 7259",
    "Patho, VIC 3564",
    "Patjarr, WA 0872",
    "Patonga, NSW 2256",
    "Patrick, QLD 4725",
    "Patrick Estate, QLD 4311",
    "Patterson, VIC 3204",
    "Patterson Lakes, VIC 3197",
    "Patyah, VIC 3318",
    "Paulls Valley, WA 6076",
    "Pauls Pocket, QLD 4800",
    "Paupong, NSW 2628",
    "Pawleena, TAS 7172",
    "Pawtella, TAS 7120",
    "Paxton, NSW 2325",
    "Paynedale, WA 6239",
    "Payneham, SA 5070",
    "Payneham South, SA 5070",
    "Paynes Crossing, NSW 2325",
    "Paynes Find, WA 6612",
    "Paynesville, WA 6638",
    "Paynesville, VIC 3880",
    "Peaceful Bay, WA 6333",
    "Peachester, QLD 4519",
    "Peachna, SA 5607",
    "Peacock Creek, NSW 2469",
    "Peacock Siding, QLD 4850",
    "Peak Crossing, QLD 4306",
    "Peak Hill, WA 6642",
    "Peak Hill, NSW 2869",
    "Peak View, NSW 2630",
    "Peake, SA 5301",
    "Peakhurst, NSW 2210",
    "Peakhurst Heights, NSW 2210",
    "Pearce, ACT 2607",
    "Pearcedale, VIC 3912",
    "Pearces Creek, NSW 2477",
    "Pearl Beach, NSW 2256",
    "Pearlah, SA 5607",
    "Pearsall, WA 6065",
    "Pearshape, TAS 7256",
    "Pearsondale, VIC 3851",
    "Peats Ridge, NSW 2250",
    "Pebbly Beach, NSW 2536",
    "Pechey, QLD 4352",
    "Pedler Creek, SA 5171",
    "Peebinga, SA 5304",
    "Peechelba, VIC 3678",
    "Peechelba East, VIC 3678",
    "Peedamulla, WA 6710",
    "Peel, NSW 2795",
    "Peel Island, QLD 4184",
    "Peelwood, NSW 2583",
    "Peep Hill, SA 5374",
    "Peerabeelup, WA 6260",
    "Peeramon, QLD 4885",
    "Pegarah, TAS 7256",
    "Pegs Creek, WA 6714",
    "Pejar, NSW 2583",
    "Pekina, SA 5431",
    "Pelaw Main, NSW 2327",
    "Pelham, QLD 4415",
    "Pelham, TAS 7030",
    "Pelican, NSW 2281",
    "Pelican Creek, QLD 4726",
    "Pelican Lagoon, SA 5222",
    "Pelican Point, SA 5291",
    "Pelican Point, WA 6230",
    "Pelican Waters, QLD 4551",
    "Pellaring Flat, SA 5238",
    "Pellew Islands, NT 0852",
    "Pelluebla, VIC 3727",
    "Pelton, NSW 2325",
    "Pelverata, TAS 7150",
    "Pemberton, WA 6260",
    "Pembrooke, NSW 2446",
    "Pemulwuy, NSW 2145",
    "Pendle Hill, NSW 2145",
    "Penfield, SA 5121",
    "Penfield Gardens, SA 5121",
    "Penguin, TAS 7316",
    "Penna, TAS 7171",
    "Pennant Hills, NSW 1715",
    "Pennant Hills, NSW 1771",
    "Pennant Hills, NSW 2120",
    "Penneshaw, SA 5222",
    "Pennington, SA 5013",
    "Pennyroyal, VIC 3235",
    "Penola, SA 5277",
    "Penong, SA 5690",
    "Penrice, SA 5353",
    "Penrith, NSW 2750",
    "Penrith, NSW 2751",
    "Penrith Plaza, NSW 2750",
    "Penrith South, NSW 2750",
    "Penrose, NSW 2579",
    "Penrose, NSW 2530",
    "Penshurst, NSW 2222",
    "Penshurst, VIC 3289",
    "Pental Island, VIC 3586",
    "Pentland, QLD 4816",
    "Pentland Hills, VIC 3341",
    "Penwhaupell, QLD 4625",
    "Penwortham, SA 5453",
    "Peppermint Grove, WA 6011",
    "Peppermint Grove Beach, WA 6271",
    "Peppimenarti, NT 0822",
    "Peranga, QLD 4352",
    "Percydale, VIC 3478",
    "Peregian Beach, QLD 4573",
    "Peregian Beach South, QLD 4573",
    "Peregian Springs, QLD 4573",
    "Perenjori, WA 6620",
    "Pericoe, NSW 2550",
    "Perillup, WA 6324",
    "Perisher Valley, NSW 2624",
    "Perlubie, SA 5680",
    "Peron, WA 6168",
    "Peronne, VIC 3413",
    "Perponda, SA 5308",
    "Perry Bridge, VIC 3862",
    "Perrys Crossing, NSW 2775",
    "Perseverance, QLD 4352",
    "Perth, WA 6000",
    "Perth, TAS 7300",
    "Perth Adelaide Tce, WA 6832",
    "Perth Airport, WA 6105",
    "Perth Bc, WA 6849",
    "Perth Gpo, WA 6000",
    "Perth St Georges Tce, WA 6831",
    "Perthville, NSW 2795",
    "Perulpa Island, QLD 4184",
    "Perup, WA 6258",
    "Perwillowen, QLD 4560",
    "Petcheys Bay, TAS 7109",
    "Peterborough, SA 5422",
    "Peterborough, VIC 3270",
    "Peterhead, SA 5016",
    "Petermann, NT 0872",
    "Petersham, NSW 2049",
    "Petersham North, NSW 2049",
    "Petersville, SA 5571",
    "Petford, QLD 4871",
    "Petherick, SA 5267",
    "Petina, SA 5680",
    "Petrie, QLD 4502",
    "Petrie Terrace, QLD 4000",
    "Petrudor, WA 6468",
    "Petticoat Creek, VIC 3233",
    "Petwood, SA 5254",
    "Pewsey Vale, SA 5351",
    "Pheasant Creek, QLD 4702",
    "Pheasant Creek, VIC 3757",
    "Pheasants Nest, NSW 2574",
    "Phegans Bay, NSW 2256",
    "Phillip, ACT 2606",
    "Phillip Bay, NSW 2036",
    "Phillip Creek Station, NT 0862",
    "Philpott, QLD 4626",
    "Phoenix Park, NSW 2321",
    "Pialba, QLD 4655",
    "Piallamore, NSW 2340",
    "Piallaway, NSW 2342",
    "Pialligo, ACT 2609",
    "Piambong, NSW 2850",
    "Piangil, VIC 3597",
    "Piara Waters, WA 6112",
    "Piavella, VIC 3572",
    "Piawaning, WA 6572",
    "Piccadilly, WA 6430",
    "Piccadilly, SA 5151",
    "Pickanjinnie, QLD 4428",
    "Pickering Brook, WA 6076",
    "Picketts Valley, NSW 2251",
    "Picnic Bay, QLD 4819",
    "Picnic Point, NSW 2213",
    "Picola, VIC 3639",
    "Picola West, VIC 3639",
    "Picton, NSW 2571",
    "Picton, WA 6229",
    "Picton East, WA 6229",
    "Pie Creek, QLD 4570",
    "Piedmont, VIC 3833",
    "Piednippie, SA 5680",
    "Pier Milan, VIC 3533",
    "Pierces Creek, QLD 4355",
    "Pierces Creek, ACT 2611",
    "Piesse Brook, WA 6076",
    "Piesseville, WA 6315",
    "Pigeon Hole, NT 0852",
    "Pigeon Ponds, VIC 3407",
    "Pigeonbah, NSW 2824",
    "Piggabeen, NSW 2486",
    "Piggoreet, VIC 3351",
    "Pikapene, NSW 2469",
    "Pike River, SA 5340",
    "Pikedale, QLD 4380",
    "Pikes Creek, QLD 4380",
    "Pile Gully, QLD 4625",
    "Pilerwa, QLD 4650",
    "Pillar Valley, NSW 2462",
    "Pilliga, NSW 2388",
    "Pilot Wilderness, NSW 2627",
    "Pilton, QLD 4361",
    "Pimba, SA 5720",
    "Pimbaacla, SA 5661",
    "Pimlico, NSW 2478",
    "Pimlico, QLD 4812",
    "Pimlico Island, NSW 2478",
    "Pimpama, QLD 4209",
    "Pimpimbudgee, QLD 4615",
    "Pimpinio, VIC 3401",
    "Pin Gin Hill, QLD 4860",
    "Pinbarren, QLD 4568",
    "Pinbeyan, NSW 2720",
    "Pindar, WA 6631",
    "Pindaroi, NSW 2361",
    "Pindi Pindi, QLD 4798",
    "Pindimar, NSW 2324",
    "Pine Camp, NSW 2648",
    "Pine Clump, NSW 2824",
    "Pine Clump, NSW 2831",
    "Pine Creek, SA 5419",
    "Pine Creek, QLD 4670",
    "Pine Creek, NT 0847",
    "Pine Grove, VIC 3573",
    "Pine Grove, NSW 2829",
    "Pine Hill, SA 5269",
    "Pine Hill, QLD 4724",
    "Pine Hills, QLD 4416",
    "Pine Lodge, NSW 2714",
    "Pine Lodge, VIC 3631",
    "Pine Mountain, VIC 3709",
    "Pine Mountain, QLD 4306",
    "Pine Point, SA 5571",
    "Pine Ridge, NSW 2343",
    "Pine Valley, NSW 2630",
    "Pine View, VIC 3579",
    "Pinelands, QLD 4355",
    "Pinelands, NT 0829",
    "Pinelands, NT 0829",
    "Pinery, SA 5460",
    "Pines Forest, VIC 3200",
    "Pinevale, QLD 4754",
    "Pinewood, VIC 3149",
    "Piney Range, NSW 2810",
    "Pingaring, WA 6357",
    "Pingelly, WA 6308",
    "Pingrup, WA 6343",
    "Pinjar, WA 6078",
    "Pinjarra, WA 6208",
    "Pinjarra Hills, QLD 4069",
    "Pink Lake, WA 6450",
    "Pink Lily, QLD 4702",
    "Pinkawillinie, SA 5641",
    "Pinkenba, QLD 4008",
    "Pinkerton Plains, SA 5400",
    "Pinkett, NSW 2370",
    "Pinks Beach, SA 5275",
    "Pinnacle, NSW 2810",
    "Pinnacle, QLD 4741",
    "Pinnacle Swamp, NSW 2849",
    "Pinnacles, QLD 4815",
    "Pinnaroo, SA 5304",
    "Pinny Beach, NSW 2281",
    "Pintharuka, WA 6623",
    "Pinwernying, WA 6317",
    "Pioneer, TAS 7264",
    "Pioneer, QLD 4825",
    "Pioneer Bay, VIC 3984",
    "Pioneers Rest, QLD 4650",
    "Piora, NSW 2470",
    "Pipeclay, NSW 2446",
    "Pipers Brook, TAS 7254",
    "Pipers Creek, VIC 3444",
    "Pipers River, TAS 7252",
    "Pippingarra, WA 6722",
    "Pira, VIC 3585",
    "Pirie East, SA 5540",
    "Piries, VIC 3723",
    "Pirlangimpi, NT 0822",
    "Pirrinuan, QLD 4405",
    "Pirron Yallock, VIC 3249",
    "Pitfield, VIC 3351",
    "Pithara, WA 6608",
    "Pitjantjatjara Homelands, SA 0872",
    "Pitnacree, NSW 2323",
    "Pitt Town, NSW 2756",
    "Pitt Town Bottoms, NSW 2756",
    "Pittong, VIC 3360",
    "Pittsworth, QLD 4356",
    "Piturie, QLD 4825",
    "Placid Hills, QLD 4343",
    "Plainby, QLD 4355",
    "Plainland, QLD 4341",
    "Pleasant Hills, NSW 2658",
    "Pleasant Park, SA 5277",
    "Pleasure Point, NSW 2172",
    "Plenty, VIC 3090",
    "Plenty, TAS 7140",
    "Pleystowe, QLD 4741",
    "Plum Tree, QLD 4702",
    "Plumpton, VIC 3335",
    "Plumpton, NSW 2761",
    "Plumridge Lakes, WA 6431",
    "Plympton, SA 5038",
    "Plympton Park, SA 5038",
    "Poatina, TAS 7302",
    "Point Arkwright, QLD 4573",
    "Point Boston, SA 5607",
    "Point Clare, NSW 2250",
    "Point Cook, VIC 3030",
    "Point Frederick, NSW 2250",
    "Point Grey, WA 6208",
    "Point Leo, VIC 3916",
    "Point Lonsdale, VIC 3225",
    "Point Lookout, QLD 4183",
    "Point Lowly, SA 5600",
    "Point Lowly North, SA 5600",
    "Point Mcleay, SA 5259",
    "Point Pass, SA 5374",
    "Point Pearce, SA 5573",
    "Point Piper, NSW 2027",
    "Point Samson, WA 6720",
    "Point Souttar, SA 5575",
    "Point Stephens, NT 0822",
    "Point Stuart, NT 0822",
    "Point Sturt, SA 5256",
    "Point Turton, SA 5575",
    "Point Vernon, QLD 4655",
    "Point Wilson, VIC 3212",
    "Point Wolstoncroft, NSW 2259",
    "Pointer Mountain, NSW 2539",
    "Pokolbin, NSW 2320",
    "Pola Creek, NSW 2440",
    "Polda, SA 5670",
    "Police Point, TAS 7116",
    "Policeman Point, SA 5264",
    "Polisbet, VIC 3585",
    "Polish Hill River, SA 5453",
    "Polo Flat, NSW 2630",
    "Poltalloch, SA 5259",
    "Pomborneit, VIC 3260",
    "Pomborneit East, VIC 3249",
    "Pomborneit North, VIC 3260",
    "Pomeroy, NSW 2580",
    "Pomona, NSW 2648",
    "Pomona, QLD 4568",
    "Pomonal, VIC 3381",
    "Pompapiel, VIC 3571",
    "Pompoota, SA 5238",
    "Ponde, SA 5238",
    "Ponto, NSW 2818",
    "Ponto, NSW 2831",
    "Pontville, TAS 7030",
    "Pontypool, TAS 7190",
    "Pony Hills, QLD 4454",
    "Poochera, SA 5655",
    "Pooginagoric, SA 5268",
    "Pooginook, SA 5330",
    "Poolaijelo, VIC 3312",
    "Poona, QLD 4650",
    "Pooncarie, NSW 2648",
    "Poonindie, SA 5607",
    "Pooraka, SA 5095",
    "Pootilla, VIC 3352",
    "Poowong, VIC 3988",
    "Poowong East, VIC 3988",
    "Poowong North, VIC 3988",
    "Popanyinning, WA 6309",
    "Porcupine, QLD 4821",
    "Porcupine Ridge, VIC 3461",
    "Porepunkah, VIC 3740",
    "Porky Flat, SA 5222",
    "Pormpuraaw, QLD 4871",
    "Porongurup, WA 6324",
    "Port Adelaide, SA 5015",
    "Port Adelaide Bc, SA 5015",
    "Port Albany, WA 6330",
    "Port Albert, VIC 3971",
    "Port Alma, QLD 4699",
    "Port Arthur, TAS 7182",
    "Port Arthur, SA 5572",
    "Port Augusta, SA 5700",
    "Port Augusta, SA 5710",
    "Port Augusta North, SA 5700",
    "Port Augusta West, SA 5700",
    "Port Bonython, SA 5600",
    "Port Botany, NSW 2036",
    "Port Broughton, SA 5522",
    "Port Campbell, VIC 3269",
    "Port Clinton, SA 5570",
    "Port Curtis, QLD 4700",
    "Port Davis, SA 5540",
    "Port Denison, WA 6525",
    "Port Douglas, QLD 4877",
    "Port Elliot, SA 5212",
    "Port Fairy, VIC 3284",
    "Port Flinders, SA 5495",
    "Port Franklin, VIC 3964",
    "Port Gawler, SA 5501",
    "Port Germein, SA 5495",
    "Port Gibbon, SA 5602",
    "Port Giles, SA 5582",
    "Port Hacking, NSW 2229",
    "Port Hedland, WA 6721",
    "Port Hughes, SA 5558",
    "Port Huon, TAS 7116",
    "Port Julia, SA 5575",
    "Port Kembla, NSW 2505",
    "Port Kennedy, WA 6172",
    "Port Kenny, SA 5671",
    "Port Latta, TAS 7321",
    "Port Lincoln, SA 5606",
    "Port Lincoln, SA 5607",
    "Port Macdonnell, SA 5291",
    "Port Macquarie, NSW 2444",
    "Port Macquarie Bc, NSW 2444",
    "Port Mannum, SA 5238",
    "Port Melbourne, VIC 8873",
    "Port Melbourne, VIC 3207",
    "Port Moorowie, SA 5576",
    "Port Neill, SA 5604",
    "Port Noarlunga, SA 5167",
    "Port Noarlunga South, SA 5167",
    "Port Of Brisbane, QLD 4178",
    "Port Paterson, SA 5700",
    "Port Pirie, SA 5540",
    "Port Pirie South, SA 5540",
    "Port Pirie West, SA 5540",
    "Port Rickaby, SA 5575",
    "Port Sorell, TAS 7307",
    "Port Stanvac, SA 5160",
    "Port Victoria, SA 5573",
    "Port Vincent, SA 5581",
    "Port Wakefield, SA 5550",
    "Port Welshpool, VIC 3965",
    "Port Willunga, SA 5173",
    "Port Wine, QLD 4724",
    "Portarlington, VIC 3223",
    "Porter Lagoon, SA 5416",
    "Porters Creek, NSW 2538",
    "Porters Retreat, NSW 2787",
    "Portland, VIC 3305",
    "Portland, NSW 2847",
    "Portland North, VIC 3305",
    "Portland Roads, QLD 4871",
    "Portland West, VIC 3305",
    "Portsea, VIC 3944",
    "Portsmith, QLD 4870",
    "Possum Brush, NSW 2430",
    "Possum Creek, NSW 2479",
    "Postans, WA 6167",
    "Postmans Ridge, QLD 4352",
    "Potato Point, NSW 2545",
    "Pottery Estate, NSW 2790",
    "Potts Hill, NSW 2143",
    "Potts Point, NSW 1335",
    "Potts Point, NSW 2011",
    "Pottsville, NSW 2489",
    "Pottsville Beach, NSW 2489",
    "Pound Creek, VIC 3996",
    "Powelltown, VIC 3797",
    "Powers Creek, VIC 3312",
    "Powlett Plains, VIC 3517",
    "Powranna, TAS 7300",
    "Pozieres, QLD 4352",
    "Prahran, VIC 3181",
    "Prahran East, VIC 3181",
    "Prairie, VIC 3572",
    "Prairie, QLD 4816",
    "Prairiewood, NSW 2176",
    "Pranjip, VIC 3666",
    "Pratten, QLD 4370",
    "Prawle, QLD 4650",
    "Premaydena, TAS 7185",
    "Premer, NSW 2381",
    "Prentice North, VIC 3685",
    "Prenzlau, QLD 4311",
    "Preolenna, TAS 7325",
    "Preservation Bay, TAS 7316",
    "Preston, VIC 3072",
    "Preston, TAS 7315",
    "Preston, QLD 4800",
    "Preston, QLD 4352",
    "Preston Beach, WA 6215",
    "Preston Lower, VIC 3072",
    "Preston Settlement, WA 6225",
    "Preston South, VIC 3072",
    "Preston South, VIC 3072",
    "Preston West, VIC 3072",
    "Prestons, NSW 2170",
    "Pretty Beach, NSW 2257",
    "Pretty Beach, NSW 2539",
    "Pretty Pine, NSW 2710",
    "Prevelly, WA 6285",
    "Price, SA 5570",
    "Priestdale, QLD 4127",
    "Primbee, NSW 2502",
    "Primrose Sands, TAS 7173",
    "Primrose Valley, NSW 2621",
    "Prince Henry Heights, QLD 4350",
    "Prince Regent River, WA 6740",
    "Princes Hill, VIC 3054",
    "Princetown, VIC 3269",
    "Promised Land, TAS 7306",
    "Promisedland, QLD 4660",
    "Proof Range, SA 5550",
    "Proserpine, QLD 4800",
    "Prospect, NSW 2148",
    "Prospect, TAS 7250",
    "Prospect, SA 5082",
    "Prospect, QLD 4715",
    "Prospect East, SA 5082",
    "Prospect Hill, SA 5201",
    "Prospect Vale, TAS 7250",
    "Prospect West, SA 5082",
    "Proston, QLD 4613",
    "Providence Portal, NSW 2629",
    "Pucawan, NSW 2666",
    "Puckapunyal, VIC 3662",
    "Puckapunyal Milpo, VIC 3662",
    "Puddledock, NSW 2350",
    "Pularumpi, NT 0822",
    "Pulganbar, NSW 2460",
    "Pullabooka, NSW 2810",
    "Pullenvale, QLD 4069",
    "Pulletop, NSW 2650",
    "Pumpenbil, NSW 2484",
    "Pumphreys Bridge, WA 6308",
    "Punchbowl, NSW 2460",
    "Punchbowl, TAS 7249",
    "Punchbowl, NSW 2196",
    "Punchs Creek, QLD 4357",
    "Puntabie, SA 5680",
    "Punthari, SA 5238",
    "Punyelroo, SA 5354",
    "Pura Pura, VIC 3271",
    "Purdeet, VIC 3289",
    "Pureba, SA 5680",
    "Purfleet, NSW 2430",
    "Purga, QLD 4306",
    "Purlewaugh, NSW 2357",
    "Purnim, VIC 3278",
    "Purnim West, VIC 3278",
    "Purnong, SA 5238",
    "Purnong Landing, SA 5238",
    "Purnululu, WA 6770",
    "Purrawunda, QLD 4356",
    "Putney, NSW 2112",
    "Putta Bucca, NSW 2850",
    "Putty, NSW 2330",
    "Pyalong, VIC 3521",
    "Pyangle, NSW 2849",
    "Pyap, SA 5333",
    "Pyap West, SA 5333",
    "Pyengana, TAS 7216",
    "Pygery, SA 5655",
    "Pymble, NSW 2073",
    "Pyramid Hill, VIC 3575",
    "Pyramul, NSW 2850",
    "Pyree, NSW 2540",
    "Pyrmont, NSW 2009",
    "Q Supercentre, QLD 4218",
    "Quaama, NSW 2550",
    "Quairading, WA 6383",
    "Quakers Hill, NSW 2763",
    "Qualco, SA 5322",
    "Qualeup, WA 6394",
    "Quambatook, VIC 3540",
    "Quambone, NSW 2831",
    "Quamby Bend, TAS 7292",
    "Quamby Brook, TAS 7304",
    "Quanda, NSW 2828",
    "Quandary, NSW 2665",
    "Quandialla, NSW 2721",
    "Quandong, VIC 3030",
    "Quantong, VIC 3401",
    "Quarry Hill, VIC 3550",
    "Queanbeyan, NSW 2620",
    "Queanbeyan East, NSW 2620",
    "Queanbeyan West, NSW 2620",
    "Quedjinup, WA 6281",
    "Queens Beach, QLD 4805",
    "Queens Domain, TAS 7000",
    "Queens Park, WA 6107",
    "Queens Park, NSW 2022",
    "Queens Pinch, NSW 2850",
    "Queenscliff, VIC 3225",
    "Queenscliff, NSW 2096",
    "Queensferry, VIC 3984",
    "Queenstown, SA 5014",
    "Queenstown, TAS 7467",
    "Queenton, QLD 4820",
    "Queenwood, WA 6239",
    "Quelagetting, WA 6405",
    "Quellington, WA 6302",
    "Quialigo, NSW 2580",
    "Quidong, NSW 2632",
    "Quiera, NSW 2622",
    "Quilpie, QLD 4480",
    "Quinalow, QLD 4403",
    "Quindalup, WA 6281",
    "Quindanning, WA 6391",
    "Quinninup, WA 6258",
    "Quinns Rocks, WA 6030",
    "Quipolly, NSW 2343",
    "Quirindi, NSW 2343",
    "Qunaba, QLD 4670",
    "Quoiba, TAS 7310",
    "Quorn, SA 5433",
    "Quorrobolong, NSW 2325",
    "Raaf Base Darwin, NT 0820",
    "Raby, NSW 2566",
    "Raby Bay, QLD 4163",
    "Racecourse, QLD 4740",
    "Racecourse Bay, SA 5291",
    "Raceview, QLD 4305",
    "Radford, QLD 4307",
    "Raglan, QLD 4697",
    "Raglan, VIC 3373",
    "Raglan, NSW 2795",
    "Railton, TAS 7305",
    "Railway Estate, QLD 4810",
    "Rainbow, VIC 3424",
    "Rainbow Beach, QLD 4581",
    "Rainbow Flat, NSW 2430",
    "Rainbow Reach, NSW 2440",
    "Rakula, NT 0822",
    "Raleigh, NSW 2454",
    "Ramco, SA 5322",
    "Ramco Heights, SA 5322",
    "Raminea, TAS 7109",
    "Ramingining, NT 0822",
    "Ramornie, NSW 2460",
    "Ramsay, QLD 4358",
    "Ramsay, SA 5575",
    "Ramsgate, NSW 2217",
    "Ramsgate Beach, NSW 2217",
    "Ranceby, VIC 3951",
    "Rand, NSW 2642",
    "Randalls Bay, TAS 7112",
    "Randwick, NSW 2031",
    "Ranelagh, TAS 7109",
    "Ranford, WA 6390",
    "Ranga, TAS 7255",
    "Rangari, NSW 2380",
    "Rangemore, QLD 4806",
    "Rangemore, QLD 4352",
    "Rangers Valley, NSW 2370",
    "Ranges Bridge, QLD 4405",
    "Rangeview, VIC 3132",
    "Rangeville, QLD 4350",
    "Rangeway, WA 6530",
    "Rangewood, QLD 4817",
    "Ranken, NT 4825",
    "Rankin Park, NSW 2287",
    "Rankins Springs, NSW 2669",
    "Rannock, NSW 2701",
    "Ransome, QLD 4154",
    "Rapid Bay, SA 5204",
    "Rapid Creek, NT 0810",
    "Rapid Creek, NT 0810",
    "Rappville, NSW 2469",
    "Rasmussen, QLD 4815",
    "Rathdowney, QLD 4287",
    "Rathmines, NSW 2283",
    "Rathscar, VIC 3465",
    "Rathscar West, VIC 3465",
    "Raukkan, SA 5259",
    "Ravenhall, VIC 3023",
    "Ravensbourne, QLD 4352",
    "Ravensdale, NSW 2259",
    "Ravenshoe, QLD 4888",
    "Ravensthorpe, WA 6346",
    "Ravenswood, NSW 2824",
    "Ravenswood, VIC 3453",
    "Ravenswood, WA 6208",
    "Ravenswood, QLD 4816",
    "Ravenswood, TAS 7250",
    "Ravenswood South, VIC 3453",
    "Ravensworth, QLD 4871",
    "Ravensworth, NSW 2330",
    "Rawbelle, QLD 4630",
    "Rawdon Island, NSW 2446",
    "Rawdon Vale, NSW 2422",
    "Rawlinna, WA 6434",
    "Raworth, NSW 2321",
    "Rawson, VIC 3825",
    "Rawsonville, NSW 2830",
    "Raymond Island, VIC 3880",
    "Raymond Terrace, NSW 2324",
    "Raymond Terrace East, NSW 2324",
    "Raywood, VIC 3570",
    "Razorback, NSW 2850",
    "Razorback, NSW 2571",
    "Recherche, TAS 7109",
    "Red Cliffs, VIC 3496",
    "Red Creek, SA 5255",
    "Red Gully, WA 6503",
    "Red Head, NSW 2430",
    "Red Hill, NSW 2720",
    "Red Hill, NSW 2824",
    "Red Hill, WA 6056",
    "Red Hill, ACT 2603",
    "Red Hill, VIC 3937",
    "Red Hill, NSW 2347",
    "Red Hill, QLD 4059",
    "Red Hill Rockhampton, QLD 4701",
    "Red Hill South, VIC 3937",
    "Red Hills, TAS 7304",
    "Red Lion, VIC 3371",
    "Red Range, NSW 2370",
    "Red River, QLD 4871",
    "Red Rock, NSW 2456",
    "Red Rocks, NSW 2577",
    "Redan, VIC 3350",
    "Redbank, WA 6721",
    "Redbank, NSW 2446",
    "Redbank, QLD 4301",
    "Redbank, VIC 3477",
    "Redbank Creek, QLD 4312",
    "Redbank Plains, QLD 4301",
    "Redbanks, SA 5502",
    "Redbournberry, NSW 2330",
    "Redcastle, VIC 3523",
    "Redcliffe, QLD 4020",
    "Redcliffe, WA 6104",
    "Redcliffe North, QLD 4020",
    "Reddestone, NSW 2370",
    "Redesdale, VIC 3444",
    "Redfern, NSW 2016",
    "Redford, QLD 4467",
    "Redgate, QLD 4605",
    "Redgate, WA 6286",
    "Redhead, NSW 2290",
    "Redhill, SA 5521",
    "Redhill Farms, QLD 4671",
    "Redland Bay, QLD 4165",
    "Redlands, NSW 2646",
    "Redlynch, QLD 4870",
    "Redmond, WA 6327",
    "Redmond West, WA 6327",
    "Redpa, TAS 7330",
    "Redridge, QLD 4660",
    "Redwood, QLD 4350",
    "Redwood Park, SA 5097",
    "Reedy, WA 6640",
    "Reedy Creek, NSW 2849",
    "Reedy Creek, NSW 2330",
    "Reedy Creek, SA 5275",
    "Reedy Creek, VIC 3658",
    "Reedy Creek, QLD 4227",
    "Reedy Dam, VIC 3395",
    "Reedy Flat, VIC 3895",
    "Reedy Lake, VIC 3579",
    "Reedy Marsh, TAS 7304",
    "Reedy Swamp, NSW 2550",
    "Reefton, NSW 2666",
    "Reefton, VIC 3799",
    "Reekara, TAS 7256",
    "Reesville, QLD 4552",
    "Reeves Plains, SA 5502",
    "Regans Ford, WA 6507",
    "Regency Downs, QLD 4341",
    "Regency Park, SA 5942",
    "Regency Park, SA 5010",
    "Regency Park Bc, SA 5010",
    "Regent West, VIC 3072",
    "Regents Park, NSW 2143",
    "Regents Park, QLD 4118",
    "Regentville, NSW 2745",
    "Reid, SA 5118",
    "Reid, ACT 2612",
    "Reid River, QLD 4816",
    "Reids Creek, QLD 4625",
    "Reids Flat, NSW 2586",
    "Reidsdale, NSW 2622",
    "Reinscourt, WA 6280",
    "Relbia, TAS 7258",
    "Remlap, WA 6472",
    "Rendelsham, SA 5280",
    "Renison Bell, TAS 7469",
    "Renmark, SA 5341",
    "Renmark North, SA 5341",
    "Renmark South, SA 5341",
    "Renmark West, SA 5341",
    "Renner Springs, NT 0862",
    "Rennie, NSW 2646",
    "Renown Park, SA 5008",
    "Renwick, NSW 2575",
    "Repentance Creek, NSW 2480",
    "Repton, NSW 2454",
    "Research, VIC 3095",
    "Reserve Creek, NSW 2484",
    "Reservoir, VIC 3073",
    "Reservoir, WA 6076",
    "Reservoir East, VIC 3073",
    "Reservoir North, VIC 3073",
    "Reservoir South, VIC 3073",
    "Retreat, NSW 2355",
    "Retreat, TAS 7254",
    "Retro, QLD 4723",
    "Revesby, NSW 2212",
    "Revesby Heights, NSW 2212",
    "Revesby North, NSW 2212",
    "Reynard, VIC 3858",
    "Reynella, SA 5161",
    "Reynella East, SA 5161",
    "Reynolds Neck, TAS 7304",
    "Rheban, TAS 7190",
    "Rheola, VIC 3517",
    "Rhine Falls, NSW 2630",
    "Rhodes, NSW 2138",
    "Rhydding, QLD 4718",
    "Rhyll, VIC 3923",
    "Rhymney, VIC 3377",
    "Rhyndaston, TAS 7120",
    "Rhynie, SA 5412",
    "Riachella, VIC 3385",
    "Riana, TAS 7316",
    "Rich Avon, VIC 3480",
    "Rich Avon East, VIC 3480",
    "Rich Avon West, VIC 3480",
    "Richardson, ACT 2905",
    "Richlands, QLD 4077",
    "Richlands, NSW 2580",
    "Richmond, SA 5033",
    "Richmond, TAS 7025",
    "Richmond, QLD 4740",
    "Richmond, NSW 2753",
    "Richmond, QLD 4822",
    "Richmond, VIC 3121",
    "Richmond East, VIC 3121",
    "Richmond Hill, NSW 2480",
    "Richmond Hill, QLD 4820",
    "Richmond Lowlands, NSW 2753",
    "Richmond North, VIC 3121",
    "Richmond Plains, VIC 3518",
    "Richmond Raaf, NSW 2755",
    "Richmond South, VIC 3121",
    "Richmond Vale, NSW 2323",
    "Riddells Creek, VIC 3431",
    "Ridgehaven, SA 5097",
    "Ridgelands, QLD 4702",
    "Ridgeway, TAS 7054",
    "Ridgewood, WA 6030",
    "Ridgewood, QLD 4563",
    "Ridgley, TAS 7321",
    "Ridleyton, SA 5008",
    "Rifle Range, QLD 4311",
    "Riggs Creek, VIC 3666",
    "Rileys Hill, NSW 2472",
    "Ringarooma, TAS 7263",
    "Ringbark, WA 6258",
    "Ringtail Creek, QLD 4565",
    "Ringwood, NSW 2646",
    "Ringwood, VIC 3134",
    "Ringwood, QLD 4343",
    "Ringwood East, VIC 3135",
    "Ringwood North, VIC 3134",
    "Riordanvale, QLD 4800",
    "Ripley, QLD 4306",
    "Ripplebrook, VIC 3818",
    "Rippleside, VIC 3215",
    "Ripponlea, VIC 3185",
    "Risdon, TAS 7017",
    "Risdon Park, SA 5540",
    "Risdon Park South, SA 5540",
    "Risdon Vale, TAS 7016",
    "Rita Island, QLD 4807",
    "River Heads, QLD 4655",
    "River Ranch, QLD 4680",
    "Riverglades, SA 5253",
    "Riverglen, SA 5253",
    "Riverhills, QLD 4074",
    "Riverina Msc, NSW 2678",
    "Riverlea, NSW 2850",
    "Riverleigh, QLD 4626",
    "Riverside, NSW 2444",
    "Riverside, TAS 7250",
    "Riverside, VIC 3401",
    "Riverside Centre, QLD 4001",
    "Riverslea, VIC 3860",
    "Riversleigh, QLD 4470",
    "Riverstone, NSW 2765",
    "Riverton, WA 6148",
    "Riverton, SA 5412",
    "Riverton, QLD 4385",
    "Rivertree, NSW 2372",
    "Rivervale, WA 6103",
    "Riverview, NSW 2066",
    "Riverview, QLD 4303",
    "Riverview, QLD 4303",
    "Riverview, NSW 2404",
    "Riverwood, NSW 2210",
    "Rivett, ACT 2611",
    "Rixs Creek, NSW 2330",
    "Roadvale, QLD 4310",
    "Rob Roy, NSW 2360",
    "Robe, SA 5276",
    "Robertson, QLD 4109",
    "Robertson, NSW 2577",
    "Robertsons Beach, VIC 3971",
    "Robertstown, SA 5381",
    "Robigana, TAS 7275",
    "Robin Falls, NT 0822",
    "Robin Hill, NSW 2795",
    "Robina, QLD 4226",
    "Robina Town Centre, QLD 4230",
    "Robinson, VIC 3019",
    "Robinson, WA 6330",
    "Robinson River, NT 0852",
    "Robinvale, VIC 3549",
    "Robinvale Irrigation District Section B, VIC 3549",
    "Robinvale Irrigation District Section C, VIC 3549",
    "Robinvale Irrigation District Section D, VIC 3549",
    "Robinvale Irrigation District Section E, VIC 3549",
    "Rochedale, QLD 4123",
    "Rochedale South, QLD 4123",
    "Rocherlea, TAS 7248",
    "Roches Beach, TAS 7170",
    "Rochester, VIC 3561",
    "Rochester, SA 5464",
    "Rochford, VIC 3442",
    "Rock Flat, NSW 2630",
    "Rock Forest, NSW 2795",
    "Rock Valley, NSW 2480",
    "Rockbank, VIC 3335",
    "Rockdale, NSW 2216",
    "Rockhampton, QLD 4700",
    "Rockhampton City, QLD 4700",
    "Rockhampton Hospital, QLD 4700",
    "Rockingham, WA 6968",
    "Rockingham, QLD 4854",
    "Rockingham, WA 6168",
    "Rockingham Beach, WA 6969",
    "Rockingham Beach, WA 6168",
    "Rocklands, VIC 3401",
    "Rocklea, QLD 4106",
    "Rocklea, WA 6751",
    "Rockleigh, SA 5254",
    "Rockley, NSW 2795",
    "Rockley Mount, NSW 2795",
    "Rocklyn, VIC 3364",
    "Rockmount, QLD 4344",
    "Rocksberg, QLD 4510",
    "Rockside, QLD 4343",
    "Rockton, NSW 2632",
    "Rockville, QLD 4350",
    "Rockwell, WA 6532",
    "Rocky Camp, SA 5280",
    "Rocky Cape, TAS 7321",
    "Rocky Creek, QLD 4357",
    "Rocky Creek, NSW 2371",
    "Rocky Creek, NSW 2390",
    "Rocky Glen, NSW 2357",
    "Rocky Gully, SA 5253",
    "Rocky Gully, WA 6397",
    "Rocky Hall, NSW 2550",
    "Rocky Hills, TAS 7190",
    "Rocky Plain, SA 5381",
    "Rocky Plain, NSW 2628",
    "Rocky Point, QLD 4873",
    "Rocky Point, QLD 4874",
    "Rocky Point, SA 5238",
    "Rocky Point, NSW 2259",
    "Rocky Point, VIC 3377",
    "Rocky River, NSW 2358",
    "Rocky River, NSW 2372",
    "Rockyview, QLD 4701",
    "Rodd Point, NSW 2046",
    "Rodds Bay, QLD 4678",
    "Rodgers Creek, QLD 4370",
    "Roebourne, WA 6718",
    "Roebuck, WA 6725",
    "Roelands, WA 6226",
    "Roger River, TAS 7330",
    "Rogues Point, SA 5571",
    "Rokeby, VIC 3821",
    "Rokeby, TAS 7019",
    "Rokewood, VIC 3330",
    "Rokewood Junction, VIC 3351",
    "Roland, TAS 7306",
    "Roleystone, WA 6111",
    "Rollands Plains, NSW 2441",
    "Rolleston, QLD 4702",
    "Rollingstone, QLD 4816",
    "Roma, QLD 4455",
    "Romaine, TAS 7320",
    "Romsey, VIC 3434",
    "Rookhurst, NSW 2422",
    "Rookwood, NSW 2141",
    "Rookwood, QLD 4871",
    "Rooty Hill, NSW 2766",
    "Ropeley, QLD 4343",
    "Ropers Road, NSW 2395",
    "Ropes Crossing, NSW 2760",
    "Rosa Brook, WA 6285",
    "Rosa Glen, WA 6285",
    "Rosalie Plains, QLD 4401",
    "Rosanna, VIC 3084",
    "Rose Bay, NSW 2029",
    "Rose Bay, TAS 7015",
    "Rose Bay North, NSW 2030",
    "Rose Park, SA 5067",
    "Rose River, VIC 3678",
    "Rose Valley, NSW 2534",
    "Rose Valley, NSW 2630",
    "Rosebank, NSW 2480",
    "Roseberg, NSW 2793",
    "Roseberry, NSW 2474",
    "Roseberry Creek, NSW 2474",
    "Rosebery, TAS 7470",
    "Rosebery, NSW 1445",
    "Rosebery, NT 0832",
    "Rosebery, NT 0832",
    "Rosebery, VIC 3395",
    "Rosebery, NSW 2018",
    "Rosebery Heights, NT 0832",
    "Rosebrook, NSW 2320",
    "Rosebrook, VIC 3285",
    "Rosebud, VIC 3939",
    "Rosebud Plaza, VIC 3939",
    "Rosebud West, VIC 3940",
    "Rosedale, NSW 2536",
    "Rosedale, VIC 3847",
    "Rosedale, QLD 4674",
    "Rosedale, SA 5350",
    "Rosegarland, TAS 7140",
    "Rosehill, NSW 2142",
    "Rosehill, QLD 4370",
    "Roselands, NSW 2196",
    "Rosella, QLD 4740",
    "Rosemeadow, NSW 2560",
    "Rosemeath, NSW 2632",
    "Rosemount, QLD 4560",
    "Roseneath, QLD 4811",
    "Rosenthal Heights, QLD 4370",
    "Roses Gap, VIC 3385",
    "Rosetown, SA 5275",
    "Rosetta, TAS 7010",
    "Rosevale, TAS 7292",
    "Rosevale, QLD 4340",
    "Rosevears, TAS 7277",
    "Roseville, NSW 2069",
    "Roseville Chase, NSW 2069",
    "Rosewater, SA 5013",
    "Rosewater East, SA 5013",
    "Rosewhite, VIC 3737",
    "Rosewood, QLD 4340",
    "Rosewood, NSW 2652",
    "Rosewood, NSW 2446",
    "Roseworthy, SA 5371",
    "Roslyn, NSW 2580",
    "Roslynmead, VIC 3564",
    "Rosny, TAS 7018",
    "Rosny Park, TAS 7018",
    "Ross, NT 0870",
    "Ross, TAS 7209",
    "Ross Creek, QLD 4570",
    "Ross Creek, VIC 3351",
    "Ross River, QLD 4816",
    "Rossarden, TAS 7213",
    "Rossbridge, VIC 3377",
    "Rossglen, NSW 2439",
    "Rossgole, NSW 2336",
    "Rossi, NSW 2621",
    "Rosslea, QLD 4812",
    "Rosslyn, QLD 4703",
    "Rosslyn Park, SA 5072",
    "Rossmore, NSW 2557",
    "Rossmore, NSW 2171",
    "Rossmore, WA 6401",
    "Rossmoya, QLD 4702",
    "Rossmoyne, WA 6148",
    "Rossvale, QLD 4356",
    "Rossville, QLD 4895",
    "Rostrevor, SA 5073",
    "Rostron, VIC 3477",
    "Rothbury, NSW 2320",
    "Rothsay, WA 6620",
    "Rothwell, QLD 4022",
    "Roto, NSW 2675",
    "Rottnest Island, WA 6161",
    "Rouchel, NSW 2336",
    "Rouchel Brook, NSW 2336",
    "Roughit, NSW 2330",
    "Round Corner, NSW 2158",
    "Round Hill, TAS 7320",
    "Round Hill, QLD 4677",
    "Round Mountain, NSW 2484",
    "Round Swamp, NSW 2846",
    "Roundstone, QLD 4718",
    "Rous, NSW 2477",
    "Rous Mill, NSW 2477",
    "Rouse Hill, NSW 2155",
    "Rowan, NSW 2650",
    "Rowella, TAS 7270",
    "Rowena, NSW 2387",
    "Rowes Bay, QLD 4810",
    "Rowland Flat, SA 5352",
    "Rowlands Creek, NSW 2484",
    "Rowsley, VIC 3340",
    "Rowville, VIC 3178",
    "Roxburgh Park, VIC 3064",
    "Roxby Downs, SA 5725",
    "Royal Australian Navy Warships, WA 6958",
    "Royal Brisbane Hospital, QLD 4029",
    "Royal George, TAS 7213",
    "Royal Melbourne Hospital, VIC 3050",
    "Royal North Shore Hospital, NSW 2065",
    "Royal Park, SA 5014",
    "Royalla, NSW 2620",
    "Royston, QLD 4515",
    "Royston Park, SA 5070",
    "Rozelle, NSW 2039",
    "Ruabon, WA 6280",
    "Rubicon, VIC 3712",
    "Ruby, VIC 3953",
    "Rubyanna, QLD 4670",
    "Rubyvale, QLD 4702",
    "Rudall, SA 5642",
    "Rudds Gully, WA 6532",
    "Ruffy, VIC 3666",
    "Rufus, NSW 2648",
    "Rugby, NSW 2583",
    "Rukenvale, NSW 2474",
    "Rules Beach, QLD 4674",
    "Rum Jungle, NT 0822",
    "Run-o-waters, NSW 2580",
    "Runaway Bay, QLD 4216",
    "Runcorn, QLD 4113",
    "Rundle Mall, SA 5000",
    "Rungoo, QLD 4849",
    "Running Creek, QLD 4287",
    "Running Creek, VIC 3691",
    "Running Stream, NSW 2850",
    "Runnyford, NSW 2536",
    "Runnymede, VIC 3558",
    "Runnymede, TAS 7190",
    "Runnymede, QLD 4615",
    "Rupanyup, VIC 3388",
    "Rural View, QLD 4740",
    "Ruse, NSW 2560",
    "Rush Creek, QLD 4521",
    "Rushcutters Bay, NSW 2011",
    "Rushes Creek, NSW 2346",
    "Rushforth, NSW 2460",
    "Rushworth, VIC 3612",
    "Rushy Lagoon, TAS 7264",
    "Russell, ACT 2600",
    "Russell Hill, ACT 2600",
    "Russell Island, QLD 4184",
    "Russell Lea, NSW 2046",
    "Russell Vale, NSW 2517",
    "Russells Bridge, VIC 3331",
    "Rutherford, NSW 2320",
    "Rutherglen, VIC 3685",
    "Ruthven, NSW 2480",
    "Ryan, NSW 2658",
    "Ryan, QLD 4825",
    "Ryans, VIC 3875",
    "Ryansbrook, WA 6395",
    "Ryanston, VIC 3992",
    "Rydal, NSW 2790",
    "Rydalmere, NSW 2116",
    "Rydalmere Bc, NSW 1701",
    "Ryde, NSW 1680",
    "Ryde, NSW 2112",
    "Rye, VIC 3941",
    "Rye Park, NSW 2586",
    "Ryeford, QLD 4361",
    "Ryhope, NSW 2283",
    "Rylstone, NSW 2849",
    "Rythdale, VIC 3810",
    "Sabina River, WA 6280",
    "Sabine, QLD 4401",
    "Sackville, NSW 2756",
    "Sackville North, NSW 2756",
    "Sadadeen, NT 0870",
    "Saddleback Mountain, NSW 2533",
    "Saddleworth, SA 5413",
    "Sadleir, NSW 2168",
    "Sadliers Crossing, QLD 4305",
    "Safety Bay, WA 6169",
    "Safety Beach, VIC 3936",
    "Safety Beach, NSW 2456",
    "Saibai Island, QLD 4875",
    "Sailors Falls, VIC 3461",
    "Sailors Gully, VIC 3556",
    "Sailors Hill, VIC 3461",
    "Saints, SA 5461",
    "Salamander Bay, NSW 2317",
    "Sale, VIC 3850",
    "Sale, VIC 3853",
    "Sale East Raaf, VIC 3852",
    "Sale North, VIC 3850",
    "Salem, SA 5255",
    "Salisbury, NSW 2420",
    "Salisbury, SA 5108",
    "Salisbury, QLD 4107",
    "Salisbury Downs, SA 5108",
    "Salisbury East, SA 5109",
    "Salisbury East, QLD 4107",
    "Salisbury East Northbri Ave, SA 5109",
    "Salisbury Heights, SA 5109",
    "Salisbury North, SA 5108",
    "Salisbury North Whites Road, SA 5108",
    "Salisbury Park, SA 5109",
    "Salisbury Plain, SA 5109",
    "Salisbury Plains, NSW 2358",
    "Salisbury South, SA 5106",
    "Salisbury South Bc, SA 5106",
    "Salisbury West, VIC 3517",
    "Sallys Flat, NSW 2850",
    "Salmon Gums, WA 6445",
    "Salt Ash, NSW 2318",
    "Salt Creek, SA 5264",
    "Salter Point, WA 6152",
    "Salter Springs, SA 5401",
    "Saltia, SA 5433",
    "Saltwater, NSW 2430",
    "Saltwater River, TAS 7186",
    "Samaria, VIC 3673",
    "Samford, QLD 4520",
    "Samford Valley, QLD 4520",
    "Samford Village, QLD 4520",
    "Sampson Flat, SA 5114",
    "Samson, WA 6163",
    "Samsonvale, QLD 4520",
    "San Isidore, NSW 2650",
    "San Remo, WA 6210",
    "San Remo, VIC 3925",
    "San Remo, NSW 2262",
    "Sancrox, NSW 2446",
    "Sanctuary Cove, QLD 4212",
    "Sanctuary Lakes, VIC 3030",
    "Sanctuary Point, NSW 2540",
    "Sandalwood, SA 5309",
    "Sandbar, NSW 2428",
    "Sandergrove, SA 5255",
    "Sanderson, NT 0812",
    "Sanderston, SA 5237",
    "Sandfly, TAS 7150",
    "Sandford, TAS 7020",
    "Sandford, VIC 3312",
    "Sandgate, NSW 2304",
    "Sandgate, QLD 4017",
    "Sandhill Lake, VIC 3579",
    "Sandhurst, VIC 3977",
    "Sandhurst East, VIC 3550",
    "Sandiford, QLD 4740",
    "Sandigo, NSW 2700",
    "Sandilands, NSW 2469",
    "Sandilands, SA 5571",
    "Sandleton, SA 5356",
    "Sandon, NSW 2463",
    "Sandon, VIC 3462",
    "Sandover, NT 0872",
    "Sandown Village, VIC 3171",
    "Sandpalms Roadhouse, NT 0822",
    "Sandpatch, WA 6330",
    "Sandringham, QLD 4701",
    "Sandringham, VIC 3191",
    "Sandringham, NSW 2219",
    "Sandsprings, WA 6532",
    "Sandstone, WA 6639",
    "Sandstone Point, QLD 4511",
    "Sandy Bay, TAS 7005",
    "Sandy Bay, TAS 7006",
    "Sandy Beach, NSW 2456",
    "Sandy Camp, QLD 4361",
    "Sandy Creek, QLD 4515",
    "Sandy Creek, NSW 2338",
    "Sandy Creek, NSW 2835",
    "Sandy Creek, VIC 3695",
    "Sandy Creek, SA 5350",
    "Sandy Crossing, NSW 2460",
    "Sandy Flat, NSW 2372",
    "Sandy Grove, SA 5275",
    "Sandy Gully, NSW 2729",
    "Sandy Gully, WA 6535",
    "Sandy Hill, NSW 2372",
    "Sandy Hollow, NSW 2333",
    "Sandy Pocket, QLD 4871",
    "Sandy Point, NSW 2172",
    "Sandy Point, VIC 3959",
    "Sandy Ridges, QLD 4615",
    "Sanger, NSW 2646",
    "Sans Souci, NSW 2219",
    "Santa Teresa, NT 0872",
    "Sapphire, NSW 2360",
    "Sapphire, QLD 4702",
    "Sapphire Beach, NSW 2450",
    "Sapphiretown, SA 5222",
    "Sarabah, QLD 4275",
    "Saratoga, NSW 2251",
    "Sargood, VIC 3858",
    "Sarina, QLD 4737",
    "Sarina Beach, QLD 4737",
    "Sarina Range, QLD 4737",
    "Sarsfield, VIC 3875",
    "Sassafras, NSW 2622",
    "Sassafras, TAS 7307",
    "Sassafras, VIC 3787",
    "Sassafras Gully, VIC 3787",
    "Saumarez, NSW 2350",
    "Saumarez Ponds, NSW 2350",
    "Saunders Beach, QLD 4818",
    "Savage River, TAS 7321",
    "Savannah, QLD 4816",
    "Savernake, NSW 2646",
    "Sawmill Settlement, VIC 3723",
    "Sawpit Creek, NSW 2474",
    "Sawtell, NSW 2452",
    "Sawyers Gully, NSW 2326",
    "Sawyers Valley, WA 6074",
    "Saxby, QLD 4822",
    "Scaddan, WA 6447",
    "Scamander, TAS 7215",
    "Scarborough, NSW 2515",
    "Scarborough, WA 6019",
    "Scarborough, QLD 4020",
    "Scarborough, WA 6922",
    "Scarness, QLD 4655",
    "Scarsdale, VIC 3351",
    "Sceale Bay, SA 5680",
    "Schell Well, SA 5311",
    "Scheyville, NSW 2756",
    "Schofields, NSW 2762",
    "Schroeder, WA 6285",
    "Scone, NSW 2337",
    "Scopus, TAS 7330",
    "Scoresby, VIC 3179",
    "Scoresby Bc, VIC 3176",
    "Scotchmans Lead, VIC 3352",
    "Scotchtown, TAS 7330",
    "Scotchy Pocket, QLD 4570",
    "Scotia, NSW 2648",
    "Scotland Island, NSW 2105",
    "Scotsburn, VIC 3352",
    "Scotsdale, WA 6333",
    "Scott Creek, SA 5153",
    "Scott River, WA 6288",
    "Scott River East, WA 6275",
    "Scotts Brook, WA 6244",
    "Scotts Creek, NSW 2338",
    "Scotts Creek, VIC 3267",
    "Scotts Flat, NSW 2330",
    "Scotts Head, NSW 2447",
    "Scottsdale, TAS 7260",
    "Scrub Creek, QLD 4313",
    "Scrubby Creek, QLD 4570",
    "Scrubby Creek, QLD 4478",
    "Scrubby Mountain, QLD 4356",
    "Scullin, ACT 2614",
    "Sea Elephant, TAS 7256",
    "Sea Lake, VIC 3533",
    "Seabird, WA 6042",
    "Seabrook, VIC 3028",
    "Seacliff, SA 5049",
    "Seacliff Park, SA 5049",
    "Seacombe, VIC 3851",
    "Seacombe Gardens, SA 5047",
    "Seacombe Heights, SA 5047",
    "Seaford, VIC 3198",
    "Seaford, SA 5169",
    "Seaford Heights, SA 5169",
    "Seaford Meadows, SA 5169",
    "Seaford Rise, SA 5169",
    "Seaforth, NSW 2092",
    "Seaforth, QLD 4741",
    "Seaham, NSW 2324",
    "Seahampton, NSW 2286",
    "Seaholme, VIC 3018",
    "Seal Bay, SA 5223",
    "Seal Rocks, NSW 2423",
    "Seaspray, VIC 3851",
    "Seaton, SA 5023",
    "Seaton, VIC 3858",
    "Seaton North, SA 5023",
    "Seaview, VIC 3821",
    "Seaview Downs, SA 5049",
    "Sebastian, VIC 3556",
    "Sebastopol, VIC 3356",
    "Sebastopol, SA 5280",
    "Sebastopol, NSW 2666",
    "Second Valley, SA 5204",
    "Secret Harbour, WA 6173",
    "Sedan, SA 5353",
    "Seddon, SA 5223",
    "Seddon, VIC 3011",
    "Seddon West, VIC 3011",
    "Sedgefield, NSW 2330",
    "Sedgeford, QLD 4724",
    "Sedgwick, VIC 3551",
    "Seelands, NSW 2460",
    "Sefton, NSW 2162",
    "Sefton Park, SA 5083",
    "Segenhoe, NSW 2337",
    "Seisia, QLD 4876",
    "Selbourne, TAS 7292",
    "Selby, VIC 3159",
    "Selene, QLD 4630",
    "Sellheim, QLD 4816",
    "Sellicks Beach, SA 5174",
    "Sellicks Hill, SA 5174",
    "Selwyn, VIC 3737",
    "Semaphore, SA 5019",
    "Semaphore Park, SA 5019",
    "Semaphore South, SA 5019",
    "Senior, SA 5268",
    "Separation Creek, VIC 3221",
    "Seppeltsfield, SA 5355",
    "Seppings, WA 6330",
    "Septimus, QLD 4754",
    "Serpentine, VIC 3517",
    "Serpentine, WA 6125",
    "Serviceton, VIC 3420",
    "Settlement City, NSW 2444",
    "Seven Hills, NSW 2147",
    "Seven Hills, QLD 4170",
    "Seven Hills, NSW 1730",
    "Seven Hills West, NSW 2147",
    "Seven Mile Beach, TAS 7170",
    "Seven Oaks, NSW 2440",
    "Sevenhill, SA 5453",
    "Seventeen Mile, QLD 4344",
    "Seventeen Mile Rocks, QLD 4073",
    "Seventeen Seventy, QLD 4677",
    "Seventy Mile, QLD 4820",
    "Severnlea, QLD 4380",
    "Seville, VIC 3139",
    "Seville, VIC 3139",
    "Seville East, VIC 3139",
    "Seville Grove, WA 6112",
    "Sexton, QLD 4570",
    "Sextonville, NSW 2470",
    "Seymour, VIC 3660",
    "Seymour, VIC 3661",
    "Seymour, TAS 7215",
    "Seymour South, VIC 3660",
    "Shackleton, WA 6386",
    "Shadforth, NSW 2800",
    "Shadforth, WA 6333",
    "Shady Creek, VIC 3821",
    "Shailer Park, QLD 4128",
    "Shallow Bay, NSW 2428",
    "Shallow Inlet, VIC 3960",
    "Shalvey, NSW 2770",
    "Shanes Park, NSW 2747",
    "Shannon, WA 6262",
    "Shannon, TAS 7030",
    "Shannon Brook, NSW 2470",
    "Shannon Vale, NSW 2370",
    "Shannondale, NSW 2460",
    "Shannons Flat, NSW 2630",
    "Shannonvale, QLD 4873",
    "Shannonvale, VIC 3898",
    "Shark Bay, WA 6537",
    "Shark Creek, NSW 2463",
    "Sharon, QLD 4670",
    "Sharps Creek, NSW 2729",
    "Shaugh, SA 5267",
    "Shaw, QLD 4818",
    "Shays Flat, VIC 3377",
    "She Oaks, VIC 3331",
    "Shea-oak Log, SA 5371",
    "Sheans Creek, VIC 3666",
    "Sheaoak Flat, SA 5581",
    "Shearwater, TAS 7307",
    "Sheedys Gully, NSW 2790",
    "Sheep Hills, VIC 3392",
    "Sheep Station Creek, QLD 4515",
    "Sheffield, TAS 7306",
    "Sheidow Park, SA 5158",
    "Shelbourne, VIC 3515",
    "Shelburne, QLD 4874",
    "Sheldon, QLD 4157",
    "Shelford, VIC 3329",
    "Shell Cove, NSW 2529",
    "Shell Pocket, QLD 4855",
    "Shelley, WA 6148",
    "Shelley, VIC 3701",
    "Shellharbour, NSW 2529",
    "Shellharbour City Centre, NSW 2529",
    "Shelly Beach, QLD 4810",
    "Shelly Beach, QLD 4551",
    "Shelly Beach, NSW 2261",
    "Shenton Park, WA 6008",
    "Shepherds Flat, VIC 3461",
    "Shepparton, VIC 3632",
    "Shepparton, VIC 3630",
    "Shepparton East, VIC 3631",
    "Shepparton North, VIC 3631",
    "Shepparton South, VIC 3630",
    "Sherbrooke, VIC 3789",
    "Sheringa, SA 5607",
    "Sherlock, WA 6714",
    "Sherlock, SA 5301",
    "Sherwood, NSW 2440",
    "Sherwood, QLD 4075",
    "Sherwood, NSW 2450",
    "Sherwood, NSW 2474",
    "Sherwood, SA 5267",
    "Shirbourne, QLD 4809",
    "Shoal Bay, NSW 2315",
    "Shoal Bay, NT 0830",
    "Shoal Point, QLD 4750",
    "Shoalhaven Heads, NSW 2535",
    "Shoalwater, WA 6169",
    "Shoalwater, QLD 4702",
    "Shooters Hill, NSW 2787",
    "Shoreham, VIC 3916",
    "Shorewell Park, TAS 7320",
    "Shorncliffe, QLD 4017",
    "Short, SA 5279",
    "Shortland, NSW 2307",
    "Shotts, WA 6225",
    "Shute Harbour, QLD 4802",
    "Sidmouth, TAS 7270",
    "Sidonia, VIC 3444",
    "Siesta Park, WA 6280",
    "Silent Grove, NSW 2372",
    "Silkstone, QLD 4304",
    "Silkwood, QLD 4856",
    "Silkwood East, QLD 4857",
    "Silky Oak, QLD 4854",
    "Silvan, VIC 3795",
    "Silver Creek, QLD 4800",
    "Silver Ridge, QLD 4352",
    "Silver Sands, WA 6210",
    "Silver Sands, SA 5173",
    "Silver Spur, QLD 4385",
    "Silver Valley, QLD 4872",
    "Silverdale, NSW 2752",
    "Silverdale, QLD 4307",
    "Silverleaf, QLD 4605",
    "Silverleaves, VIC 3922",
    "Silverleigh, QLD 4401",
    "Silverton, NSW 2880",
    "Silverton, SA 5204",
    "Silverwater, NSW 1811",
    "Silverwater, NSW 2264",
    "Silverwater, NSW 2128",
    "Silverwood, QLD 4370",
    "Simmie, QLD 4454",
    "Simpkins Creek, NSW 2469",
    "Simpson, NT 0872",
    "Simpson, VIC 3266",
    "Simpsons Bay, TAS 7150",
    "Simpsons Creek, VIC 3888",
    "Simson, VIC 3465",
    "Sinagra, WA 6065",
    "Sinclair, WA 6450",
    "Singleton, NSW 2330",
    "Singleton, WA 6175",
    "Singleton Heights, NSW 2330",
    "Singleton Military Area, NSW 2331",
    "Singleton Milpo, NSW 2331",
    "Singletons Mill, NSW 2775",
    "Sinnamon Park, QLD 4073",
    "Sippy Downs, QLD 4556",
    "Sir Samuel, WA 6437",
    "Sisters Beach, TAS 7321",
    "Sisters Creek, TAS 7325",
    "Six Mile Swamp, NSW 2469",
    "Skeleton Rock, WA 6426",
    "Skenes Creek, VIC 3233",
    "Skenes Creek North, VIC 3233",
    "Skennars Head, NSW 2478",
    "Skibo, VIC 3260",
    "Skillion Flat, NSW 2440",
    "Skinners Flat, VIC 3518",
    "Skinners Shoot, NSW 2481",
    "Skipton, VIC 3361",
    "Skye, VIC 3977",
    "Skye, SA 5072",
    "Skyring Reserve, QLD 4671",
    "Slacks Creek, QLD 4127",
    "Slade Point, QLD 4740",
    "Sladevale, QLD 4370",
    "Slaty Creek, VIC 3477",
    "Sleaford, SA 5607",
    "Sleepy Hollow, NSW 2483",
    "Sloping Main, TAS 7186",
    "Smeaton, VIC 3364",
    "Smeaton Grange, NSW 2567",
    "Smith Brook, WA 6258",
    "Smithfield, SA 5114",
    "Smithfield, NSW 2164",
    "Smithfield, QLD 4878",
    "Smithfield Plains, SA 5114",
    "Smithfield West, SA 5114",
    "Smithfield West, NSW 2164",
    "Smithlea, QLD 4385",
    "Smiths Beach, VIC 3922",
    "Smiths Creek, NSW 2460",
    "Smiths Creek, NSW 2484",
    "Smiths Creek, NSW 2474",
    "Smiths Gully, VIC 3760",
    "Smiths Lake, NSW 2428",
    "Smithton, TAS 7330",
    "Smithtown, NSW 2440",
    "Smokeytown, VIC 3364",
    "Smoko, VIC 3741",
    "Smoky Bay, SA 5680",
    "Smoky Creek, QLD 4702",
    "Smythes Creek, VIC 3351",
    "Smythesdale, VIC 3351",
    "Snake Island, VIC 3971",
    "Snake Valley, VIC 3351",
    "Snakes Plain, NSW 2824",
    "Snowball, NSW 2622",
    "Snowtown, SA 5520",
    "Snowy Plain, NSW 2628",
    "Snug, TAS 7054",
    "Sodwalls, NSW 2790",
    "Sofala, NSW 2795",
    "Soldiers Hill, VIC 3350",
    "Soldiers Hill, QLD 4825",
    "Soldiers Point, NSW 2317",
    "Solomon, SA 5641",
    "Solomontown, SA 5540",
    "Solus, WA 6207",
    "Somers, VIC 3927",
    "Somersby, NSW 2250",
    "Somerset, TAS 7322",
    "Somerset Dam, QLD 4312",
    "Somerton, VIC 3062",
    "Somerton, NSW 2340",
    "Somerton Park, SA 5044",
    "Somerton Park, VIC 3851",
    "Somerville, WA 6430",
    "Somerville, VIC 3912",
    "Somme, QLD 4382",
    "Sorell, TAS 7172",
    "Sorell Creek, TAS 7140",
    "Sorrento, WA 6020",
    "Sorrento, VIC 3943",
    "South Albury, NSW 2640",
    "South Arm, NSW 2460",
    "South Arm, TAS 7022",
    "South Arm, NSW 2449",
    "South Ballina, NSW 2478",
    "South Bathurst, NSW 2795",
    "South Bingera, QLD 4670",
    "South Bodallin, WA 6424",
    "South Boulder, WA 6432",
    "South Bowenfels, NSW 2790",
    "South Brighton, SA 5048",
    "South Brisbane, QLD 4101",
    "South Brisbane Bc, QLD 4101",
    "South Broken Hill, NSW 2880",
    "South Bruny, TAS 7150",
    "South Bunbury, WA 6230",
    "South Burnie, TAS 7320",
    "South Burracoppin, WA 6421",
    "South Carnarvon, WA 6701",
    "South Coogee, NSW 2034",
    "South Datatine, WA 6317",
    "South Doodlakine, WA 6411",
    "South Dudley, VIC 3995",
    "South Durras, NSW 2536",
    "South East Nanango, QLD 4615",
    "South End, QLD 4680",
    "South Evans Head, NSW 2473",
    "South Forest, TAS 7330",
    "South Fremantle, WA 6162",
    "South Geelong, VIC 3220",
    "South Gladstone, QLD 4680",
    "South Glencoe, WA 6317",
    "South Golden Beach, NSW 2483",
    "South Grafton, NSW 2460",
    "South Granville, NSW 2142",
    "South Greenough, WA 6528",
    "South Guildford, WA 6055",
    "South Gundagai, NSW 2722",
    "South Gundurimba, NSW 2480",
    "South Guyra, NSW 2365",
    "South Hedland, WA 6722",
    "South Hobart, TAS 7004",
    "South Hummocks, SA 5550",
    "South Hurstville, NSW 2221",
    "South Innisfail, QLD 4860",
    "South Isis, QLD 4660",
    "South Johnstone, QLD 4859",
    "South Kalgoorlie, WA 6430",
    "South Kempsey, NSW 2440",
    "South Kilkerran, SA 5573",
    "South Kingsville, VIC 3015",
    "South Kolan, QLD 4670",
    "South Kukerin, WA 6352",
    "South Kumminin, WA 6368",
    "South Kununoppin, WA 6489",
    "South Lake, WA 6164",
    "South Lake Grace, WA 6353",
    "South Launceston, TAS 7249",
    "South Lismore, NSW 2480",
    "South Littleton, NSW 2790",
    "South Mackay, QLD 4740",
    "South Maclean, QLD 4280",
    "South Maitland, NSW 2320",
    "South Maroota, NSW 2756",
    "South Melbourne, VIC 3205",
    "South Mission Beach, QLD 4852",
    "South Molle, QLD 4741",
    "South Morang, VIC 3752",
    "South Mount Cameron, TAS 7264",
    "South Murchison, WA 6635",
    "South Murwillumbah, NSW 2484",
    "South Nanango, QLD 4615",
    "South Newdegate, WA 6355",
    "South Nietta, TAS 7315",
    "South Nowra, NSW 2541",
    "South Pambula, NSW 2549",
    "South Penrith, NSW 2750",
    "South Perth, WA 6151",
    "South Perth, WA 6951",
    "South Perth Angelo St, WA 6151",
    "South Plantations, WA 6701",
    "South Plympton, SA 5038",
    "South Preston, TAS 7315",
    "South Purrumbete, VIC 3260",
    "South Quairading, WA 6383",
    "South Riana, TAS 7316",
    "South Ripley, QLD 4306",
    "South Spreyton, TAS 7310",
    "South Springfield, TAS 7260",
    "South Stirling, WA 6324",
    "South Stradbroke, QLD 4216",
    "South Talwood, QLD 4496",
    "South Tammin, WA 6409",
    "South Tamworth, NSW 2340",
    "South Toowoomba, QLD 4350",
    "South Townsville, QLD 4810",
    "South Trayning, WA 6488",
    "South Trees, QLD 4680",
    "South Turramurra, NSW 2074",
    "South Wellesley Islands, QLD 4871",
    "South Wentworthville, NSW 2145",
    "South West Rocks, NSW 2431",
    "South Wharf, VIC 3006",
    "South Windsor, NSW 2756",
    "South Wolumla, NSW 2550",
    "South Yaamba, QLD 4702",
    "South Yarra, VIC 3141",
    "South Yelbeni, WA 6487",
    "South Yilgarn, WA 6426",
    "South Yuna, WA 6532",
    "South Yunderup, WA 6208",
    "Southampton, WA 6253",
    "Southampton, NSW 2460",
    "Southbank, VIC 3006",
    "Southbrook, QLD 4363",
    "Southedge, QLD 4871",
    "Southend, SA 5280",
    "Southern Brook, WA 6401",
    "Southern Cross, WA 6426",
    "Southern Cross, QLD 4820",
    "Southern Cross, VIC 3283",
    "Southern Lamington, QLD 4211",
    "Southern River, WA 6110",
    "Southgate, NSW 2460",
    "Southland Centre, VIC 3192",
    "Southport, QLD 4215",
    "Southport, NT 0822",
    "Southport, TAS 7109",
    "Southport Bc, QLD 4215",
    "Southport Lagoon, TAS 7109",
    "Southport Park, QLD 4215",
    "Southside, QLD 4570",
    "Southtown, QLD 4350",
    "Southwood, QLD 4406",
    "Sovereign Islands, QLD 4216",
    "Spalding, WA 6530",
    "Spalding, SA 5454",
    "Spalford, TAS 7315",
    "Spargo Creek, VIC 3461",
    "Spearwood, WA 6163",
    "Spectacle Lake, SA 5345",
    "Speed, VIC 3488",
    "Speedwell, QLD 4613",
    "Speers Point, NSW 2284",
    "Speewa, NSW 2735",
    "Speewa, VIC 3585",
    "Speewah, QLD 4881",
    "Spence, SA 5271",
    "Spence, ACT 2615",
    "Spencer, NSW 2775",
    "Spencer Park, WA 6330",
    "Spencers Brook, WA 6401",
    "Spicers Creek, NSW 2820",
    "Spicketts Creek, NSW 2454",
    "Spit Junction, NSW 2088",
    "Splinter Creek, QLD 4630",
    "Split Yard Creek, QLD 4306",
    "Splitters Creek, NSW 2640",
    "Spotswood, VIC 3015",
    "Spreadborough, QLD 4825",
    "Sprent, TAS 7315",
    "Spreyton, TAS 7310",
    "Spring Beach, TAS 7190",
    "Spring Bluff, QLD 4352",
    "Spring Creek, NSW 2800",
    "Spring Creek, QLD 4420",
    "Spring Creek, QLD 4361",
    "Spring Creek, QLD 4343",
    "Spring Farm, NSW 2570",
    "Spring Farm, SA 5453",
    "Spring Flat, NSW 2850",
    "Spring Grove, NSW 2470",
    "Spring Gully, SA 5453",
    "Spring Gully, VIC 3550",
    "Spring Hill, NSW 2800",
    "Spring Hill, VIC 3444",
    "Spring Hill, QLD 4000",
    "Spring Hill, QLD 4004",
    "Spring Hill, NSW 2500",
    "Spring Mountain, NSW 2360",
    "Spring Mountain, QLD 4124",
    "Spring Plains, NSW 2388",
    "Spring Ridge, NSW 2343",
    "Spring Terrace, NSW 2798",
    "Springbank, VIC 3352",
    "Springbrook, QLD 4213",
    "Springdale, NSW 2666",
    "Springdale, QLD 4380",
    "Springdale Heights, NSW 2641",
    "Springdallah, VIC 3351",
    "Springfield, QLD 4871",
    "Springfield, VIC 3434",
    "Springfield, NSW 2250",
    "Springfield, WA 6525",
    "Springfield, QLD 4300",
    "Springfield, SA 5062",
    "Springfield, NSW 2630",
    "Springfield, VIC 3544",
    "Springfield, TAS 7260",
    "Springfield Central, QLD 4300",
    "Springfield Lakes, QLD 4300",
    "Springhurst, VIC 3682",
    "Springlands, QLD 4804",
    "Springmount, VIC 3364",
    "Springrange, NSW 2618",
    "Springs, WA 6308",
    "Springside, NSW 2800",
    "Springside, QLD 4356",
    "Springsure, QLD 4722",
    "Springton, SA 5235",
    "Springvale, NSW 2650",
    "Springvale, NSW 2790",
    "Springvale, VIC 3171",
    "Springvale South, VIC 3172",
    "Springwood, NSW 2777",
    "Springwood, QLD 4127",
    "Spurgeon, QLD 4873",
    "Square Mile, SA 5291",
    "Squeaking Point, TAS 7307",
    "St Agnes, SA 5097",
    "St Agnes, QLD 4671",
    "St Albans, VIC 3021",
    "St Albans, NSW 2775",
    "St Albans Park, VIC 3219",
    "St Andrews, NSW 2566",
    "St Andrews, VIC 3761",
    "St Andrews Beach, VIC 3941",
    "St Arnaud, VIC 3478",
    "St Arnaud East, VIC 3477",
    "St Arnaud North, VIC 3477",
    "St Aubyn, QLD 4352",
    "St Clair, NSW 2330",
    "St Clair, VIC 3995",
    "St Clair, NSW 2759",
    "St Fillans, NSW 2850",
    "St George, NSW 2622",
    "St George, QLD 4487",
    "St George Ranges, WA 6728",
    "St Georges, SA 5064",
    "St Georges Basin, NSW 2540",
    "St Germains, VIC 3620",
    "St Helena, VIC 3088",
    "St Helens, QLD 4356",
    "St Helens, QLD 4650",
    "St Helens, TAS 7216",
    "St Helens, VIC 3285",
    "St Helens Beach, QLD 4798",
    "St Helens Park, NSW 2560",
    "St Helens Plains, VIC 3401",
    "St Huberts Island, NSW 2257",
    "St Ives, NSW 2075",
    "St Ives, SA 5252",
    "St Ives Chase, NSW 2075",
    "St James, VIC 3727",
    "St James, WA 6102",
    "St Johns, SA 5373",
    "St Johns Park, NSW 2176",
    "St Kilda, SA 5110",
    "St Kilda, VIC 3182",
    "St Kilda, QLD 4671",
    "St Kilda East, VIC 3183",
    "St Kilda Road, VIC 8004",
    "St Kilda Road Central, VIC 8008",
    "St Kilda Road Central, VIC 3004",
    "St Kilda South, VIC 3182",
    "St Kilda West, VIC 3182",
    "St Kitts, SA 5356",
    "St Lawrence, QLD 4707",
    "St Leonards, VIC 3223",
    "St Leonards, NSW 2065",
    "St Leonards, NSW 1590",
    "St Leonards, TAS 7250",
    "St Lucia, QLD 4067",
    "St Lucia South, QLD 4067",
    "St Mary, QLD 4650",
    "St Marys, SA 5042",
    "St Marys, TAS 7215",
    "St Marys, NSW 1790",
    "St Marys, NSW 2760",
    "St Marys East, NSW 2760",
    "St Marys South, NSW 2760",
    "St Morris, SA 5068",
    "St Pauls, NSW 2031",
    "St Peters, NSW 2044",
    "St Peters, SA 5069",
    "St Ronans, WA 6302",
    "St Ruth, QLD 4405",
    "Staaten, QLD 4871",
    "Staceys Bridge, VIC 3971",
    "Stafford, QLD 4053",
    "Stafford Bc, QLD 4053",
    "Stafford Heights, QLD 4053",
    "Staffordshire Reef, VIC 3351",
    "Staghorn Flat, VIC 3691",
    "Stake Hill, WA 6181",
    "Stake Hill, WA 6210",
    "Stalworth, QLD 4613",
    "Stamford, QLD 4821",
    "Stanage, QLD 4702",
    "Stanborough, NSW 2360",
    "Stanbridge, NSW 2705",
    "Stanford Merthyr, NSW 2327",
    "Stanhope, NSW 2335",
    "Stanhope, VIC 3623",
    "Stanhope Gardens, NSW 2768",
    "Stanhope South, VIC 3623",
    "Stanley, TAS 7331",
    "Stanley, SA 5415",
    "Stanley, VIC 3747",
    "Stanley Flat, SA 5453",
    "Stanmore, QLD 4514",
    "Stanmore, NSW 2048",
    "Stannifer, NSW 2369",
    "Stannum, NSW 2371",
    "Stansbury, SA 5582",
    "Stanthorpe, QLD 4380",
    "Stanwell, QLD 4702",
    "Stanwell Park, NSW 2508",
    "Stanwell Tops, NSW 2508",
    "Stapleton, NT 0822",
    "Stapylton, QLD 4207",
    "Starcke, QLD 4895",
    "State Mine Gully, NSW 2790",
    "Station Arcade, SA 5000",
    "Staughton Vale, VIC 3221",
    "Stavely, VIC 3379",
    "Staverton, TAS 7306",
    "Stawell, VIC 3380",
    "Stawell West, VIC 3380",
    "Steam Plains, NSW 2710",
    "Steels Creek, VIC 3775",
    "Steelton, SA 5413",
    "Steeple Flat, NSW 2631",
    "Steiglitz, VIC 3331",
    "Steiglitz, QLD 4207",
    "Steinfeld, SA 5356",
    "Stenhouse Bay, SA 5575",
    "Stephens Island, QLD 4875",
    "Stephenston, SA 5433",
    "Stepney, SA 5069",
    "Steppes, TAS 7030",
    "Stewart Creek Valley, QLD 4873",
    "Stewart Range, SA 5271",
    "Stewarton, VIC 3725",
    "Stewarton, QLD 4702",
    "Stewarts Brook, NSW 2337",
    "Stewarts Mount, NSW 2795",
    "Stewarts River, NSW 2443",
    "Stieglitz, TAS 7216",
    "Stirling, WA 6021",
    "Stirling, VIC 3893",
    "Stirling, SA 5152",
    "Stirling, ACT 2611",
    "Stirling Estate, WA 6271",
    "Stirling North, SA 5710",
    "Stirling Range National Park, WA 6338",
    "Stockdale, VIC 3862",
    "Stockhaven, QLD 4625",
    "Stockinbingal, NSW 2725",
    "Stockleigh, QLD 4280",
    "Stockport, SA 5410",
    "Stockrington, NSW 2322",
    "Stockton, QLD 4871",
    "Stockton, NSW 2295",
    "Stockwell, SA 5355",
    "Stockyard, QLD 4344",
    "Stockyard, QLD 4703",
    "Stockyard Creek, NSW 2460",
    "Stockyard Creek, SA 5460",
    "Stockyard Hill, VIC 3373",
    "Stockyard Plain, SA 5330",
    "Stokers Siding, NSW 2484",
    "Stokes, QLD 4823",
    "Stokes Bay, SA 5223",
    "Stone Hut, SA 5480",
    "Stone Well, SA 5352",
    "Stonehaven, VIC 3221",
    "Stonehenge, QLD 4730",
    "Stonehenge, QLD 4357",
    "Stonehenge, NSW 2370",
    "Stonehenge, TAS 7120",
    "Stonelands, QLD 4612",
    "Stoneleigh, QLD 4356",
    "Stoneleigh, VIC 3373",
    "Stonequarry, NSW 2580",
    "Stones Corner, QLD 4120",
    "Stoneville, WA 6081",
    "Stonor, TAS 7119",
    "Stony Chute, NSW 2480",
    "Stony Creek, QLD 4514",
    "Stony Creek, NSW 2850",
    "Stony Creek, NSW 2550",
    "Stony Creek, VIC 3371",
    "Stony Creek, VIC 3957",
    "Stony Crossing, NSW 2734",
    "Stony Head, TAS 7252",
    "Stony Rise, TAS 7310",
    "Stonyfell, SA 5066",
    "Stonyford, VIC 3260",
    "Stoodley, TAS 7306",
    "Storm King, QLD 4380",
    "Stormlea, TAS 7184",
    "Stoters Hill, QLD 4860",
    "Stotts Creek, NSW 2487",
    "Stove Hill, WA 6714",
    "Stow, SA 5461",
    "Stowport, TAS 7321",
    "Stradbroke, VIC 3851",
    "Strahan, TAS 7468",
    "Strangways, VIC 3461",
    "Straten, VIC 3533",
    "Stratford, NSW 2422",
    "Stratford, QLD 4870",
    "Stratford, VIC 3862",
    "Strath Creek, VIC 3658",
    "Strathalbyn, WA 6530",
    "Strathalbyn, SA 5255",
    "Strathallan, VIC 3622",
    "Stratham, WA 6237",
    "Strathblane, TAS 7109",
    "Strathbogie, VIC 3666",
    "Strathcedar, NSW 2429",
    "Strathdale, VIC 3550",
    "Strathdickie, QLD 4800",
    "Strathdownie, VIC 3312",
    "Stratheden, NSW 2470",
    "Stratherne, WA 6309",
    "Strathewen, VIC 3099",
    "Strathfield, NSW 2135",
    "Strathfield, QLD 4742",
    "Strathfield, NSW 1816",
    "Strathfield, NSW 1819",
    "Strathfield South, NSW 2136",
    "Strathfieldsaye, VIC 3551",
    "Strathgordon, TAS 7139",
    "Strathkellar, VIC 3301",
    "Strathlea, VIC 3364",
    "Strathmerton, VIC 3641",
    "Strathmore, QLD 4871",
    "Strathmore, VIC 3041",
    "Strathmore Heights, VIC 3041",
    "Strathpine, QLD 4500",
    "Strathpine Centre, QLD 4500",
    "Stratton, WA 6056",
    "Strawberry Hills, NSW 1420",
    "Strawberry Hills, NSW 1421",
    "Strawberry Hills, NSW 1422",
    "Strawberry Hills, NSW 1423",
    "Strawberry Hills, NSW 1424",
    "Strawberry Hills, NSW 1425",
    "Strawberry Hills, NSW 1426",
    "Strawberry Hills, NSW 1427",
    "Strawberry Hills, NSW 1428",
    "Strawberry Hills, NSW 1429",
    "Strawberry Hills, NSW 2012",
    "Strawberry Hills, NSW 2013",
    "Streaky Bay, SA 5680",
    "Streatham, VIC 3351",
    "Strelley, WA 6721",
    "Stretton, QLD 4116",
    "Strickland, TAS 7140",
    "Stroud, NSW 2425",
    "Stroud Hill, NSW 2420",
    "Stroud Road, NSW 2415",
    "Struan, SA 5271",
    "Struck Oil, QLD 4714",
    "Strzelecki, TAS 7255",
    "Strzelecki, VIC 3950",
    "Stuart, NT 0870",
    "Stuart, QLD 4811",
    "Stuart, SA 5320",
    "Stuart Mill, VIC 3477",
    "Stuart Park, NT 0820",
    "Stuart Park, NT 0820",
    "Stuart Town, NSW 2820",
    "Stuarts Point, NSW 2441",
    "Stubbo, NSW 2852",
    "Stud Park, NSW 2710",
    "Studfield, VIC 3152",
    "Studio Village, QLD 4210",
    "Stun'sail Boom, SA 5223",
    "Sturt, QLD 4829",
    "Sturt, SA 5047",
    "Sturt Creek, WA 6770",
    "Sturt Plateau, NT 0852",
    "Sturt Street, SA 5000",
    "Styx, TAS 7140",
    "Subiaco, WA 6008",
    "Subiaco East, WA 6008",
    "Subiaco Po, WA 6904",
    "Success, WA 6164",
    "Success, WA 6964",
    "Suffolk Park, NSW 2481",
    "Sugarloaf, VIC 3221",
    "Sugarloaf, NSW 2420",
    "Sugarloaf, QLD 4380",
    "Sugarloaf, QLD 4800",
    "Sugarloaf Creek, VIC 3658",
    "Suggan Buggan, VIC 3885",
    "Sulky, VIC 3352",
    "Sullivan, SA 5607",
    "Sulphur Creek, TAS 7316",
    "Summer Hill, NSW 2130",
    "Summer Hill, NSW 2421",
    "Summer Hill Creek, NSW 2800",
    "Summer Island, NSW 2440",
    "Summerhill, TAS 7250",
    "Summerholm, QLD 4341",
    "Summerland Point, NSW 2259",
    "Summerlands, VIC 3922",
    "Summertown, SA 5141",
    "Sumner, QLD 4074",
    "Sumner, VIC 3057",
    "Sumner Park Bc, QLD 4074",
    "Sun Valley, QLD 4680",
    "Sun Valley, NSW 2777",
    "Sunbury, VIC 3429",
    "Sunday Creek, VIC 3658",
    "Sunderland Bay, VIC 3922",
    "Sundown, QLD 4860",
    "Sunlands, SA 5322",
    "Sunny Corner, NSW 2795",
    "Sunny Nook, QLD 4605",
    "Sunnybank, QLD 4109",
    "Sunnybank Hills, QLD 4109",
    "Sunnybank South, QLD 4109",
    "Sunnybrae, SA 5422",
    "Sunnycliffs, VIC 3496",
    "Sunnydale, SA 5354",
    "Sunnyside, WA 6256",
    "Sunnyside, QLD 4416",
    "Sunnyside, QLD 4737",
    "Sunnyside, TAS 7305",
    "Sunnyside, SA 5253",
    "Sunnyvale, SA 5552",
    "Sunrise Beach, QLD 4567",
    "Sunset, QLD 4825",
    "Sunset Beach, WA 6530",
    "Sunset Strip, VIC 3922",
    "Sunset Strip, NSW 2879",
    "Sunshine, VIC 3020",
    "Sunshine, NSW 2264",
    "Sunshine Acres, QLD 4655",
    "Sunshine Bay, NSW 2536",
    "Sunshine Beach, QLD 4567",
    "Sunshine Coast, QLD 4564",
    "Sunshine North, VIC 3020",
    "Sunshine Plaza, QLD 4558",
    "Sunshine West, VIC 3020",
    "Suntop, NSW 2820",
    "Surat, QLD 4417",
    "Surbiton, QLD 4724",
    "Surf Beach, NSW 2536",
    "Surf Beach, VIC 3922",
    "Surfers Paradise, QLD 4217",
    "Surfside, NSW 2536",
    "Surges Bay, TAS 7116",
    "Surprise Bay, TAS 7256",
    "Surrey Downs, SA 5126",
    "Surrey Hills, VIC 3127",
    "Surrey Hills North, VIC 3127",
    "Surrey Hills South, VIC 3125",
    "Surry Hills, NSW 2010",
    "Surveyors Bay, TAS 7116",
    "Susan River, QLD 4655",
    "Sussex Inlet, NSW 2540",
    "Sutherland, NSW 2232",
    "Sutherland, NSW 1499",
    "Sutherland, VIC 3477",
    "Sutherlands, SA 5374",
    "Sutherlands Creek, VIC 3331",
    "Sutton, NSW 2620",
    "Sutton, VIC 3530",
    "Sutton Forest, NSW 2577",
    "Sutton Grange, VIC 3448",
    "Suttontown, SA 5291",
    "Suttor, QLD 4743",
    "Svensson Heights, QLD 4670",
    "Swan Bay, VIC 3225",
    "Swan Bay, TAS 7252",
    "Swan Bay, NSW 2471",
    "Swan Bay, NSW 2324",
    "Swan Creek, NSW 2462",
    "Swan Creek, QLD 4370",
    "Swan Hill, VIC 3585",
    "Swan Hill Pioneer, VIC 3585",
    "Swan Hill West, VIC 3585",
    "Swan Island, VIC 3225",
    "Swan Marsh, VIC 3249",
    "Swan Point, TAS 7275",
    "Swan Reach, SA 5354",
    "Swan Reach, VIC 3903",
    "Swan Vale, NSW 2370",
    "Swan View, WA 6056",
    "Swanbank, QLD 4306",
    "Swanbourne, WA 6010",
    "Swanbrook, NSW 2360",
    "Swanfels, QLD 4371",
    "Swanhaven, NSW 2540",
    "Swanpool, VIC 3673",
    "Swanport, SA 5253",
    "Swans Crossing, NSW 2439",
    "Swans Lagoon, QLD 4807",
    "Swansea, TAS 7190",
    "Swansea, NSW 2281",
    "Swansea Heads, NSW 2281",
    "Swanston, TAS 7120",
    "Swanwater, VIC 3477",
    "Swanwater West, VIC 3480",
    "Swede Flat, SA 5270",
    "Sweetmans Creek, NSW 2325",
    "Swifts Creek, VIC 3896",
    "Swinger Hill, ACT 2606",
    "Sydenham, NSW 2044",
    "Sydenham, VIC 3037",
    "Sydney, NSW 2000",
    "Sydney Domestic Airport, NSW 2020",
    "Sydney Gateway Facility, NSW 2891",
    "Sydney International Airport, NSW 2020",
    "Sydney Markets, NSW 2129",
    "Sydney Olympic Park, NSW 2127",
    "Sylvania, NSW 2224",
    "Sylvania Southgate, NSW 2224",
    "Sylvania Waters, NSW 2224",
    "Sylvaterre, VIC 3575",
    "Symonston, ACT 2609",
    "Syndal, VIC 3149",
    "Syndicate, QLD 4873",
    "Taabinga, QLD 4610",
    "Taabinga Village, QLD 4610",
    "Tabberabbera, VIC 3875",
    "Tabbil Creek, NSW 2420",
    "Tabbimoble, NSW 2472",
    "Tabbita, NSW 2652",
    "Tabilk, VIC 3607",
    "Table Cape, TAS 7325",
    "Table Top, NSW 2640",
    "Tablederry, QLD 4732",
    "Tablelands, NT 0862",
    "Tablelands, QLD 4680",
    "Tablelands, QLD 4605",
    "Tabooba, QLD 4285",
    "Tabor, VIC 3289",
    "Tabragalba, QLD 4285",
    "Tabulam, NSW 2469",
    "Tacoma, NSW 2259",
    "Tacoma South, NSW 2259",
    "Taggerty, VIC 3714",
    "Tahara, VIC 3301",
    "Tahara Bridge, VIC 3315",
    "Tahara West, VIC 3310",
    "Tahlee, NSW 2324",
    "Tahmoor, NSW 2573",
    "Taigum, QLD 4018",
    "Tailem Bend, SA 5259",
    "Tailem Bend, SA 5260",
    "Takalarup, WA 6324",
    "Takilberan, QLD 4671",
    "Takone, TAS 7325",
    "Takura, QLD 4655",
    "Talandji, WA 6710",
    "Talarm, NSW 2447",
    "Talaroo, QLD 4871",
    "Talawa, TAS 7263",
    "Talawanta, NSW 2839",
    "Talbingo, NSW 2720",
    "Talbot, VIC 3371",
    "Talbot, WA 6302",
    "Talbot Island, QLD 4875",
    "Talbot West, WA 6302",
    "Taldora, QLD 4823",
    "Taldra, SA 5311",
    "Talegalla Weir, QLD 4650",
    "Talgai, QLD 4362",
    "Talgarno, VIC 3691",
    "Talgomine, WA 6490",
    "Talia, SA 5670",
    "Talisker, WA 6701",
    "Tallai, QLD 4213",
    "Tallandoon, VIC 3701",
    "Tallangatta, VIC 3700",
    "Tallangatta East, VIC 3700",
    "Tallangatta South, VIC 3701",
    "Tallangatta Valley, VIC 3701",
    "Tallarook, VIC 3659",
    "Tallawang, NSW 2852",
    "Tallebudgera, QLD 4228",
    "Tallebudgera Valley, QLD 4228",
    "Tallegalla, QLD 4340",
    "Tallimba, NSW 2669",
    "Tallong, NSW 2579",
    "Tallowal, NSW 2540",
    "Tallowwood Ridge, NSW 2453",
    "Tallwood, NSW 2798",
    "Tallwoods Village, NSW 2430",
    "Tallygaroopna, VIC 3634",
    "Talmalmo, NSW 2640",
    "Talofa, NSW 2481",
    "Taloumbi, NSW 2463",
    "Talwood, QLD 4496",
    "Tam O'shanter, QLD 4852",
    "Tamala, WA 6532",
    "Tamala Park, WA 6030",
    "Tamarama, NSW 2026",
    "Tamaree, QLD 4570",
    "Tamban, NSW 2441",
    "Tambar Springs, NSW 2381",
    "Tambaroora, NSW 2850",
    "Tambaroora, NSW 2795",
    "Tambellup, WA 6320",
    "Tambo, QLD 4478",
    "Tambo Crossing, VIC 3893",
    "Tambo Upper, VIC 3885",
    "Tamboon, VIC 3890",
    "Tamborine, QLD 4270",
    "Tamborine Mountain, QLD 4272",
    "Tamboritha, VIC 3858",
    "Taminda, NSW 2340",
    "Taminick, VIC 3675",
    "Tamleugh, VIC 3669",
    "Tamleugh North, VIC 3669",
    "Tammin, WA 6409",
    "Tampu, WA 6472",
    "Tamrookum, QLD 4285",
    "Tamrookum Creek, QLD 4285",
    "Tamworth, NSW 2340",
    "Tanah Merah, QLD 4128",
    "Tanami, NT 0872",
    "Tanami, WA 6770",
    "Tanami East, NT 0852",
    "Tanawha, QLD 4556",
    "Tanbar, QLD 4481",
    "Tanby, QLD 4703",
    "Tandarook, VIC 3260",
    "Tandarra, VIC 3571",
    "Tandegin, WA 6415",
    "Tandora, QLD 4650",
    "Tandur, QLD 4570",
    "Tangalooma, QLD 4025",
    "Tangambalanga, VIC 3691",
    "Tanglewood, NSW 2488",
    "Tangorin, QLD 4821",
    "Tanilba Bay, NSW 2319",
    "Tanja, NSW 2550",
    "Tanjil, VIC 3825",
    "Tanjil Bren, VIC 3833",
    "Tanjil South, VIC 3825",
    "Tannabar, NSW 2357",
    "Tannas Mount, NSW 2795",
    "Tannum Sands, QLD 4680",
    "Tannymorel, QLD 4372",
    "Tansey, QLD 4601",
    "Tantangara, NSW 2629",
    "Tantanoola, SA 5280",
    "Tantaraboo, VIC 3764",
    "Tantawangalo, NSW 2550",
    "Tantonan, NSW 2731",
    "Tanunda, SA 5352",
    "Tanwood, VIC 3478",
    "Tanybryn, VIC 3249",
    "Taperoo, SA 5017",
    "Tapitallee, NSW 2540",
    "Taplan, SA 5311",
    "Tapping, WA 6065",
    "Tara, QLD 4421",
    "Tara, NT 0872",
    "Tara, NSW 2665",
    "Tara Station, QLD 4725",
    "Taradale, VIC 3447",
    "Taradale, NSW 2653",
    "Tarago, NSW 2580",
    "Taragoola, QLD 4680",
    "Taralga, NSW 2580",
    "Tarampa, QLD 4311",
    "Tarana, NSW 2787",
    "Taranganba, QLD 4703",
    "Taranna, TAS 7180",
    "Taratap, SA 5275",
    "Tarawera, QLD 4494",
    "Tarban, NSW 2372",
    "Tarbuck Bay, NSW 2428",
    "Tarcombe, VIC 3666",
    "Tarcoola, SA 5710",
    "Tarcoola Beach, WA 6530",
    "Tarcowie, SA 5431",
    "Tarcutta, NSW 2652",
    "Tardun, WA 6628",
    "Taree, NSW 2430",
    "Taree South, NSW 2430",
    "Taree West, NSW 2430",
    "Taren Point, NSW 2229",
    "Targa, TAS 7259",
    "Targinie, QLD 4694",
    "Tarilta, VIC 3451",
    "Tarin Rock, WA 6353",
    "Taringa, QLD 4068",
    "Taripta, VIC 3620",
    "Tarlee, SA 5411",
    "Tarleton, TAS 7310",
    "Tarlo, NSW 2580",
    "Tarnagulla, VIC 3551",
    "Tarneit, VIC 3029",
    "Tarnma, SA 5413",
    "Tarnook, VIC 3670",
    "Tarome, QLD 4309",
    "Taromeo, QLD 4306",
    "Tarong, QLD 4615",
    "Taroom, QLD 4420",
    "Taroomball, QLD 4703",
    "Taroon, VIC 3265",
    "Taroona, TAS 7053",
    "Tarpeena, SA 5277",
    "Tarra Valley, VIC 3971",
    "Tarraganda, NSW 2550",
    "Tarragindi, QLD 4121",
    "Tarraleah, TAS 7140",
    "Tarramba, QLD 4715",
    "Tarranyurk, VIC 3414",
    "Tarraville, VIC 3971",
    "Tarrawanna, NSW 2518",
    "Tarrawarra, VIC 3775",
    "Tarrawingee, VIC 3678",
    "Tarrayoukyan, VIC 3315",
    "Tarrengower, VIC 3463",
    "Tarrenlea, VIC 3315",
    "Tarriaro, NSW 2390",
    "Tarrington, VIC 3301",
    "Tarro, NSW 2322",
    "Tarrone, VIC 3283",
    "Tarwin, VIC 3956",
    "Tarwin Lower, VIC 3956",
    "Tarzali, QLD 4885",
    "Tascott, NSW 2250",
    "Tatachilla, SA 5171",
    "Tatham, NSW 2471",
    "Tathra, NSW 2550",
    "Tatong, VIC 3673",
    "Tatton, NSW 2650",
    "Tatura, VIC 3616",
    "Tatura East, VIC 3616",
    "Tatyoon, VIC 3378",
    "Taunton, SA 5235",
    "Taunton, QLD 4674",
    "Tawonga, VIC 3697",
    "Tawonga South, VIC 3698",
    "Tayene, TAS 7259",
    "Taylor, ACT 2913",
    "Taylor Bay, VIC 3713",
    "Taylors Arm, NSW 2447",
    "Taylors Beach, QLD 4850",
    "Taylors Beach, NSW 2316",
    "Taylors Flat, NSW 2586",
    "Taylors Hill, VIC 3037",
    "Taylors Lakes, VIC 3038",
    "Taylorville, SA 5330",
    "Te Kowai, QLD 4740",
    "Tea Gardens, NSW 2324",
    "Tea Tree, TAS 7017",
    "Tea Tree Gully, SA 5091",
    "Teal Flat, SA 5238",
    "Teal Point, VIC 3579",
    "Tecoma, VIC 3160",
    "Teddington, QLD 4650",
    "Teddywaddy, VIC 3527",
    "Teddywaddy West, VIC 3527",
    "Teebar, QLD 4620",
    "Teelah, QLD 4306",
    "Teelba, QLD 4423",
    "Teesdale, VIC 3328",
    "Teesdale, WA 6213",
    "Telangatuk East, VIC 3401",
    "Telarah, NSW 2320",
    "Telegraph Point, NSW 2441",
    "Telfer, WA 6762",
    "Telford, VIC 3730",
    "Telina, QLD 4680",
    "Telita, TAS 7264",
    "Tellebang, QLD 4630",
    "Telopea, NSW 2117",
    "Telopea Downs, VIC 3420",
    "Telowie, SA 5540",
    "Temagog, NSW 2440",
    "Temma, TAS 7330",
    "Temora, NSW 2666",
    "Tempe, NSW 2044",
    "Templers, SA 5371",
    "Templestowe, VIC 3106",
    "Templestowe Lower, VIC 3107",
    "Templin, QLD 4310",
    "Tempy, VIC 3489",
    "Ten Mile Hollow, NSW 2250",
    "Tenambit, NSW 2323",
    "Tenandra, NSW 2824",
    "Tenby Point, VIC 3984",
    "Teneriffe, QLD 4005",
    "Tenindewa, WA 6632",
    "Tennant Creek, NT 0860",
    "Tennant Creek, NT 0861",
    "Tennant Creek, NT 0862",
    "Tennyson, QLD 4105",
    "Tennyson, SA 5022",
    "Tennyson, VIC 3572",
    "Tennyson, NSW 2754",
    "Tennyson Point, NSW 2111",
    "Tent Hill, NSW 2371",
    "Tenterden, WA 6322",
    "Tenterden, NSW 2365",
    "Tenterfield, NSW 2372",
    "Tepko, SA 5254",
    "Teralba, NSW 2284",
    "Terang, VIC 3264",
    "Terania Creek, NSW 2480",
    "Terara, NSW 2540",
    "Teridgerie, NSW 2829",
    "Teringie, SA 5072",
    "Terip Terip, VIC 3719",
    "Termeil, NSW 2539",
    "Terowie, SA 5421",
    "Terrabella, NSW 2831",
    "Terrace Creek, NSW 2474",
    "Terragon, NSW 2484",
    "Terramungamine, NSW 2830",
    "Terranora, NSW 2486",
    "Terrappee, VIC 3525",
    "Terreel, NSW 2422",
    "Terrey Hills, NSW 2084",
    "Terrica, QLD 4387",
    "Terrick Terrick, VIC 3575",
    "Terrick Terrick East, VIC 3573",
    "Terrigal, NSW 2260",
    "Terry Hie Hie, NSW 2400",
    "Tesbury, VIC 3260",
    "Tetoora Road, VIC 3821",
    "Teven, NSW 2478",
    "Teviotville, QLD 4309",
    "Tewantin, QLD 4565",
    "Tewinga, NSW 2449",
    "Tewkesbury, TAS 7321",
    "Texas, NSW 4385",
    "Texas, QLD 4385",
    "Thabeban, QLD 4670",
    "Thagoona, QLD 4306",
    "Thalgarrah, NSW 2350",
    "Thalia, VIC 3527",
    "Thallon, QLD 4497",
    "Thalloo, VIC 3825",
    "Thamarrurr, NT 0822",
    "Thane, QLD 4370",
    "Thanes Creek, QLD 4370",
    "Thangkenharenge, NT 0872",
    "Thangool, QLD 4716",
    "Tharbogang, NSW 2680",
    "Thargomindah, QLD 4492",
    "Tharwa, ACT 2620",
    "The Angle, NSW 2620",
    "The Basin, VIC 3154",
    "The Basin, NSW 2365",
    "The Bight, NSW 2429",
    "The Bluff, QLD 4355",
    "The Bluff, QLD 4340",
    "The Branch, NSW 2425",
    "The Brothers, NSW 2630",
    "The Cape, QLD 4816",
    "The Caves, QLD 4702",
    "The Channon, NSW 2480",
    "The Common, QLD 4701",
    "The Cove, VIC 3268",
    "The Dawn, QLD 4570",
    "The Devils Wilderness, NSW 2758",
    "The Dimonds, QLD 4650",
    "The Entrance, NSW 2261",
    "The Entrance North, NSW 2261",
    "The Falls, QLD 4373",
    "The Fingerboard, VIC 3864",
    "The Freshwater, NSW 2466",
    "The Gap, QLD 4825",
    "The Gap, SA 5271",
    "The Gap, NT 0870",
    "The Gap, NSW 2650",
    "The Gap, NSW 2472",
    "The Gap, QLD 4061",
    "The Gardens, NT 0820",
    "The Gardens, TAS 7216",
    "The Gardens, NT 0820",
    "The Glen, NSW 2476",
    "The Glen, QLD 4370",
    "The Gulf, NSW 2365",
    "The Gulf, NSW 2371",
    "The Gums, QLD 4406",
    "The Gurdies, VIC 3984",
    "The Hatch, NSW 2444",
    "The Head, QLD 4373",
    "The Heart, VIC 3851",
    "The Hermitage, QLD 4370",
    "The Hill, NSW 2300",
    "The Honeysuckles, VIC 3851",
    "The Hummock, QLD 4670",
    "The Junction, NSW 2291",
    "The Keppels, QLD 4700",
    "The Lagoon, NSW 2795",
    "The Lakes, WA 6556",
    "The Leap, QLD 4740",
    "The Limits, QLD 4625",
    "The Marra, NSW 2831",
    "The Meadows, NSW 2787",
    "The Mine, QLD 4714",
    "The Monument, QLD 4825",
    "The Narrows, QLD 4695",
    "The Narrows, NT 0820",
    "The Narrows, NT 0820",
    "The Oaks, NSW 2570",
    "The Palms, QLD 4570",
    "The Patch, VIC 3792",
    "The Percy Group, QLD 4707",
    "The Pilliga, NSW 2388",
    "The Pines, QLD 4357",
    "The Pines, SA 5575",
    "The Pines, VIC 3109",
    "The Pinnacles, NSW 2460",
    "The Plains, WA 6237",
    "The Pocket, NSW 2483",
    "The Ponds, NSW 2769",
    "The Range, QLD 4700",
    "The Range, SA 5172",
    "The Ridgeway, NSW 2620",
    "The Risk, NSW 2474",
    "The Rock, NSW 2655",
    "The Rocks, NSW 2795",
    "The Rocks, NSW 2000",
    "The Sandon, NSW 2463",
    "The Sisters, VIC 3265",
    "The Slopes, NSW 2754",
    "The Spectacles, WA 6167",
    "The Summit, QLD 4377",
    "The University Of Adelaide, SA 5005",
    "The University Of Sydney, NSW 2006",
    "The Vines, WA 6069",
    "The Whiteman, NSW 2460",
    "Thebarton, SA 5031",
    "Theebine, QLD 4570",
    "Theodore, QLD 4719",
    "Theodore, ACT 2905",
    "Theresa Creek, QLD 4721",
    "Theresa Creek, NSW 2469",
    "Theresa Park, NSW 2570",
    "Thevenard, SA 5690",
    "Thevenard Island, WA 6711",
    "Thinoomba, QLD 4650",
    "Thirldene, NSW 2347",
    "Thirlmere, NSW 2572",
    "Thirloene, NSW 2347",
    "Thirlstane, TAS 7307",
    "Thirroul, NSW 2515",
    "Thologolong, VIC 3691",
    "Thomas Plain, SA 5554",
    "Thomastown, VIC 3074",
    "Thompson Beach, SA 5501",
    "Thompson Point, QLD 4702",
    "Thomson, VIC 3825",
    "Thomson, VIC 3219",
    "Thomson Brook, WA 6239",
    "Thoona, VIC 3726",
    "Thoopara, QLD 4800",
    "Thora, NSW 2454",
    "Thornborough, QLD 4871",
    "Thornbury, VIC 3071",
    "Thorndale, QLD 4380",
    "Thorneside, QLD 4158",
    "Thorngate, SA 5082",
    "Thornlands, QLD 4164",
    "Thornlea, SA 5280",
    "Thornleigh, NSW 2120",
    "Thornlie, WA 6988",
    "Thornlie, WA 6108",
    "Thornton, VIC 3712",
    "Thornton, QLD 4341",
    "Thornton, NSW 2322",
    "Thornton Beach, QLD 4873",
    "Thornville, QLD 4352",
    "Thorpdale, VIC 3835",
    "Thorpdale South, VIC 3824",
    "Thowgla Valley, VIC 3707",
    "Thredbo, NSW 2625",
    "Three Bridges, VIC 3797",
    "Three Hummock Island, TAS 7330",
    "Three Moon, QLD 4630",
    "Three Rivers, QLD 4824",
    "Three Springs, WA 6519",
    "Three Ways, NT 0862",
    "Thrington, SA 5552",
    "Throssell, WA 6401",
    "Thrumster, NSW 2444",
    "Thuddungra, NSW 2594",
    "Thule, NSW 2732",
    "Thulimbah, QLD 4376",
    "Thumb Creek, NSW 2447",
    "Thurgoona, NSW 2640",
    "Thuringowa Central, QLD 4817",
    "Thursday Island, QLD 4875",
    "Thyra, NSW 2731",
    "Ti Tree, NT 0872",
    "Tianjara, NSW 2622",
    "Tiaro, QLD 4650",
    "Tiatukia, SA 5607",
    "Tibbuc, NSW 2422",
    "Tiberias, TAS 7120",
    "Tibooburra, NSW 2880",
    "Tibradden, WA 6532",
    "Tichborne, NSW 2870",
    "Tichular, NSW 2850",
    "Tickera, SA 5555",
    "Tidal River, VIC 3960",
    "Tiddy Widdy Beach, SA 5571",
    "Tieri, QLD 4709",
    "Tighes Hill, NSW 2297",
    "Tilba Tilba, NSW 2546",
    "Tilbuster, NSW 2350",
    "Tilley Swamp, SA 5275",
    "Tilligerry Creek, NSW 2319",
    "Tilpa, NSW 2840",
    "Timbarra, VIC 3885",
    "Timbarra, NSW 2372",
    "Timber Creek, NT 0852",
    "Timbillica, NSW 2551",
    "Timboon, VIC 3268",
    "Timboon West, VIC 3268",
    "Timbumburi, NSW 2340",
    "Timmering, VIC 3561",
    "Timor, NSW 2338",
    "Timor, VIC 3465",
    "Timor West, VIC 3465",
    "Tin Can Bay, QLD 4580",
    "Tinamba, VIC 3859",
    "Tinamba West, VIC 3859",
    "Tinana, QLD 4650",
    "Tinana South, QLD 4650",
    "Tinaroo, QLD 4872",
    "Tinbeerwah, QLD 4563",
    "Tincurrin, WA 6361",
    "Tindal, NT 0853",
    "Tindarey, NSW 2835",
    "Tinderbox, TAS 7054",
    "Tinderry, NSW 2620",
    "Tingalpa, QLD 4173",
    "Tingalpa Bc, QLD 4173",
    "Tingha, NSW 2369",
    "Tingira Heights, NSW 2290",
    "Tingledale, WA 6333",
    "Tingoora, QLD 4608",
    "Tingun, QLD 4455",
    "Tinnanbar, QLD 4650",
    "Tinonee, NSW 2430",
    "Tinpot, NSW 2546",
    "Tintaldra, VIC 3708",
    "Tintenbar, NSW 2478",
    "Tintinara, SA 5266",
    "Tintinhull, NSW 2352",
    "Tiona, NSW 2428",
    "Tipperary, NT 0822",
    "Tipperary, NSW 2429",
    "Tipton, QLD 4405",
    "Tiri, NSW 2424",
    "Tirrannaville, NSW 2580",
    "Tirroan, QLD 4671",
    "Titaatee Creek, NSW 2422",
    "Titjikala, NT 0872",
    "Tittybong, VIC 3542",
    "Tivendale, NT 0822",
    "Tivoli, QLD 4305",
    "Tiwi, NT 0810",
    "Tiwi, NT 0810",
    "Tiwi Islands, NT 0822",
    "Tjirrkarli, WA 0872",
    "Tjukurla, WA 0872",
    "Tocal, NSW 2421",
    "Tocal, QLD 4730",
    "Tocumwal, NSW 2714",
    "Tods Corner, TAS 7030",
    "Togari, TAS 7330",
    "Toiberry, TAS 7301",
    "Toko, QLD 4829",
    "Tol Tol, VIC 3549",
    "Tolderol, SA 5256",
    "Tolga, QLD 4882",
    "Toll, QLD 4820",
    "Tolland, NSW 2650",
    "Tolmans Hill, TAS 7007",
    "Tolmie, VIC 3723",
    "Tolwong, NSW 2622",
    "Tom Groggin, VIC 3707",
    "Tom Price, WA 6751",
    "Tomago, NSW 2322",
    "Tomahawk, TAS 7262",
    "Tomakin, NSW 2537",
    "Tomalla, NSW 2337",
    "Tombong, NSW 2633",
    "Tomboye, NSW 2622",
    "Tomerong, NSW 2540",
    "Tomewin, NSW 2484",
    "Tomingley, NSW 2869",
    "Tomki, NSW 2470",
    "Toms Creek, NSW 2446",
    "Tonderburine, NSW 2828",
    "Tonebridge, WA 6244",
    "Tongala, VIC 3621",
    "Tonganah, TAS 7260",
    "Tongarra, NSW 2527",
    "Tonghi Creek, VIC 3890",
    "Tongio, VIC 3896",
    "Tonimbuk, VIC 3815",
    "Tooan, VIC 3409",
    "Toobanna, QLD 4850",
    "Toobeah, QLD 4498",
    "Tooborac, VIC 3522",
    "Toodyay, WA 6566",
    "Toogong, NSW 2864",
    "Toogoolawah, QLD 4313",
    "Toogoom, QLD 4655",
    "Toolakea, QLD 4818",
    "Toolamba, VIC 3614",
    "Toolamba West, VIC 3614",
    "Toolangi, VIC 3777",
    "Toolara Forest, QLD 4570",
    "Toolburra, QLD 4370",
    "Toolern Vale, VIC 3337",
    "Tooleybuc, NSW 2736",
    "Toolibin, WA 6312",
    "Tooligie, SA 5607",
    "Toolijooa, NSW 2534",
    "Toolleen, VIC 3551",
    "Toolome, VIC 3860",
    "Toolondo, VIC 3401",
    "Toolong, VIC 3285",
    "Toolonga, WA 6532",
    "Toolooa, QLD 4680",
    "Tooloom, NSW 2475",
    "Tooloon, NSW 2829",
    "Tooma, NSW 2642",
    "Toombon, VIC 3825",
    "Toombul, QLD 4012",
    "Toompup, WA 6336",
    "Tooms Lake, TAS 7209",
    "Toomulla, QLD 4816",
    "Toondahra, QLD 4625",
    "Toongabbie, NSW 2146",
    "Toongabbie, VIC 3856",
    "Toongabbie East, NSW 2146",
    "Toongi, NSW 2830",
    "Toonpan, QLD 4816",
    "Toonumbar, NSW 2474",
    "Tooperang, SA 5255",
    "Toora, VIC 3962",
    "Toora, SA 5253",
    "Toora North, VIC 3962",
    "Tooradin, VIC 3980",
    "Toorak, VIC 3142",
    "Toorak Gardens, SA 5065",
    "Tooranie, NSW 2734",
    "Tooraweenah, NSW 2831",
    "Toorbul, QLD 4510",
    "Toorloo Arm, VIC 3909",
    "Toormina, NSW 2452",
    "Toorongo, VIC 3833",
    "Toorooka, NSW 2440",
    "Tootenilla, SA 5607",
    "Tootgarook, VIC 3941",
    "Toothdale, NSW 2550",
    "Tootool, NSW 2655",
    "Toowong, QLD 4066",
    "Toowong Bc, QLD 4066",
    "Toowoomba, QLD 4350",
    "Toowoomba Bc, QLD 4350",
    "Toowoomba City, QLD 4350",
    "Toowoomba East, QLD 4350",
    "Toowoomba Mc, QLD 4352",
    "Toowoomba South, QLD 4350",
    "Toowoomba Village Fair, QLD 4350",
    "Toowoomba West, QLD 4350",
    "Toowoon Bay, NSW 2261",
    "Top Camp, QLD 4350",
    "Top Naas, ACT 2620",
    "Top Springs, NT 0852",
    "Topaz, QLD 4885",
    "Topi Topi, NSW 2423",
    "Torbanlea, QLD 4662",
    "Torbay, WA 6330",
    "Torndirrup, WA 6330",
    "Toronto, NSW 2283",
    "Torquay, VIC 3228",
    "Torquay, QLD 4655",
    "Torrens, ACT 2607",
    "Torrens Creek, QLD 4816",
    "Torrens Island, SA 5960",
    "Torrens Park, SA 5062",
    "Torrens Vale, SA 5203",
    "Torrensville, SA 5031",
    "Torrensville Plaza, SA 5031",
    "Torrington, NSW 2371",
    "Torrington, QLD 4350",
    "Torrita, VIC 3490",
    "Torrumbarry, VIC 3562",
    "Torryburn, NSW 2358",
    "Torryburn, NSW 2421",
    "Tortilla Flats, NT 0822",
    "Torwood, VIC 3821",
    "Tostaree, VIC 3888",
    "Tothill Belt, SA 5413",
    "Tothill Creek, SA 5413",
    "Totnes Valley, NSW 2850",
    "Totness, SA 5250",
    "Tottenham, NSW 2873",
    "Tottenham, VIC 3012",
    "Tottington, VIC 3477",
    "Touga, NSW 2622",
    "Toukley, NSW 2263",
    "Tourello, VIC 3363",
    "Towallum, NSW 2460",
    "Towamba, NSW 2550",
    "Towan, VIC 3596",
    "Towaninny, VIC 3527",
    "Towaninny South, VIC 3527",
    "Towen Mountain, QLD 4560",
    "Tower Hill, VIC 3283",
    "Towers Hill, QLD 4820",
    "Towitta, SA 5353",
    "Town Common, QLD 4810",
    "Townsend, NSW 2463",
    "Townsendale, WA 6311",
    "Townson, QLD 4341",
    "Townsville, QLD 4810",
    "Townsville City, QLD 4810",
    "Townsville Milpo, QLD 4813",
    "Townview, QLD 4825",
    "Towong, VIC 3707",
    "Towong Upper, VIC 3707",
    "Towradgi, NSW 2518",
    "Towrang, NSW 2580",
    "Trafalgar, VIC 3824",
    "Trafalgar, WA 6431",
    "Trafalgar East, VIC 3824",
    "Trafalgar South, VIC 3824",
    "Tragowel, VIC 3579",
    "Tralee, NSW 2620",
    "Trangie, NSW 2823",
    "Tranmere, TAS 7018",
    "Tranmere, SA 5073",
    "Tranmere North, SA 5073",
    "Traralgon, VIC 3844",
    "Traralgon East, VIC 3844",
    "Traralgon South, VIC 3844",
    "Travancore, VIC 3032",
    "Travellers Rest, TAS 7250",
    "Traveston, QLD 4570",
    "Trawalla, VIC 3373",
    "Trawool, VIC 3660",
    "Trayning, WA 6488",
    "Traynors Lagoon, VIC 3477",
    "Trebonne, QLD 4850",
    "Treeton, WA 6284",
    "Tregeagle, NSW 2480",
    "Tregear, NSW 2770",
    "Tregony, QLD 4370",
    "Tremont, VIC 3785",
    "Trenah, TAS 7263",
    "Trenayr, NSW 2460",
    "Trent, WA 6333",
    "Trentham, VIC 3458",
    "Trentham Cliffs, NSW 2738",
    "Trentham East, VIC 3458",
    "Tresco, VIC 3583",
    "Tresco West, VIC 3584",
    "Trevallyn, TAS 7250",
    "Trewilga, NSW 2869",
    "Triabunna, TAS 7190",
    "Trial Harbour, TAS 7469",
    "Triamble, NSW 2850",
    "Triangle Flat, NSW 2795",
    "Trida, VIC 3953",
    "Trigg, WA 6029",
    "Trigwell, WA 6244",
    "Trihi, SA 5279",
    "Trinity Beach, QLD 4879",
    "Trinity Gardens, SA 5068",
    "Trinity Park, QLD 4879",
    "Trott Park, SA 5158",
    "Trotter Creek, QLD 4714",
    "Trowutta, TAS 7330",
    "Truganina, VIC 3029",
    "Trunding, QLD 4874",
    "Trundle, NSW 2875",
    "Trungley Hall, NSW 2666",
    "Trunkey Creek, NSW 2795",
    "Truro, SA 5356",
    "Trustums Hill, NSW 2472",
    "Tuan, QLD 4650",
    "Tuan Forest, QLD 4650",
    "Tuart Hill, WA 6060",
    "Tuart Hill, WA 6937",
    "Tuart Hill, WA 6938",
    "Tuart Hill, WA 6939",
    "Tuart Hill, WA 6940",
    "Tubbamurra, NSW 2365",
    "Tubbul, NSW 2594",
    "Tubbut, VIC 3888",
    "Tucabia, NSW 2462",
    "Tuchekoi, QLD 4570",
    "Tucki Tucki, NSW 2480",
    "Tuckombil, NSW 2477",
    "Tuckurimba, NSW 2480",
    "Tuen, QLD 4490",
    "Tuena, NSW 2583",
    "Tuerong, VIC 3915",
    "Tuggerah, NSW 2259",
    "Tuggeranong, ACT 2900",
    "Tuggerawong, NSW 2259",
    "Tugrabakh, NSW 2422",
    "Tugrah, TAS 7310",
    "Tugun, QLD 4224",
    "Tugun Heights, QLD 4224",
    "Tulendeena, TAS 7260",
    "Tulka, SA 5607",
    "Tulka North, SA 5607",
    "Tulkara, VIC 3384",
    "Tullah, TAS 7321",
    "Tullakool, NSW 2732",
    "Tullamarine, VIC 3043",
    "Tullamore, NSW 2874",
    "Tullarwalla, NSW 2540",
    "Tullera, NSW 2480",
    "Tullibigeal, NSW 2669",
    "Tullimbar, NSW 2527",
    "Tulloona, NSW 2400",
    "Tully, QLD 4854",
    "Tully Heads, QLD 4854",
    "Tullymorgan, NSW 2463",
    "Tumbarumba, NSW 2653",
    "Tumbi Umbi, NSW 2261",
    "Tumbling Waters, NT 0822",
    "Tumblong, NSW 2729",
    "Tumbulgum, NSW 2490",
    "Tumby Bay, SA 5605",
    "Tummaville, QLD 4352",
    "Tumorrama, NSW 2720",
    "Tumoulin, QLD 4888",
    "Tumut, NSW 2720",
    "Tumut Plains, NSW 2720",
    "Tunbridge, TAS 7120",
    "Tuncester, NSW 2480",
    "Tuncurry, NSW 2428",
    "Tungamah, VIC 3728",
    "Tungamull, QLD 4702",
    "Tungkillo, SA 5236",
    "Tunglebung, NSW 2469",
    "Tunkalilla, SA 5203",
    "Tunnack, TAS 7120",
    "Tunnel, TAS 7254",
    "Tunstall Square Po, VIC 3109",
    "Tuntable Creek, NSW 2480",
    "Tuppal, NSW 2714",
    "Tura Beach, NSW 2548",
    "Turallin, QLD 4357",
    "Turill, NSW 2850",
    "Turkey Beach, QLD 4678",
    "Turkey Hill, WA 6426",
    "Turlinjah, NSW 2537",
    "Turner, ACT 2612",
    "Turners Beach, TAS 7315",
    "Turners Flat, NSW 2440",
    "Turners Marsh, TAS 7267",
    "Turoar, VIC 3546",
    "Turondale, NSW 2795",
    "Tuross, NSW 2630",
    "Tuross Head, NSW 2537",
    "Turramurra, NSW 2074",
    "Turrawan, NSW 2390",
    "Turrawulla, QLD 4742",
    "Turrella, NSW 2205",
    "Turriff, VIC 3488",
    "Turriff East, VIC 3488",
    "Turtons Creek, VIC 3960",
    "Turvey Park, NSW 2650",
    "Tusmore, SA 5065",
    "Tutunup, WA 6280",
    "Tutye, VIC 3490",
    "Tweed Heads, NSW 2485",
    "Tweed Heads South, NSW 2486",
    "Tweed Heads West, NSW 2485",
    "Twelve Mile, NSW 2850",
    "Twelve Mile Creek, NSW 2324",
    "Twelve Mile Peg, NSW 2540",
    "Twenty Forests, NSW 2795",
    "Twin Rivers, NSW 2410",
    "Twin Waters, QLD 4564",
    "Two Mile, QLD 4570",
    "Two Mile Flat, NSW 2852",
    "Two Rocks, WA 6037",
    "Two Wells, SA 5501",
    "Tyaak, VIC 3658",
    "Tyabb, VIC 3913",
    "Tyagarah, NSW 2481",
    "Tyalgum, NSW 2484",
    "Tyalgum Creek, NSW 2484",
    "Tyenna, VIC 3533",
    "Tyenna, TAS 7140",
    "Tyers, VIC 3844",
    "Tygalgah, NSW 2484",
    "Tylden, VIC 3444",
    "Tylden South, VIC 3444",
    "Tyndale, NSW 2460",
    "Tynong, VIC 3813",
    "Tynong North, VIC 3813",
    "Tyntynder, VIC 3586",
    "Tyntynder South, VIC 3586",
    "Tyrconnel, QLD 4467",
    "Tyrendarra, VIC 3285",
    "Tyrendarra East, VIC 3285",
    "Tyringa, SA 5671",
    "Tyringham, NSW 2453",
    "Tyrrell, VIC 3533",
    "Tyrrell Downs, VIC 3533",
    "Uarbry, NSW 2329",
    "Ubobo, QLD 4680",
    "Ucarty, WA 6462",
    "Ucarty West, WA 6460",
    "Ucolta, SA 5422",
    "Uduc, WA 6220",
    "Uki, NSW 2484",
    "Ulamambri, NSW 2357",
    "Ulan, NSW 2850",
    "Ularring, WA 6436",
    "Uley, SA 5607",
    "Uleybury, SA 5114",
    "Ulladulla, NSW 2539",
    "Ullamalla, NSW 2850",
    "Ullina, VIC 3370",
    "Ullswater, VIC 3318",
    "Ulmarra, NSW 2462",
    "Ulogie, QLD 4702",
    "Ulong, NSW 2450",
    "Ulooloo, SA 5419",
    "Ultima, VIC 3544",
    "Ultima East, VIC 3544",
    "Ultimo, NSW 2007",
    "Ulupna, VIC 3641",
    "Uluru, NT 0872",
    "Ulverstone, TAS 7315",
    "Ulyerra, SA 5670",
    "Umagico, QLD 4876",
    "Umbakumba, NT 0822",
    "Umbiram, QLD 4352",
    "Umina Beach, NSW 2257",
    "Umpangara, NT 0872",
    "Unanderra, NSW 2526",
    "Undalya, SA 5451",
    "Undera, VIC 3629",
    "Underbank, NSW 2420",
    "Underbool, VIC 3509",
    "Underdale, SA 5032",
    "Underwood, TAS 7268",
    "Underwood, QLD 4119",
    "Undoolya, NT 0870",
    "Undullah, QLD 4285",
    "Ungarie, NSW 2669",
    "Ungarra, SA 5607",
    "University Of Adelaide, SA 5005",
    "University Of Canberra, ACT 2617",
    "University Of Melbourne, VIC 3010",
    "University Of New England, NSW 2351",
    "University Of Queensland, QLD 4072",
    "University Of Tasmania, TAS 7005",
    "University Of Wollongong, NSW 2522",
    "Unknown, WA 0000",
    "Unley, SA 5061",
    "Unley Bc, SA 5061",
    "Unley Park, SA 5061",
    "Unumgar, NSW 2474",
    "Upotipotpon, VIC 3669",
    "Upper Allyn, NSW 2311",
    "Upper Barron, QLD 4883",
    "Upper Bingara, NSW 2404",
    "Upper Blessington, TAS 7212",
    "Upper Brookfield, QLD 4069",
    "Upper Burnie, TAS 7320",
    "Upper Burringbar, NSW 2483",
    "Upper Bylong, NSW 2849",
    "Upper Caboolture, QLD 4510",
    "Upper Capel, WA 6239",
    "Upper Castra, TAS 7315",
    "Upper Colo, NSW 2756",
    "Upper Coomera, QLD 4209",
    "Upper Coopers Creek, NSW 2482",
    "Upper Cooyar Creek, QLD 4402",
    "Upper Copmanhurst, NSW 2460",
    "Upper Corindi, NSW 2456",
    "Upper Crystal Creek, NSW 2484",
    "Upper Daintree, QLD 4873",
    "Upper Daradgee, QLD 4860",
    "Upper Dartbrook, NSW 2336",
    "Upper Dawson, QLD 4454",
    "Upper Duck Creek, NSW 2469",
    "Upper Duroby, NSW 2486",
    "Upper Eden Creek, NSW 2474",
    "Upper Esk, TAS 7214",
    "Upper Ferntree Gully, VIC 3156",
    "Upper Fine Flower, NSW 2460",
    "Upper Flagstone, QLD 4344",
    "Upper Freestone, QLD 4370",
    "Upper Glastonbury, QLD 4570",
    "Upper Growee, NSW 2849",
    "Upper Gundowring, VIC 3691",
    "Upper Haughton, QLD 4809",
    "Upper Hermitage, SA 5131",
    "Upper Horseshoe Creek, NSW 2474",
    "Upper Horton, NSW 2347",
    "Upper Kandanga, QLD 4570",
    "Upper Kangaroo River, NSW 2577",
    "Upper Kangaroo Valley, NSW 2577",
    "Upper Karuah River, NSW 2415",
    "Upper Kedron, QLD 4055",
    "Upper Lansdowne, NSW 2430",
    "Upper Lockyer, QLD 4352",
    "Upper Lurg, VIC 3673",
    "Upper Macdonald, NSW 2775",
    "Upper Main Arm, NSW 2482",
    "Upper Mangrove, NSW 2250",
    "Upper Manilla, NSW 2346",
    "Upper Mongogarie, NSW 2470",
    "Upper Mount Gravatt, QLD 4122",
    "Upper Mount Gravatt Bc, QLD 4122",
    "Upper Murray, WA 6390",
    "Upper Myall, NSW 2423",
    "Upper Natone, TAS 7321",
    "Upper Nile, NSW 2849",
    "Upper Obley, NSW 2868",
    "Upper Orara, NSW 2450",
    "Upper Pappinbarra, NSW 2446",
    "Upper Pilton, QLD 4361",
    "Upper Pinelands, QLD 4355",
    "Upper Plenty, VIC 3756",
    "Upper Rollands Plains, NSW 2441",
    "Upper Rouchel, NSW 2336",
    "Upper Ryans Creek, VIC 3673",
    "Upper Scamander, TAS 7215",
    "Upper Stone, QLD 4850",
    "Upper Stowport, TAS 7321",
    "Upper Sturt, SA 5156",
    "Upper Swan, WA 6069",
    "Upper Taylors Arm, NSW 2447",
    "Upper Tenthill, QLD 4343",
    "Upper Tooloom, NSW 2475",
    "Upper Turon, NSW 2795",
    "Upper Warrego, QLD 4477",
    "Upper Warren, WA 6258",
    "Upper Wheatvale, QLD 4370",
    "Upper Wilsons Creek, NSW 2482",
    "Upper Woodstock, TAS 7150",
    "Upper Yarraman, QLD 4614",
    "Upsalls Creek, NSW 2439",
    "Upton Hill, VIC 3664",
    "Upwey, VIC 3158",
    "Uraidla, SA 5142",
    "Uralba, NSW 2477",
    "Uralla, NSW 2358",
    "Uralla, NT 0852",
    "Urana, NSW 2645",
    "Urangan, QLD 4655",
    "Urangeline, NSW 2656",
    "Urangeline East, NSW 2656",
    "Urania, SA 5573",
    "Uranquinty, NSW 2652",
    "Urapuntja, NT 0872",
    "Urawilkie, NSW 2829",
    "Urbenville, NSW 2475",
    "Uriarra, NSW 2611",
    "Uriarra, ACT 2611",
    "Uriarra Forest, ACT 2611",
    "Urila, NSW 2620",
    "Urliup, NSW 2484",
    "Urraween, QLD 4655",
    "Urrbrae, SA 5064",
    "Urunga, NSW 2455",
    "Useless Loop, WA 6537",
    "Usher, WA 6230",
    "Utakarra, WA 6530",
    "Utchee Creek, QLD 4871",
    "Utungun, NSW 2447",
    "Uworra, SA 5690",
    "Uxbridge, TAS 7140",
    "V Gate, QLD 4465",
    "Vacy, NSW 2421",
    "Valdora, QLD 4561",
    "Vale Of Clwydd, NSW 2790",
    "Vale Park, SA 5081",
    "Vale View, QLD 4352",
    "Valencia Creek, VIC 3860",
    "Valentine, WA 6532",
    "Valentine, NSW 2280",
    "Valentine Plains, QLD 4715",
    "Valery, NSW 2454",
    "Valkyrie, QLD 4742",
    "Valla, NSW 2448",
    "Valla Beach, NSW 2448",
    "Valley Heights, NSW 2777",
    "Valley Of Lagoons, QLD 4850",
    "Valley View, SA 5093",
    "Vancouver Peninsula, WA 6330",
    "Varley, WA 6355",
    "Varroville, NSW 2566",
    "Varsity Lakes, QLD 4227",
    "Vasa Views, QLD 4860",
    "Vasey, VIC 3407",
    "Vasse, WA 6280",
    "Vaucluse, NSW 2030",
    "Vaughan, VIC 3451",
    "Vectis, VIC 3401",
    "Veitch, SA 5311",
    "Venn, NT 0852",
    "Ventnor, QLD 4630",
    "Ventnor, VIC 3922",
    "Venus Bay, SA 5607",
    "Venus Bay, VIC 3956",
    "Veradilla, QLD 4347",
    "Verdun, SA 5245",
    "Veresdale, QLD 4285",
    "Veresdale Scrub, QLD 4285",
    "Vergemont, QLD 4730",
    "Verges Creek, NSW 2440",
    "Vermont, VIC 3133",
    "Vermont South, VIC 3133",
    "Vernon Islands, NT 0822",
    "Vernor, QLD 4306",
    "Verona, NSW 2550",
    "Verona Sands, TAS 7112",
    "Verran, SA 5603",
    "Verrierdale, QLD 4562",
    "Vervale, VIC 3814",
    "Vesper, VIC 3833",
    "Veteran, QLD 4570",
    "Victor Harbor, SA 5211",
    "Victoria Estate, QLD 4850",
    "Victoria Hill, QLD 4361",
    "Victoria Park, WA 6979",
    "Victoria Park, WA 6100",
    "Victoria Plains, QLD 4751",
    "Victoria Plantation, QLD 4850",
    "Victoria Point, VIC 3294",
    "Victoria Point, QLD 4165",
    "Victoria Point West, QLD 4165",
    "Victoria River, NT 0852",
    "Victoria River Downs, NT 0852",
    "Victoria Rock, WA 6429",
    "Victoria Vale, QLD 4822",
    "Victoria Valley, VIC 3294",
    "Victoria Valley, TAS 7140",
    "Victory Heights, QLD 4570",
    "Victory Heights, WA 6432",
    "Viewbank, VIC 3084",
    "Villawood, NSW 2163",
    "Villeneuve, QLD 4514",
    "Vincent, QLD 4814",
    "Vincentia, NSW 2540",
    "Vine Vale, SA 5352",
    "Vinegar Hill, QLD 4343",
    "Vineyard, NSW 2765",
    "Vinifera, VIC 3591",
    "Violet Hill, NSW 2423",
    "Violet Town, VIC 3669",
    "Virginia, SA 5120",
    "Virginia, QLD 4014",
    "Virginia, NT 0834",
    "Virginia Bc, QLD 4014",
    "Vista, SA 5091",
    "Vite Vite, VIC 3325",
    "Vite Vite North, VIC 3325",
    "Vittoria, WA 6230",
    "Vittoria, NSW 2799",
    "Viveash, WA 6056",
    "Vivonne Bay, SA 5223",
    "Voyager Point, NSW 2172",
    "W Tree, VIC 3885",
    "Waaia, VIC 3637",
    "Waanyarra, VIC 3551",
    "Waarre, VIC 3269",
    "Wabonga, VIC 3678",
    "Wacol, QLD 4076",
    "Wadalba, NSW 2259",
    "Wadbilliga, NSW 2546",
    "Waddamana, TAS 7030",
    "Wadderin, WA 6369",
    "Waddikee, SA 5640",
    "Waddington, WA 6509",
    "Waddy Forest, WA 6515",
    "Wadeville, NSW 2474",
    "Wadeye, NT 0822",
    "Waeel, WA 6407",
    "Wagait Beach, NT 0822",
    "Wagaman, NT 0810",
    "Wagaman, NT 0810",
    "Wagerup, WA 6215",
    "Wagga Wagga, NSW 2650",
    "Wagga Wagga Bc, NSW 2650",
    "Wagga Wagga Raaf, NSW 2651",
    "Waggarandall, VIC 3646",
    "Waggrakine, WA 6530",
    "Wagin, WA 6315",
    "Wagstaffe, NSW 2257",
    "Wahgunyah, VIC 3687",
    "Wahoon, QLD 4625",
    "Wahring, VIC 3608",
    "Wahroonga, NSW 2076",
    "Waikerie, SA 5330",
    "Waikiki, WA 6169",
    "Wail, VIC 3401",
    "Wainui, QLD 4404",
    "Wairewa, VIC 3887",
    "Wairuna, QLD 4872",
    "Waitara, NSW 2077",
    "Waitchie, VIC 3544",
    "Waitpinga, SA 5211",
    "Waitui, NSW 2443",
    "Wak Wak, NT 0822",
    "Wakefield, NSW 2278",
    "Wakeley, NSW 2176",
    "Wakerley, QLD 4154",
    "Wakool, NSW 2710",
    "Wal Wal, VIC 3385",
    "Walang, NSW 2795",
    "Walbundrie, NSW 2642",
    "Walcha, NSW 2354",
    "Walcha Road, NSW 2354",
    "Waldara, VIC 3678",
    "Waldegrave, NSW 2800",
    "Walebing, WA 6510",
    "Walgett, NSW 2832",
    "Walgoolan, WA 6422",
    "Walhalla, VIC 3825",
    "Walhalla East, VIC 3825",
    "Walkamin, QLD 4872",
    "Walkaway, WA 6528",
    "Walker Flat, SA 5238",
    "Walkers Point, QLD 4650",
    "Walkerston, QLD 4751",
    "Walkervale, QLD 4670",
    "Walkerville, SA 5081",
    "Walkerville, VIC 3956",
    "Walkerville South, VIC 3956",
    "Walkley Heights, SA 5098",
    "Wall Flat, SA 5238",
    "Walla Walla, NSW 2659",
    "Wallabadah, NSW 2343",
    "Wallabi Point, NSW 2430",
    "Wallace, VIC 3352",
    "Wallace Rockhole, NT 0872",
    "Wallacedale, VIC 3303",
    "Wallaces Creek, QLD 4310",
    "Wallacetown, NSW 2650",
    "Wallacia, NSW 2745",
    "Wallaga Lake, NSW 2546",
    "Wallagaraugh, VIC 3891",
    "Wallagoot, NSW 2550",
    "Wallala, SA 5661",
    "Wallalong, NSW 2320",
    "Wallaloo, VIC 3387",
    "Wallaloo East, VIC 3387",
    "Wallaman, QLD 4850",
    "Wallamore, NSW 2340",
    "Wallan, VIC 3756",
    "Wallanbah, NSW 2422",
    "Wallangarra, QLD 4383",
    "Wallangra, NSW 2360",
    "Wallanthery, NSW 2675",
    "Wallarah, NSW 2259",
    "Wallareenya, WA 6721",
    "Wallaringa, NSW 2420",
    "Wallarobba, NSW 2420",
    "Wallaroo, WA 6429",
    "Wallaroo, SA 5556",
    "Wallaroo, QLD 4702",
    "Wallaroo, NSW 2618",
    "Wallaroo Mines, SA 5554",
    "Wallaroo Plain, SA 5556",
    "Wallaville, QLD 4671",
    "Wallendbeen, NSW 2588",
    "Wallerawang, NSW 2845",
    "Walleroobie, NSW 2665",
    "Walliebum, QLD 4655",
    "Walligan, QLD 4655",
    "Wallinduc, VIC 3351",
    "Wallingat, NSW 2428",
    "Wallington, VIC 3221",
    "Wallis Lake, NSW 2428",
    "Walliston, WA 6076",
    "Walloon, QLD 4306",
    "Walloway, SA 5431",
    "Wallsend, NSW 2287",
    "Wallsend South, NSW 2287",
    "Wallu, QLD 4570",
    "Wallumbilla, QLD 4428",
    "Wallumbilla North, QLD 4428",
    "Wallumbilla South, QLD 4428",
    "Wallup, VIC 3401",
    "Walmer, NSW 2820",
    "Walmer, VIC 3463",
    "Walmsley, WA 6330",
    "Walmul, QLD 4714",
    "Walpa, VIC 3875",
    "Walpeup, VIC 3507",
    "Walpole, WA 6398",
    "Walsall, WA 6280",
    "Walter Hill, QLD 4854",
    "Walter Lever Estate, QLD 4856",
    "Walterhall, QLD 4714",
    "Waltowa, SA 5264",
    "Walwa, VIC 3709",
    "Walyormouring, WA 6460",
    "Walyunga National Park, WA 6084",
    "Walyurin, WA 6363",
    "Wamban, NSW 2537",
    "Wambangalang, NSW 2830",
    "Wamberal, NSW 2260",
    "Wamboin, NSW 2620",
    "Wambool, NSW 2795",
    "Wambrook, NSW 2630",
    "Wamenusking, WA 6383",
    "Wami Kata, SA 5700",
    "Wamuran, QLD 4512",
    "Wamuran Basin, QLD 4512",
    "Wanaaring, NSW 2840",
    "Wanalta, VIC 3612",
    "Wanbi, SA 5310",
    "Wandal, QLD 4700",
    "Wandana, SA 5690",
    "Wandana, WA 6532",
    "Wandana Heights, VIC 3216",
    "Wandandian, NSW 2540",
    "Wandearah, SA 5523",
    "Wandearah East, SA 5523",
    "Wandearah West, SA 5523",
    "Wandella, NSW 2550",
    "Wandella, VIC 3579",
    "Wandera, NSW 2360",
    "Wandering, WA 6308",
    "Wandi, WA 6167",
    "Wandiligong, VIC 3744",
    "Wandillup, WA 6256",
    "Wandilo, SA 5291",
    "Wandin East, VIC 3139",
    "Wandin North, VIC 3139",
    "Wandina, WA 6530",
    "Wando Bridge, VIC 3312",
    "Wando Vale, VIC 3312",
    "Wandoan, QLD 4419",
    "Wandong, VIC 3758",
    "Wandook, NSW 2710",
    "Wandown, VIC 3549",
    "Wandsworth, NSW 2365",
    "Wanerie, WA 6503",
    "Wang Wauk, NSW 2423",
    "Wangan, QLD 4871",
    "Wangandary, VIC 3678",
    "Wanganella, NSW 2710",
    "Wanganui, NSW 2482",
    "Wangara, WA 6065",
    "Wangarabell, VIC 3891",
    "Wangaratta, QLD 4806",
    "Wangaratta, VIC 3676",
    "Wangaratta, VIC 3677",
    "Wangaratta Forward, VIC 3678",
    "Wangaratta South, VIC 3678",
    "Wangaratta West, VIC 3677",
    "Wangary, SA 5607",
    "Wangetti, QLD 4877",
    "Wangi Wangi, NSW 2267",
    "Wangie, VIC 3530",
    "Wangolina, SA 5275",
    "Wangoom, VIC 3279",
    "Wanguri, NT 0810",
    "Wanguri, NT 0810",
    "Wanilla, SA 5607",
    "Wanjuru, QLD 4860",
    "Wannamal, WA 6505",
    "Wannanup, WA 6210",
    "Wannarn, WA 0872",
    "Wanneroo, WA 6065",
    "Wanneroo, WA 6946",
    "Wanniassa, ACT 2903",
    "Wannon, VIC 3301",
    "Wanora, QLD 4306",
    "Wansbrough, WA 6320",
    "Wantabadgery, NSW 2650",
    "Wantagong, NSW 2644",
    "Wantiool, NSW 2663",
    "Wantirna, VIC 3152",
    "Wantirna South, VIC 3152",
    "Wapengo, NSW 2550",
    "Wappilka, SA 5332",
    "Warabrook, NSW 2304",
    "Waramanga, ACT 2611",
    "Warana, QLD 4575",
    "Waranga, VIC 3616",
    "Waranga Shores, VIC 3612",
    "Waratah, NSW 2298",
    "Waratah, TAS 7321",
    "Waratah Bay, VIC 3959",
    "Waratah West, NSW 2298",
    "Warawarrup, WA 6220",
    "Warburn, NSW 2680",
    "Warburto, SA 5556",
    "Warburton, WA 6431",
    "Warburton, QLD 4823",
    "Warburton, VIC 3799",
    "Ward Belt, SA 5118",
    "Ward Hill, SA 5522",
    "Wardell, NSW 2477",
    "Wardering, WA 6311",
    "Warding East, WA 6405",
    "Wardrop Valley, NSW 2484",
    "Wards Mistake, NSW 2350",
    "Wards River, NSW 2422",
    "Wareek, VIC 3465",
    "Wareemba, NSW 2046",
    "Warenda, QLD 4829",
    "Wargan, VIC 3505",
    "Warialda, NSW 2402",
    "Warialda Rail, NSW 2402",
    "Warilla, NSW 2528",
    "Warkon, QLD 4417",
    "Warkworth, NSW 2330",
    "Warmun, WA 6743",
    "Warmur, VIC 3482",
    "Warnbro, WA 6169",
    "Warncoort, VIC 3243",
    "Warne, VIC 3530",
    "Warneet, VIC 3980",
    "Warner, QLD 4500",
    "Warner Glen, WA 6288",
    "Warners Bay, NSW 2282",
    "Warnertown, SA 5540",
    "Warnervale, NSW 2259",
    "Warnoah, QLD 4718",
    "Warnung, QLD 4605",
    "Warooka, SA 5577",
    "Waroona, WA 6215",
    "Warra, QLD 4411",
    "Warrabah, NSW 2346",
    "Warraber Island, QLD 4875",
    "Warrabkook, VIC 3286",
    "Warrachie, SA 5607",
    "Warrachuppin, WA 6423",
    "Warracknabeal, VIC 3393",
    "Warradale, SA 5046",
    "Warradale North, SA 5046",
    "Warradarge, WA 6518",
    "Warraderry, NSW 2810",
    "Warragai Creek, NSW 2460",
    "Warragamba, NSW 2752",
    "Warragoon, NSW 2710",
    "Warragul, VIC 3820",
    "Warragul South, VIC 3821",
    "Warragul West, VIC 3821",
    "Warrah, NSW 2339",
    "Warrah Creek, NSW 2339",
    "Warrah Ridge, NSW 2343",
    "Warrak, VIC 3377",
    "Warral, NSW 2340",
    "Warralakin, WA 6421",
    "Warramboo, SA 5650",
    "Warrami, QLD 4854",
    "Warrandyte, VIC 3113",
    "Warrandyte South, VIC 3134",
    "Warrane, TAS 7018",
    "Warranulla, NSW 2423",
    "Warranwood, VIC 3134",
    "Warrawee, NSW 2074",
    "Warrawidgee, NSW 2680",
    "Warrawong, NSW 2502",
    "Warrayure, VIC 3301",
    "Warrazambil Creek, NSW 2474",
    "Warregah Island, NSW 2469",
    "Warrego, NT 0862",
    "Warrell Creek, NSW 2447",
    "Warren, SA 5233",
    "Warren, NSW 2824",
    "Warrenbayne, VIC 3670",
    "Warrenheip, VIC 3352",
    "Warrenmang, VIC 3478",
    "Warrentinna, TAS 7261",
    "Warrenup, WA 6330",
    "Warri, NSW 2622",
    "Warriewood, NSW 2102",
    "Warriewood Shopping Square, NSW 2102",
    "Warrill View, QLD 4307",
    "Warrimoo, NSW 2774",
    "Warringah Mall, NSW 2100",
    "Warrion, VIC 3249",
    "Warriwillah, NSW 2429",
    "Warrnambool, VIC 3280",
    "Warrnambool East, VIC 3280",
    "Warrnambool West, VIC 3280",
    "Warrock, VIC 3312",
    "Warrong, VIC 3283",
    "Warroo, NSW 2871",
    "Warroo, QLD 4387",
    "Warrow, SA 5607",
    "Warrubullen, QLD 4871",
    "Warrumbungle, NSW 2828",
    "Warruwi, NT 0822",
    "Wartook, VIC 3401",
    "Warumungu, NT 0852",
    "Warunda, SA 5607",
    "Warwick, WA 6024",
    "Warwick, QLD 4370",
    "Warwick Farm, NSW 2170",
    "Washpool, NSW 2460",
    "Washpool, NSW 2425",
    "Washpool, SA 5454",
    "Washpool, QLD 4306",
    "Wasleys, SA 5400",
    "Watalgan, QLD 4670",
    "Watanobbi, NSW 2259",
    "Watchem, VIC 3482",
    "Watchem West, VIC 3482",
    "Watchman, SA 5461",
    "Watchupga, VIC 3485",
    "Wateranga, QLD 4621",
    "Waterbank, WA 6725",
    "Watercarrin, WA 6407",
    "Waterfall, NSW 2233",
    "Waterfall Gully, SA 5066",
    "Waterford, WA 6152",
    "Waterford, QLD 4133",
    "Waterford, VIC 3862",
    "Waterford Park, VIC 3658",
    "Waterford West, QLD 4133",
    "Waterfront Place, QLD 4001",
    "Watergardens, VIC 3038",
    "Waterholes, VIC 3875",
    "Waterhouse, TAS 7262",
    "Waterloo, VIC 3373",
    "Waterloo, SA 5413",
    "Waterloo, NSW 1440",
    "Waterloo, TAS 7109",
    "Waterloo, WA 6228",
    "Waterloo, QLD 4673",
    "Waterloo, NSW 2017",
    "Waterloo Corner, SA 5110",
    "Watermans Bay, WA 6020",
    "Watersleigh, NSW 2540",
    "Watervale, SA 5452",
    "Waterview, NSW 2460",
    "Waterview Heights, NSW 2460",
    "Waterways, VIC 3195",
    "Watheroo, WA 6513",
    "Watraba, SA 5690",
    "Watson, ACT 2602",
    "Watsonia, VIC 3087",
    "Watsonia North, VIC 3087",
    "Watsons Bay, NSW 2030",
    "Watsons Creek, NSW 2355",
    "Watsons Creek, VIC 3097",
    "Watsons Crossing, QLD 4385",
    "Watsonville, QLD 4887",
    "Wattamolla, NSW 2535",
    "Wattamondara, NSW 2794",
    "Wattening, WA 6568",
    "Wattle Bank, VIC 3995",
    "Wattle Camp, QLD 4615",
    "Wattle Creek, VIC 3384",
    "Wattle Flat, VIC 3352",
    "Wattle Flat, NSW 2795",
    "Wattle Flat, SA 5203",
    "Wattle Glen, VIC 3096",
    "Wattle Grove, QLD 4610",
    "Wattle Grove, NSW 2173",
    "Wattle Grove, TAS 7109",
    "Wattle Grove, WA 6107",
    "Wattle Hill, TAS 7172",
    "Wattle Hill, VIC 3237",
    "Wattle Park, VIC 3128",
    "Wattle Park, SA 5066",
    "Wattle Ponds, NSW 2330",
    "Wattle Range, SA 5280",
    "Wattle Range East, SA 5279",
    "Wattle Ridge, QLD 4357",
    "Wattle Ridge, NSW 2575",
    "Wattle Springs, NSW 2357",
    "Wattlebank, QLD 4704",
    "Wattletree Road Po, VIC 3145",
    "Wattleup, WA 6166",
    "Watton, NSW 2795",
    "Wattoning, WA 6479",
    "Waubra, VIC 3352",
    "Wauchope, NSW 2446",
    "Waugh Pocket, QLD 4871",
    "Waugorah, NSW 2711",
    "Waukaringa, SA 5440",
    "Waukivory, NSW 2422",
    "Wauraltee, SA 5573",
    "Waurn Ponds, VIC 3216",
    "Wave Hill, NT 0852",
    "Wavell Heights, QLD 4012",
    "Wavell Heights North, QLD 4012",
    "Waverley, QLD 4825",
    "Waverley, TAS 7250",
    "Waverley, NSW 2024",
    "Waverley Gardens, VIC 3170",
    "Waverly, NSW 2337",
    "Waverton, NSW 2060",
    "Way Way, NSW 2447",
    "Wayatinah, TAS 7140",
    "Waygara, VIC 3888",
    "Wayo, NSW 2580",
    "Wayville, SA 5034",
    "Weabonga, NSW 2340",
    "Wealwandangie, QLD 4722",
    "Wean, NSW 2382",
    "Weatherboard, VIC 3352",
    "Webb, QLD 4860",
    "Webb Beach, SA 5501",
    "Webbers Creek, NSW 2421",
    "Webberton, WA 6530",
    "Webbs Creek, NSW 2775",
    "Weddell, NT 0822",
    "Wedderburn, VIC 3518",
    "Wedderburn, NSW 2560",
    "Wedderburn Junction, VIC 3518",
    "Wedge Island, WA 6044",
    "Wedgecarrup, WA 6315",
    "Wedgefield, WA 6721",
    "Wee Jasper, NSW 2582",
    "Wee Waa, NSW 2388",
    "Wee Wee Rup, VIC 3568",
    "Weeaproinah, VIC 3237",
    "Weedallion, NSW 2594",
    "Weegena, TAS 7304",
    "Weelong, NSW 2871",
    "Weemelah, NSW 2406",
    "Weengallon, QLD 4497",
    "Weering, VIC 3251",
    "Weerite, VIC 3260",
    "Weerriba, QLD 4703",
    "Weetah, TAS 7304",
    "Weetaliba, NSW 2395",
    "Weetangera, ACT 2614",
    "Weethalle, NSW 2669",
    "Weetulta, SA 5573",
    "Wehla, VIC 3518",
    "Weilmoringle, NSW 2839",
    "Weipa, QLD 4874",
    "Weipa Airport, QLD 4874",
    "Weir River, QLD 4406",
    "Weismantels, NSW 2415",
    "Welaregang, NSW 2642",
    "Welbungin, WA 6477",
    "Welby, NSW 2575",
    "Welcome Creek, QLD 4670",
    "Weld Range, WA 6640",
    "Weldborough, TAS 7264",
    "Welland, SA 5007",
    "Wellard, WA 6170",
    "Wellcamp, QLD 4350",
    "Wellers Hill, QLD 4121",
    "Wellesley, QLD 4417",
    "Wellesley, WA 6233",
    "Wellesley Islands, QLD 4871",
    "Wellingrove, NSW 2370",
    "Wellington, NSW 2820",
    "Wellington, SA 5259",
    "Wellington East, SA 5259",
    "Wellington Forest, WA 6236",
    "Wellington Mill, WA 6236",
    "Wellington Park, TAS 7054",
    "Wellington Point, QLD 4160",
    "Wellington Vale, NSW 2371",
    "Wells Crossing, NSW 2460",
    "Wellsford, VIC 3551",
    "Wellstead, WA 6328",
    "Welsby, QLD 4507",
    "Welshmans Reef, VIC 3462",
    "Welshpool, VIC 3966",
    "Welshpool, WA 6106",
    "Wembley, WA 6913",
    "Wembley, WA 6014",
    "Wembley Downs, WA 6019",
    "Wemen, VIC 3549",
    "Wendoree Park, NSW 2250",
    "Wendouree, VIC 3355",
    "Wendouree Village, VIC 3355",
    "Wengenville, QLD 4615",
    "Wenlock, QLD 4874",
    "Wensleydale, VIC 3241",
    "Wentworth, NSW 2648",
    "Wentworth, VIC 3875",
    "Wentworth Falls, NSW 2782",
    "Wentworth Point, NSW 2127",
    "Wentworthville, NSW 2145",
    "Wepar, SA 5278",
    "Wepowie, SA 5482",
    "Werai, NSW 2577",
    "Weranga, QLD 4405",
    "Were Street Po, VIC 3186",
    "Wereboldera, NSW 2720",
    "Weribone, QLD 4417",
    "Wermatong, NSW 2720",
    "Werneth, VIC 3352",
    "Werombi, NSW 2570",
    "Werona, VIC 3364",
    "Werri Beach, NSW 2534",
    "Werribee, VIC 3030",
    "Werribee South, VIC 3030",
    "Werrikimbe, NSW 2446",
    "Werrimull, VIC 3496",
    "Werrington, NSW 2747",
    "Werrington County, NSW 2747",
    "Werrington Downs, NSW 2747",
    "Werris Creek, NSW 2341",
    "Wesburn, VIC 3799",
    "Wesley Vale, TAS 7307",
    "West Albury, NSW 2640",
    "West Amberley, QLD 4306",
    "West Armidale, NSW 2350",
    "West Arnhem, NT 0822",
    "West Ballidu, WA 6606",
    "West Ballina, NSW 2478",
    "West Bathurst, NSW 2795",
    "West Beach, SA 5024",
    "West Beach, WA 6450",
    "West Bendigo, VIC 3550",
    "West Binnu, WA 6532",
    "West Bundaleer, SA 5491",
    "West Bungawalbin, NSW 2469",
    "West Burleigh, QLD 4219",
    "West Busselton, WA 6280",
    "West Cape Howe, WA 6330",
    "West Casuarinas, WA 6630",
    "West Chatswood, NSW 1515",
    "West Coolup, WA 6214",
    "West Coraki, NSW 2471",
    "West Creek, VIC 3992",
    "West Croydon, SA 5008",
    "West Devonport, TAS 7310",
    "West End, QLD 4101",
    "West End, QLD 4810",
    "West End, WA 6530",
    "West Fitzgerald, WA 6337",
    "West Footscray, VIC 3012",
    "West Gladstone, QLD 4680",
    "West Gosford, NSW 2250",
    "West Haldon, QLD 4359",
    "West Haven, NSW 2443",
    "West Hindmarsh, SA 5007",
    "West Hobart, TAS 7000",
    "West Holleton, WA 6369",
    "West Hoxton, NSW 2171",
    "West Ipswich, QLD 4305",
    "West Island Cocos (keeling) Islands, WA 6799",
    "West Kalgoorlie, WA 6430",
    "West Kempsey, NSW 2440",
    "West Kentish, TAS 7306",
    "West Lakes, SA 5021",
    "West Lakes Shore, SA 5020",
    "West Lamington, WA 6430",
    "West Launceston, TAS 7250",
    "West Leederville, WA 6901",
    "West Leederville, WA 6007",
    "West Lyons River, WA 6705",
    "West Mackay, QLD 4740",
    "West Melbourne, VIC 3003",
    "West Montagu, TAS 7330",
    "West Moonah, TAS 7009",
    "West Mooreville, TAS 7321",
    "West Nowra, NSW 2541",
    "West Pennant Hills, NSW 2125",
    "West Perth, WA 6872",
    "West Perth, WA 6005",
    "West Pine, TAS 7316",
    "West Pingelly, WA 6308",
    "West Pinjarra, WA 6208",
    "West Point, QLD 4819",
    "West Popanyinning, WA 6309",
    "West Prairie, QLD 4403",
    "West Pymble, NSW 2073",
    "West Range, SA 5275",
    "West Richmond, SA 5033",
    "West Ridgley, TAS 7321",
    "West River, WA 6346",
    "West Rockhampton, QLD 4700",
    "West Ryde, NSW 1685",
    "West Ryde, NSW 2114",
    "West Scottsdale, TAS 7260",
    "West Stowe, QLD 4680",
    "West Swan, WA 6055",
    "West Takone, TAS 7325",
    "West Tamworth, NSW 2340",
    "West Toodyay, WA 6566",
    "West Ulverstone, TAS 7315",
    "West Wallsend, NSW 2286",
    "West Wellesley Islands, QLD 4871",
    "West Wiangaree, NSW 2474",
    "West Wodonga, VIC 3690",
    "West Wollongong, NSW 2500",
    "West Woombye, QLD 4559",
    "West Wyalong, NSW 2671",
    "Westall, SA 5680",
    "Westbourne Park, SA 5041",
    "Westbrook, NSW 2330",
    "Westbrook, QLD 4350",
    "Westbury, TAS 7303",
    "Westbury, VIC 3825",
    "Westby, VIC 3579",
    "Westcourt, QLD 4870",
    "Westdale, NSW 2653",
    "Westdale, WA 6304",
    "Westdale, NSW 2340",
    "Westella, NSW 2831",
    "Western Creek, QLD 4357",
    "Western Creek, TAS 7304",
    "Western Flat, SA 5268",
    "Western Junction, TAS 7212",
    "Western River, SA 5223",
    "Westerway, TAS 7140",
    "Westgate, NSW 2048",
    "Westgrove, QLD 4454",
    "Westlake, QLD 4074",
    "Westleigh, NSW 2120",
    "Westmar, QLD 4422",
    "Westmead, NSW 2145",
    "Westmeadows, VIC 3049",
    "Westmere, VIC 3351",
    "Westminster, WA 6061",
    "Weston, ACT 2611",
    "Weston, NSW 2326",
    "Weston Creek, ACT 2611",
    "Westonia, WA 6423",
    "Westwood, TAS 7292",
    "Westwood, NSW 2729",
    "Westwood, QLD 4702",
    "Westwood, WA 6316",
    "Wetherill Park, NSW 2164",
    "Wetheron, QLD 4625",
    "Wetuppa, NSW 2734",
    "Weyba Downs, QLD 4562",
    "Weymouth, TAS 7252",
    "Whalan, NSW 2770",
    "Whale Beach, NSW 2107",
    "Whanregarwen, VIC 3714",
    "Wharminda, SA 5603",
    "Wharparilla, VIC 3564",
    "Wharps, QLD 4850",
    "Wheatlands, QLD 4606",
    "Wheatsheaf, VIC 3461",
    "Wheatvale, QLD 4370",
    "Wheeler Heights, NSW 2097",
    "Wheelers Hill, VIC 3150",
    "Wheeny Creek, NSW 2758",
    "Wheeo, NSW 2583",
    "Wherrol Flat, NSW 2429",
    "Whetstone, QLD 4387",
    "Whian Whian, NSW 2480",
    "Whichello, QLD 4352",
    "Whim Creek, WA 6718",
    "Whiporie, NSW 2469",
    "Whipstick, VIC 3556",
    "Whirily, VIC 3483",
    "Whitby, WA 6123",
    "White Beach, TAS 7184",
    "White Cliffs, NSW 2836",
    "White Gum Valley, WA 6162",
    "White Gums, NT 0870",
    "White Hill, SA 5253",
    "White Hills, TAS 7258",
    "White Hills, VIC 3550",
    "White Hut, SA 5575",
    "White Mountain, QLD 4352",
    "White Patch, QLD 4507",
    "White Peak, WA 6532",
    "White Rock, QLD 4868",
    "White Rock, NSW 2795",
    "White Rock, QLD 4306",
    "White Sands, SA 5253",
    "White Well Corner, SA 5690",
    "Whitebridge, NSW 2290",
    "Whitefoord, TAS 7120",
    "Whiteheads Creek, VIC 3660",
    "Whitelaw, VIC 3950",
    "Whiteman, WA 6068",
    "Whiteman Creek, NSW 2460",
    "Whitemark, TAS 7255",
    "Whitemore, TAS 7303",
    "Whites Flat, SA 5607",
    "Whites River, SA 5607",
    "Whites Valley, SA 5172",
    "Whiteside, QLD 4503",
    "Whitfield, VIC 3733",
    "Whitfield, QLD 4870",
    "Whitlands, VIC 3678",
    "Whitsundays, QLD 4802",
    "Whittaker, WA 6207",
    "Whittingham, NSW 2330",
    "Whittington, VIC 3219",
    "Whittlesea, VIC 3757",
    "Whitton, NSW 2705",
    "Whitwarta, SA 5461",
    "Whoorel, VIC 3243",
    "Whoota, NSW 2428",
    "Whorouly, VIC 3735",
    "Whorouly East, VIC 3735",
    "Whorouly South, VIC 3735",
    "Whroo, VIC 3612",
    "Whyalla, SA 5600",
    "Whyalla Jenkins, SA 5609",
    "Whyalla Norrie, SA 5608",
    "Whyalla Norrie East, SA 5608",
    "Whyalla Norrie North, SA 5608",
    "Whyalla Playford, SA 5600",
    "Whyalla Stuart, SA 5608",
    "Whyanbeel, QLD 4873",
    "Whyte Yarcowie, SA 5420",
    "Wiagdon, NSW 2795",
    "Wialki, WA 6473",
    "Wiangaree, NSW 2474",
    "Wiarborough, NSW 2580",
    "Wicherina, WA 6532",
    "Wicherina South, WA 6532",
    "Wickepin, WA 6370",
    "Wickham, NSW 2293",
    "Wickham, WA 6720",
    "Wickham, NT 0822",
    "Wickham, TAS 7256",
    "Wickliffe, VIC 3379",
    "Widden, NSW 2328",
    "Widgee, QLD 4570",
    "Widgee Crossing North, QLD 4570",
    "Widgee Crossing South, QLD 4570",
    "Widgeegoara, QLD 4490",
    "Widgelli, NSW 2680",
    "Widgiemooltha, WA 6443",
    "Wieambilla, QLD 4413",
    "Wights Mountain, QLD 4520",
    "Wigley Flat, SA 5330",
    "Wigton, QLD 4612",
    "Wilberforce, WA 6302",
    "Wilberforce, NSW 2756",
    "Wilbetree, NSW 2850",
    "Wilbinga, WA 6041",
    "Wilburville, TAS 7030",
    "Wilby, VIC 3728",
    "Wilcannia, NSW 2836",
    "Wilcherry, SA 5641",
    "Wild Cattle Creek, NSW 2453",
    "Wild Dog Valley, SA 5271",
    "Wild Dog Valley, VIC 3953",
    "Wild Horse Plains, SA 5501",
    "Wildash, QLD 4370",
    "Wildes Meadow, NSW 2577",
    "Wildwood, VIC 3429",
    "Wiley Park, NSW 2195",
    "Wilga, WA 6243",
    "Wilga West, WA 6243",
    "Wilgarrup, WA 6258",
    "Wilgoyne, WA 6479",
    "Wilkawatt, SA 5301",
    "Wilkesdale, QLD 4608",
    "Wilkur, VIC 3393",
    "Willagee, WA 6156",
    "Willagee Central, WA 6156",
    "Willala, NSW 2382",
    "Willalo, SA 5419",
    "Willalooka, SA 5267",
    "Willamulka, SA 5554",
    "Willangie, VIC 3485",
    "Willare, WA 6728",
    "Willaston, SA 5118",
    "Willatook, VIC 3283",
    "Willaura, VIC 3379",
    "Willaura North, VIC 3379",
    "Willawarrin, NSW 2440",
    "Willawong, QLD 4110",
    "Willbriggie, NSW 2680",
    "Willenabrina, VIC 3393",
    "Willetton, WA 6155",
    "Willetton, WA 6955",
    "Willi Willi, NSW 2440",
    "William Bay, WA 6333",
    "Williams, WA 6391",
    "Williams Landing, VIC 3027",
    "Williamsdale, NSW 2620",
    "Williamsdale, ACT 2620",
    "Williamstown, SA 5351",
    "Williamstown, VIC 3016",
    "Williamstown, WA 6430",
    "Williamstown North, VIC 3016",
    "Williamtown, NSW 2318",
    "Williamtown Raaf, NSW 2314",
    "Willigobung, NSW 2653",
    "Willina, NSW 2423",
    "Willmot, NSW 2770",
    "Willochra, SA 5433",
    "Willoughby, SA 5222",
    "Willoughby, NSW 2068",
    "Willoughby East, NSW 2068",
    "Willoughby North, NSW 2068",
    "Willow Banks, SA 5253",
    "Willow Creek, SA 5211",
    "Willow Grove, VIC 3825",
    "Willow Tree, NSW 2339",
    "Willow Vale, NSW 2534",
    "Willow Vale, NSW 2575",
    "Willow Vale, QLD 4209",
    "Willowbank, QLD 4306",
    "Willowie, SA 5431",
    "Willowmavin, VIC 3764",
    "Willowra, NT 0872",
    "Willows, QLD 4702",
    "Willows Gemfields, QLD 4702",
    "Willowvale, VIC 3360",
    "Willowvale, QLD 4370",
    "Wills, QLD 4829",
    "Willson River, SA 5222",
    "Willsons Downfall, NSW 2372",
    "Willung, VIC 3847",
    "Willung South, VIC 3847",
    "Willunga, SA 5172",
    "Willunga Hill, SA 5172",
    "Willunga South, SA 5172",
    "Willurah, NSW 2710",
    "Willyaroo, SA 5255",
    "Willyung, WA 6330",
    "Wilmington, SA 5485",
    "Wilmot, TAS 7310",
    "Wilora, NT 0872",
    "Wilpinjong, NSW 2850",
    "Wilson, WA 6107",
    "Wilson Beach, QLD 4800",
    "Wilson Valley, QLD 4625",
    "Wilsons Creek, NSW 2482",
    "Wilsons Hill, VIC 3515",
    "Wilsons Plains, QLD 4307",
    "Wilsons Pocket, QLD 4570",
    "Wilsons Promontory, VIC 3960",
    "Wilsonton, QLD 4350",
    "Wilsonton Heights, QLD 4350",
    "Wilston, QLD 4051",
    "Wilton, NT 0852",
    "Wilton, NSW 2571",
    "Wiltshire, TAS 7321",
    "Wiluna, WA 6646",
    "Wilyabrup, WA 6280",
    "Wimbledon, NSW 2795",
    "Wimbledon Heights, VIC 3922",
    "Wimborne, NSW 2346",
    "Winburndale, NSW 2795",
    "Winchelsea, VIC 3241",
    "Winchelsea South, VIC 3241",
    "Windabout, WA 6450",
    "Windale, NSW 2306",
    "Windang, NSW 2528",
    "Windaroo, QLD 4207",
    "Windella, NSW 2320",
    "Windellama, NSW 2580",
    "Windera, NSW 2800",
    "Windera, QLD 4605",
    "Windermere, VIC 3352",
    "Windermere, NSW 2321",
    "Windermere, TAS 7252",
    "Windermere, QLD 4670",
    "Windermere Park, NSW 2264",
    "Windeyer, NSW 2850",
    "Windeyer, QLD 4478",
    "Windorah, QLD 4481",
    "Windowie, NSW 2720",
    "Windradyne, NSW 2795",
    "Windsor, SA 5501",
    "Windsor, VIC 3181",
    "Windsor, QLD 4030",
    "Windsor, NSW 2756",
    "Windsor Downs, NSW 2756",
    "Windsor Gardens, SA 5087",
    "Windy, NSW 2343",
    "Windy Harbour, WA 6262",
    "Winegrove, NSW 2460",
    "Winfield, QLD 4670",
    "Wingadee, NSW 2829",
    "Wingan River, VIC 3891",
    "Wingaroo, TAS 7255",
    "Wingeel, VIC 3321",
    "Wingellina, WA 0872",
    "Wingello, NSW 2579",
    "Wingen, NSW 2337",
    "Wingfield, SA 5013",
    "Wingham, NSW 2429",
    "Winifred, NSW 2631",
    "Winjallok, VIC 3477",
    "Winkie, SA 5343",
    "Winkleigh, TAS 7275",
    "Winlaton, VIC 3585",
    "Winmalee, NSW 2777",
    "Winnaleah, TAS 7265",
    "Winnambool, VIC 3546",
    "Winnap, VIC 3304",
    "Winnejup, WA 6255",
    "Winnellie, NT 0820",
    "Winnellie, NT 0821",
    "Winnellie, NT 0906",
    "Winnellie, NT 0907",
    "Winnellie, NT 0822",
    "Winnellie, NT 0820",
    "Winnindoo, VIC 3858",
    "Winninowie, SA 5710",
    "Winslow, VIC 3281",
    "Winston, QLD 4825",
    "Winston Hills, NSW 2153",
    "Winter Valley, VIC 3358",
    "Wintergarden, QLD 4002",
    "Winthrop, WA 6150",
    "Winton, QLD 4735",
    "Winton, VIC 3673",
    "Winton, NSW 2344",
    "Winton North, VIC 3673",
    "Winulta, SA 5555",
    "Winwill, QLD 4347",
    "Winya, QLD 4515",
    "Wirlinga, NSW 2640",
    "Wirraba, NSW 2849",
    "Wirrabara, SA 5481",
    "Wirragulla, NSW 2420",
    "Wirrate, VIC 3608",
    "Wirrega, SA 5267",
    "Wirrimah, NSW 2803",
    "Wirrimbi, NSW 2447",
    "Wirrina Cove, SA 5204",
    "Wirrinya, NSW 2871",
    "Wirrulla, SA 5661",
    "Wisanger, SA 5223",
    "Wiseleigh, VIC 3885",
    "Wisemans Creek, NSW 2795",
    "Wisemans Ferry, NSW 2775",
    "Wishart, QLD 4122",
    "Wishart, NT 0822",
    "Wishart, VIC 3189",
    "Wistow, SA 5251",
    "Witchcliffe, WA 6286",
    "Witchelina, SA 5731",
    "Witera, SA 5671",
    "Withcott, QLD 4352",
    "Witheren, QLD 4275",
    "Withers, WA 6230",
    "Witta, QLD 4552",
    "Wittenoom, WA 6751",
    "Wittenoom Hills, WA 6447",
    "Wittitrin, NSW 2440",
    "Wivenhoe, TAS 7320",
    "Wivenhoe Hill, QLD 4311",
    "Wivenhoe Pocket, QLD 4306",
    "Wiyarra, QLD 4370",
    "Woden, ACT 2606",
    "Wodonga, VIC 3689",
    "Wodonga, VIC 3690",
    "Wodonga Forward, VIC 3691",
    "Wodonga Plaza, VIC 3690",
    "Wog Wog, NSW 2622",
    "Wog Wog, NSW 2550",
    "Wogolin, WA 6370",
    "Wokalup, WA 6221",
    "Woko, NSW 2422",
    "Wokurna, SA 5520",
    "Wolfang, QLD 4721",
    "Wolffdene, QLD 4207",
    "Wolgan Valley, NSW 2790",
    "Wollangambe, NSW 2790",
    "Wollar, NSW 2850",
    "Wollemi, NSW 2330",
    "Wollert, VIC 3750",
    "Wolli Creek, NSW 2205",
    "Wollogorang, NSW 2581",
    "Wollogorang Station, NT 0862",
    "Wollombi, NSW 2325",
    "Wollomombi, NSW 2350",
    "Wollongbar, NSW 2477",
    "Wollongong, NSW 2520",
    "Wollongong, NSW 2500",
    "Wollongong West, NSW 2500",
    "Wollstonecraft, NSW 2065",
    "Wollumboola, NSW 2540",
    "Wollun, NSW 2354",
    "Wolseley, SA 5269",
    "Wolumla, NSW 2550",
    "Wolvi, QLD 4570",
    "Womalilla, QLD 4465",
    "Womarden, WA 6519",
    "Wombarra, NSW 2515",
    "Wombat, NSW 2587",
    "Wombat Creek, NSW 2460",
    "Wombat Creek, VIC 3888",
    "Wombats Rest, SA 5320",
    "Wombelano, VIC 3409",
    "Wombeyan Caves, NSW 2580",
    "Womboota, NSW 2731",
    "Womerah, NSW 2756",
    "Womina, QLD 4370",
    "Won Wron, VIC 3971",
    "Wonbah, QLD 4671",
    "Wonbah Forest, QLD 4671",
    "Wonboyn, NSW 2551",
    "Wonboyn Lake, NSW 2551",
    "Wonboyn North, NSW 2551",
    "Wondabyne, NSW 2256",
    "Wondai, QLD 4606",
    "Wondalga, NSW 2729",
    "Wondalli, QLD 4390",
    "Wondecla, QLD 4887",
    "Wondunna, QLD 4655",
    "Wonga, VIC 3960",
    "Wonga, QLD 4873",
    "Wonga Park, VIC 3115",
    "Wongabel, QLD 4883",
    "Wongaling Beach, QLD 4852",
    "Wongamine, WA 6401",
    "Wongan Hills, WA 6603",
    "Wongarbon, NSW 2831",
    "Wongarra, VIC 3221",
    "Wongawallan, QLD 4210",
    "Wongawilli, NSW 2530",
    "Wonglepong, QLD 4275",
    "Wongo Creek, NSW 2346",
    "Wongoondy, WA 6630",
    "Wongulla, SA 5238",
    "Wongungarra, VIC 3862",
    "Wongwibinda, NSW 2350",
    "Wongyarra, SA 5481",
    "Wonna, SA 5419",
    "Wonnangatta, VIC 3737",
    "Wonnerup, WA 6280",
    "Wonthaggi, VIC 3995",
    "Wonthella, WA 6530",
    "Wonuarra, SA 5340",
    "Wonwondah, VIC 3401",
    "Wonyip, VIC 3962",
    "Woocoo, QLD 4620",
    "Wood Wood, VIC 3596",
    "Woodanilling, WA 6316",
    "Woodberry, NSW 2322",
    "Woodbine, QLD 4343",
    "Woodbine, NSW 2560",
    "Woodbridge, TAS 7162",
    "Woodbridge, WA 6056",
    "Woodburn, NSW 2538",
    "Woodburn, NSW 2472",
    "Woodbury, QLD 4703",
    "Woodbury, TAS 7120",
    "Woodchester, SA 5255",
    "Woodcroft, SA 5162",
    "Woodcroft, NSW 2767",
    "Woodenbong, NSW 2476",
    "Woodend, VIC 3442",
    "Woodend, QLD 4305",
    "Woodend North, VIC 3442",
    "Wooderson, QLD 4680",
    "Woodfield, VIC 3715",
    "Woodford, QLD 4514",
    "Woodford, NSW 2778",
    "Woodford, VIC 3281",
    "Woodford Island, NSW 2463",
    "Woodforde, SA 5072",
    "Woodgate, QLD 4660",
    "Woodglen, VIC 3875",
    "Woodhill, NSW 2535",
    "Woodhill, QLD 4285",
    "Woodhouse, VIC 3294",
    "Woodhouse, SA 5246",
    "Woodhouselee, NSW 2580",
    "Woodlands, WA 6018",
    "Woodlands, NSW 2536",
    "Woodlands, QLD 4343",
    "Woodlands, NSW 2575",
    "Woodlane, SA 5238",
    "Woodlawn, NSW 2480",
    "Woodleigh, QLD 4352",
    "Woodleigh, WA 6701",
    "Woodleigh, SA 5311",
    "Woodleigh, VIC 3945",
    "Woodleigh Gardens, NT 0812",
    "Woodmillar, QLD 4625",
    "Woodpark, NSW 2164",
    "Woodridge, WA 6041",
    "Woodridge, QLD 4114",
    "Woodrising, NSW 2284",
    "Woodroffe, NT 0830",
    "Woodroffe, NT 0830",
    "Woods Point, VIC 3723",
    "Woods Point, SA 5253",
    "Woodsdale, TAS 7120",
    "Woodside, VIC 3874",
    "Woodside, SA 5244",
    "Woodside, NSW 2372",
    "Woodside Beach, VIC 3874",
    "Woodside North, VIC 3874",
    "Woodsreef, NSW 2347",
    "Woodstock, NSW 2360",
    "Woodstock, NSW 2793",
    "Woodstock, QLD 4816",
    "Woodstock, VIC 3751",
    "Woodstock, NSW 2538",
    "Woodstock, TAS 7109",
    "Woodstock On Loddon, VIC 3551",
    "Woodstock West, VIC 3463",
    "Woodvale, WA 6026",
    "Woodvale, VIC 3556",
    "Woodview, NSW 2470",
    "Woodville, NSW 2321",
    "Woodville, SA 5011",
    "Woodville Gardens, SA 5012",
    "Woodville North, SA 5012",
    "Woodville Park, SA 5011",
    "Woodville South, SA 5011",
    "Woodville West, SA 5011",
    "Woodwark, QLD 4802",
    "Woody Head, NSW 2466",
    "Woody Point, QLD 4019",
    "Woogenellup, WA 6324",
    "Wool Bay, SA 5575",
    "Wool Wool, VIC 3249",
    "Woolamai, VIC 3995",
    "Woolaning, NT 0822",
    "Woolbrook, NSW 2354",
    "Woolein, QLD 4702",
    "Woolenook, VIC 3860",
    "Woolgar, QLD 4822",
    "Woolgarlo, NSW 2582",
    "Woolgoolga, NSW 2456",
    "Woolgorong, WA 6630",
    "Wooli, NSW 2462",
    "Woollahra, NSW 1350",
    "Woollahra, NSW 2025",
    "Woollamia, NSW 2540",
    "Woolloomooloo, NSW 2011",
    "Woolloongabba, QLD 4102",
    "Woolmar, QLD 4515",
    "Woolmer, QLD 4352",
    "Woolner, NT 0820",
    "Woolner, NT 0820",
    "Woolners Arm, NSW 2470",
    "Woolnorth, TAS 7330",
    "Woolocutty, WA 6369",
    "Woolomin, NSW 2340",
    "Woolooga, QLD 4570",
    "Woolooma, NSW 2337",
    "Woolooman, QLD 4310",
    "Woolooware, NSW 2230",
    "Wooloowin, QLD 4030",
    "Wooloweyah, NSW 2464",
    "Woolpunda, SA 5330",
    "Woolshed, QLD 4340",
    "Woolshed, VIC 3747",
    "Woolshed Flat, SA 5412",
    "Woolshed Flat, VIC 3518",
    "Woolsheds, SA 5400",
    "Woolsthorpe, VIC 3276",
    "Woolumbool, SA 5272",
    "Woolundunga, SA 5710",
    "Woolwich, NSW 2110",
    "Woomargama, NSW 2644",
    "Woombah, NSW 2469",
    "Woombye, QLD 4559",
    "Woomelang, VIC 3485",
    "Woomera, SA 5720",
    "Woondul, QLD 4357",
    "Woondum, QLD 4570",
    "Woongarra, QLD 4670",
    "Woongarrah, NSW 2259",
    "Woongoolba, QLD 4207",
    "Woonona, NSW 2517",
    "Woonona East, NSW 2517",
    "Woopen Creek, QLD 4871",
    "Woorabinda, QLD 4713",
    "Wooragee, VIC 3747",
    "Wooramel, WA 6701",
    "Woorarra East, VIC 3962",
    "Woorarra West, VIC 3960",
    "Woori Yallock, VIC 3139",
    "Woorim, QLD 4507",
    "Woorinen, VIC 3589",
    "Woorinen North, VIC 3589",
    "Woorinen South, VIC 3588",
    "Woorndoo, VIC 3272",
    "Wooroloo, WA 6558",
    "Wooroolin, QLD 4608",
    "Wooroona, QLD 4702",
    "Wooroonden, QLD 4605",
    "Wooroonook, VIC 3525",
    "Wooroonooran, QLD 4860",
    "Woorree, WA 6530",
    "Woosang, VIC 3518",
    "Wootha, QLD 4552",
    "Wootong Vale, VIC 3315",
    "Woottating, WA 6562",
    "Wootton, NSW 2423",
    "Woowoonga, QLD 4621",
    "Wooyung, NSW 2483",
    "Woree, QLD 4868",
    "World Square, NSW 2002",
    "World Trade Centre, VIC 8005",
    "World Trade Centre, VIC 3005",
    "Worlds End, NSW 2850",
    "Worlds End, SA 5381",
    "Worlds End Creek, SA 5381",
    "Worongary, QLD 4213",
    "Woronora, NSW 2232",
    "Woronora Dam, NSW 2508",
    "Woronora Heights, NSW 2233",
    "Worrigee, NSW 2540",
    "Worrolong, SA 5291",
    "Worrowing, VIC 3858",
    "Worrowing Heights, NSW 2540",
    "Worsley, WA 6225",
    "Wowan, QLD 4702",
    "Woy Woy, NSW 2256",
    "Woy Woy Bay, NSW 2256",
    "Wrathall, NSW 2642",
    "Wrathung, VIC 3860",
    "Wrattens Forest, QLD 4601",
    "Wrattonbully, SA 5271",
    "Wright, ACT 2611",
    "Wrights Beach, NSW 2540",
    "Wrights Creek, NSW 2775",
    "Wrights Creek, QLD 4869",
    "Wrixon, VIC 3860",
    "Wrotham, QLD 4871",
    "Wroxham, VIC 3891",
    "Wubin, WA 6612",
    "Wudinna, SA 5652",
    "Wujal Wujal, QLD 4895",
    "Wuk Wuk, VIC 3875",
    "Wulagi, NT 0812",
    "Wulagi, NT 0812",
    "Wulgulmerang, VIC 3885",
    "Wulgulmerang East, VIC 3885",
    "Wulgulmerang West, VIC 3885",
    "Wulguru, QLD 4811",
    "Wulkuraka, QLD 4305",
    "Wundowie, WA 6560",
    "Wunghnu, VIC 3635",
    "Wungong, WA 6112",
    "Wunjunga, QLD 4806",
    "Wunkar, SA 5311",
    "Wura, QLD 4714",
    "Wuraming, WA 6390",
    "Wurdiboluc, VIC 3241",
    "Wurdong Heights, QLD 4680",
    "Wurruk, VIC 3850",
    "Wurrumiyanga, NT 0822",
    "Wurtulla, QLD 4575",
    "Wuruma Dam, QLD 4627",
    "Wutul, QLD 4352",
    "Wutunugurra, NT 0872",
    "Wuuluman, NSW 2820",
    "Wy Yung, VIC 3875",
    "Wyaga, QLD 4390",
    "Wyalkatchem, WA 6485",
    "Wyalla, QLD 4615",
    "Wyalla Plaza, QLD 4350",
    "Wyalong, NSW 2671",
    "Wyan, NSW 2469",
    "Wyanbene, NSW 2622",
    "Wyandra, QLD 4489",
    "Wyangala, NSW 2808",
    "Wyangle, NSW 2720",
    "Wyaralong, QLD 4310",
    "Wyberba, QLD 4382",
    "Wybong, NSW 2333",
    "Wybung, NSW 2259",
    "Wycarbah, QLD 4702",
    "Wycheproof, VIC 3527",
    "Wycheproof South, VIC 3527",
    "Wychitella, VIC 3525",
    "Wychitella North, VIC 3525",
    "Wycliffe Well, NT 0862",
    "Wycombe, QLD 4455",
    "Wye, SA 5291",
    "Wye River, VIC 3221",
    "Wyee, NSW 2259",
    "Wyee Point, NSW 2259",
    "Wyelangta, VIC 3237",
    "Wyena, TAS 7254",
    "Wyening, WA 6568",
    "Wylie Creek, NSW 2372",
    "Wylies Flat, NSW 2330",
    "Wymah, NSW 2640",
    "Wynarka, SA 5306",
    "Wyndham, NSW 2550",
    "Wyndham, WA 6740",
    "Wyndham Vale, VIC 3024",
    "Wyneden, NSW 2474",
    "Wynn Vale, SA 5127",
    "Wynnum, QLD 4178",
    "Wynnum Central, QLD 4178",
    "Wynnum North, QLD 4178",
    "Wynnum Plaza, QLD 4178",
    "Wynnum West, QLD 4178",
    "Wynyard, TAS 7325",
    "Wyola West, WA 6407",
    "Wyomi, SA 5275",
    "Wyoming, NSW 2250",
    "Wyong, NSW 2259",
    "Wyong Creek, NSW 2259",
    "Wyongah, NSW 2259",
    "Wyrallah, NSW 2480",
    "Wyreema, QLD 4352",
    "Wyuna, VIC 3620",
    "Wyuna East, VIC 3620",
    "Xantippe, WA 6609",
    "Yaamba, QLD 4704",
    "Yaapeet, VIC 3424",
    "Yabba North, VIC 3646",
    "Yabba South, VIC 3646",
    "Yabberup, WA 6239",
    "Yabbra, NSW 2469",
    "Yabulu, QLD 4818",
    "Yacka, SA 5470",
    "Yackandandah, VIC 3749",
    "Yadboro, NSW 2539",
    "Yagaburne, QLD 4390",
    "Yagon, NSW 2423",
    "Yagoona, NSW 2199",
    "Yagoona West, NSW 2199",
    "Yagoonya, QLD 4871",
    "Yahl, SA 5291",
    "Yakamia, WA 6330",
    "Yalanda, SA 5641",
    "Yalangur, QLD 4352",
    "Yalardy, WA 6701",
    "Yalata, SA 5690",
    "Yalboroo, QLD 4741",
    "Yalbraith, NSW 2580",
    "Yalca, VIC 3637",
    "Yalgoo, WA 6635",
    "Yalla-y-poora, VIC 3378",
    "Yallabatharra, WA 6535",
    "Yallah, NSW 2530",
    "Yallambie, VIC 3085",
    "Yallaroi, NSW 2408",
    "Yallingup, WA 6282",
    "Yallingup Siding, WA 6282",
    "Yallourn, VIC 3825",
    "Yallourn North, VIC 3825",
    "Yallunda Flat, SA 5607",
    "Yalmy, VIC 3885",
    "Yalpara, SA 5431",
    "Yalwal, NSW 2540",
    "Yalyalup, WA 6280",
    "Yam Island, QLD 4875",
    "Yamala, QLD 4720",
    "Yamanto, QLD 4305",
    "Yamba, NSW 2464",
    "Yamba, SA 5340",
    "Yambacoona, TAS 7256",
    "Yambuk, VIC 3285",
    "Yambulla, NSW 2550",
    "Yambuna, VIC 3621",
    "Yan Yean, VIC 3755",
    "Yanac, VIC 3418",
    "Yanakie, VIC 3960",
    "Yanchep, WA 6035",
    "Yanco, NSW 2703",
    "Yandanooka, WA 6522",
    "Yandaran, QLD 4673",
    "Yandarlo, QLD 4478",
    "Yanderra, NSW 2574",
    "Yandilla, QLD 4352",
    "Yandina, QLD 4561",
    "Yandina Creek, QLD 4561",
    "Yando, VIC 3537",
    "Yandoit, VIC 3461",
    "Yandoit Hills, VIC 3461",
    "Yandoo Creek, WA 6701",
    "Yanerbie, SA 5680",
    "Yanga, NSW 2711",
    "Yangan, QLD 4371",
    "Yangebup, WA 6164",
    "Yangery, VIC 3283",
    "Yangoura, VIC 3858",
    "Yaninee, SA 5653",
    "Yankalilla, SA 5203",
    "Yankees Creek, NSW 2550",
    "Yanmah, WA 6258",
    "Yannarie, WA 6710",
    "Yannathan, VIC 3981",
    "Yannergee, NSW 2343",
    "Yantabulla, NSW 2840",
    "Yantanabie, SA 5661",
    "Yanyarrie, SA 5432",
    "Yaouk, NSW 2629",
    "Yapeen, VIC 3451",
    "Yaraka, QLD 4702",
    "Yarara, NSW 2644",
    "Yarawindah, WA 6509",
    "Yarck, VIC 3719",
    "Yardarino, WA 6525",
    "Yargullen, QLD 4401",
    "Yarloop, WA 6218",
    "Yaroomba, QLD 4573",
    "Yarpturk, VIC 3283",
    "Yarra, NSW 2580",
    "Yarra Creek, TAS 7256",
    "Yarra Glen, VIC 3775",
    "Yarra Junction, VIC 3797",
    "Yarra Junction, VIC 3797",
    "Yarrabah, QLD 4871",
    "Yarrabandai, NSW 2875",
    "Yarrabar, NSW 2830",
    "Yarraberb, VIC 3516",
    "Yarrabilba, QLD 4207",
    "Yarrabin, NSW 2850",
    "Yarraden, QLD 4871",
    "Yarragadee, WA 6522",
    "Yarragal, NSW 2820",
    "Yarragon, VIC 3823",
    "Yarragon South, VIC 3823",
    "Yarragong, NSW 2871",
    "Yarragrin, NSW 2842",
    "Yarragundry, NSW 2650",
    "Yarrah, SA 5433",
    "Yarrahapinni, NSW 2441",
    "Yarralin, NT 0852",
    "Yarralumla, ACT 2600",
    "Yarram, VIC 3971",
    "Yarramalong, NSW 2259",
    "Yarraman, QLD 4614",
    "Yarraman, NSW 2343",
    "Yarrambat, VIC 3091",
    "Yarramundi, NSW 2753",
    "Yarranbella, NSW 2447",
    "Yarrangobilly, NSW 2720",
    "Yarranlea, QLD 4356",
    "Yarras, NSW 2446",
    "Yarras, NSW 2795",
    "Yarratt Forest, NSW 2429",
    "Yarravel, NSW 2440",
    "Yarraville, VIC 3013",
    "Yarraville West, VIC 3013",
    "Yarrawa, NSW 2328",
    "Yarrawalla, VIC 3575",
    "Yarrawarrah, NSW 2233",
    "Yarrawonga, NSW 2850",
    "Yarrawonga, VIC 3730",
    "Yarrawonga, NT 0830",
    "Yarrawonga, NT 0830",
    "Yarrawonga Park, NSW 2264",
    "Yarrawonga South, VIC 3730",
    "Yarrie Lake, NSW 2388",
    "Yarrol, QLD 4630",
    "Yarrow, NSW 2620",
    "Yarroweyah, VIC 3644",
    "Yarrowford, NSW 2370",
    "Yarrowitch, NSW 2354",
    "Yarrowyck, NSW 2358",
    "Yarrunga, VIC 3677",
    "Yarwun, QLD 4694",
    "Yass, NSW 2582",
    "Yass River, NSW 2582",
    "Yatala, QLD 4207",
    "Yatala Vale, SA 5126",
    "Yatchaw, VIC 3301",
    "Yathella, NSW 2650",
    "Yathroo, WA 6507",
    "Yatina, SA 5422",
    "Yattalunga, NSW 2251",
    "Yattalunga, SA 5114",
    "Yatte Yattah, NSW 2539",
    "Yaven Creek, NSW 2729",
    "Yawong Hills, VIC 3478",
    "Yea, VIC 3717",
    "Yeagarup, WA 6260",
    "Yeal, WA 6503",
    "Yealering, WA 6372",
    "Yeelanna, SA 5632",
    "Yeerongpilly, QLD 4105",
    "Yelarbon, QLD 4388",
    "Yelbeni, WA 6487",
    "Yelgun, NSW 2483",
    "Yellingbo, VIC 3139",
    "Yellow Dam, NSW 2371",
    "Yellow Pinch, NSW 2548",
    "Yellow Rock, NSW 2777",
    "Yellow Rock, NSW 2527",
    "Yellowdine, WA 6426",
    "Yelta, VIC 3505",
    "Yelta, SA 5558",
    "Yelverton, WA 6280",
    "Yenda, NSW 2681",
    "Yenda, QLD 4625",
    "Yendon, VIC 3352",
    "Yengarie, QLD 4650",
    "Yennora, NSW 2161",
    "Yeo, VIC 3249",
    "Yeodene, VIC 3249",
    "Yeoval, NSW 2868",
    "Yeppoon, QLD 4703",
    "Yerecoin, WA 6571",
    "Yering, VIC 3770",
    "Yerong Creek, NSW 2642",
    "Yeronga, QLD 4104",
    "Yerra, QLD 4650",
    "Yerrinbool, NSW 2575",
    "Yerriyong, NSW 2540",
    "Yessabah, NSW 2440",
    "Yetholme, NSW 2795",
    "Yetman, NSW 2410",
    "Yetna, WA 6532",
    "Yeungroon, VIC 3525",
    "Yeungroon East, VIC 3525",
    "Yielima, VIC 3638",
    "Yilkari, WA 6430",
    "Yilki, SA 5211",
    "Yilliminning, WA 6312",
    "Yimbun, QLD 4313",
    "Yinkanie, SA 5332",
    "Yinnar, VIC 3869",
    "Yinnar South, VIC 3869",
    "Yippin Creek, NSW 2446",
    "Yirrkala, NT 0880",
    "Yoganup, WA 6275",
    "Yokine, WA 6060",
    "Yokine South, WA 6060",
    "Yolla, TAS 7325",
    "Yongala, SA 5493",
    "Yoogali, NSW 2680",
    "Yoongarillup, WA 6280",
    "York, WA 6302",
    "York Plains, TAS 7120",
    "York Plains, VIC 3477",
    "York Town, TAS 7270",
    "Yorke Island, QLD 4875",
    "Yorke Valley, SA 5573",
    "Yorketown, SA 5576",
    "Yorkeys Knob, QLD 4878",
    "Yorklea, NSW 2470",
    "Yornaning, WA 6311",
    "Yornup, WA 6256",
    "Yoting, WA 6383",
    "Youanmite, VIC 3646",
    "Youarang, VIC 3728",
    "Youndegin, WA 6407",
    "Young, NSW 2594",
    "Younghusband, SA 5238",
    "Younghusband Holdings, SA 5238",
    "Youngs Siding, WA 6330",
    "Youngtown, TAS 7249",
    "Yourdamung Lake, WA 6225",
    "Yowah, QLD 4490",
    "Yowie Bay, NSW 2228",
    "Yowrie, NSW 2550",
    "Yuelamu, NT 0872",
    "Yuendumu, NT 0872",
    "Yugar, QLD 4520",
    "Yulabilla, QLD 4416",
    "Yulara, NT 0872",
    "Yuleba, QLD 4427",
    "Yuleba North, QLD 4427",
    "Yuleba South, QLD 4427",
    "Yulecart, VIC 3301",
    "Yullundry, NSW 2867",
    "Yumali, SA 5261",
    "Yuna, WA 6532",
    "Yundi, SA 5172",
    "Yundool, VIC 3727",
    "Yungaburra, QLD 4884",
    "Yunta, SA 5440",
    "Yuraygir, NSW 2464",
    "Yuroke, VIC 3063",
    "Yuruga, QLD 4850",
    "Yuulong, VIC 3237",
    "Zadows Landing, SA 5254",
    "Zanthus, WA 6434",
    "Zara, NSW 2484",
    "Zeehan, TAS 7469",
    "Zeerust, VIC 3634",
    "Zetland, NSW 2017",
    "Zillmere, QLD 4034",
    "Zilzie, QLD 4710",
    "Zuccoli, NT 0832",
    "Zumsteins, VIC 3401",
    "Zuytdorp, WA 6536"
]
