import React, { FC, useEffect, useState } from 'react';
import { SxProps } from '@mui/system';
import { Input, TextField, Theme } from '@mui/material';
import { useDebounce } from '../../hooks/useDebounce';
import { InputProps } from '@mui/material/Input/Input';
import TextareaAutosize from 'react-textarea-autosize';
import { TextareaAutosizeProps } from 'react-textarea-autosize/dist/declarations/src';
interface Props extends InputProps {
    id: string;
    value: string;
    onHandleChange: any;
    sx: SxProps<Theme>;
}
export const FastInput: FC<Props> = ({ id, onHandleChange, value, sx, ...props }) => {
    const [localValue, setLocalValue] = useState('');
    const handleChangeValue = () => {
        onHandleChange(id, localValue, false);
    };

    useDebounce(handleChangeValue, 500, [localValue]);

    return <Input id={id} fullWidth value={localValue} sx={sx} onChange={(e) => setLocalValue(e.currentTarget.value)} {...props} />;
};

interface AreaProps extends TextareaAutosizeProps {
    id: string;
    value: string;
    onHandleChange: any;
}

export const FastInputArea = ({ id, onHandleChange, value, ...props }: AreaProps) => {
    const [localValue, setLocalValue] = useState('');
    const handleChangeValue = () => {
        onHandleChange(id, localValue, false);
    };

    useDebounce(handleChangeValue, 500, [localValue]);

    // @ts-ignore
    return (
        <TextareaAutosize
            id={id}
            style={
                props.style ?? {
                    width: '100%',
                    fontSize: '16px',
                    fontFamily: "'Open Sans',sans-serif",
                    padding: '20px'
                }
            }
            minRows={props.minRows ?? 3}
            value={localValue}
            onChange={(e) => setLocalValue(e.currentTarget.value)}
            {...props}
        />
    );
};
