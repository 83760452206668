import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { InventoryDataTypes } from '../../const/form.data';
import { Box, ButtonBase, Input, InputBase, TextField, Typography } from '@mui/material';
import { IconMinus, IconPlus } from '@tabler/icons';
interface Props {
    data: { title: string; value: number };
    index: number;
    parent: string;
    parentItem: { title: string; icon: string; items: { title: string; value: number }[] };
    setData: Dispatch<SetStateAction<InventoryDataTypes>>;
}
export const InventoryItem = ({ data, index, parent, setData, parentItem }: Props) => {
    const changeData = (type: 'up' | 'down') => {
        const { items } = parentItem;
        if (items[index]) {
            const tempItem = items[index];
            const { value } = tempItem;
            const tempValue = type === 'up' ? value + 1 : value <= 0 ? 0 : value - 1;
            const changeItemValue = { ...tempItem, value: tempValue };
            const changeArray = items;
            changeArray[index] = changeItemValue;
            const changeValue = { ...parentItem, items: [...changeArray] };
            setData((p) => {
                return {
                    ...p,
                    [parent]: {
                        ...changeValue
                    }
                };
            });
        }
    };

    const changeInputData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const targetValue = e.currentTarget.value;
        const changeValueIsNaN = Number(targetValue);
        console.log('t', changeValueIsNaN);
        if (!isNaN(changeValueIsNaN)) {
            const { items } = parentItem;
            if (items[index]) {
                const tempItem = items[index];
                const { value } = tempItem;
                const tempValue = changeValueIsNaN <= 0 ? 0 : changeValueIsNaN;
                const changeItemValue = { ...tempItem, value: tempValue };
                const changeArray = items;
                changeArray[index] = changeItemValue;
                const changeValue = { ...parentItem, items: [...changeArray] };
                setData((p) => {
                    return {
                        ...p,
                        [parent]: {
                            ...changeValue
                        }
                    };
                });
            }
        }
    };
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginBottom: '10px'
            }}
        >
            <Box sx={{ marginRight: '20px', width: { xs: '50%' }, justifyContent: 'center' }}>
                <Typography sx={{ fontSize: { xs: '12px', md: '16px' }, fontWeight: 600 }}>{data.title}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '50%' }, justifyContent: 'center' }}>
                <ButtonBase
                    onClick={() => changeData('down')}
                    sx={{
                        padding: { xs: '4px 7px', md: '8px 15px' },
                        backgroundColor: '#f6f6f6',
                        border: '2px solid #e5e5e5  !important',
                        '&:hover': {
                            backgroundColor: '#eeeeee',
                            border: '2px solid #d0d0d0  !important'
                        },
                        borderRadius: '8px 0 0 8px'
                    }}
                >
                    <IconMinus width={'16px'} />
                </ButtonBase>
                <InputBase
                    style={{ textAlign: 'center' }}
                    sx={{
                        maxWidth: { xs: '30px', md: '50px' },
                        fontSize: { xs: '12px', md: '16px' },
                        fontWeight: 600,
                        textAlign: 'center',
                        backgroundColor: '#f6f6f6',
                        borderTop: '2px solid #e5e5e5',
                        borderBottom: '2px solid #e5e5e5',
                        padding: { xs: '2px 7px', md: '4px 15px' }
                    }}
                    onChange={(e) => changeInputData(e)}
                    value={data.value}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'center' } }}
                />
                <ButtonBase
                    onClick={() => changeData('up')}
                    sx={{
                        padding: { xs: '4px 7px', md: '8px 15px' },
                        backgroundColor: '#f6f6f6',
                        border: '2px solid #e5e5e5 !important',
                        '&:hover': {
                            backgroundColor: '#eeeeee',
                            border: '2px solid #d0d0d0 !important'
                        },
                        borderRadius: '0 8px 8px 0'
                    }}
                >
                    <IconPlus width={'16px'} />
                </ButtonBase>
            </Box>
        </Box>
    );
};
