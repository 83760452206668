import React from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, DocumentData, getFirestore } from '@firebase/firestore';
import firebase from '../../utils/firebase';
import { BoxDataTypes, InventoryDataTypes, ItemDataTypes, RootDataTypes } from '../../const/form.data';
import * as uuid from 'uuid';
import { SelectEnum } from '../../types/select.types';
import { convertValidateAndDate } from '../../utils/common.func';

const EmailForm = () => {
    const [value, loading, error] = useDocument(doc(getFirestore(firebase), 'quotes', '63badca4-db11-4045-98b0-e34a93c24f4d'), {
        snapshotListenOptions: { includeMetadataChanges: true }
    });

    const formItemRoot = (value: RootDataTypes) => {
        if (value) {
            switch (value.type) {
                case SelectEnum.Local:
                case SelectEnum.InterState:
                case SelectEnum.Office:
                case SelectEnum.Single:
                    return `
                       ${inventItems(value.inventory)}
                       ${formingItem('Pick-up', value.values.pickAddress)}
                       ${formingItem('Drop-off', value.values.dropAddress)}
                       ${formingItem('When', value.values.when)}
                       ${formingItem('Date', convertValidateAndDate(value.values?.whenDate))}
                       ${formingItem('Other', value.values.otherDetails)}
                       ${boxItems(value.boxData)}
                    `;
                case SelectEnum.Few:
                case SelectEnum.Furniture:
                case SelectEnum.Piano:
                case SelectEnum.Pool:
                case SelectEnum.Ebay:
                case SelectEnum.Fragile:
                    return `
                       ${formingItem('Pick-up', value.values.pickAddress)}
                       ${formingItem('Drop-off', value.values.dropAddress)}
                       ${formingItem('When', value.values.when)}
                       ${formingItem('Date', convertValidateAndDate(value.values?.whenDate))}
                       ${formingItem('Other', value.values.otherDetails)}
                       ${inventItems2(value.item)}
                    `;
                case SelectEnum.Removal:
                    return `
                       ${formingItem('Pick-up', value.values.pickAddress)}
                       ${formingItem('Drop-off', value.values.dropAddress)}
                       ${formingItem('When', value.values.when)}
                        ${formingItem('Date', value.values?.whenDate ?? '')}
                       ${formingItem('Other', value.values.otherDetails)}
                    `;
                default:
                    return '';
            }
        } else {
            return '';
        }
    };

    const formItem = () => {
        if (value) {
            const data: DocumentData = value.data();
            return `
            <div style='width: 100%'>
                ${formingItem('dddd', 'dddddd')}
                ${inventItems(data.inventory)}
                ${inventItems2(data.item)}
            </div>`;
        } else {
            return '';
        }
    };

    const formingItem = (title, content) => {
        return ` <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 0px 10px;'>
                    <div style='width: 30%;'>
                        <p style='font-weight: 600'>
                            ${title}
                        </p>
                    </div>
                    <div style='width: 70%;'>
                        <p style='font-weight: 600'>
                            ${content}
                        </p>
                    </div>
                </div>`;
    };
    const inventItems = (value: InventoryDataTypes) => {
        const objectArray = Object.entries(itemCheckMinus(value));
        let reHtml = '';
        for (const row of objectArray) {
            const [title, values] = row;
            const temp = `                
                        <div style='width: 70%;'>
                            <p style='font-weight: 800;'>
                                ${title}
                            </p>
                            ${values.items.map((v) => itemInnerItems(v.title, v.value))}
                        </div>
            `;
            reHtml += temp;
        }
        return ` <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 0px 10px;'>
                    <div style='width: 30%;'>
                        <p style='font-weight: 600'>
                            Inventory
                        </p>
                    </div>
                    <div style='width: 70%;'>
                            ${reHtml}
                    </div>
                </div>`;
    };

    const itemCheckMinus = (v: InventoryDataTypes) => {
        const temp = { ...v };
        for (const row of Object.entries(temp)) {
            const items = row[1].items.filter((v) => v.value > 0);
            if (items.length === 0) {
                delete temp[row[0]];
            } else {
                temp[row[0]] = {
                    ...row[1],
                    items
                };
            }
        }
        return temp;
    };

    const itemInnerItems = (title, value) => {
        if (value === 0) {
            return '';
        }
        return `
              <div class='initems' style='display:flex; padding-left: 30px'>
                    <p style='font-weight: 600;'>
                        ${value} x
                    </p>
                    <p style='padding-left: 10px'>
                        ${title}
                    </p>
               </div>
        `;
    };
    const ivenvtItmesInner = (title, value) => {
        return `
               <div class='initems' style='display:flex;'>
                    <p style='font-weight: 600;'>
                        ${title}
                    </p>
                    <p style='padding-left: 10px'>
                        ${value}
                    </p>
               </div>
        `;
    };
    const inventItems2 = (value: ItemDataTypes) => {
        const objectArray = [...value];
        let reHtml = '';
        for (const row of objectArray) {
            const { id, description, qty, length, width, height, unit, guess1, weight, guess2 } = row;
            console.log(row);
            const temp = `                
                        <div style='width: 70%;'>
                            ${ivenvtItmesInner('description', description)}
                            ${ivenvtItmesInner('width', width)}
                            ${ivenvtItmesInner('length', length)}
                            ${ivenvtItmesInner('height', height)}
                            ${ivenvtItmesInner('unit', unit)}
                            ${ivenvtItmesInner('weight', weight)}
                        </div>
            `;
            reHtml += temp;
        }
        return ` <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 0px 10px;'>
                    <div style='width: 30%;'>
                        <p style='font-weight: 600'>
                            Items
                        </p>
                    </div>
                    <div style='width: 70%;'>
                            ${reHtml}
                    </div>
                </div>`;
    };
    const totalAmountCalculator = (boxData) => {
        let total = 0;
        for (const row of boxData) {
            const add = row.qty * row.amount;
            total += add;
        }
        return total;
    };
    const boxItems = (value: BoxDataTypes) => {
        if (!value) {
            return '';
        }
        const total = totalAmountCalculator(value);
        let reHtml = '';
        const objectArray = value.filter((v) => v.qty > 0);
        for (const row of objectArray) {
            const temp = `                
                        <div style='width: 100%; display:flex;'>
                         <div style='width: 30%; display:flex;'>
                            <p style='font-weight: 800;'>
                                ${row.title} x ${row.qty}
                            </p>
                         </div>
                         <div class='initems' style='width: 70%; display:flex;'>
                            <p style='font-weight: 600;'>
                                $ ${row.qty * row.amount}
                            </p>
                        </div>
                        </div>
                       
                        
            `;
            reHtml += temp;
        }

        return ` <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 0px 10px;'>
                    <div style='width: 30%;'>
                        <p style='font-weight: 600'>
                            Order
                        </p>
                    </div>
                    <div style='width: 70%;'>
                        <p style='font-weight: 600'>
                            $ ${total}
                        </p>
                    </div>
                </div>
                <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 0px 10px;'>
                    <div style='width: 100%; display: flex; flex-direction: column;'>
                            ${reHtml}
                    </div>
                </div>
`;
    };
    return (
        <>
            {error && <p>error</p>}
            {loading && <p>loading</p>}
            {value && <div dangerouslySetInnerHTML={{ __html: formItemRoot(value.data() as any) }} />}
        </>
    );
};

export default EmailForm;
