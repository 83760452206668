import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { FormSchema } from '../categoryForm';
import { useNavigate, useParams } from 'react-router-dom';
import { FormIdKey, SelectTypeParamsType } from '../../types/select.types';
import ContainerLayout from '../../layout/container.layout';
import { SelectSwitchingTitle } from '../../const/switch.string';
import { IconEye, IconEyeOff } from '@tabler/icons';
import { values } from 'lodash';
import { collection, deleteField, doc, getDoc, getFirestore, setDoc, Timestamp, updateDoc } from '@firebase/firestore';
import firebase, { dbService } from '../../utils/firebase';
import { useDocument } from 'react-firebase-hooks/firestore';
import { RootDataTypes } from '../../const/form.data';
import * as uuid from 'uuid';
import emailjs from '@emailjs/browser';
import LoadingScreen from '../../componets/loadable/fullscreen.loading';
const validationSchema = yup.object({
    password: yup.string().required('password is required')
});
const validationSchema2 = yup.object({
    email: yup.string().email().required('email is required'),
    name: yup.string().required('name is required')
});
const PrivateScreen = () => {
    const formik = useFormik({
        initialValues: {
            password: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            handleValidateSucess(values);
        }
    });
    const formik2 = useFormik({
        initialValues: {
            email: '',
            name: ''
        },
        validationSchema: validationSchema2,
        onSubmit: async (values) => {
            handleValidateEmail(values);
        }
    });
    useEffect(() => {}, []);
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [toEmails, setToEmails] = useState<{ email: string; name: string }[]>([]);

    const handleValidateSucess = (value) => {
        const p = value.password;
        if (p === process.env.REACT_APP_PASSWORD) {
            setSuccess(true);
        } else {
            formik.setErrors({ password: 'password is different' });
        }
    };

    const handleValidateEmail = (value) => {
        const email = value.email;
        const name = value.name;
        if (!toEmails.includes(email)) {
            setToEmails((p) => [...p, { email, name: name ?? '-' }]);
            formik2.resetForm();
            emailInput?.current?.focus();
        }
    };
    const handleDeleteEmail = (value) => {
        const convertList = toEmails.filter((v) => v.email !== value.email);
        setToEmails((p) => [...convertList]);
    };

    const sendEmail = async (value) => {
        await emailjs.send(
            'DEFAULT',
            'template_d7oirrn',
            {
                to_email: value.email,
                name: value.name
            },
            'CyHPstdp2khyLVcw0'
        );
    };

    const sendEmailsList = async () => {
        try {
            setLoading(true);
            for (const row of toEmails) {
                await sendEmail(row);
            }
            alert('Send Success');
            setLoading(false);
        } catch (e) {
            alert('error');
        } finally {
            resetForm();
            setLoading(false);
            setOpen(false);
        }
    };
    const resetForm = () => {
        setToEmails([...[]]);
        formik.resetForm();
        formik2.resetForm();
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [loading, setLoading] = useState(false);
    const emailInput = useRef(null);
    const nameInput = useRef(null);
    console.log('nameInput', nameInput);
    return (
        <>
            <ContainerLayout>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: { md: '80px 15px', xs: '30px 15px' }
                    }}
                >
                    {success ? (
                        <Box width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' }, maxWidth: '900px' }}>
                            <Box width={'100%'} sx={{ marginBottom: '50px' }}>
                                <Typography variant={'h5'}>Email List</Typography>
                                <Box width={'100%'} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {toEmails.length === 0 && (
                                        <Typography variant={'h5'} sx={{ marginY: '50px' }}>
                                            Please enter an email and add it
                                        </Typography>
                                    )}
                                    {toEmails.map((v) => (
                                        <Typography
                                            onClick={() => handleDeleteEmail(v)}
                                            key={uuid.v4()}
                                            variant={'h5'}
                                            sx={{
                                                margin: '10px',
                                                padding: '10px',
                                                borderRadius: 12,
                                                background: 'black',
                                                color: 'white',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {v.email} / {v.name}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="address_input" sx={{ fontSize: '16px' }}>
                                    Email
                                </InputLabel>
                                <Input
                                    inputRef={emailInput}
                                    id={'email'}
                                    fullWidth
                                    value={formik2.values.email}
                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                    onBlur={formik2.handleBlur}
                                    error={formik2.touched.email && Boolean(formik2.errors.email)}
                                    onChange={formik2.handleChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            nameInput?.current?.focus();
                                        }
                                    }}
                                />
                                {formik2.touched.email && Boolean(formik2.errors.email) ? (
                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                        {formik2.touched.email && formik2.errors.email}
                                    </FormHelperText>
                                ) : (
                                    <FormHelperText id="component-helper-text">Please Input Email</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="address_input" sx={{ fontSize: '16px' }}>
                                    Name
                                </InputLabel>
                                <Input
                                    inputRef={nameInput}
                                    id={'name'}
                                    fullWidth
                                    value={formik2.values.name}
                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                    onBlur={formik2.handleBlur}
                                    error={formik2.touched.name && Boolean(formik2.errors.name)}
                                    onChange={formik2.handleChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            formik2.handleSubmit();
                                        }
                                    }}
                                />
                                {formik2.touched.name && Boolean(formik2.errors.name) ? (
                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                        {formik2.touched.name && formik2.errors.name}
                                    </FormHelperText>
                                ) : (
                                    <FormHelperText id="component-helper-text">Please Input Name</FormHelperText>
                                )}
                            </FormControl>
                            {toEmails.length > 0 && (
                                <Button variant="outlined" onClick={handleClickOpen} sx={{ marginTop: '20px' }}>
                                    Email Send
                                </Button>
                            )}
                        </Box>
                    ) : (
                        <Box width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' }, maxWidth: '900px' }}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="address_input" sx={{ fontSize: '16px' }}>
                                    Password
                                </InputLabel>
                                <Input
                                    id={'password'}
                                    fullWidth
                                    value={formik.values.password}
                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    onChange={formik.handleChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            formik.handleSubmit();
                                        }
                                    }}
                                />
                                {formik.touched.password && Boolean(formik.errors.password) ? (
                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                        {formik.touched.password && formik.errors.password}
                                    </FormHelperText>
                                ) : (
                                    <FormHelperText id="component-helper-text">Please Input password</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                    )}
                </Box>
            </ContainerLayout>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{'Email Send'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to send mail to {toEmails.length} users?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancle</Button>
                    <Button onClick={() => sendEmailsList()} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            {loading && <LoadingScreen />}
        </>
    );
};

export default PrivateScreen;
