import { BoxDataTypes, InventoryDataTypes, ItemDataTypes, RootDataTypes } from '../../const/form.data';
import { SelectEnum } from '../../types/select.types';
import { AddSFunc, BoxItemSum, convertValidateAndDate } from '../../utils/common.func';
import { AddressMenuFind } from '../categoryForm/address.form';

export const dataToHtml = (rootValue) => {
    const formItemRoot = (value: RootDataTypes) => {
        if (value) {
            switch (value.type) {
                case SelectEnum.Local:
                case SelectEnum.InterState:
                case SelectEnum.Office:
                case SelectEnum.Single:
                    return `
                       <div style='margin-top: 40px;'/>
                       ${inventItems(value.inventory)}
                       ${
                           value.values.inventOther && value.values.inventOther! === ''
                               ? formingItem('Extra items for the inventory', value.values.inventOther)
                               : ''
                       }
                       ${formingItem('Pick-up', value.values.pickAddress)}
                       ${formingItem('Pick-up Property Type', `${AddressMenuFind(value.values.pickType)} / ${value.values.pickRooms}`)}
                        ${
                            value.values.pickEtc && value.values.pickEtc !== ''
                                ? formingItem('Parking accessibility', value.values.pickEtc)
                                : ''
                        }
                       ${formingItem('Drop-off', value.values.dropAddress)}
                       ${formingItem('Drop-off Property Type', `${AddressMenuFind(value.values.dropType)} / ${value.values.dropRooms}`)}
                          ${
                              value.values.dropEtc && value.values.dropEtc !== ''
                                  ? formingItem('Parking accessibility', value.values.dropEtc)
                                  : ''
                          }
                                                   
    ${formingItem('Email', value.values.email)}
                       ${formingItem('When', value.values.when)}
                       ${formingItem('Date', convertValidateAndDate(value.values?.whenDate))}
                       ${formingItem('Mobile', value.values.phone)}
                       ${formingItem('Other', value.values.otherDetails)}
                       <div style='margin-top: 20px;'/>
                       ${boxItems(value.boxData)}
                        <div style='margin-top: 40px;'/>
                    `;

                case SelectEnum.Few:
                case SelectEnum.Furniture:
                case SelectEnum.Piano:
                case SelectEnum.Pool:
                case SelectEnum.Ebay:
                case SelectEnum.Fragile:
                    return `
                       <div style='margin-top: 40px;'/>
                     
                       ${formingItem('Pick-up', value.values.pickAddress)}
                          ${
                              value.values.pickEtc && value.values.pickEtc !== ''
                                  ? formingItem('Parking accessibility', value.values.pickEtc)
                                  : ''
                          }
                       ${formingItem('Drop-off', value.values.dropAddress)}
                           ${formingItem('Email', value.values.email)}
                       ${formingItem('When', value.values.when)}
                       ${formingItem('Date', convertValidateAndDate(value.values?.whenDate))}
                       ${formingItem('Mobile', value.values.phone)}
                       ${formingItem('Other', value.values.otherDetails)}
                       ${inventItems2(value.item)}
                       <div style='margin-top: 20px;'/>
                       ${boxItems(value.boxData)}
                        <div style='margin-top: 40px;'/>
                    `;
                case SelectEnum.Removal:
                    return `
                        <div style='margin-top: 40px;'/>
                       ${formingItem('Pick-up', value.values.pickAddress)}
                          ${
                              value.values.pickEtc && value.values.pickEtc !== ''
                                  ? formingItem('Parking accessibility', value.values.pickEtc)
                                  : ''
                          }
                       ${formingItem('Drop-off', value.values.dropAddress)}
                          ${
                              value.values.dropEtc && value.values.dropEtc !== ''
                                  ? formingItem('Parking accessibility', value.values.dropEtc)
                                  : ''
                          }
                              ${formingItem('Email', value.values.email)}
                       ${formingItem('When', value.values.when)}
                       ${formingItem('Date', convertValidateAndDate(value.values?.whenDate))}
                       ${formingItem('Mobile', value.values.phone)}
                       ${formingItem('Other', value.values.otherDetails)}
                       <div style='margin-top: 20px;'/>
                       ${boxItems(value.boxData)}
                       <div style='margin-top: 40px;'/>

                    `;
                case SelectEnum.Box:
                    return `
                       <div style='margin-top: 40px;'/>
                        ${formingItem('Address', value.values.pickAddress)}
                        ${formingItem('Detail address', value.values?.pickEtc ?? '')}
                          <div style='margin-top: 20px;'/>
                       ${boxItems(value.boxData)}
                       <div style='margin-top: 40px;'/>
                    `;
                default:
                    return '';
            }
        } else {
            return '';
        }
    };

    const formingItem = (title, content) => {
        return ` <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 20px 10px;'>
                    <div style='width: 30%;'>
                        <p style='font-weight: 600'>
                            ${title}
                        </p>
                    </div>
                    <div style='width: 70%;'>
                        <p style='font-weight: 600'>
                            ${content}
                        </p>
                    </div>
                </div>`;
    };
    const inventItems = (value: InventoryDataTypes) => {
        const objectArray = Object.entries(itemCheckMinus(value));
        let reHtml = '';
        for (const row of objectArray) {
            const [title, values] = row;
            const temp = `                
                        <div style='width: 70%; margin-bottom: 10px;'>
                            <p style='font-weight: 800;'>
                                ${title}
                            </p>
                            ${values.items.map((v) => itemInnerItems(v.title, v.value, v?.amount ?? 0))}
                        </div>
            `;

            reHtml += temp;
        }
        return ` <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 20px 10px;'>
                    <div style='width: 30%;'>
                        <p style='font-weight: 600'>
                            Inventory
                        </p>
                    </div>
                    <div style='width: 100%;'>
                            ${reHtml.replace(/\,/g, '')}
                            <div style='width: 100%;'>
                              <p style='font-weight: 600'>
                                    Total Cubic Volume: ${BoxItemSum(value)}
                               </p>
                            </div>
                    </div>
                </div>
                
`;
    };

    const itemCheckMinus = (v: InventoryDataTypes) => {
        const temp = { ...v };
        for (const row of Object.entries(temp)) {
            const items = row[1].items.filter((v) => v.value > 0);
            if (items.length === 0) {
                delete temp[row[0]];
            } else {
                temp[row[0]] = {
                    ...row[1],
                    items
                };
            }
        }
        return temp;
    };

    const itemInnerItems = (title, value, amount) => {
        if (value === 0) {
            return '';
        }
        return `
              <div class='initems' style='display:flex; padding-left: 30px;'>
                    <p style='font-weight: 600;'>
                        ${value} x
                    </p>
                    <p style='padding-left: 10px;'>
                        ${title}
                    </p>
                     <p style='padding-left: 10px; color: #ACACAC'>
                        - ${amount} m³
                    </p>
               </div>
        `;
    };
    const ivenvtItmesInner = (title, value) => {
        return `
               <div class='initems' style='display:flex; margin-bottom: 10px;'>
                    <p style='font-weight: 600;'>
                        ${title}
                    </p>
                    <p style='padding-left: 10px'>
                        ${value}
                    </p>
               </div>
        `;
    };
    const inventItems2 = (value: ItemDataTypes) => {
        const objectArray = [...value];
        let reHtml = '';
        for (const row of objectArray) {
            const { id, description, qty, length, width, height, unit, guess1, weight, guess2 } = row;
            const temp = `                
                        <div style='width: 70%;'>
                            ${ivenvtItmesInner('description', description)}
                            ${ivenvtItmesInner('width', width)}
                            ${ivenvtItmesInner('length', length)}
                            ${ivenvtItmesInner('height', height)}
                            ${ivenvtItmesInner('unit', unit)}
                            ${ivenvtItmesInner('weight', weight)}
                        </div>
            `;
            reHtml += temp;
        }
        return ` <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 20px 10px;'>
                    <div style='width: 30%;'>
                        <p style='font-weight: 600'>
                            Items
                        </p>
                    </div>
                    <div style='width: 70%;'>
                            ${reHtml}
                    </div>
                </div>`;
    };
    const totalAmountCalculator = (boxData) => {
        let total = 0;
        for (const row of boxData) {
            const add = row.qty * row.amount;
            total += add;
        }
        return total;
    };
    const boxItems = (value: BoxDataTypes) => {
        if (!value) {
            return '';
        }
        const total = totalAmountCalculator(value);
        let reHtml = '';
        const objectArray = value.filter((v) => v.qty > 0);
        for (const row of objectArray) {
            const temp = `                
                        <div style='width: 100%; display:flex; margin-top: 10px;'>
                         <div style='width: 40%; display:flex;'>
                            <p style='font-weight: 800;'>
                                ${row.title} x ${row.qty}
                            </p>
                         </div>
                         <div class='initems' style='width: 70%; display:flex;'>
                            <p style='font-weight: 600;'>
                                $ ${row.qty * row.amount}
                            </p>
                        </div>
                        </div>
                       
                        
            `;
            reHtml += temp;
        }

        return ` <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 20px 10px;'>
                    <div style='width: 40%;'>
                        <p style='font-weight: 600'>
                            Order
                        </p>
                    </div>
                    <div style='width: 70%;'>
                        <p style='font-weight: 600'>
                            TOTAL $ ${total}
                        </p>
                    </div>
                </div>
                <div style='width: 100%; display: flex; border-bottom: 1px solid #9e9e9e; padding: 0px 10px;'>
                    <div style='width: 100%; padding: 20px 0px;'>
                            ${reHtml}
                    </div>
                </div>
`;
    };
    return formItemRoot(rootValue);
};
