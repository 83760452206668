import { parse, isValid, format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { InventoryDataTypes } from '../const/form.data';

export function isValidDate(day) {
    const parsed = parse(`${day}`, 'P', new Date(), { locale: enGB });
    console.log('DAT', parsed);
    return isValid(parsed);
}

export function convertValidateAndDate(day) {
    const isVali = isValid(new Date(day));
    if (!isVali) {
        return '-';
    }

    return format(new Date(day), 'dd/MM/yyyy');
}
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function convertValidateAndDateFormat(day) {
    const isVali = isValid(new Date(day));
    console.log('is', day);
    if (!isVali) {
        return '-';
    }

    return format(new Date(day), 'dd / MM / yyyy');
}

export function BoxItemSum(items: InventoryDataTypes) {
    const item = { ...items };
    const array = Object.values(item);
    let number = 0;
    for (const row of array) {
        for (const rows of row.items) {
            const amount = rows.value > 0 ? rows.amount * rows.value : 0;
            number += amount;
        }
    }
    return `${number.toFixed(2)} m³`;
}

export function BoxItemSumRef(data: { title: string; value: number; amount: number }[]) {
    const item = [...data];
    let number = 0;
    for (const row of item) {
        const amount = row.value > 0 ? row.amount * row.value : 0;
        number += amount;
    }
    return `${number.toFixed(2)} m³`;
}

export const NumberRefry = (n: any) => {
    if (!n) {
        return 0;
    }
    if (Number.isNaN(n)) {
        return 0;
    }
    const numberChange = Number(n);
    if (Number.isNaN(numberChange)) {
        return 0;
    }
    return numberChange;
};

export const ExportOnlyNumber = (n: any) => {
    if (!n) {
        return 0;
    }
    const regex = /[^0-9]/g;
    const result = n.replace(regex, '');
    return NumberRefry(result);
};

export const AddSFunc = (n: any, s: string) => {
    if (!n) {
        return `${s}`;
    }
    const numberChange = Number(n);
    if (Number.isNaN(numberChange)) {
        return `${s}`;
    }
    if (numberChange > 1) {
        return `${s}s`;
    }
    return `${s}`;
};
