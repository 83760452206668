import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { InventoryData, InventoryDataTypes } from '../../const/form.data';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InventoryItem } from './inventory.item';
import { IconCheck, IconEmergencyBed } from '@tabler/icons';
import TextareaAutosize from 'react-textarea-autosize';
import { InventorySwitching } from '../../const/switch.string';
import { SelectEnum } from '../../types/select.types';
import { FastInputArea } from '../../componets/inputs/fast.input';
import { useFormikContext } from 'formik';
import { FormSchema } from './index';

interface Props {
    type: SelectEnum;
    formik: any;
    data: InventoryDataTypes;
    setData: Dispatch<SetStateAction<InventoryDataTypes>>;
    setInventOther: Dispatch<SetStateAction<string>>;
    inventOther: string;
}
export const InventoryForm = ({ type, data, setData, setInventOther, inventOther, formik }: Props) => {
    const objectData = InventorySwitching({ type, data });
    //console.log('In Invent From Data', objectData);
    const [expanded, setExpanded] = useState<string | boolean>(false);
    const rootFormik = useFormikContext<typeof FormSchema>();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const itemsSelectedFinder = (v: { title: string; value: number }[]) => {
        let finderValue = 0;
        const finderArray = [...v];
        for (const row of finderArray) {
            finderValue += row.value;
        }
        return finderValue;
    };

    const itemsSelectedFinderStringCom = (v: { title: string; value: number }[]) => {
        const vv = itemsSelectedFinder(v);
        if (vv <= 0) {
            return (
                <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                    <IconCheck width={'0px'} />
                    <Typography
                        sx={{ marginLeft: '10px', color: 'text.secondary', fontSize: { xs: '10px', md: '14px' } }}
                    >{`${0} Items Selected`}</Typography>
                </Box>
            );
        } else if (vv === 1) {
            return (
                <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                    <IconCheck width={'14px'} color={'green'} />
                    <Typography
                        sx={{ marginLeft: '10px', color: 'text.secondary', fontSize: { xs: '10px', md: '14px' } }}
                    >{`${vv} Item Selected`}</Typography>
                </Box>
            );
        } else {
            return (
                <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                    <IconCheck width={'14px'} color={'green'} />
                    <Typography
                        sx={{ marginLeft: '10px', color: 'text.secondary', fontSize: { xs: '10px', md: '14px' } }}
                    >{`${vv} Items Selected`}</Typography>
                </Box>
            );
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                '&:hover': {
                    boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                },
                transition: 'box-shadow 0.25s',
                transitionTimingFunction: 'ease',
                borderRadius: '8px',
                padding: '10px',
                paddingY: '40px'
            }}
        >
            <Box marginBottom={'40px'} width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' } }}>
                <Box display={'flex'} marginBottom={'20px'}>
                    <Typography variant={'h2'} color={'primary'}>
                        {`1. `}
                    </Typography>
                    <Typography variant={'h2'} paddingLeft={'8px'}>
                        Inventory
                    </Typography>
                </Box>
                <Typography variant={'h5'}>
                    Please choose the goods you want to move by clicking on each of the rooms or areas below.
                </Typography>
            </Box>
            <Box width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' }, maxWidth: '900px' }}>
                {objectData.map(([p, v], i) => {
                    const Icon = IconEmergencyBed;
                    return (
                        <Accordion key={`${i}_arcco`} expanded={expanded === `inven_${i}`} onChange={handleChange(`inven_${i}`)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '95%' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ flexShrink: 0, fontWeight: 600, fontSize: { xs: '12px', md: '16px' } }}>
                                            {v.title}
                                        </Typography>
                                    </Box>
                                    {itemsSelectedFinderStringCom(v.items ?? [])}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box width={'100%'}>
                                    {v.items?.map((_v, _i) => (
                                        <InventoryItem
                                            key={`form_${_i}${p}`}
                                            data={_v}
                                            setData={setData}
                                            index={_i}
                                            parent={p}
                                            parentItem={v}
                                        />
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
                <Box>
                    <Typography sx={{ fontWeight: 600, fontSize: '16px', marginY: '40px' }}>Extra items for the inventory</Typography>
                    <FastInputArea
                        id={'inventOther'}
                        minRows={3}
                        value={rootFormik.values.inventOther}
                        onHandleChange={rootFormik.setFieldValue}
                        placeholder="If you have any products that aren't in the inventory above, please list them here."
                        style={{
                            width: '100%',
                            fontSize: '16px',
                            fontFamily: "'Open Sans',sans-serif",
                            padding: '20px'
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};
