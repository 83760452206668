import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';

import ContainerLayout from '../../layout/container.layout';
import { SelectSwitchingTitle } from '../../const/switch.string';
import { SelectEnum, SelectTypeParamsType } from '../../types/select.types';
import { useNavigate, useParams } from 'react-router-dom';
import { InventoryData, ItemData, ItemDataTypes } from '../../const/form.data';
import { RootForm } from './root.form';
import * as uuid from 'uuid';
import { FocusError } from 'focus-formik-error';

export const FormSchema = {
    pickAddress: '',
    pickPostCode: '',
    pickType: '', // '' == 0
    pickRooms: '',
    pickOk: 'no',
    pickEtc: '',
    pickLat: '',
    pickLng: '',
    dropAddress: '',
    dropPostCode: '',
    dropType: '', // '' == 0
    dropEtc: '',
    dropRooms: '',
    dropOk: 'no',
    dropLat: '',
    dropLng: '',
    when: '', // '' == 0
    packing: '0',
    unpacking: '0',
    storage: '0',
    cleaning: '0',
    level: '1',
    levelText: 'Budget',
    otherDetails: '',
    expireIn: '1',
    email: '',
    whenDate: '',
    inventOther: ''
};
export const schema = {
    pickAddress: yup.string().required('Pick-up address is required'),
    pickPostCode: yup.string(),
    pickType: yup.string().required('Pick-up property type of bedrooms is required'),
    pickRooms: yup.string(),
    pickEtc: yup.string().required('Please select Parking accessibility'),
    pickOk: yup.string(),
    pickLat: yup.string(),
    pickLng: yup.string(),
    dropAddress: yup.string().required('Drop-off address is required'),
    dropPostCode: yup.string(),
    dropType: yup.string().required('Drop-off property type of bedrooms is required'),
    dropEtc: yup.string().required('Please select Parking accessibility'),
    dropRooms: yup.string(),
    dropOk: yup.string(),
    dropLat: yup.string(),
    dropLng: yup.string(),
    when: yup.string().required('when is required'),
    packing: yup.string(),
    unpacking: yup.string(),
    storage: yup.string(),
    cleaning: yup.string(),
    level: yup.string().required('Please specify desired level of service'),
    levelText: yup.string(),
    otherDetails: yup.string(),
    expireIn: yup.string(),
    email: yup.string(),
    whenDate: yup.string(),
    inventOther: yup.string()
};

const validationSchema = yup.object(schema);

const CategoryFormScreen = () => {
    const formik = useFormik({
        initialValues: FormSchema,
        validationSchema,
        validate: (values) => {},
        onSubmit: async (values) => {
            localSaveData();
        }
    });
    console.warn(formik.errors);
    const navigation = useNavigate();
    const { type } = useParams<SelectTypeParamsType>();
    const [formType, setFormType] = useState<SelectEnum>(SelectEnum.Local);
    const [invent, setInvent] = useState(InventoryData);
    const [item, setItem] = useState<ItemDataTypes>(ItemData);
    const [inventOther, setInventOther] = useState('');
    const checkHandler = () => {};

    const [submit, setSubmit] = useState(false);
    const [itemError, setItemError] = useState<any>({});
    const [pickError, setPickError] = useState({});
    const [dropError, setDropError] = useState({});
    const allSubmit = () => {
        setSubmit(true);
        formik.handleSubmit();
        setTimeout(() => {
            setSubmit(false);
        }, 4000);
    };

    const localSaveData = () => {
        try {
            const data = saveDataGenerator();
            console.log('DATA', data);
            localStorage.setItem(data.id, JSON.stringify(data));
            navigation(`/boxAdd/${data.id}`);
        } catch (e) {
            console.log(e);
            alert('error');
        }
    };

    const loadLocalSaveData = () => {
        try {
            const loadData = localStorage.getItem('a1fc265e-b4fa-40bd-8650-e269c24079c7');
            if (loadData) {
                const data = JSON.parse(loadData);
                setInvent({ ...data.inventory });
                setFormType(data.type);
                setItem([...data.item]);
                formik.setValues({ ...data.values });
            }
        } catch (e) {
            console.log('load Error');
        }
    };

    useEffect(() => {}, []);

    const saveDataGenerator = () => {
        const data = {
            id: uuid.v4(),
            type,
            inventory: invent,
            values: formik.values,
            item: [...item],
            inventOther: inventOther,
            createdAt: new Date()
        };
        return data;
    };

    useEffect(() => {
        setFormType(type);
    }, [type]);

    console.log('value', formik.values);
    console.log('error', formik.errors);
    console.log('touced', formik.touched);

    return (
        <ContainerLayout>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: { md: '80px 15px', xs: '30px 15px' }
                }}
            >
                <Typography variant={'h1'} marginBottom={'8px'}>
                    {SelectSwitchingTitle(type as any)}
                </Typography>
                <Typography variant={'h5'}>Simply respond to the questions below for quote</Typography>
                <FormikProvider value={formik}>
                    <FocusError formik={formik} />
                    <RootForm
                        formik={formik}
                        items={item}
                        setItem={setItem}
                        setItemError={setItemError}
                        itemError={itemError}
                        pickError={pickError}
                        setPickError={setPickError}
                        dropError={dropError}
                        setDropError={setDropError}
                        submit={submit}
                        allSubmit={allSubmit}
                        inventData={invent}
                        setInventData={setInvent}
                        type={formType}
                        inventOther={inventOther}
                        setInventOther={setInventOther}
                    />
                </FormikProvider>
            </Box>
        </ContainerLayout>
    );
};

export default CategoryFormScreen;
