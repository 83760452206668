import React from 'react';
import Lottie from 'react-lottie';
import LottieFile from 'assets/lottie/32459-phone-call.json';
import { useNavigate } from 'react-router-dom';
export const CopyHeader = () => {
    const navigation = useNavigate();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LottieFile,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div
            data-collapse="medium"
            data-animation="default"
            data-duration="400"
            data-easing="ease"
            data-easing2="ease"
            data-doc-height="1"
            role="banner"
            className="navbar w-nav"
        >
            <div className="container w-container">
                <a href="https://www.wecuberemovals.com.au/" aria-current="page" className="brand w-nav-brand w--current" />
                <nav role="navigation" className="nav-menu w-nav-menu">
                    <a href="https://www.wecuberemovals.com.au/about-us" className="nav-link w-nav-link">
                        About us
                    </a>
                    <a href="https://www.wecuberemovals.com.au/services" className="nav-link w-nav-link">
                        services
                    </a>
                    <a href="/" className="nav-link w-nav-link">
                        Get a quote
                    </a>
                    <a href="/boxPage" className="nav-link w-nav-link">
                        BOX &amp; packing materials
                    </a>
                    <div className="div-block-10">
                        <div
                            className="lottie-animation"
                            data-w-id="0d94df58-e89c-9610-e0a8-e4562ef43d54"
                            data-animation-type="lottie"
                            data-src="documents/32459-phone-call.json"
                            data-loop="1"
                            data-direction="1"
                            data-autoplay="1"
                            data-is-ix2-target="0"
                            data-renderer="svg"
                            data-default-duration="1.5"
                            data-duration="0"
                        >
                            <Lottie options={defaultOptions} />
                        </div>
                        <div className="div-block-11">
                            <div className="text-block-3">Call Us Now</div>
                            <a href="tel:0423690837" className="link-4">
                                0423 690 837
                            </a>
                        </div>
                    </div>
                </nav>
                <div className="menu-button w-nav-button">
                    <div className="icon w-icon-nav-menu"></div>
                </div>
            </div>
        </div>
    );
};
