import React from 'react';
import { Suspense, LazyExoticComponent, ComponentType } from 'react';

// project imports
import Loader, { LoaderProps } from './loadable.loader';
const Loadable = (Component: LazyExoticComponent<() => JSX.Element>) => (props: LoaderProps) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;
