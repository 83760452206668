import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
    Box,
    ButtonBase,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import { useFormik, useFormikContext, Formik } from 'formik';
import { FormSchema } from './index';
import { ItemData, ItemDataTypes } from '../../const/form.data';
import * as uuid from 'uuid';
import * as yup from 'yup';
import { IconMinus, IconPlus } from '@tabler/icons';
import { useDebounce } from '../../hooks/useDebounce';
const itemSchema = {
    items: yup.array().of(
        yup.object().shape({
            id: yup.string(),
            description: yup.string().required('description is required'),
            qty: yup.string().required('qty is required'),
            length: yup.string().required('length is required'),
            width: yup.string().required('width is required'),
            height: yup.string().required('height is required'),
            unit: yup.string().required('unit is required'),
            guess1: yup.string(),
            weight: yup.string().required('weight is required'),
            guess2: yup.string()
        })
    )
};
const itemValidationSchema = yup.object().shape(itemSchema);
const UnitMenu = [
    { label: 'cm', value: 'cm' },
    { label: 'm', value: 'm' },
    { label: 'ft', value: 'ft' }
];
interface Props {
    items: ItemDataTypes;
    setItem: Dispatch<SetStateAction<ItemDataTypes>>;
    submit: boolean;
    setItemError: Dispatch<SetStateAction<any>>;
}
export const ItemForm = ({ items, submit, setItem, setItemError }: Props) => {
    const itemFormik = useFormik({
        initialValues: { items },
        validationSchema: itemValidationSchema,
        validate: () => {
            console.log('dd');
        },
        onSubmit: async (values) => {
            const { items: itemUpdateValue } = values;
            setItem([...itemUpdateValue]);
        }
    });
    // useEffect(() => {
    //     itemFormik.setFieldValue('items', items);
    // }, [items]);
    useDebounce(
        () => {
            const { items: itemUpdateValue } = itemFormik.values;
            setItem([...itemUpdateValue]);
        },
        1000,
        [itemFormik.values.items]
    );
    // const handleChangeItem2 = () => {
    //     const { items: itemUpdateValue } = itemFormik.values;
    //     setItem([...itemUpdateValue]);
    // };
    useEffect(() => {
        if (submit) {
            setItemError({ ...itemFormik.errors });
            itemFormik.handleSubmit();
        }
    }, [submit]);
    console.log('item.formik', itemFormik.values);
    console.log('item.formik', itemFormik.touched);
    const okTouched = (value, index) => {
        const a = itemFormik.touched?.items ?? null;
        if (a && a[index]) {
            const t = a[index][value];
            if (t) {
                return true;
            }
            return false;
        }
        return false;
    };
    const okError = (value, index) => {
        const a = itemFormik.errors?.items ?? null;
        if (a && a[index]) {
            const t = a[index][value];
            if (t) {
                return t;
            }
            return false;
        }
        return false;
    };
    console.log(okError('description', 0));
    const handleChange = (type, event) => {
        itemFormik.setFieldValue(type, event.target.value);
    };
    const addItem = () => {
        const tempValue = itemFormik.values.items ?? [];
        itemFormik.setFieldValue(`items`, [
            ...tempValue,
            {
                id: uuid.v4(),
                description: '',
                qty: '1',
                length: '',
                width: '',
                height: '',
                unit: 'cm',
                guess1: '0',
                weight: '',
                guess2: '0'
            }
        ]);
    };
    const removeItem = (index) => {
        if (index === 0) {
            return;
        }
        const tempValue = itemFormik.values.items ?? [];
        const filterItems = tempValue.filter((v, i) => i !== index);
        itemFormik.setFieldValue(`items`, [...filterItems]);
    };
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                '&:hover': {
                    boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                },
                transition: 'box-shadow 0.25s',
                transitionTimingFunction: 'ease',
                borderRadius: '8px',
                padding: '10px',
                paddingY: '40px'
            }}
        >
            <Box marginBottom={'40px'} width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' } }}>
                <Box display={'flex'} marginBottom={'20px'}>
                    <Typography variant={'h2'} color={'primary'}>
                        {`1. `}
                    </Typography>
                    <Typography variant={'h2'} paddingLeft={'8px'}>
                        Item Details
                    </Typography>
                </Box>
                <Box marginTop={'20px'}>
                    <Box>
                        {itemFormik.values?.items &&
                            itemFormik.values?.items.length > 0 &&
                            itemFormik.values?.items?.map((v, _) => (
                                <Box>
                                    {_ > 0 && <Box width={'100%'} marginY={'60px'} borderBottom={'2px dashed red'} />}
                                    <Grid container columns={12} spacing={2}>
                                        <Grid item xs={8}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="Description_input" sx={{ fontSize: '16px' }}>
                                                    Description
                                                </InputLabel>
                                                <Input
                                                    id={`items[${_}].description`}
                                                    fullWidth
                                                    value={itemFormik.values.items[_].description}
                                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                                    onChange={itemFormik.handleChange}
                                                    onBlur={itemFormik.handleBlur}
                                                    error={okTouched('description', _) && okError('description', _)}
                                                />
                                                {okTouched('description', _) && okError('description', _) && (
                                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                                        {okTouched('description', _) && okError('description', _)}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="Qty_input" sx={{ fontSize: '16px' }}>
                                                    Qty
                                                </InputLabel>
                                                <Input
                                                    id={`items[${_}].qty`}
                                                    fullWidth
                                                    value={itemFormik.values.items[_].qty}
                                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                                    onChange={itemFormik.handleChange}
                                                    onBlur={itemFormik.handleBlur}
                                                    error={okTouched('qty', _) && okError('qty', _)}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                />
                                                {okTouched('qty', _) && okError('qty', _) && (
                                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                                        {okTouched('qty', _) && okError('qty', _)}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container columns={12} spacing={2} marginTop={'20px'}>
                                        <Grid item xs={3}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="Length_input" sx={{ fontSize: '16px' }}>
                                                    Length
                                                </InputLabel>
                                                <Input
                                                    id={`items[${_}].length`}
                                                    fullWidth
                                                    value={itemFormik.values.items[_].length}
                                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                                    onChange={itemFormik.handleChange}
                                                    onBlur={itemFormik.handleBlur}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    error={okTouched('length', _) && okError('length', _)}
                                                />
                                                {okTouched('length', _) && okError('length', _) && (
                                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                                        {okTouched('length', _) && okError('length', _)}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="Width_input" sx={{ fontSize: '16px' }}>
                                                    Width
                                                </InputLabel>
                                                <Input
                                                    id={`items[${_}].width`}
                                                    fullWidth
                                                    value={itemFormik.values.items[_].width}
                                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                                    onChange={itemFormik.handleChange}
                                                    onBlur={itemFormik.handleBlur}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    error={okTouched('width', _) && okError('width', _)}
                                                />
                                                {okTouched('width', _) && okError('width', _) && (
                                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                                        {okTouched('width', _) && okError('width', _)}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="Height_input" sx={{ fontSize: '16px' }}>
                                                    Height
                                                </InputLabel>
                                                <Input
                                                    id={`items[${_}].height`}
                                                    fullWidth
                                                    value={itemFormik.values.items[_].height}
                                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                                    onChange={itemFormik.handleChange}
                                                    onBlur={itemFormik.handleBlur}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    error={okTouched('height', _) && okError('height', _)}
                                                />
                                                {okTouched('height', _) && okError('height', _) && (
                                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                                        {okTouched('height', _) && okError('height', _)}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id={`items[${_}].unit`}>Unit</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id={`items[${_}].unit`}
                                                    value={itemFormik.values.items[_].unit}
                                                    label="Unit"
                                                    onChange={(e) => handleChange(`items[${_}].unit`, e)}
                                                    onBlur={itemFormik.handleBlur}
                                                    error={okTouched('unit', _) && okError('unit', _)}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                >
                                                    {UnitMenu.map((v, _) => (
                                                        <MenuItem value={v.value} sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                                                            {v.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ marginTop: '40px' }}>
                                        <FormControlLabel
                                            label="Dimensions are an estimate / guess only"
                                            control={
                                                <Checkbox
                                                    checked={itemFormik.values?.items[_]?.guess1 === '1'}
                                                    onChange={(e) =>
                                                        itemFormik.setFieldValue(`items[${_}].guess1`, e.currentTarget.checked ? '1' : '0')
                                                    }
                                                />
                                            }
                                        />
                                    </Box>
                                    <Grid container columns={12} spacing={2}>
                                        <Grid item xs={3}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="Length_input" sx={{ fontSize: '16px' }}>
                                                    Weight
                                                </InputLabel>
                                                <Input
                                                    id={`items[${_}].weight`}
                                                    fullWidth
                                                    value={itemFormik.values.items[_].weight}
                                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                                    onChange={itemFormik.handleChange}
                                                    onBlur={itemFormik.handleBlur}
                                                    endAdornment={<Typography>Kg</Typography>}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    error={okTouched('weight', _) && okError('weight', _)}
                                                />
                                                {okTouched('weight', _) && okError('weight', _) && (
                                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                                        {okTouched('weight', _) && okError('weight', _)}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ marginTop: '20px' }}>
                                        <FormControlLabel
                                            label="Weight is an estimate / guess only"
                                            control={
                                                <Checkbox
                                                    checked={itemFormik.values?.items[_]?.guess2 === '1'}
                                                    onChange={(e) =>
                                                        itemFormik.setFieldValue(`items[${_}].guess2`, e.currentTarget.checked ? '1' : '0')
                                                    }
                                                />
                                            }
                                        />
                                    </Box>
                                    {_ > 0 && (
                                        <Box marginTop={'40px'} marginBottom={'20px'} paddingBottom={'20px'}>
                                            <ButtonBase
                                                onClick={() => removeItem(_)}
                                                sx={{
                                                    backgroundColor: '#eeeeee',
                                                    borderRadius: '8px',
                                                    border: '2px solid #d0d0d0',
                                                    padding: '10px 20px'
                                                }}
                                            >
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <IconMinus />
                                                    <Typography sx={{ marginLeft: '10px', fontSize: { xs: '12px', md: '14px' } }}>
                                                        Remove Item
                                                    </Typography>
                                                </Box>
                                            </ButtonBase>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        <Box marginTop={'40px'}>
                            <ButtonBase
                                onClick={() => addItem()}
                                sx={{ backgroundColor: '#eeeeee', borderRadius: '8px', border: '2px solid #d0d0d0', padding: '10px 20px' }}
                            >
                                <Box display={'flex'} alignItems={'center'}>
                                    <IconPlus />
                                    <Typography sx={{ marginLeft: '10px', fontSize: { xs: '12px', md: '14px' } }}>
                                        Add Another Item
                                    </Typography>
                                </Box>
                            </ButtonBase>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
