import React from 'react';
import { Box } from '@mui/system';
interface Props {
    children: React.ReactElement;
}
const ContainerLayout = ({ children }: Props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                minHeight: '80vh',
                backgroundColor: '#ffffff',
                padding: {
                    xs: '15px 0'
                }
            }}
        >
            {children}
        </Box>
    );
};

export default ContainerLayout;
