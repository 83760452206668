import React, { useEffect, useState } from 'react';
import {
    Box,
    ButtonBase,
    CircularProgress,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { FormSchema } from '../categoryForm';
import { useNavigate, useParams } from 'react-router-dom';
import { FormIdKey, SelectTypeParamsType } from '../../types/select.types';
import ContainerLayout from '../../layout/container.layout';
import { SelectSwitchingTitle } from '../../const/switch.string';
import { IconEye, IconEyeOff } from '@tabler/icons';
import { values } from 'lodash';
import { collection, deleteField, doc, getDoc, getFirestore, setDoc, Timestamp, updateDoc } from '@firebase/firestore';
import firebase, { dbService } from '../../utils/firebase';
import { useDocument } from 'react-firebase-hooks/firestore';
import { dataToHtml } from '../emailForm/dataToHtml';
import emailjs from '@emailjs/browser';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
const informationSchema = {
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    password: yup.string(),
    phone: yup.string().required('phone is required'),
    pickAddress: yup.string().required('address is required'),
    pickEtc: yup.string(),
    pickLat: yup.string(),
    pickLng: yup.string(),
    pickPostCode: yup.string()
};
const informationInit = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    pickAddress: '',
    pickEtc: '',
    pickLat: '',
    pickLng: '',
    pickPostCode: ''
};
const validationSchema = yup.object(informationSchema);
const InformationWithAddress = () => {
    const formik = useFormik({
        initialValues: informationInit,
        validationSchema,
        validate: () => {
            console.log('dd');
        },
        onSubmit: async (values) => {
            await combineLocalData();
        }
    });

    const { key } = useParams<FormIdKey>();
    const naviagtion = useNavigate();
    const saveData = async (data) => {
        setLoading(true);
        const ref = doc(dbService, 'quotes', data.id);
        setDoc(ref, data, { merge: true })
            .then(() => {
                console.log('Successful');
                const html = dataToHtml(data);
                console.log('HTML', html);
                emailjs.send(
                    'DEFAULT',
                    'template_tejj7f9',
                    {
                        name: data.values.firstName,
                        key: data.id,
                        reply_to: 'info@wecuberemovals.com.au',
                        from: 'info@wecuberemovals.com.au',
                        to: data.values.email,
                        html
                    },
                    'CyHPstdp2khyLVcw0'
                );
                localStorage.removeItem(data.id);
                naviagtion(`/done/${key}`, { replace: true });
            })
            .catch((error) => {
                console.log(`Unsuccessful returned error ${error}`);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const loadLocalSaveData = () => {
        try {
            const loadData = localStorage.getItem(key);
            if (loadData) {
                const data = JSON.parse(loadData);
                const formikData = { ...data.values };
                formik.setFieldValue('email', formikData.email);
            }
        } catch (e) {
            console.log('load Error');
        }
    };
    useEffect(() => {
        loadLocalSaveData();
    }, [key]);
    // const [value, l, error] = useDocument(doc(getFirestore(firebase), 'quotes', key), {
    //     snapshotListenOptions: { includeMetadataChanges: true }
    // });
    // console.log('VLAUE', value.data());

    const combineLocalData = async () => {
        const loadData = localStorage.getItem(key);
        if (loadData) {
            const data = JSON.parse(loadData);
            const formikData = { ...data.values, ...formik.values };
            const status = [{ status: '1', content: '' }];
            const combine = { ...data, values: formikData, status };
            console.log('Combie', combine);
            await saveData(combine);
        }
    };

    const [passwordView, setPasswordView] = useState(false);
    const [loading, setLoading] = useState(false);
    const allSubmit = () => {
        formik.handleSubmit();
    };
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
            types: [],
            language: 'en-AU',
            componentRestrictions: { country: 'AU' }
        },
        debounce: 300
    });
    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const handleSelect = ({ description }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        console.log(description);
        setLoading(true);
        getGeocode({ address: description })
            .then((results) => {
                const { lat, lng } = getLatLng(results[0]);
                console.log('📍 Coordinates: ', results);
                formattingAddress(results[0], description, lat, lng);
                setValue(description, false);
                clearSuggestions();
            })
            .finally(() => {
                setLoading(false);
            });

        // Get latitude and longitude via utility functions
    };
    const formattingAddress = (a: any, ad, la, ln) => {
        const { address_components, formatted_address } = a;
        if (!address_components) {
        }
        let addressString = '';
        let moreString = '';
        let postcode = '';
        const addressStreet = [];
        const componentForm = {
            street_number: ['short_name', 'main', 'street'],
            route: ['short_name', 'main', 'street'],
            locality: ['short_name', 'main', ''],
            sublocality_level_1: ['short_name', 'main', ''],
            administrative_area_level_1: ['short_name', 'more', ''],
            administrative_area_level_2: ['short_name', 'more', ''],
            postal_code: ['short_name', 'no', '']
        };
        for (let i = 0; i < address_components.length; i++) {
            // warning this only gets the first type of the component. E.g. 'sublocality_level_1'
            const addressType = address_components[i].types[0];
            if (componentForm[addressType]) {
                const [addressText, type, street] = componentForm[addressType];
                const val = address_components[i][addressText];
                if (street === 'street') {
                    // @ts-ignore
                    addressStreet.push(val);
                }
                if (type === 'main') {
                    addressString += '' + val + ' ';
                }
                if (type === 'more') {
                    moreString += '' + val + ' ';
                }
                if (type === 'no') {
                    postcode += val;
                }
            }
        }
        const formatting = {
            route: addressStreet.slice(0, 2).join(' '),
            address: addressString.replace(/,\s*$/, ''),
            addressMore: moreString.replace(/,\s*$/, ''),
            postcode
        };
        formik.setFieldValue('pickAddress', formatting.address + formatting.addressMore);
        formik.setFieldValue('pickPostCode', postcode);
        formik.setFieldValue('pickLat', la);
        formik.setFieldValue('pickLng', ln);

        // setFieldValue(ok, 'ok');
        // setTemp(ad);
        // setFieldValue(address, ad as any);
        // setFieldValue(postCode, postcodeVal as any);
        // setFieldValue(fLat, la as any);
        // setFieldValue(fLng, ln as any);

        // await formik.setFieldValue(postCode as any, postcodeVal as any);
    };

    const renderSuggestions = () =>
        data.map((suggestion, i) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text }
            } = suggestion;
            return (
                <ButtonBase
                    key={place_id}
                    sx={{ width: '100%', padding: '10px 0', overflow: 'hidden', maxHeight: '40px' }}
                    onClick={handleSelect(suggestion)}
                >
                    <Box display={'flex'} width={'100%'} overflow={'hidden'} maxHeight={'40px'} flexWrap={'nowrap'}>
                        <Typography
                            sx={{ fontSize: { xs: '10px', md: '16px' } }}
                            overflow={'hidden'}
                            maxHeight={'40px'}
                            textOverflow={'hidden'}
                            whiteSpace={'nowrap'}
                        >
                            {main_text}
                            <small style={{ marginLeft: '5px' }}>{secondary_text}</small>
                        </Typography>
                    </Box>
                </ButtonBase>
            );
        });
    console.log('value', formik.values);
    return (
        <ContainerLayout>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: { md: '80px 15px', xs: '30px 15px' }
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '900px',
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                        '&:hover': {
                            boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                        },
                        transition: 'box-shadow 0.25s',
                        transitionTimingFunction: 'ease',
                        borderRadius: '8px',
                        padding: '10px',
                        paddingY: '40px'
                    }}
                >
                    <Box marginBottom={'40px'} width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' } }}>
                        <Box display={'flex'} marginBottom={'20px'}>
                            <Typography variant={'h2'} color={'primary'}>
                                {`Almost Done...`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' }, maxWidth: '900px' }}>
                        <Box sx={{ marginBottom: '20px' }}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="firstName_input" sx={{ fontSize: '16px' }}>
                                    First Name
                                </InputLabel>
                                <Input
                                    id={'firstName'}
                                    fullWidth
                                    value={formik.values.firstName}
                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.firstName && Boolean(formik.errors.firstName) && (
                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                        {formik.touched.firstName && formik.errors.firstName}
                                    </FormHelperText>
                                )}
                                {/*{formik.touched.firstName && Boolean(formik.errors.firstName) ? (*/}
                                {/*    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>*/}
                                {/*        {formik.touched.firstName && formik.errors.firstName}*/}
                                {/*    </FormHelperText>*/}
                                {/*) : (*/}
                                {/*    <FormHelperText id="component-helper-text">type 2 or more charactors and select</FormHelperText>*/}
                                {/*)}*/}
                            </FormControl>
                        </Box>

                        <Box sx={{ marginBottom: '20px' }}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="firstName_input" sx={{ fontSize: '16px' }}>
                                    Last name
                                </InputLabel>
                                <Input
                                    id={'lastName'}
                                    fullWidth
                                    value={formik.values.lastName}
                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.lastName && Boolean(formik.errors.lastName) && (
                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                        {formik.touched.lastName && formik.errors.lastName}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box sx={{ marginBottom: '20px' }}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="firstName_input" sx={{ fontSize: '16px' }}>
                                    Email
                                </InputLabel>
                                <Input
                                    id={'email'}
                                    fullWidth
                                    value={formik.values.email}
                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.email && Boolean(formik.errors.email) && (
                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                        {formik.touched.email && formik.errors.email}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>

                        <Box sx={{ marginBottom: '20px' }}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="firstName_input" sx={{ fontSize: '16px' }}>
                                    Phone
                                </InputLabel>
                                <Input
                                    id={'phone'}
                                    fullWidth
                                    value={formik.values.phone}
                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.phone && Boolean(formik.errors.phone) ? (
                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                        {formik.touched.phone && formik.errors.phone}
                                    </FormHelperText>
                                ) : (
                                    <FormHelperText id="component-helper-text">
                                        Only shared with providers who have quoted on your job
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box sx={{ marginBottom: '20px' }} ref={ref}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="firstName_pickAddress" sx={{ fontSize: '16px' }}>
                                    Your Address
                                </InputLabel>
                                <Input
                                    id={'pickAddress'}
                                    fullWidth
                                    // onFocus={() => setFieldValue(ok, 'no')}
                                    value={formik.values.pickAddress}
                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.pickAddress && Boolean(formik.errors.pickAddress)}
                                    onChange={(e) => {
                                        formik.setFieldValue('pickAddress', e.currentTarget.value);
                                        setValue(e.currentTarget.value);
                                    }}
                                />
                                {formik.touched.pickAddress && Boolean(formik.errors.pickAddress) && (
                                    <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>
                                        {formik.touched.pickAddress && formik.errors.pickAddress}
                                    </FormHelperText>
                                )}
                                {status === 'OK' && <Box>{renderSuggestions()}</Box>}
                            </FormControl>
                        </Box>
                        <Box sx={{ marginBottom: '20px' }} ref={ref}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="firstName_pickEtc" sx={{ fontSize: '16px' }}>
                                    Detail address
                                </InputLabel>
                                <Input
                                    id={'pickEtc'}
                                    fullWidth
                                    // onFocus={() => setFieldValue(ok, 'no')}
                                    value={formik.values.pickEtc}
                                    sx={{ fontSize: { xs: '12px', md: '16px' } }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.pickEtc && Boolean(formik.errors.pickEtc)}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Box>
                    </Box>

                    <Box marginTop={'20px'}>
                        <ButtonBase
                            sx={{ borderRadius: '8px', backgroundColor: '#428DFF', minWidth: '200px', padding: '10px 20px' }}
                            onClick={() => allSubmit()}
                        >
                            {loading ? (
                                <Typography sx={{ color: 'white', fontSize: { xs: '16px', md: '22px' } }}>
                                    {' '}
                                    <CircularProgress color="inherit" size={16} />
                                </Typography>
                            ) : (
                                <Typography sx={{ color: 'white', fontSize: { xs: '16px', md: '22px' } }}>
                                    Complete Quote Request
                                </Typography>
                            )}
                        </ButtonBase>
                    </Box>
                </Box>
            </Box>
        </ContainerLayout>
    );
};

export default InformationWithAddress;
