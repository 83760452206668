import React from 'react';
import { AppBar, Box, CssBaseline, Grid, styled, Theme, Toolbar, useTheme } from '@mui/material';
import MainHeader from './header/main.header';
import { Outlet } from 'react-router-dom';
import { CopyHeader } from './header/copy.header';
import { CopyFooter } from './footer/copy.footer';
interface MainStyleProps {
    theme: Theme;
}
const Main = styled('main')(({ theme }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    backgroundColor: '#fff',
    ...{
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            width: `calc(100%)`
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100%)`
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100%)`
        },
        padding: 0,
        marginRight: 0,
        minHeight: '80vh'
    }
}));

const Sub = styled('main')(({ theme }: MainStyleProps) => ({
    ...theme.typography.subContent,
    backgroundColor: '#fff',
    ...{
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            width: `calc(100%)`
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100%)`
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100%)`
        },
        padding: 0,
        marginRight: 0
    }
}));

export const MainLayout = () => {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default
                }}
            >
                <CopyHeader />
            </AppBar>
            <Main theme={theme}>
                <Outlet />
            </Main>

            <CopyFooter />
        </Box>
    );
};

export const EmbeddedLayout = () => {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
            <Sub theme={theme}>
                <Outlet />
            </Sub>
        </Box>
    );
};
