import { SelectEnum } from '../types/select.types';
import { IconHome2 } from '@tabler/icons';
import { FewIcon, InterStateIcon, MovingIcon, OfficeIcon, SingeIcon } from '../assets/icons/Local.icon';
import { InventoryDataTypes } from './form.data';

export const SelectSwitching = (t: SelectEnum) => {
    switch (t) {
        case SelectEnum.Local:
            return 'Moving Local';
        case SelectEnum.InterState:
            return 'Moving Interstate';
        case SelectEnum.Office:
            return 'Moving Office';
        case SelectEnum.Single:
            return 'Moving International';
        case SelectEnum.Few:
            return 'Storage services';
        case SelectEnum.Furniture:
            return 'Moving Furniture Items';
        case SelectEnum.Piano:
            return 'Moving a Piano';
        case SelectEnum.Pool:
            return 'Moving as Pool Table';
        case SelectEnum.Ebay:
            return 'Gumtree/Ebay Pickups';
        case SelectEnum.Fragile:
            return 'Moving Fragile Items';
        case SelectEnum.Removal:
            return 'Rubbish Removal';

        default:
            return 'no';
    }
};

interface Props {
    type: SelectEnum;
}
export const SelectSwitchingIcon = ({ type }: Props) => {
    switch (type) {
        case SelectEnum.Local:
            return MovingIcon;
        case SelectEnum.InterState:
            return InterStateIcon;
        case SelectEnum.Office:
            return OfficeIcon;
        case SelectEnum.Single:
            return SingeIcon;
        case SelectEnum.Few:
            return FewIcon;
        case SelectEnum.Furniture:
        case SelectEnum.Piano:
        case SelectEnum.Pool:
        case SelectEnum.Ebay:
        case SelectEnum.Fragile:
        case SelectEnum.Removal:
        default:
            return IconHome2;
    }
};

interface IInventorySwitchingProps {
    type: SelectEnum;
    data: InventoryDataTypes;
}
export const InventorySwitching = ({ type, data }: IInventorySwitchingProps) => {
    return Object.entries(data).filter(([key, value]) => (type === SelectEnum.Office ? key === 'office' : key !== 'office'));
};

export const SelectSwitchingTitle = (t: SelectEnum) => {
    switch (t) {
        case SelectEnum.Local:
            return 'Get Moving Local Quote';
        case SelectEnum.InterState:
            return 'Get Interstate Removals Quote';
        case SelectEnum.Office:
            return 'Get Office Removals Quote';
        case SelectEnum.Single:
            return 'Get International Removals Quote';
        case SelectEnum.Few:
            return 'Get Items Delivery Quote';
        case SelectEnum.Furniture:
            return 'Get Furniture Removalist Quotes';
        case SelectEnum.Piano:
            return 'Get Piano Removal Quotes';
        case SelectEnum.Pool:
            return 'Get Pool Table Removal Quotes';
        case SelectEnum.Ebay:
            return 'Get Gumtree / Ebay Delivery Quotes';
        case SelectEnum.Fragile:
            return 'Get Fragile Item Transport Quotes';
        case SelectEnum.Removal:
            return 'Get Rubbish Removal Quotes';

        default:
            return 'no';
    }
};
