import { useNavigate } from 'react-router-dom';

// project imports
import { GuardProps } from '../types/guard.types';
import { useEffect } from 'react';

const GuestGuard = ({ children }: GuardProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        // if (isLoggedIn) {
        //     navigate(config.defaultPath, { replace: true });
        // }
    }, [navigate]);

    return children;
};

export default GuestGuard;
