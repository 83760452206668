import { lazy } from 'react';
import GuestGuard from '../route.guard/guest.guard';
import { EmbeddedLayout, MainLayout } from '../layout/main.layout';
import Loadable from '../componets/loadable/loadable';
import EmailForm from '../containers/emailForm';
import TempDownload from '../componets/download/temp.download';
import BoxPage from '../containers/boxForm/box.page';
import InformationWithAddress from '../containers/informationForm/informatin.with.address';
import { CrawlingPage } from '../containers/crawling/crawling.page';
import { Box } from '@mui/material';
import PrivateScreen from '../containers/privated';

// page routing
const MainScreen = Loadable(lazy(() => import('containers/common/main.screen')));
const SelectScreen = Loadable(lazy(() => import('containers/selectScreen')));
const CategoryFormScreen = Loadable(lazy(() => import('containers/categoryForm')));
const InformationAddScreen = Loadable(lazy(() => import('containers/informationForm')));
const DoneScreen = Loadable(lazy(() => import('containers/informationForm/done.screen')));
const DashBoardScreen = Loadable(lazy(() => import('containers/dashboard')));
const BoxScreen = Loadable(lazy(() => import('containers/boxForm')));

// import MainScreen from '../containers/common/main.screen';
export const MainRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <MainLayout />
        </GuestGuard>
    ),
    children: [
        {
            path: '/select',
            element: <SelectScreen />
        },
        {
            path: '/',
            element: <SelectScreen />
        },
        {
            path: '/new/:type',
            element: <CategoryFormScreen />
        },
        {
            path: '/boxAdd/:key',
            element: <BoxScreen />
        },
        {
            path: '/boxPage/',
            element: <BoxPage />
        },
        {
            path: '/informationAdd/:key',
            element: <InformationAddScreen />
        },
        {
            path: '/informationBox/:key',
            element: <InformationWithAddress />
        },
        {
            path: '/done/:key',
            element: <DoneScreen />
        },
        {
            path: '/dashboard/:key',
            element: <DashBoardScreen />
        },
        {
            path: '/3',
            element: <EmailForm />
        },
        {
            path: '/4',
            element: <TempDownload />
        },
        {
            path: '/private',
            element: <PrivateScreen />
        }
    ]
};
export const EmbeddedRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <EmbeddedLayout />
        </GuestGuard>
    ),
    children: [
        {
            path: '/embedded/review',
            element: <CrawlingPage />
        }
    ]
};
