import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useFormik, useFormikContext } from 'formik';
import { FormSchema, schema } from './index';
import * as yup from 'yup';
import TextareaAutosize from 'react-textarea-autosize';
import { FastInput, FastInputArea } from '../../componets/inputs/fast.input';

interface Props {
    submit: boolean;
    indexNumber: number;
}

const otherMenu = [
    { label: '1 day', value: '1' },
    { label: '3 days', value: '3' },
    { label: '5 days', value: '5' },
    { label: '7 days', value: '7' },
    { label: '14 days', value: '14' },
    { label: '30 days', value: '30' }
];
const otherInit = {
    otherDetails: '',
    expireIn: '1',
    email: ''
};
const otherV = {
    otherDetails: yup.string(),
    expireIn: yup.string(),
    email: yup.string().email('Enter a valid email').required('Email is required')
};
export const OtherForm = ({ indexNumber, submit }: Props) => {
    const formik = useFormik({
        initialValues: otherInit,
        validationSchema: yup.object(otherV),
        validate: (v) => {
            handleSubmitArray(v);
        },
        onSubmit: async (values) => {}
    });
    const { values, setFieldValue, errors, handleChange, handleBlur, touched, setValues } = useFormikContext<typeof FormSchema>();
    const handleSubmitArray = (v) => {
        setFieldValue('expireIn', v.expireIn);
        setFieldValue('email', v.email);
    };
    const handleSubmitArray2 = () => {
        setFieldValue('expireIn', formik.values.expireIn);
        setFieldValue('email', formik.values.email);
        setFieldValue('otherDetails', details);
    };
    useEffect(() => {}, [submit]);
    const [details, setDetails] = useState('');
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                '&:hover': {
                    boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                },
                transition: 'box-shadow 0.25s',
                transitionTimingFunction: 'ease',
                borderRadius: '8px',
                padding: '10px',
                paddingY: '40px'
            }}
        >
            <Box marginBottom={'40px'} width={'100%'} sx={{ padding: { md: '0px 80px', xs: '0px 5px' } }}>
                <Box display={'flex'} marginBottom={'20px'}>
                    <Typography variant={'h2'} color={'primary'}>
                        {`${indexNumber}. `}
                    </Typography>
                    <Typography variant={'h2'} paddingLeft={'8px'}>
                        {indexNumber === 3 ? 'Job Details' : 'Other Details'}
                    </Typography>
                </Box>
                <Box marginBottom={'20px'}>
                    <Typography variant={'h5'} marginBottom={'20px'}>
                        {indexNumber === 3 ? 'Describe the rubbish that you need removed' : 'Any other important details'}
                    </Typography>
                    <FastInputArea value={details} id={'otherDetails'} onHandleChange={setFieldValue} />
                </Box>
                {/*<Box marginBottom={'20px'}>*/}
                {/*    <FormControl fullWidth sx={{ marginTop: '0px' }}>*/}
                {/*        <InputLabel id="whenId">Quote request to expire in</InputLabel>*/}
                {/*        <Select*/}
                {/*            labelId="Quote request to expire in"*/}
                {/*            id="expireIn"*/}
                {/*            value={formik.values.expireIn}*/}
                {/*            onBlur={formik.handleBlur}*/}
                {/*            error={formik.touched.expireIn && Boolean(formik.errors.expireIn)}*/}
                {/*            label="Quote request to expire in"*/}
                {/*            onChange={(e) => formik.setFieldValue('expireIn', e.target.value)}*/}
                {/*        >*/}
                {/*            {otherMenu.map((v, _) => (*/}
                {/*                <MenuItem value={v.value} sx={{ fontSize: { xs: '12px', md: '16px' } }}>*/}
                {/*                    {v.label}*/}
                {/*                </MenuItem>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*    {formik.touched.expireIn && Boolean(formik.errors.expireIn) && (*/}
                {/*        <FormHelperText id="component-helper-text222" sx={{ color: 'red' }}>*/}
                {/*            {formik.touched.expireIn && formik.errors.expireIn}*/}
                {/*        </FormHelperText>*/}
                {/*    )}*/}
                {/*</Box>*/}
                {/*<Box marginBottom={'20px'}>*/}
                {/*    <FormControl variant="standard" fullWidth>*/}
                {/*        <InputLabel htmlFor="address_input" sx={{ fontSize: '16px' }}>*/}
                {/*            Your Email Address*/}
                {/*        </InputLabel>*/}
                {/*        <FastInput*/}
                {/*            id={'email'}*/}
                {/*            value={values.email}*/}
                {/*            sx={{ fontSize: { xs: '12px', md: '16px' } }}*/}
                {/*            onBlur={handleBlur}*/}
                {/*            error={touched.email && Boolean(errors.email)}*/}
                {/*            onHandleChange={setFieldValue}*/}
                {/*        />*/}
                {/*        {touched.email && Boolean(errors.email) && (*/}
                {/*            <FormHelperText id="component-helper-text" sx={{ color: 'red' }}>*/}
                {/*                {touched.email && errors.email}*/}
                {/*            </FormHelperText>*/}
                {/*        )}*/}
                {/*    </FormControl>*/}
                {/*</Box>*/}
            </Box>
        </Box>
    );
};
