import React from 'react';
import { BoxDataTypes } from '../../const/form.data';
import { Box, ButtonBase, Grid, InputBase, Typography } from '@mui/material';
import { Img } from 'react-image';
import { IconMinus, IconPlus } from '@tabler/icons';

interface Props {
    data: {
        title: string;
        content: string;
        image: any;
        qty: number;
        amount: number;
    };
    handlerQtyChange: (type: 'up' | 'down', i: number) => void;
    index: number;
}
export const BoxItem = ({ data, index, handlerQtyChange }: Props) => {
    return (
        <Grid item xs={2} sm={2} md={3} lg={3} key={index} onClick={() => null}>
            <Box
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: { xs: '170px', md: '170px' },
                    boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                    '&:hover': {
                        boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                    },
                    transition: 'box-shadow 0.25s',
                    transitionTimingFunction: 'ease',
                    borderRadius: '8px',
                    padding: '10px',
                    paddingX: '5px',
                    cursor: 'pointer'
                }}
            >
                <Typography fontSize={'14px'} lineHeight={'1.5'} fontWeight={600} color={'#333'} textAlign={'center'}>
                    {data.title}
                </Typography>
                <Typography fontSize={'12px'} lineHeight={'1.5'} fontWeight={600} color={'#333'} textAlign={'center'}>
                    {data.content}
                </Typography>
                <Box
                    onClick={() => handlerQtyChange('up', index)}
                    sx={{
                        padding: { xs: '16px' }
                    }}
                >
                    <Img src={data.image} />
                    <Box>
                        <Typography fontSize={'16px'} lineHeight={'1.5'} fontWeight={600} color={'#333'} textAlign={'center'}>
                            {`$${data.amount.toFixed(1)}/each`}
                        </Typography>
                    </Box>
                </Box>

                {data.qty > 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%' }, justifyContent: 'center' }}>
                        <ButtonBase
                            onClick={() => handlerQtyChange('down', index)}
                            sx={{
                                padding: { xs: '8px 15px', md: '8px 15px' },
                                backgroundColor: '#f6f6f6',
                                border: '2px solid #e5e5e5 !important',
                                '&:hover': {
                                    backgroundColor: '#eeeeee',
                                    border: '2px solid #d0d0d0 !important'
                                },
                                borderRadius: '8px 0 0 8px'
                            }}
                        >
                            <IconMinus width={'16px'} />
                        </ButtonBase>
                        <Box
                            sx={{
                                padding: { xs: '10px 15px', md: '10px 15px' },
                                fontSize: { xs: '16px', md: '16px' },
                                fontWeight: 600,
                                textAlign: 'center',
                                backgroundColor: '#f6f6f6',
                                borderTop: '2px solid #e5e5e5',
                                borderBottom: '2px solid #e5e5e5'
                            }}
                        >
                            {data.qty}
                        </Box>
                        <ButtonBase
                            onClick={() => handlerQtyChange('up', index)}
                            sx={{
                                padding: { xs: '8px 15px', md: '8px 15px' },
                                backgroundColor: '#f6f6f6',
                                border: '2px solid #e5e5e5 !important',
                                '&:hover': {
                                    backgroundColor: '#eeeeee',
                                    border: '2px solid #d0d0d0 !important'
                                },
                                borderRadius: '0 8px 8px 0'
                            }}
                        >
                            <IconPlus width={'16px'} />
                        </ButtonBase>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%' }, justifyContent: 'center' }}>
                        <Box
                            onClick={() => handlerQtyChange('up', index)}
                            sx={{
                                padding: { xs: '10px 21px', md: '10px 21px' },
                                fontSize: { xs: '16px', md: '16px' },
                                fontWeight: 600,
                                textAlign: 'center',
                                backgroundColor: '#f6f6f6',
                                border: '2px solid #e5e5e5',
                                borderRadius: '8px',
                                cursor: 'pointer'
                            }}
                        >
                            {'Add to Cart'}
                        </Box>
                    </Box>
                )}
            </Box>
        </Grid>
    );
};
