import { createTheme, ThemeOptions, Theme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';
import colorTheme from 'assets/scss/_theme.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import customShadows from './shadows';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = () => {
    const themeOption = {
        colors: colorTheme,
        heading: '',
        paper: '',
        backgroundDefault: '',
        background: '',
        darkTextPrimary: '',
        darkTextSecondary: '',
        textDark: '',
        menuSelected: '',
        menuSelectedBack: '',
        divider: ''
    };

    themeOption.paper = colorTheme.paper;
    themeOption.backgroundDefault = colorTheme.paper;
    themeOption.background = colorTheme.primaryLight;
    themeOption.darkTextPrimary = colorTheme.grey700;
    themeOption.darkTextSecondary = colorTheme.grey500;
    themeOption.textDark = colorTheme.grey900;
    themeOption.menuSelected = colorTheme.secondaryDark;
    themeOption.menuSelectedBack = colorTheme.secondaryLight;
    themeOption.divider = colorTheme.grey200;
    themeOption.heading = colorTheme.grey900;

    const themeOptions: ThemeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(themeOption),
        breakpoints: {
            values: {
                xs: 0,
                smm: 425,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536
            }
        }
    };

    const themes: Theme = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
